import React, { Fragment } from "react";

let BannerImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/amazon_banner.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/seo_amazon_logo.png",
];
function AmazonPriceBanner() {
  return (
    <Fragment>
      <section className="banner_sction amazon_banner">
        <figure className="banner_figure amazon_banner_figure">
          <img src={BannerImgs[0]} alt="img" width="100%" />
          <figcaption className="banner_caption amazon_banner_caption">
            <div className="container">
              <img src={BannerImgs[1]} alt="img" className="banner_amzon_img" />
            </div>
          </figcaption>
        </figure>
      </section>
    </Fragment>
  );
}

export default AmazonPriceBanner;
