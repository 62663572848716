import React from "react";
// import CancellationPolicyImgs from "../../ImagesComponents/all-images/seo_traffic_bg.jpg";
let CancellationPolicyImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/seo_traffic_bg.jpg",
];
const CancellationPolicy = () => {
  return (
    <>
      <section className="cancellation_policy_section">
        <figure className="policy_figure">
          <img src={CancellationPolicyImgs[0]} alt="img" width="100%" />
        </figure>
        <div className="policy_detail text-center">
          <div className="container">
            <h2>Cancellation And Refund Policy</h2>
            <p>
              EZ Marketing Tech privacy policy covers the collection and use of
              personal information that may be collected by EZ Marketing Tech
              anytime you interact with EZ Marketing Tech such as when you
              purchase an EZ Marketing Tech product, contact a technical support
              or sales associate, sign up for an e-newsletter or request a
              download of demonstration software.{" "}
            </p>{" "}
            <p>
              We collect your personal information because it assists us in
              delivering a high level of customer service. For example, your
              personal information helps us to keep you informed and up-to-date
              on the latest product updates and announcements as well as special
              offers that may be extended from time to time.{" "}
            </p>
            <p>
              {" "}
              When you provide EZ Marketing Tech with your name, email address,
              mailing address or telephone number, and you do not "opt-out", EZ
              Marketing Tech and EZ Marketing Tech representatives and
              authorized affiliates may use the provided information to alert
              you to product updates, special offers, updated information, new
              products and other EZ Marketing Tech information. Other than EZ
              Marketing Tech representatives and authorized affiliates (such as
              value-added resellers), EZ Marketing Tech does not offer, sell, or
              otherwise make available any user-provided information to third
              parties.{" "}
            </p>
            <p>
              {" "}
              EZ Marketing Tech will send notifications regarding product
              updates and technical support notices. These messages are deemed
              essential to the functionality of your product and as such, there
              is no "opt-out" available for update notifications.{" "}
            </p>
            <p>
              If you do not want EZ Marketing Tech or EZ Marketing Tech
              representatives to contact you, you may "opt-out" at any time. You
              may "opt-out" by contacting EZ Marketing Tech Customer Service
              Department or by following the instructions included in the email
              correspondence.{" "}
            </p>
            <p>
              {" "}
              EZ Marketing Tech website utilizes visitor log files or cookies as
              well as hosted tracking with our website. The information
              collected with visitor cookies and through the hosted tracking is
              used to gain a better understanding of which sections of our site
              are most important to our users as well as identify possible
              navigation issues. This allows us to enhance your user experience
              in the future. The data collected from these visitor cookies and
              hosted tracking does not collect any personally identifiable
              information and is used in aggregate form only.{" "}
            </p>
            <p>
              Where personal information is required, the EZ Marketing Tech
              website uses Secure Sockets Layer (SSL) encryption to transmit
              this data. This is done to prevent the loss, misuse or alteration
              of the information you have offered. While EZ Marketing Tech
              strives to protect the personal information you submit to us, no
              data transmission over the Internet is 100% secure. As such, EZ
              Marketing Tech cannot guarantee or warrant the security of any
              information you transmit to us or from our products and you do so
              at your own risk. Once your information has been received by EZ
              Marketing Tech , we will make our best effort to ensure the safety
              and security on our systems.{" "}
            </p>
            <p>
              At times, EZ Marketing Tech may hire other companies to provide
              limited services on our behalf. Such limited services include, but
              are not limited to, order fulfillment, direct mail services and
              answering customer questions about products and services. These
              companies will only be provided the information they need to
              perform the service, and they are strictly prohibited from using
              that information for any other purpose.
            </p>
            <p>
              {" "}
              EZ Marketing Tech may occasionally present a special contest or
              promotion sponsored by another company. You may be asked to
              provide personal information in order to quality for this contest
              or promotion. If your personal information will be shared with the
              sponsor(s), you will be notified prior to your submission of
              personal information. Finally, while we will make every effort to
              preserve the privacy of our users, at times we may be required by
              law to disclose personal information.{" "}
            </p>
            <p>
              Each EZ Marketing Tech customer agrees to provide EZ Marketing
              Tech 30 days to attempt settlement of any billing dispute before
              disputing with any third-party credit/debit card company or bank.
              Should EZ Marketing Tech receive a chargeback from a third-party
              credit/debit card company or bank on the customer's behalf before
              EZ Marketing Tech has been given a chance to resolve the issue, EZ
              Marketing Tech has the right to charge the customer for its time
              spent in resolving such disputes and any associated fees incurred
              by EZ Marketing Tech , in addition to the $50 chargeback fee
              mentioned above. Regardless of the outcome of the chargeback, EZ
              Marketing Tech retains the right to collect on any Services or
              fees that are due. EZ Marketing Tech may submit any disputed
              amounts to a collection agency. Once a chargeback has been
              received, EZ Marketing Tech has the right to suspend the account
              until the matter is resolved.{" "}
            </p>
            <p>
              Any claim or disputes (billing or otherwise) relating to EZ
              Marketing Tech offerings including this site shall be governed by
              the laws of the State of Delaware or court of competent
              jurisdiction within the United States without regard to its
              conflict of law provisions.{" "}
            </p>
            <p>
              Each EZ Marketing Tech customer has to provide EZ Marketing Tech
              30 days to attempt closure activities of any services availed
              before disputing with any third-party credit/debit card company or
              before filing a cancellation or stoppage of services.
            </p>
            <p>
              Service fees, including but not limited to those related to the
              Online Marketing Services, Web Development Services and App
              Development Services, are nonrefundable however EZ Marketing Tech
              will review each request subjectively and will reserve the right
              to issue or deny a refund.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default CancellationPolicy;
