import React, { Fragment, useEffect } from "react";
import ReputationManagementBanner from "./ReputationManagementBanner";
import ReputationManagementAnalysis from "./ReputationManagementAnalysis";
import dataReputation from "./ReputationData";

// import SocialMediaReputation from "./SocialMediaReputation";
import PriceAllFaq from "../PriceAllFaq";
// import Seo from "../Seo";
// import TabsData from "./SocialMediaData";
// import PriceClientReview from "../PricesComponents/PriceClientReview";
import { Helmet } from "react-helmet";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
const ReputationManagementServ = () => {
  useEffect(() => {
    document.body.classList.add(
      "reputation_marketing_seo",
      "inner-page-layout"
    );
    return () => {
      document.body.classList.remove("reputation_marketing_seo");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>
          Reputation Management Services | Protect &amp; Enhance Your Online
          Reputation
        </title>
        <link rel="canonical" href="/reputation-management-services" />
        <meta
          name="description"
          content="Keep your brand's reputation intact with EZ Marketing Tech's expert reputation management services. Our team will monitor and manage your online presence to ensure a positive brand image."
        />
      </Helmet>
      <ReputationManagementBanner />
      <ReputationManagementAnalysis />

      <PriceAllFaq
        faqDetail={dataReputation}
        mainHeading="Frequently Asked Questions"
      />

      <ReviesAll
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default ReputationManagementServ;
