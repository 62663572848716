import React, { Fragment, useEffect } from "react";
import LocationBanner from "./LocationBanner";
import LocationSeoApproch from "./LocationSeoApproch";
import TopSeoNevada from "./TopSeoNevada";
import LocationTools from "./LocationTools";
// import LocationFaq from "./LocationFaq";
// import PriceClientReview from "../PricesComponents/PriceClientReview";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
// import Seo from "../Seo";
import { Helmet } from "react-helmet";
const LocationTexas = () => {
  useEffect(() => {
    document.body.classList.add("location-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("location-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Best SEO Company Texas, USA</title>
        <link rel="canonical" href="/locationtexas" />
        <meta
          name="description"
          content="Looking for the best SEO company in Texas, USA? Your search ends at EZ Marketing Tech, a one-stop destination for all your SEO needs! Connect with us today."
        />
      </Helmet>
      <LocationBanner />
      <LocationSeoApproch />
      <TopSeoNevada />
      <LocationTools />
      {/* <LocationFaq /> */}
      {/* <PriceClientReview
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
      /> */}
      <ReviesAll
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default LocationTexas;
