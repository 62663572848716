// import reviewAllImg from "../../ImagesComponents/all-images/navada_icon1.png";
// import reviewAllImg from "../../ImagesComponents/all-images/review_client_img.png";
// import reviewAllImg from "../../ImagesComponents/all-images/review_client_img2.png";
// import reviewAllImg from "../../ImagesComponents/all-images/review_client_img3.png";
// import reviewAllImg from "../../ImagesComponents/all-images/review_client_img_second.png";
let reviewAllImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon1.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/KristaGomez.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/Marilyn_Deas.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/review_client_img3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/review_client_img_second.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/Daniel_Garcia.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ZoricaStakich.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/MargoDilling.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/MasonHouston.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/JamieCrow.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/DenisePane.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/Firouzehsalimi.png",
];
const DataGoogleClients = [
  {
    id: 1,
    GoogleQuoteImg: reviewAllImg[0],
    GoogleQuoteText:
      "Ez marketing team has helped my business to grow many folds. Employees of Ez marketing specially my project manager Amit is very professional and knows what  he is doing. I would surely recommend them for SEO and website development.",
    GoogleReviewClientImgs: reviewAllImg[1],
    ReviewClientName: "Krista Gomez",
  },
  {
    id: 2,
    GoogleQuoteImg: reviewAllImg[0],
    GoogleQuoteText:
      "EZ Marketing Tech has brought my business to a new level. They handle all my technical needs (website, SEO, Google, Amazon) and I am free to run my business. I highly recommend them. My Project Manager is always available and ready to address any and all of my questions or concerns. He has become indispensable. Many companies offer to do the same things but with EZ Marketing Tech there is the emphasis on the relationship that is built. They take my business needs very personally and that is priceless.",
    GoogleReviewClientImgs: reviewAllImg[2],
    ReviewClientName: "Marilyn Deas",
  },
  {
    id: 3,
    GoogleQuoteImg: reviewAllImg[0],
    GoogleQuoteText:
      "I have used EZ Marketing for the last two years to build two web sites for me. My account manager is Gary and he is so professional and has the best customer service. They have done a very good job at SEO services and have been very proactive when ever I have a change or concern. They have saved me a lot of time and money. One caveat is you have to be patient as they are growing fast. But it is worth the wait.",
    GoogleReviewClientImgs: reviewAllImg[5],
    ReviewClientName: "Daniel Garcia",
  },
  {
    id: 4,
    GoogleQuoteImg: reviewAllImg[0],
    GoogleQuoteText:
      "Don't hesitate to use these folks to do your marketing. They are amazing. Easy too deal with nice to talk with and prices are fair And come on guys they get the job done. I've been in business for 30 years and have had other marketing companies that kind of mess around and do hardly anything for the same or more money a month. These guys are awesome. I'd give him 9 stars if I could.",
    GoogleReviewClientImgs: reviewAllImg[3],
    ReviewClientName: "david richwine",
    ReviewClientType: "Local Guide",
    ReviewClientWork: "a month ago",
  },
];
const TrustpilotReviews = [
  {
    id: 1,
    GoogleQuoteImg: reviewAllImg[4],
    GoogleQuoteText1:
      "EZ Marketing, Professional Results at a Reasonable Price",
    GoogleQuoteText:
      "I have used EZ Marketing for the last two years to build two websites for me. My account manager is Gary and  he is so professional and has the best customer service. They have done a very good job at SEO services and have been very proactive whenever I have a change or concern. They have saved me a lot of time and money. One caveat is  you have to be patient as they are growing fast. But it is worth the wait. ots-training.com",

    GoogleReviewClientImgs: reviewAllImg[5],
    ReviewClientName: " Daniel Garcia",
    ReviewClientLocation: "US",
    ReviewClientDate: "January 01, 2023",
  },
  {
    id: 2,
    GoogleQuoteImg: reviewAllImg[4],
    GoogleQuoteText1: "It has been a delightful experience…",
    GoogleQuoteText:
      "It has been a delightful experience working with our project manager Puja. Stakich being a family-run business, needed someone who could help it grow and expand online. I love working with Puja, she is very professional and her attention to detail and promptness have helped us keep pace with changing SEO challenges and take the best decisions for better online exposure.",

    GoogleReviewClientImgs: reviewAllImg[6],
    ReviewClientName: " Zorica Stakich",
    ReviewClientLocation: "US",
    ReviewClientDate: "January 25, 2023",
  },
  {
    id: 3,
    GoogleQuoteImg: reviewAllImg[4],
    GoogleQuoteText1: "Professional and Personable",
    GoogleQuoteText:
      "I just signed up with EZ Marketing Tech and already I feel a huge relief. My first contact was with Nikhil Yadav. He was professional and at the same time personable. That's what I've found with all the contacts I've made there. They make you feel at home while addressing the marketing needs of your business. I am looking forward to a long relationship with this company.",

    GoogleReviewClientImgs: reviewAllImg[9],
    ReviewClientName: "Jamie Crow",
    ReviewClientLocation: "US",
    ReviewClientDate: "May 01, 2023",
  },
  // {
  //   id: 4,
  //   GoogleQuoteImg: reviewAllImg[4],
  //   GoogleQuoteText1: "EZ Marketing Tech has been a total game…",
  //   GoogleQuoteText:
  //     " EZ Marketing Tech has been a total game changer for my  business. Our project manager, Puja, has been so thoroughly responsive, supportive and kind, and the  results have been incredible. We are getting more and more inquiries about our services every day! I highly recommend this company.",

  //   GoogleReviewClientImgs: "mm",
  //   ReviewClientName: "Mara McLoughlin",
  //   ReviewClientLocation: "US",
  //   ReviewClientDate: "January 25, 2023",
  // },
  // {
  //   id: 5,
  //   GoogleQuoteImg: reviewAllImg[4],
  //   GoogleQuoteText1: "Game Changer",
  //   GoogleQuoteText:
  //     "  Dipika and EZ Marketing Tech was a game changer in the success of my business. Dipika's knowledge of SEO put my website at the top of the first page for many of my keywords, I recommend Dipika and EZ marketing Tech for anyone who wants ti succeed in business!",

  //   GoogleReviewClientImgs: "gn",
  //   ReviewClientName: "Christopher Brill",
  //   ReviewClientLocation: "US",
  //   ReviewClientDate: "January 26, 2023",
  // },
  {
    id: 6,
    GoogleQuoteImg: reviewAllImg[4],
    GoogleQuoteText1: "I recommend Ez marketing very highly",
    GoogleQuoteText:
      "I recommend Ez marketing very highly ! They are very professional & their mission is to serve their clients in the highest possible way !",

    GoogleReviewClientImgs: reviewAllImg[11],
    ReviewClientName: "Firouzeh salimi",
    ReviewClientLocation: "US",
    ReviewClientDate: "January 25, 2023",
  },
  {
    id: 6,
    GoogleQuoteImg: reviewAllImg[4],
    GoogleQuoteText1: "EZ Marketing Tech knows what your company needs!",
    GoogleQuoteText:
      "EZ Marketing Tech has been everything a small, growing, business like ours can use as they've gone over and above anything I ever expected out of their expertise.  Our project manager, Iti, has been dependable, thorough, and explained thoroughly any questions that I had.  I highly recommend these experts, both the whole team, and my project manager Iti.  Iti has taken me through the steps for Search Engine Optimization just as she told me they would do.  I'm so happy that I came across EZ Marketing at just the right moment in time.  They've taken my website, used top key words, and straightened out issues that a view may have with my website.  The growth of organic traffic to our site has been steadily increasing in just over two months.  Iti has always been on time with appointments and has always been over the top in professionalism.  Five stars to this company and Iti, the team leader/project manager for our company.  We are a growing, resort-style fashion brand for women out of Austin, Texas",

    GoogleReviewClientImgs: reviewAllImg[7],
    ReviewClientName: "Margo Dilling",
    ReviewClientLocation: "US",
    ReviewClientDate: "January 27, 2023",
  },
  {
    id: 7,
    GoogleQuoteImg: reviewAllImg[4],
    GoogleQuoteText1: "Ez Marketing Tech is very knowledgeable…",
    GoogleQuoteText:
      "Ez Marketing Tech is very knowledgeable and easy to work with. I would recommend them to anyone looking to better their website and SEO campaign.",

    GoogleReviewClientImgs: reviewAllImg[8],
    ReviewClientName: "Mason Houston",
    ReviewClientLocation: "US",
    ReviewClientDate: "January 27, 2023",
  },
  {
    id: 8,
    GoogleQuoteImg: reviewAllImg[4],
    GoogleQuoteText1: "Great Results!",
    GoogleQuoteText:
      "I have worked with EZMarketingTech for 4 years. It has been a very fruitful relationship. I have been consistently impressed with their clear communication,timely responses to my emails or calls, the impressive SEO   results they deliver, and their thorough knowledge of the world of SEO. I highly recommend them.",

    GoogleReviewClientImgs: reviewAllImg[10],
    ReviewClientName: "Denise Pane",
    ReviewClientLocation: "US",
    ReviewClientDate: "November 15, 2019",
  },
];
export default DataGoogleClients;
export { TrustpilotReviews };
