import React, { Fragment } from "react";
// import ExTeamIconImgs0 from "../../ImagesComponents/all-images/colorado_ex_team.png";
// import ExTeamIconImgs1 from "../../ImagesComponents/all-images/colorado_ex_team2.png";
// import ExTeamIconImgs2 from "../../ImagesComponents/all-images/colorado_ex_team3.png";
// import ExTeamIconImgs3 from "../../ImagesComponents/all-images/colorado_ex_team4.png";
// import ExTeamIconImgs4 from "../../ImagesComponents/all-images/colorado_ex_team5.png";
// import ExTeamIconImgs5 from "../../ImagesComponents/all-images/colorado_ex_team6.png";
// import ExTeamIconImgs6 from "../../ImagesComponents/all-images/colorado_ex_team7.png";
// import ExTeamIconImgs7 from "../../ImagesComponents/all-images/colorado_ex_team8.png";
let ExTeamIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team6.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team7.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team8.png",
];
function TopSeoNevada() {
  return (
    <Fragment>
      <section className="seo_nevada_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <h3>
                Reach New Heights With
                <br />
                Unmatched <span>seo</span> Services In
                <br />
                Colorado
              </h3>
              {/* <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIcon} alt="Ex Team icon" />
                  <h4>Experienced Team</h4>
                  <p>
                    From keyword optimization to content optimization and social
                    media campaigns, our team knows what it takes to get you the
                    organic traffic you're looking for. We help our clients
                    achieve their goals and drive business growth.
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="customized_box">
                <p>
                  SEO (Search Engine Optimization) is critical to an effective
                  online strategy. It helps businesses rank higher on search
                  engines like Google, making them more visible to potential
                  customers. At EZ Marketing Tech, we offer comprehensive SEO
                  services to businesses in Colorado that want to attract more
                  customers. To give you a look into what you can expect, here
                  is our approach
                </p>
              </div>
            </div>
          </div>
          <div className="row j-center customized_box">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[0]} alt="Ex Team icon" />
                  <h4>Planning</h4>
                  <p>
                    Here, we come up with a detailed SEO strategy with a
                    timeline, deliverables, and budget to achieve the desired
                    outcomes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[1]} alt="Ex Team icon" />
                  <h4>Content Optimization</h4>
                  <p>
                    We research relevant keywords and generate content ideas.
                    The content created is based on the established objectives
                    and serves as the backbone for all other activities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[2]} alt="Ex Team icon" />
                  <h4>Technical SEO </h4>
                  <p>
                    We examine your site for technical issues and errors, fixing
                    them for better crawling and indexing.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[3]} alt="Ex Team icon" />
                  <h4>Offsite Optimization</h4>
                  <p>
                    This step includes link building and creating social
                    profiles, along with citation building and press releases,
                    if necessary.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[4]} alt="Ex Team icon" />
                  <h4>Local SEO </h4>
                  <p>
                    We maximize local SEO signals like GMB profiles and local
                    citations to improve your online presence in the Colorado
                    region.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[5]} alt="Ex Team icon" />
                  <h4>Reporting </h4>
                  <p>
                    We track the progress of our SEO campaigns, monitoring
                    changes and adjustments to ensure your site remains visible
                    and successful.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[6]} alt="Ex Team icon" />
                  <h4>Research & Analysis </h4>
                  <p>
                    We start with a thorough analysis of your website, your
                    competitors’ sites, and relevant keyword trends.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[7]} alt="Ex Team icon" />
                  <h4>Adaptation & Improvement </h4>
                  <p>
                    We regularly check your SEO results in Colorado and modify
                    strategies to meet changing market demands and industry
                    standards.ava
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TopSeoNevada;
