import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import AllAmazonPriceTable from "./AllAmazonPriceTable";
import AmazonPriceBanner from "./AmazonPriceBanner";

const AmazonPrice = () => {
  useEffect(() => {
    document.body.classList.add("amazon-price-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("amazon-price-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Amazon Price Packages </title>
      </Helmet>
      <AmazonPriceBanner />
      <AllAmazonPriceTable />
    </Fragment>
  );
};

export default AmazonPrice;
