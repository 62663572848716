import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { IoLogoYoutube } from "react-icons/io";
// import ApprochIconImgs0 from "../../ImagesComponents/all-images/colorado_icon.png";
// import ApprochIconImgs1 from "../../ImagesComponents/all-images/colorado_icon2.png";
// import ApprochIconImgs2 from "../../ImagesComponents/all-images/colorado_icon3.png";
// import ApprochIconImgs3 from "../../ImagesComponents/all-images/colorado_icon4.png";
// import ApprochIconImgs4 from "../../ImagesComponents/all-images/colorado_icon5.png";
// import ApprochIconImgs5 from "../../ImagesComponents/all-images/colorado_roi_figure.png";
let ApprochIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_roi_figure.png",
];
// import NavadaImgs1 from "../../ImagesComponents/all-images/navada_icon2.png";
// import NavadaImgs2 from "../../ImagesComponents/all-images/navada_icon3.png";
// import NavadaImgs3 from "../../ImagesComponents/all-images/navada_icon4.png";
// import NavadaImgs4 from "../../ImagesComponents/all-images/navada_icon5.png";
// import NavadaImgs5 from "../../ImagesComponents/all-images/navada_icon6.png";
let NavadaImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon6.png",
];
function LocationSeoApproch() {
  return (
    <Fragment>
      <section className="seo_approch_sec">
        <div className="container">
          <h2>
            Tailored SEO Solutions
            <br />
            Time To Boost Rankings In Colorado & Beyond
          </h2>
          <p className="mb-5">
            Do you want to make your Colorado business stand out from the crowd?
            With our SEO services, you can optimize your website to maximize
            your search engine visibility and gain an edge over your
            competitors.
          </p>
          <div className="row j-center">
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[0]} alt="img" />
                <h3>Improved Online Visibility</h3>
                <p>
                  We will help you rank higher on search engines and get more
                  exposure to potential customers.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[1]} alt="img" />
                <h3>Increased Traffic</h3>
                <p>
                  With higher rankings, you can expect more targeted traffic to
                  your website!
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col mb-5 b-left ">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[2]} alt="img" />
                <h3>Enhanced Credibility</h3>
                <p>
                  A robust online presence can enhance your business's
                  credibility and authority in your industry. This means
                  returning customers!
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[3]} alt="img" />
                <h3>
                  Better Return On
                  <br />
                  Investment
                </h3>
                <p>
                  We understand the value of providing a cost-effective return
                  on investment and are committed to optimizing your success
                  through SEO.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[4]} alt="img" />
                <h3>
                  Stay Ahead Of The
                  <br />
                  Competition
                </h3>
                <p>
                  Maintain your position as a leader in your industry with our
                  cutting-edge approach to SEO.
                </p>
              </div>
            </div>
          </div>
          <div className="strategies_wrap">
            <h3>
              Maximize Your Online Presence In Colorado With
              <br /> Our Proven SEO Strategies
            </h3>
            <p>
              As a business owner in Colorado, you understand the importance of
              having a solid online presence. People look for products and
              services online. So, your business must be easily discoverable.
              This is why partnering with a top SEO agency in Colorado can
              benefit your business.
            </p>
            <p>
              Get on board with the top SEO agency in Colorado and quickly start
              seeing real, measurable results. Our comprehensive strategies can
              help your website achieve top rankings on the search engine
              results page. Whether you're new to SEO or looking for a
              refresher, partner with the best SEO agency in Colorado to get the
              boost you need for maximum visibility in cities like Denver,
              Colorado Springs, Aurora, and Fort Collins!
            </p>
          </div>
          <figure className="roi_figure">
            <img src={ApprochIconImgs[5]} alt="img" />
            <figcaption className="roi_caption">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5 col-lg-5"></div>
                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                  <h4>
                    The Secret to Online Success?
                    <br /> EZ Marketing Tech
                  </h4>
                  <p>
                    Grow your business and take your website to the next level
                    with the power of EZ Marketing Tech. With our customized SEO
                    strategy, we'll help your business achieve online success.
                  </p>
                  <p>
                    Our SEO team has years of in-depth knowledge and the ability
                    to utilize effective techniques that will help drive real
                    results. Through data-driven insights, our solutions will
                    provide improved visibility, increased website traffic, and
                    higher search engine rankings.
                  </p>
                  <p>
                    Choose EZ Marketing Tech and enjoy the added convenience of
                    working with a team of experts who guide you through the
                    process. We provide an approach that fits your budget and a
                    clear road map for success. Get the knowledge, support, and
                    powerful SEO tools needed to dominate the ever-changing
                    digital landscape.
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </figcaption>
          </figure>
          <div className="navada_social text-center">
            <h3>
              Unparalleled SEO Services In Maryland
              <br />
              So That You Rule Over:{" "}
            </h3>

            <ul className="navada_Social_list">
              {/* <li>
                <a href="/">
                  <img src={NavadaImgs0} alt="img" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://goo.gl/maps/9sbfKJ2rmYFLoJFu9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[0]} alt="img location" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ezmarketingtech?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[1]} alt="img twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ezmarketingtech/ "
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[2]} alt="img facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ezmarketingtech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[3]} alt="img instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/ez-marketing-tech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[4]} alt="img" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCU1DcAmBpnswfX88X3E7XTA "
                  rel="noreferrer"
                  target="_blank"
                >
                  <IoLogoYoutube className="you_tube_icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default LocationSeoApproch;
