import React, { Fragment } from "react";
import { BsArrowRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

const PpcServiceSlider = (props) => {
  return (
    <Fragment>
      <section className="service_slider">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 col-lg-8">
              <h3>Additional PPC advertising services</h3>
              <p>
                In addition to our PPC management services, we also offer
                platform- or strategy-specific pay-per-click services, like for
                advertising on Facebook or targeting users with remarketing.
                Browse these services now, or contact us online to talk with an
                experienced strategist about these different options.
              </p>
              <p>
                If you are looking to advertise on Google (whether with search,
                display, or shopping ads) or Bing, our core PPC management
                service offers what you’re looking for in an online ad
                management service.
              </p>
            </div>
          </div>

          <div className="marketing_slider">
            <OwlCarousel
              className="rs-carousel owl-carousel"
              loop={true}
              margin={10}
              nav={true}
              items={3}
              autoplay={true}
              autoplayHoverPause={true}
              responsive={{
                1: {
                  items: 1,
                },

                768: {
                  items: 2,
                },
                1200: {
                  items: 3,
                  dots: true,
                },
              }}
            >
              {props.details.map((value, index) => (
                <div className="ppc_slider_wrapper">
                  <h3>
                    {value.slideHeading} <br /> SERVICES
                  </h3>
                  <p>{value.slideTextContent}</p>
                  <NavLink>
                    {value.slidebtn} <BsArrowRight />
                  </NavLink>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PpcServiceSlider;
