import React from "react";
// import audienceImgs0 from "../../ImagesComponents/all-images/audience1.png";
// import audienceImgs1 from "../../ImagesComponents/all-images/quality.png";
// import audienceImgs2 from "../../ImagesComponents/all-images/route.png";
// import audienceImgs3 from "../../ImagesComponents/all-images/running.png";
// import audienceImgs4 from "../../ImagesComponents/all-images/label-tag.png";
// import audienceImgs5 from "../../ImagesComponents/all-images/rectangle.jpg";
let audienceImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/audience1.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/quality.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/route.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/running.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/label-tag.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/rectangle.jpg",
];
const CbdMarketing = () => {
  return (
    <>
      <section className="cdb_marketing_section">
        <div className="container">
          <h3 className="mb-6">
            <strong> Challenges In CBD Marketing</strong>
          </h3>
          <p className="mb-5 cbd_primery_text">
            Since cannabis has long been a controversial topic around the world,
            marketing CBD products can be the most challenging part of starting
            a CBD business.Let’s look at the factors keeping the industry back
            in terms of promotion:
          </p>
          <p>
            FDA Restrictions
            <br /> The stigma around CBD is arguably a larger problem than the
            law. You can test hemp and approve it for consumption, but making
            the public understand the differences between CBD and THC will take
            time. 
          </p>{" "}
          <p>
            {" "}
            A Lack of Education about Promotion
            <br /> As of right now, Google Ads and most social media platforms
            do not allow the advertisement of CBD products. Currently, Google
            Ads penalizes advertisers for publishing  CBD content, while Twitter
            and Instagram ban users for promoting “illicit” content.
          </p>
          <ul className="audience_list ">
            <li>
              <img src={audienceImgs[0]} alt="img" />
              <h4>Audience Targeting</h4>
            </li>
            <li>
              <img src={audienceImgs[1]} alt="img" />
              <h4>Supply Quality</h4>
            </li>
            <li>
              <img src={audienceImgs[2]} alt="img" />
              <h4>Navigating eCommerce Challenges</h4>
            </li>
            <li>
              <img src={audienceImgs[3]} alt="img" />
              <h4>Competition</h4>
            </li>
            <li>
              <img src={audienceImgs[4]} alt="img" />
              <h4>Labeling</h4>
            </li>
          </ul>
          <div className="main_target">
            <h3>
              <strong>About Main Target Audience</strong>
            </h3>
            <div className="row mt-5">
              <div className="col-12 col-sm-12 col-md-6">
                <figure className="target_figure">
                  <img src={audienceImgs[5]} alt="img" width="100%" />
                </figure>
              </div>
              <div className="col-12 col-sm-12 col-md-6 target_text">
                <p>
                  While both men and women consume CBD, many CBD companies
                  target women—whose role has traditionally been associated with
                  safeguarding the family's health.
                </p>
                <p>
                  According to Brightfield Group, women use CBD slightly more
                  than men—52% of CBD consumers are female
                </p>
                <p>
                  33% of Americans have used CBD products at least once in their
                  lifetime. Americans aged between 18 and 29 use CBD products
                  the most. Based on market research, there are 20% of adults
                  younger than 30 who use CBD.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CbdMarketing;
