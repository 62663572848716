import React, { Fragment } from "react";
// import FormImg from "../../ImagesComponents/bannerImg/form_img.png";
import ServiceForm from "./ServiceForm";
function EcommerceSeoBanner() {
  return (
    <Fragment>
      <section className="ecom_seo_banner">
        <div className="container">
          <h2>
            Drive Incredible Traffic to
            <br /> Your Business with <span>eCommerce SEO</span>
          </h2>
          <p>
            In 2021, there were 2.14 billion digital buyers, comprising 27.6% of
            the world’s population. It’s a good enough reason to invest in
            eCommerce SEO. This will ensure that online buyers find your
            business online before your competitors.
          </p>
          <p>
            The sole purpose behind eCommerce SEO is to rank your online
            business higher on SERPs. But there is more than meets the eye! You
            need to consider some key concepts to ensure that your eCommerce SEO
            strategy is flawless.
          </p>
          <p>
            eCommerce SEO is more than integrating best SEO practices for
            eCommerce searches. It is about making your online store as
            accessible as a brick-and-mortar store.
          </p>
          <div className="ecom_form_wrapper">
            {/* <img src={FormImg} alt="img" /> */}
            <h3>REQUEST A QUOTE</h3>
            <ServiceForm/>
          </div>
          <div className="ecomm_concern">
            <h3>
              But you need not be concerned about your eCommerce website!
              <br /> We have got your back!
            </h3>
            <p>
              EZ Marketing Tech  can help improve your eCommerce platform's
              visibility on Search Engines and get ahead of competitors on
              SERPs. We provide SEO services to improve the quality of eCommerce
              content. Our strategy includes optimizing product descriptions and
              using eCommerce SEO methods to boost content quality. From videos
              to text to image alt text, we ensure your products are accurately
              described to help your customers settle down with the best
              products.
            </p>
            <p>
              Our Content Marketing professionals are highly skilled in all
              those tools that help optimize the content of product pages,
              reviews, and feedback. We also leverage Guest Posts to your
              advantage, so visitors of renowned websites also notice your
              online store through those posts
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default EcommerceSeoBanner;
