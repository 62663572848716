import React, { Fragment, useRef } from "react";
// import { NavLink } from "react-router-dom";
import { ImFacebook2 } from "react-icons/im";
import { ImLinkedin } from "react-icons/im";
import { ImTwitter } from "react-icons/im";
import { ImYoutube } from "react-icons/im";
import ReCAPTCHA from "react-google-recaptcha";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// import ContactIconImgs0 from "../../ImagesComponents/all-images/open-email.png";
// import ContactIconImgs1 from "../../ImagesComponents/all-images/smartphone2.png";
// import ContactIconImgs2 from "../../ImagesComponents/all-images/location.png";
let ContactIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/open-email.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/smartphone2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/location.png",
];
function ContsctForm() {
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false);

  function onChange(value) {
    setIsCaptchaSuccess(true);
    console.log("captcha value: ", value);
  }
  const captchaRef = useRef(null);
  function onSubmit(type) {
    return () => {
      switch (type) {
        case "success":
          NotificationManager.success("Successfully Submitted");
          break;
        default:
        case " ":
      }
    };
  }

  return (
    <Fragment>
      <section className="rs-contact style2">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-5">
              <div className="contact-box">
                <div className="sec-title mb-3">
                  <h2 className="title title4">
                    Talk With Our Expert Advisors
                  </h2>
                </div>
                <div className="address-box mb-3">
                  <div className="address-img">
                    <img src={ContactIconImgs[0]} alt="images" />
                  </div>
                  <div className="address-text">
                    <span className="label">Email</span>
                    <a exact href="mailto:sales@ezmarketingtech.com">
                      sales@ezmarketingtech.com
                    </a>
                  </div>
                </div>
                <div className="address-box mb-3">
                  <div className="address-img">
                    <img src={ContactIconImgs[1]} alt="images" />
                  </div>
                  <div className="address-text">
                    <span className="label">Call Us</span>
                    <a exact href="tel:+1 (855) 781-2099">
                      +1 (855) 781-2099
                    </a>
                  </div>
                </div>
                <div className="address-box mb-5">
                  <div className="address-img">
                    <img src={ContactIconImgs[2]} alt="images" />
                  </div>
                  <div className="address-text">
                    <span className="label">Office Address</span>
                    <div className="desc">
                      EZ Marketing Tech
                      <br />
                      8605 Santa Monica,
                      <br />
                      Blvd #15749,West Hollywood,
                      <br />
                      California 90069-4109 US
                    </div>
                  </div>
                </div>
                <ul className="social-icon pt-60">
                  <li>
                    <a
                      href="https://www.facebook.com/ezmarketingtech/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <ImFacebook2 />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="paste-bg"
                      href="https://twitter.com/ezmarketingtech?lang=en "
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <ImTwitter />
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      className="pink-bg"
                      href="https://www.youtube.com/channel/UCU1DcAmBpnswfX88X3E7XTA "
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <ImYoutube />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="black-bg"
                      href="https://www.linkedin.com/company/ez-marketing-tech"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <ImLinkedin />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 pl-35 md-pl-15">
              <div className="contact-widget">
                <div className="sec-title2 mb-40">
                  <span className="sub-text contact mb-15">Get In Touch</span>
                  <h2 className="title">Fill The Form Below</h2>
                </div>
                <div id="form-messages"></div>
                <form
                  id="contact-form"
                  action="https://beta.ezmarketingtech.com/mail"
                  method="POST"
                  onSubmit={onSubmit("success")}
                >
                  <fieldset>
                    <div className="row">
                      <input
                        className="from-control"
                        type="hidden"
                        id="key"
                        name="key"
                        value="contact_us"
                      />

                      <div className="col-lg-6 mb-3 col-md-6 col-sm-6">
                        <input
                          className="from-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          required={true}
                        />
                      </div>
                      <div className="col-lg-6 mb-3 col-md-6 col-sm-6">
                        <input
                          className="from-control"
                          type="email"
                          id="email"
                          name="email"
                          placeholder="E-Mail"
                          required={true}
                        />
                      </div>
                      <div className="col-lg-6 mb-3 col-md-6 col-sm-6">
                        <input
                          className="from-control"
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          required={true}
                        />
                      </div>
                      <div className="col-lg-6 mb-3 col-md-6 col-sm-6">
                        <input
                          className="from-control"
                          type="text"
                          id="Website"
                          name="subject"
                          placeholder="Your Website"
                          required={true}
                        />
                      </div>

                      <div className="col-lg-12 mb-3">
                        <textarea
                          className="from-control"
                          id="message"
                          name="message"
                          placeholder="Your Message"
                          required={true}
                        ></textarea>
                      </div>
                    </div>
                    <NotificationContainer />
                    <div className="btn-part">
                      <div className="form-group recaptcha_group mb-0">
                        <ReCAPTCHA
                          sitekey="6LfYM_oUAAAAAAkkme8vL33ls2GEqWhU3ZRCNX4C"
                          onChange={setTimeout(onChange, 2000)}
                          className="recaptcha"
                          ref={captchaRef}
                        />
                        <button
                          disabled={!isCaptchaSuccessful}
                          className="readon submit new"
                        >
                          Submit Now
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="map-canvas pt-120 md-pt-80">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13217.100802047944!2d-118.3794525!3d34.0880898!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bbfe61556ac5%3A0x89f85f7bd9347997!2sEZ%20Marketing%20Tech!5e0!3m2!1sen!2sin!4v1680607543917!5m2!1sen!2sin"
            width="100%"
            height="550"
            title="map"
            allowfullscreen=""
            referrerpolicy="no-referrer-when-downgrade"
          >
            digital marketing agency,seo services,seo, seo services near me
          </iframe>
        </div>
      </section>
    </Fragment>
  );
}

export default ContsctForm;
