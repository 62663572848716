import React, { Fragment, useEffect } from "react";
import LocationBanner from "./LocationBanner";
import LocationSeoApproch from "./LocationSeoApproch";
import TopSeoNevada from "./TopSeoNevada";
import LocationTools from "./LocationTools";
// import LocationFaq from "./LocationFaq";
// import PriceClientReview from "../PricesComponents/PriceClientReview";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
// import Seo from "../Seo";
import { Helmet } from "react-helmet";
const LocationColorado = () => {
  useEffect(() => {
    document.body.classList.add("location-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("location-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Best SEO Company Colorado, USA | EZ Marketing Tech</title>
        <meta
          name="description"
          content="Trust the leading SEO company in Colorado for maximum online
          impact! EZ Marketing Tech offers customized SEO solutions to meet your unique
          business needs and goals."
        />
      </Helmet>
      <LocationBanner />
      <LocationSeoApproch />
      <TopSeoNevada />
      <LocationTools />
      {/* <LocationFaq /> */}
      {/* <PriceClientReview
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
      /> */}
      <ReviesAll
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default LocationColorado;
