import React, { Fragment } from "react";
// import ExTeamIconImgs0 from "../../ImagesComponents/all-images/california_seo_icon.png";
// import ExTeamIconImgs1 from "../../ImagesComponents/all-images/california_seo_icon2.png";
// import ExTeamIconImgs2 from "../../ImagesComponents/all-images/california_seo_icon3.png";
// import ExTeamIconImgs3 from "../../ImagesComponents/all-images/california_seo_icon4.png";
// import ExTeamIconImgs4 from "../../ImagesComponents/all-images/california_seo_icon5.png";
// import ExTeamIconImgs5 from "../../ImagesComponents/all-images/california_seo_icon6.png";
let ExTeamIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon6.png",
];
function TopSeoNevada() {
  return (
    <Fragment>
      <section className="seo_nevada_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
              <h3>
                Our <span>seo</span> Process
                <br />
                For Companies In California
              </h3>
              {/* <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIcon} alt="Ex Team icon" />
                  <h4>Experienced Team</h4>
                  <p>
                    From keyword optimization to content optimization and social
                    media campaigns, our team knows what it takes to get you the
                    organic traffic you're looking for. We help our clients
                    achieve their goals and drive business growth.
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <div className="customized_box">
                <p>
                  At EZ Marketing Tech, we believe that the development of an
                  effective SEO plan begins with understanding your company’s
                  business objectives and the goals you have for achieving
                  success. Here is a look at our multi-step process:
                </p>
              </div>
            </div>
          </div>
          <div className="row customized_box">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[0]} alt="Ex Team icon" />
                  <h4>Identify Your SEO Goals</h4>
                  <p>
                    First, we will discuss what you wish to accomplish through
                    an SEO campaign and analyze your current online presence.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[1]} alt="Ex Team icon" />
                  <h4>Audit Your Website</h4>
                  <p>
                    Next, we will thoroughly audit your website to determine
                    which pages should be optimized for search engine rankings
                    and analyze your competitor’s website and link-building
                    strategy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[2]} alt="Ex Team icon" />
                  <h4>Keyword Research & Analysis</h4>
                  <p>
                    We will also conduct extensive keyword analysis to determine
                    the best target keywords to improve your rankings in SERPs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[3]} alt="Ex Team icon" />
                  <h4>Technical SEO Implementation</h4>
                  <p>
                    After identifying the target keywords, we will implement
                    technical SEO steps like optimization of meta tags,
                    canonical tags, internal linking, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[4]} alt="Ex Team icon" />
                  <h4>
                    Content Optimization &<br />
                    Link Building
                  </h4>
                  <p>
                    We will create engaging content for your website as well as
                    promote it using off-page techniques like link building and
                    other methods to boost search engine visibility for your
                    website.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[5]} alt="Ex Team icon" />
                  <h4>
                    Analyzing Results & Ongoing
                    <br />
                    Monitoring
                  </h4>
                  <p>
                    Once the SEO plan is implemented, we’ll closely monitor
                    progress & provide detailed reports regarding its
                    success/failure rate so that necessary adjustments can be
                    made if needed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TopSeoNevada;
