import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import LocationForm from "../LocationPageComponents/LocationForm";
function LocationBanner() {
  return (
    <Fragment>
      <section className="banner_section location_banner">
        <div className="container">
          <div className="location_banner_wrap">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <div className="location_banner_text">
                  <h2>
                    Increase SEO Rankings And
                    <br />
                    Convert Leads Today In Colorado!
                  </h2>
                  <p>
                    Our SEO experts specialize in providing effective and
                    long-lasting solutions to get your website to the top of
                    search engine rankings. As a top SEO agency catering to
                    Colorado businesses, we can increase traffic to your site,
                    boost leads and revenue, and expand your brand visibility.
                    Contact us today, and we'll help you get discovered!
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                <div className="location_proposal_wrap">
                  <h3>GET MY FREE PROPOSAL</h3>
                  <LocationForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default LocationBanner;
