import React, { Fragment } from "react";
// import foundationImgs0 from "../../ImagesComponents/all-images/foundation_icon.png";
// import foundationImgs1 from "../../ImagesComponents/all-images/foundation_icon2.png";
// import foundationImgs2 from "../../ImagesComponents/all-images/foundation_icon3.png";
let foundationImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/foundation_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/foundation_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/foundation_icon3.png",
];
function FoundationalPillars() {
  return (
    <Fragment>
      <section className="foundational_seo">
        <div className="container">
          <h3>Foundational Pillars of SEO</h3>
          <p className="text-center">
            The three pillars of SEO listed below—authority, content, and
            experience—form the foundation of SEO.
            <br /> Let's examine each of these A.R.E. classes in more detail to
            see how a comprehensive SEO program should incorporate them.
          </p>
          <article className="foundational_artical">
            <div className="artic_text">
              <h4>Authority: The Key To SEO Excellence</h4>
              <p>
                In SEO, the term "authority" describes the significance or
                weight attached to a page in relation to a specific search
                query. When determining the authority of a webpage, modern
                search engines like Google consider various factors (or
                signals). Google wants to draw attention to the websites that
                are most likely to provide the user with accurate, trustworthy
                information that fully satisfies the query's intent. Because
                users who are satisfied with the pages, they click through to
                from Google are more likely to use Google again, which increases
                exposure to Google's ads—the company's primary source of
                income—Google cares about providing users with the most reliable
                pages for their queries.
              </p>
              <p>
                Given that the architecture pillar is where your site's
                technical foundation is located, starting your SEO project there
                makes sense. Correctly configuring your hosting, enhancing page
                speed, removing crawling barriers, and ensuring that your pages
                contain the proper structural elements are all essential.
                However, that is the trickiest course of action you can take, so
                you might need outside assistance for this.
              </p>
              <span className="foundational_icon">
                <img src={foundationImgs[0]} alt="img" />
              </span>
            </div>
          </article>
          <article className="foundational_artical">
            <div className="artic_text">
              <h4>Relevant Content: Keywords and Pages</h4>
              <p>
                Without addressing this pillar, you can't make significant SEO
                progress. The content pillar, where anyone can participate,
                makes for a more specific starting point. In this case, you
                choose the keywords your website should rank for and optimize or
                produce content that can do the same. According to a HubSpot
                study, companies that regularly publish content receive 55% more
                visitors, 350% more traffic, and generate 67% more leads than
                those that do not.
              </p>
              <p>
                Demand Metric's study also reveals that indexed pages for blog
                posts have increased by 434% and inbound links to them by 97%.
                "Content" is one of Google's top 3 ranking factors, as even
                Google has acknowledged. You must consider these three key
                elements both when writing content and before publishing it:
              </p>
              <ul className="foundation_list">
                <li>
                  Only original and informative content that is related to your
                  niche.
                </li>
                <li>Article Length.</li>
                <li>Optimizing your keywords.</li>
              </ul>
              <p>
                Only 1% of marketers, according to a study on marketing, produce
                content; the other 99% only consume it. And the biggest
                challenge for marketers is creating original, educational, and
                consistent content. Therefore, to avoid penalties, when creating
                content, make sure it is pertinent to your niche and is original
                and free of plagiarism.
              </p>
              <span className="foundational_icon">
                <img src={foundationImgs[1]} alt="img" />
              </span>
            </div>
          </article>
          <article className="foundational_artical">
            <div className="artic_text">
              <h4>Driving Success Through User Experience.</h4>
              <p>
                As we discussed above, Google initially concentrated on ranking
                pages by the authority before discovering methods to determine
                relevance. The evaluation of user experience represented the
                third evolution of search. In fact, many SEO experts (including
                us) prefer to refer to SEO as search experience optimization
                rather than search engine optimization.
              </p>
              <p>
                Google acknowledged that, despite their importance,
                authoritativeness and relevancy were not the only factors users
                considered when conducting searches. Users also want to enjoy
                their time on the websites and pages that Google directs them
                to. What does{" "}
                <a
                  href="https://www.ezmarketingtech.com/reviews"
                  target="_blank"
                  rel="noreferrer"
                  className="go_url"
                >
                  "good user experience"
                </a>{" "}
                mean? It at least consists of the following:
              </p>

              <ul className="foundation_list">
                <li>
                  The searcher arrives at the page they would anticipate seeing,
                  given their query. There is no trick.
                </li>
                <li>
                  The landing page's content is very pertinent to the user's
                  search.
                </li>
                <li>
                  Although links to additional pertinent sources and related
                  topics exist, the content sufficiently addresses the users
                  question's main points.
                </li>
                <li>
                  All aspects of Google's Page Experience Update are present:
                  the page loads quickly, the relevant content is immediately
                  apparent, and page elements quickly find their proper
                  positions.
                </li>
                {/* <li>
                  All aspects of Google's Page Experience Update are present:
                  the page loads quickly, the relevant content is immediately
                  apparent, and page elements quickly find their proper
                  positions.
                </li> */}
              </ul>
              <p>
                In addition, many of the recommendations made above for
                improving content also apply to user experience. In conclusion,
                Google wants to prioritize pages that answer the search query
                and make it simple for users to recognize and comprehend the
                content they seek. Now that you know the fundamentals, it is
                time to know how to create a good SEO strategy.
              </p>
              <span className="foundational_icon">
                <img src={foundationImgs[2]} alt="img" />
              </span>
            </div>
          </article>
        </div>
      </section>
    </Fragment>
  );
}

export default FoundationalPillars;
