import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import { BsFillStarFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";

import { TrustpilotReviews } from "./ReviewAllData";
function TrustpilotDetail(val) {
  return (
    <div className="testimonial_cover">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="testimonial_wrapper">
            <span className="quote_img">
              <img src={val.GoogleQuoteImg} alt="img" />
            </span>

            <p>
              <strong>{val.GoogleQuoteText1}</strong>
            </p>
            <p>{val.GoogleQuoteText}</p>

            <ul className="reviewImg">
              <li className="review_client">
                <span className="img_option optin_clint">
                  <img src={val.GoogleReviewClientImgs} alt="img" />
                </span>
                <h4>
                  {val.ReviewClientName}
                  <br />
                  <span>
                    <MdLocationOn /> {val.ReviewClientLocation}
                  </span>
                </h4>
              </li>
              <li className="review_star">
                <div className="star_wrapper">
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                </div>
                <h5>{val.ReviewClientDate}</h5>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
const ReviewClientSliderSecond = () => {
  return (
    <Fragment>
      <section className="review_client_sec review_client_second">
        <div className="container">
          <OwlCarousel
            className="rs-carousel owl-carousel review_client_carousel"
            loop={true}
            margin={10}
            nav={true}
            dots={false}
            items={3}
            autoplay={true}
            autoplayHoverPause={true}
            responsive={{
              1: {
                items: 1,
              },

              768: {
                items: 1,
              },
              992: {
                items: 2,
              },
              1200: {
                items: 3,
              },
            }}
          >
            {TrustpilotReviews.map(TrustpilotDetail)}
          </OwlCarousel>
          <div className="view_more_wrapper">
            <a
              href="https://www.trustpilot.com/review/ezmarketingtech.com"
              target="_blank"
              rel="noopener noreferrer"
              className="view_more_link"
            >
              View More Reviews
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ReviewClientSliderSecond;
