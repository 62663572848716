import React, { Fragment } from "react";
//import SeoEngImgs from "../../ImagesComponents/all-images/process_eng.png";
let SeoEngImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/process_eng.png",
];
function SeoSearchEngines() {
  return (
    <Fragment>
      <section className="seo_search_engines text-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <h3>Why do search engines care about SEO?</h3>
              <p>
                Why, in your opinion, does a search engine care whether you use
                it or not? And keep in mind that you are not paying them. That
                is correct! Search engines consider search engine optimization
                (SEO) because it helps them provide users with relevant,
                high-quality search results on the search engine results page
                (SERP). This means that users are more likely to find the
                information, products, or services they are looking for quickly
                and easily. The SERP layout typically consists of both organic
                results and pay-per-click (PPC) ads.
              </p>
              <p>
                SEO impacts the SERP's{" "}
                <a
                  href="https://www.ezmarketingtech.com/reviews"
                  target="_blank"
                  rel="noreferrer"
                  className="go_url"
                >
                  organic results,
                </a>{" "}
                which are the unpaid listings, while PPC ads are sponsored
                listings that appear at the top or bottom of the SERP. However,
                businesses cannot pay search engines to appear in any position
                in the{" "}
                <a
                  href="https://www.ezmarketingtech.com/reviews"
                  target="_blank"
                  rel="noreferrer"
                  className="go_url"
                >
                  organic results.
                </a>
                Despite this, search engines profit from advertising, so they
                prioritize displaying ads on their pages. By optimizing their
                websites for SEO, businesses can improve their chances of
                appearing at the top of the{" "}
                <a
                  href="https://www.ezmarketingtech.com/reviews"
                  target="_blank"
                  rel="noreferrer"
                  className="go_url"
                >
                  organic results
                </a>{" "}
                on the SERP, driving more traffic to their site, while search
                engines can display more ads and generate more revenue as more
                people use their platform.
              </p>
              <img src={SeoEngImgs[0]} width="100%" alt="img" />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SeoSearchEngines;
