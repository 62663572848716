import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
// import CBanchmarkImg from "../../ImagesComponents/all-images/cbd_banchmark_img.jpg";
// let CBanchmarkImg = [
//   "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/cbd_banchmark_img.jpg",
// ];
const CbdKeywords = () => {
  return (
    <>
      <section className="cbd_section">
        <div className="container">
          <h2>
            <strong>Best Cannabis and CBD Keywords (2023 Updated)</strong>
          </h2>
          <p className="mb-5">
            The CBD keyword list that works for a particular case is
            unquestionably unique. You can outline several win-win phrases
            necessary to rank your website.{" "}
          </p>
          <p>
            {" "}
            <strong> Brand Name:</strong> The promotion of any brand is crucial.
            If a person is searching for your website, chances are that your
            competitors’ site will also appear in the SERP. Therefore, along
            with the best CBD keywords, include your brand.{" "}
          </p>
          <p>
            <strong> Best or Cheapest:</strong> A lot of consumers look for the
            products considered as the best or the cheapest. So, if you are in
            CBD e-commerce business, ranking for key phrases “the best product”
            and “cheap product” is a good way to drive some traffic.{" "}
          </p>{" "}
          <p>
            {" "}
            <strong> Buy Phrases:</strong> Undeniably, if the search phrase of
            users includes the word “buy”, they are potential clients.
            Therefore, including “buy + product” or “order + service” in your
            target keywords can rank  your website higher.{" "}
          </p>{" "}
          <p>
            {" "}
            <strong> Near Me: </strong> This term is particularly important for
            dispensaries and physical stores. If you are selling CBD products
            from a physical store, you can add “near me” to the list of keywords
            for CBD products to attract local customers.{" "}
          </p>{" "}
          <p>
            We will provide you with some CBD keywords list examples that might
            be useful for your CBD business. However, we would like to remind
            you that the cannabis industry has a lot of specifics, and if you
            are new to the field, better reach out to a professional.
          </p>
          <div className="seo_keywords">
            <h3>
              <strong>
                SEO keywords for cannabis dispensaries{" "}
                <span>
                  <MdKeyboardArrowDown />
                </span>
              </strong>
            </h3>
            <p className="mb-5">
              We have made a list of keywords associated with dispensaries for
              the American cannabis market. Here are the top-15 phrases with the
              highest volume per month. As you can see, the phrase “near me” is
              really often used when users look for dispensaries.
            </p>
            <div className="table-responsive">
              <table className="md_table table">
                <thead>
                  <tr>
                    <th>Keyword</th>
                    <th>Difficulty</th>
                    <th>Volume</th>
                    <th>Parent Keyword</th>
                    <th>Global Volume</th>
                    <th>Traffic Potential</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>dispensary Near Me</td>
                    <td>82</td>
                    <td>2150000</td>
                    <td>dispensary Near Me</td>
                    <td>2210000</td>
                    <td>1410000</td>
                  </tr>
                  <tr>
                    <td>dispensary</td>
                    <td>76</td>
                    <td>1110000</td>
                    <td>dispensary</td>
                    <td>1280000</td>
                    <td>156000</td>
                  </tr>
                  <tr>
                    <td>marijuana Dispensary Near Me</td>
                    <td>84</td>
                    <td>129000</td>
                    <td>dispensary Near Me</td>
                    <td>133000</td>
                    <td>1400000</td>
                  </tr>
                  <tr>
                    <td>recreational Dispensary Near Me</td>
                    <td>77</td>
                    <td>103000</td>
                    <td>dispensary Near Me</td>
                    <td>107000</td>
                    <td>704000</td>
                  </tr>
                  <tr>
                    <td>medical Marajuana Dispensary Near Me</td>
                    <td>84</td>
                    <td>68000</td>
                    <td>dispensary Near Me</td>
                    <td>68000</td>
                    <td>1350000</td>
                  </tr>
                  <tr>
                    <td>marijuana Dispensary</td>
                    <td>90</td>
                    <td>68000</td>
                    <td>dispensary Near Me</td>
                    <td>75000</td>
                    <td>1420000</td>
                  </tr>
                  <tr>
                    <td>weed Dispensary Near Me</td>
                    <td>85</td>
                    <td>67000</td>
                    <td>dispensary Near Me</td>
                    <td>78000</td>
                    <td>1350000</td>
                  </tr>
                  <tr>
                    <td>cannabis Dispensary Near Me</td>
                    <td>86</td>
                    <td>66000</td>
                    <td>dispensary Near Me</td>
                    <td>78000</td>
                    <td>1350000</td>
                  </tr>
                  <tr>
                    <td>the Dispensary</td>
                    <td>35</td>
                    <td>65000</td>
                    <td>dispensary</td>
                    <td>70000</td>
                    <td>157000</td>
                  </tr>
                  <tr>
                    <td>dispensary Recreation</td>
                    <td>73</td>
                    <td>63000</td>
                    <td>dispensary Recreation</td>
                    <td>63000</td>
                    <td>61000</td>
                  </tr>
                  <tr>
                    <td>medical Dispensary Near Me</td>
                    <td>86</td>
                    <td>56000</td>
                    <td>dispensary Near Me</td>
                    <td>59000</td>
                    <td>704000</td>
                  </tr>
                  <tr>
                    <td>cannabis Dispensary</td>
                    <td>85</td>
                    <td>50000</td>
                    <td>dispensary Near Me</td>
                    <td>57000</td>
                    <td>569000</td>
                  </tr>
                  <tr>
                    <td>dispensary Near Me Open</td>
                    <td>69</td>
                    <td>33000</td>
                    <td>dispensary Near Me</td>
                    <td>39000</td>
                    <td>1360000</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 className="border_btm">
              <strong>
                Conversion is the key of business: Strategy to Optimized in CBD
              </strong>
            </h3>
            <p>
              Prioritize Retention - For most CBD customers, loyalty is
              everything. If they like the product and the business, they’re
              likely to return to it. A good way to gain customer loyalty is
              through three key Customer Retention strategies:
            </p>
            <p>
              Having a product entirely unique to your brand.
              <br />
              Building “cult” loyalty for your business with brand
              differentiation.
              <br />
              Regularly engaging with customers who actively show an interest in
              your brand.
            </p>

            <div className="cbd_banchmark">
              <h4>CBD shop benchmarks overview This CBD report found that:</h4>
              <div className="table-responsive">
              <table className="table mb-0 table_banchmark table-borderd">
                <thead className="table_banchmark_header">
                  <tr>
                    <th>b2c</th>
                    <th>Retention rate</th>
                    <th>Revenue from returning customers</th>
                    <th>Orders/Customer</th>
                    <th>TBO(days)</th>
                    <th>LTV</th>
                    <th>Cart aband. rate</th>
                    <th>CR</th>
                    <th>AOV</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table_banchmark_content">
                    <td>max</td>
                    <td>99.3%</td>
                    <td>99.9%</td>
                    <td>18.7</td>
                    <td>97</td>
                    <td>$3,764</td>
                    <td>88%</td>
                    <td>16%</td>
                    <td>$314</td>
                  </tr>
                  <tr className="table_banchmark_content">
                    <td>min</td>
                    <td>15.7%</td>
                    <td>39.9%</td>
                    <td>1.3</td>
                    <td>21</td>
                    <td>$108</td>
                    <td>30%</td>
                    <td>4%</td>
                    <td>$42</td>
                  </tr>
                  <tr className="table_banchmark_header">
                    <td>average</td>
                    <td>47.9%</td>
                    <td>78.4%</td>
                    <td>3.7</td>
                    <td>61</td>
                    <td>$588</td>
                    <td>57%</td>
                    <td>10%</td>
                    <td>$129</td>
                  </tr>
                </tbody>
              </table>
              </div>
              
              {/* <img src={CBanchmarkImg} alt="img" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CbdKeywords;
