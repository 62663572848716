import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
// import seoServBannerImgs0 from "../../ImagesComponents/all-images/seo_traffic_bg.jpg";
// import seoServBannerImgs1 from "../../ImagesComponents/all-images/go_down.png";
let seoServBannerImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/seo_traffic_bg.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/go_down.png",
];
function SeoTrafficBanner() {
  return (
    <Fragment>
      <section className="banner_section">
        <figure className="banner_figure seo_traffic_banner">
          <img src={seoServBannerImgs[0]} alt="img" width="100%" />
          <figcaption className="seo_traffic_banner_caption text-center">
            <div className="container">
              <h1>Search Engine Optimization</h1>
              <p>
                Invest in Your Future with EZ Marketing’s SEO strategies that
                Generate Results.
              </p>
              <button className="seo_banner_btn">
                <NavLink to="/seo-packages" className="seo_banner_link_text">
                  Start a Project
                </NavLink>
              </button>
              <button className="seo_banner_btn">
                <NavLink to="/contact-us" className="seo_banner_link_text">
                  Contact Us
                </NavLink>
              </button>
            </div>
          </figcaption>
          <span className="go_down">
            <img src={seoServBannerImgs[1]} alt="img" />
          </span>
        </figure>
      </section>
    </Fragment>
  );
}

export default SeoTrafficBanner;
