import React, { useEffect, Fragment } from "react";
import ReviewBanner from "./ReviewBanner";
import ReviewClientSlider from "./ReviewClientSlider";
import ReviewClientSliderSecond from "./ReviewClientSliderSecond";
// import ReviewClientSliderThird from "./ReviewClientSliderThird";
import ReviewClientSliderVideo from "./ReviewClientSliderVideo";
// import Seo from "../Seo";
import { Helmet } from "react-helmet";
const Review = () => {
  useEffect(() => {
    document.body.classList.add("review-layout");
    return () => {
      document.body.classList.remove("review-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Clients Reviews | EZ Marketing Tech</title>
        <meta
          name="description"
          content="At EZ Marketing Tech, an internet marketing agency, we build trust
          and make long-term relationships with our customers. We’re focused on driving
          real results. Our client reviews say it all!"
        />
      </Helmet>
      <ReviewBanner />
      <ReviewClientSlider />
      <ReviewClientSliderSecond />
      {/* <ReviewClientSliderThird /> */}
      <ReviewClientSliderVideo />
    </Fragment>
  );
};

export default Review;
