import React, { Fragment } from "react";
import { BiPlus } from "react-icons/bi";
import { AiOutlineMinus } from "react-icons/ai";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
function SeoFaqTabs() {
  return (
    <Fragment>
      <section className="seo_faq_section">
        <div className="container">
          <h3>Search Engine Optimization FAQ</h3>

          <div className="seo_tabs_wrapper">
            <Accordion preExpanded={["a"]}>
              <AccordionItem uuid="a">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>
                      <BiPlus className=" auto_show" />
                      <AiOutlineMinus className=" after_show" />
                      What are the 3 types of SEO?
                    </h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Accordion preExpanded={["b"]}>
                    <AccordionItem uuid="b" className="sub_tabs">
                      <p>
                        What tends to come to your mind when you think of SEO
                        (Search Engine Optimization)? Keyword phrases! But it's
                        more than that: we have discussed types of SEO before,
                        so why again? To simply say, these are more on the side
                        of SEO tactics than types, but they are nonetheless
                        important. These three SEO strategies can help you
                        become more visible and rank higher in organic search
                        results.
                      </p>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4>
                            <BiPlus className=" auto_show" />
                            <AiOutlineMinus className=" after_show" />
                            On-Page SEO
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="seo_content_tabs_detail">
                          {/* <h5>
                            <span>x</span> E-A-T
                          </h5> */}
                          <p>
                            You are probably most familiar with on-page SEO as a
                            business, as it's the most "popular." This type of
                            SEO entails improving your website's content and
                            source code to better position it on search engine
                            results pages (SERPs). Search engines can better
                            serve users with high-quality content related to a
                            given search query with the help of an effective
                            on-page SEO strategy. Essentially, the purpose of
                            on-site SEO is to enable users and search engines
                            to:
                          </p>
                          <ul>
                            <li>Become familiar with the website's context.</li>
                            <li>
                              Determine whether the page is relevant to a search
                              query.
                            </li>
                            <li>
                              Find a page optimized to rank high in search
                              engine results.
                            </li>
                          </ul>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="sub_tabs">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4>
                            <BiPlus className=" auto_show" />
                            <AiOutlineMinus className=" after_show" />
                            off-Page SEO
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="seo_content_tabs_detail">
                          {/* <h5>
                            <span>x</span> E-A-T
                          </h5> */}
                          <p>
                            Off-page SEO entails actions taken beyond the intent
                            of your website to influence your SERP rankings. By
                            focusing on off-site ranking factors, you can
                            increase perceptions of your site's popularity and
                            relevance online while establishing trust and
                            authority. There are two main categories of off-page
                            SEO that we should examine: link-related and
                            non-link-related.
                          </p>
                          <ul>
                            <li>
                              <strong>Link-Related SEO</strong>: The foundation
                              of off-page SEO is link building. Search engines
                              use these backlinks to determine the quality of
                              the content, and those with a lot of "high-value"
                              backlinks will probably rank higher in the SERP.
                              Natural links, manually constructed links, and
                              self-created links are the three main categories
                              of links.
                            </li>
                            <li>
                              <strong>Non-Link-Related SEO</strong>: Although
                              obtaining links from other websites constitutes
                              the majority of off-page SEO, other SEO tactics
                              are also crucial for raising your search engine
                              ranking. Social media marketing is among the most
                              widely used SEO techniques that don't involve
                              links. You promote your website from other
                              websites by directing traffic there from those
                              other websites. Guest blogging, linked and
                              unlinked brand mentions and influencer marketing
                              are some additional off-page SEO techniques.
                            </li>
                          </ul>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="sub_tabs">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4>
                            <BiPlus className=" auto_show" />
                            <AiOutlineMinus className=" after_show" />
                            Technical SEO
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="seo_content_tabs_detail">
                          {/* <h5>
                            <span>x</span> E-A-T
                          </h5> */}
                          <p>
                            Although some consider technical SEO part of on-page
                            SEO, we'll treat it separately here. In a nutshell,
                            technical SEO is connected to on-page elements but
                            focuses on operational issues. Search engines, for
                            instance, might favor websites with responsive
                            designs that adapt well to mobile devices. Another
                            crucial element is page speed; if your page loads
                            slowly, you risk losing visitors and being penalized
                            by ranking algorithms. Your SEO can benefit from
                            image optimization, using a secure HTTPS connection,
                            caching data to speed up load times, uploading
                            detailed sitemaps, and other technical factors.
                            Additionally, each page's HTML needs to be
                            optimized. SEO-related factors include:
                          </p>
                          <ul>
                            <li>Using the right kind of redirects.</li>
                            <li>
                              Using schema markup to explain precisely what's on
                              your page to search engines.
                            </li>
                            <li>
                              Making it simple for crawlers to understand what
                              your page is about.
                            </li>
                          </ul>
                          <p>
                            Speaking of crawlers, it's essential to ensure your
                            robots.txt file has been updated to make it easier
                            for crawlers to find the information they need.
                            <br />
                            Although technical SEO can be intimidating, it is
                            primarily governed by the same guidelines as on-page
                            SEO: a positive user experience is essential. You'll
                            be halfway there if your website loads quickly and
                            is simple to use. Just make sure it's simple for
                            Google's crawlers to navigate now.
                          </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>
                      <BiPlus className=" auto_show" />
                      <AiOutlineMinus className=" after_show" />
                      What are The Key Players In SEO ?
                    </h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Accordion preExpanded={["c"]}>
                    <AccordionItem uuid="c" className="sub_tabs">
                      <p>
                        From Core Web Vitals to the Knowledge Graph, semantics,
                        and entities, here are a few fundamental SEO principles
                        to know:
                      </p>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4>
                            <BiPlus className=" auto_show" />
                            <AiOutlineMinus className=" after_show" />
                            Core Web Vitals
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="seo_content_tabs_detail">
                          {/* <h5>
                            <span>x</span> E-A-T
                          </h5> */}
                          <p>
                            Google will use a new set of criteria called Core
                            Web Vitals to determine whether a page offers a
                            positive user experience. These indicators are:
                          </p>
                          <ul>
                            <li>
                              <strong>Largest Contentful Paint (L.C.P.)</strong>
                              : This metric gauges how quickly the primary
                              content of a page load. Within five seconds of
                              landing on a page, this should happen.
                            </li>
                            <li>
                              <strong>First Input Delay (FID)</strong>: measures
                              how quickly users can engage with a page after
                              arriving at it. This ought to happen within 100
                              ms.
                            </li>
                            <li>
                              <strong>Cumulative Layout Shift (C.L.S.)</strong>:
                              measures the frequency of unexpected layout
                              changes for users. Pages should keep their C.L.S.
                              below 0.1.
                            </li>
                          </ul>
                          <p>
                            The most important issues to pay attention to are:
                          </p>
                          <ul className="faq_number_list">
                            <li>The experience of using a mobile device.</li>
                            <li>Compression of the image.</li>
                            <li>Content on an informational page.</li>
                            <li>Technical architecture and site speed.</li>
                          </ul>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="sub_tabs">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4>
                            <BiPlus className=" auto_show" />
                            <AiOutlineMinus className=" after_show" />
                            Mobile-First Indexing
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="seo_content_tabs_detail">
                          {/* <h5>
                            <span>x</span> E-A-T
                          </h5> */}
                          <p>
                            Before switching to mobile variants, Googlebot
                            mainly used a website's desktop site to assess a
                            page's relevance to a search query. Because the site
                            served to mobile users is a responsive version of
                            the desktop site with no other changes to the actual
                            content that's served, this won't be a problem for
                            many businesses.
                            <br /> But some things need to be considered if a
                            website serves different desktop and mobile
                            versions. Because of how Mobile First came to be,
                            your ranking signals will now originate from your
                            site's mobile version rather than its desktop
                            version. In other words, it's time to start
                            prioritizing your mobile SEO before considering your
                            desktop site as an adjunct to mobile. Here are some
                            viable strategies to raise your mobile SEO:
                          </p>
                          <ul>
                            <li>The expertise of the content's creator.</li>
                            <li>Fix faulty redirects and broken links.</li>
                            <li>Uncompressed images should be compressed.</li>
                            <li>
                              Delete any inactive or restricted resources (e.g.,
                              JavaScript, CSS, specific images).
                            </li>
                            <li>
                              Interstitials and obtrusive pop-ups should be
                              removed.
                            </li>
                            <li>
                              The usability of mobile devices (e.g., text size,
                              viewport configuration, tap target size).
                            </li>
                            <li>
                              Run a mobile site audit to discover additional
                              site components (like structured data or title
                              tags) that can be optimized for mobile.
                            </li>
                          </ul>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="sub_tabs">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4>
                            <BiPlus className=" auto_show" />
                            <AiOutlineMinus className=" after_show" />
                            Machine Learning and Automation
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="seo_content_tabs_detail">
                          <p>
                            Neural networks, artificial intelligence, and
                            machine learning are the current SEO industry
                            buzzwords. The ability of these technologies to
                            automate time-consuming tasks at scale, which can
                            result in more effective campaigns, has been
                            highlighted by marketers. Making sense of data dumps
                            requires the use of ML-driven tools. Why can't we,
                            then, if Google can? Mainly if we can obtain
                            ongoing, fact-based insights to enhance traffic,
                            keywords, rankings, content, and other factors. The
                            good news is that ML-driven SEO tools and Google's
                            constant updates are already available. Adoption is
                            now imminent among SEO experts, professionals,
                            search marketers, and content marketers.
                          </p>
                          <h5>
                            <span>x</span> E-A-T
                          </h5>
                          <p>
                            The acronym E.A.T. stands for expertise, authority,
                            and trustworthiness. It is incorporated into
                            Google's Search Quality Evaluator Guidelines and is
                            part of its algorithm. Although it's crucial to
                            remember that E-A-T isn't a direct Google ranking
                            factor, SEO and E-A-T still go hand in hand.
                            Evaluators of the quality of Google searches were
                            told to focus on the following:
                          </p>
                          <ul>
                            <li>The expertise of the content's creator.</li>
                            <li>
                              The authoritativeness of the website, the
                              content's author, and the content itself.
                            </li>
                            <li>
                              The trustworthiness of the content's author, the
                              piece of content, and the website.
                            </li>
                          </ul>
                          <p>
                            It is essential for all queries, but some more so
                            than others. For instance:
                          </p>
                          <ul>
                            <li>
                              E-A-T probably doesn't matter all that much if
                              you're just looking for images of cute cats. Since
                              this is a debatable subject, it doesn't matter if
                              you don't find a cat to be adorable.
                            </li>
                            <li>
                              On the other hand, E-A-T is unquestionably
                              significant if you're trying to figure out the
                              right amount of aspirin to take while pregnant.
                              The likelihood of inaccurate or misleading content
                              is high if Google surfaces content on this subject
                              that was written by a naive author and published
                              on an unreliable, unqualified website.
                            </li>
                          </ul>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SeoFaqTabs;
