import React, { Fragment } from "react";
import { BiPlus } from "react-icons/bi";
import { FiMinus } from "react-icons/fi";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// import { NavLink } from "react-router-dom";
const PriceAllFaq = (props) => {
  return (
    <Fragment>
      <section className="freFrequently_asked">
        <div className="container">
          <h2>{props.mainHeading}</h2>

          <div className="freFrequently_acordian_wrapper">
            <Accordion preExpanded={[0]}>
              {props.faqDetail.map((value, index) => (
                <AccordionItem uuid={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>
                        <BiPlus className="auto_show" />
                        <FiMinus className="after_show" />
                        {value.packageHeding}
                      </span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{value.packageText}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PriceAllFaq;
