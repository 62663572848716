import React, { Fragment, useEffect } from "react";
// import PriceBanner from "./PriceBanner";
import PricesContent from "./PricesContent";
import PriceTable from "./PriceTable";
import ReadyPriceForm from "./ReadyPriceForm";
// import PriceClientReview from "./PriceClientReview";
// import FaqAskQ from "./FaqAskQ";
import { testimonialData } from "./PriceData";
import ReviesAll from "../ReviesAll";
import AllFaq from "../AllFaq";
import { AllFaqDetail } from "./PriceData";
import { Helmet } from "react-helmet";

const Prices = () => {
  useEffect(() => {
    document.body.classList.add("price-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("price-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>SEO Packages | SEO Company USA | EZ Marketing Tech</title>
        <meta
          name="description"
          content="Looking for a cost-effective way to promote your business locally?
          Choose EZ Marketing Tech&#39;s local SEO packages and watch your business grow."
        />
      </Helmet>
      <PricesContent />
      <PriceTable />
      <ReadyPriceForm />
      <ReviesAll
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
      <AllFaq
        mainHeading="Frequently Asked Questions"
        detailFaq={AllFaqDetail}
      />
    </Fragment>
  );
};

export default Prices;
