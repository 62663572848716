import React, { Fragment, useEffect } from "react";
import LocalSeoBannerText from "./LocalSeoBannerText";
import LocalSeoPriceTable from "./LocalSeoPriceTable";
import ReadyPriceForm from "../PricesComponents/ReadyPriceForm";
import localSeoData from "./LocalSeoAllData";
import PriceAllFaq from "../PriceAllFaq";
import { Helmet } from "react-helmet";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";

function LocalSeo() {
  useEffect(() => {
    document.body.classList.add("price-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("price-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Local SEO Packages | Local SEO Company USA </title>
        <meta
          name="description"
          content=" Looking for a cost-effective way to promote your business locally? Choose EZ Marketing Tech's local SEO packages and watch your business grow."
        />
      </Helmet>
      <LocalSeoBannerText />
      <LocalSeoPriceTable />
      <ReadyPriceForm />

      <ReviesAll
        mainclassName="home_restimonials ppc_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
      <PriceAllFaq
        faqDetail={localSeoData}
        mainHeading="Frequently Asked Questions"
      />
    </Fragment>
  );
}

export default LocalSeo;
