import React, { Fragment, useEffect } from "react";
import SocialMediaBanner from "./SocialMediaBanner";
import SocialMediaAnalysis from "./SocialMediaAnalysis";
// import Seo from "../Seo";
import { Helmet } from "react-helmet";
import { DataFaq } from "./SocialMediaData";
import PriceAllFaq from "../PriceAllFaq";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
const SocialMediaMarketing = () => {
  useEffect(() => {
    document.body.classList.add(
      "social_media_marketing_seo",
      "inner-page-layout"
    );
    return () => {
      document.body.classList.remove("social_media_marketing_seo");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>
          Social Media Marketing Services | Social Media Marketing Company
        </title>
        <link rel="canonical" href="/social-media-marketing" />
        <meta
          name="description"
          content="Boost your brand's online presence &amp; drive more traffic with EZ Marketing Tech's social media marketing services. We'll create engaging content to attract the target audience &amp; retain customers."
        />
      </Helmet>
      <SocialMediaBanner />
      <SocialMediaAnalysis />

      <PriceAllFaq
        faqDetail={DataFaq}
        mainHeading="Frequently Asked Questions"
      />

      <ReviesAll
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default SocialMediaMarketing;
