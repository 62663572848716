import React, { Fragment } from "react";
function PpcBannerText() {
  return (
    <Fragment>
      <section className="price_table_banner">
        <div className="container">
          <div className="services_heading_wrapper">
            <h2>Select A Pricing Plan Tailored To Your Requirements</h2>
            <p>
            With EZ Marketing Tech's PPC packages, you can rest assured knowing that your campaigns are in the hands of experts. We handle every aspect, from thorough keyword research and compelling ad copy creation to continuous campaign optimization and monitoring. Check below for the perfect plan for your business needs.
            </p>
          </div>
          <div className="price_plane_wrapper">
            <div className="row row_scroll m-0">
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>PPC-Starter</h3>
                      <p>
                      This plan is designed for businesses to dip their toes into PPC advertising. We offer a solid foundation with basic campaign setup, keyword targeting, ad creation, and more!
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$399</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/bIYg0A9de3aG10I6ow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>PPC-Serious</h3>
                      <p>
                      Enjoy advanced campaign optimizations, comprehensive keyword research, ad testing, and ongoing monitoring to maximize ROI and drive conversions.
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$599</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/dR68y84WY5iO5gY8wF"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>PPC-Strategy</h3>
                      <p>
                      This plan is perfect for businesses seeking a comprehensive and targeted PPC strategy.
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$899</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/dR601C0GI4eK38Q3cm"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>PPC-Steroid</h3>
                      <p>
                      Supercharge your PPC results with this plan! It comes with in-depth competitor analysis, extensive keyword targeting, ad optimization, remarketing campaigns, and more!
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$1199</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/4gw29K4WYcLgbFm4gr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default PpcBannerText;
