import React, { Fragment } from "react";
// import ToolImgs0 from "../../ImagesComponents/all-images/tool_img.png";
// import ToolImgs1 from "../../ImagesComponents/all-images/tool_img2.png";
// import ToolImgs2 from "../../ImagesComponents/all-images/tool_img3.png";
// import ToolImgs3 from "../../ImagesComponents/all-images/tool_img4.png";
// import ToolImgs4 from "../../ImagesComponents/all-images/tool_img5.png";
let ToolImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img5.png",
];
function LocationTools() {
  return (
    <Fragment>
      <section className="tools_section text-center">
        <div className="container">
          <h3>Tools We Bank Upon</h3>
          <ul className="tool_list row">
            <li>
              <img src={ToolImgs[0]} alt="" />
            </li>
            <li>
              <img src={ToolImgs[1]} alt="" />
            </li>
            <li>
              <img src={ToolImgs[2]} alt="" />
            </li>
            <li>
              <img src={ToolImgs[3]} alt="" />
            </li>
            <li>
              <img src={ToolImgs[4]} alt="" />
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
}

export default LocationTools;
