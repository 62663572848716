import React, { Fragment } from "react";
import { HiPlus } from "react-icons/hi";
import { FaMinus } from "react-icons/fa";
// import "react-accessible-accordion/dist/fancy-example.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { NavLink } from "react-router-dom";
function FaqAskQ() {
  return (
    <Fragment>
      <section className="freFrequently_asked">
        <div className="container">
          <h2>Frequently Asked Questions</h2>

          <div className="freFrequently_acordian_wrapper">
            <Accordion preExpanded={["a"]}>
              <AccordionItem uuid="a">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>
                      <HiPlus className="auto_show" />
                      <FaMinus className="after_show" />
                      Is there a free trial available?
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    EZ Marketing Tech provides online market research solutions.
                    We are a skilled team of former business analysts and
                    technologists from Google and AOL who are familiar with the
                    ecosystem of search engines. We believe in what we offer,
                    and to provide client assurance, the client can make a
                    partial payment of 25% to start the project. However, no, we
                    don&#39;t offer a free trial.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>
                      <HiPlus className="auto_show" />
                      <FaMinus className="after_show" />
                      What are the payment options?
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    We offer more than one payment option, which helps our
                    clients feel at ease with their purchases. The most common
                    modes of payment available with us include credit cards,
                    PayPal, and bank transfers.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>
                      <HiPlus className="auto_show" />
                      <FaMinus className="after_show" />
                      Are there any hidden fees or costs?
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    These days, hidden costs seem to appear out of nowhere.
                    Sometimes you may find yourself paying for more than you had
                    planned, and for the majority of people, these charges are
                    usually unexpected. When additional costs aren&#39;t
                    immediately disclosed upfront, it can be especially
                    frustrating. Therefore, we believe in being upfront about
                    everything and have no hidden fees. If the client wants
                    anything that is not part of the package, we charge extra
                    depending on the work.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>
                      <HiPlus className="auto_show" />
                      <FaMinus className="after_show" />
                      How does billing work? Is it monthly or annually?
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Deciding whether to use a monthly or yearly billing cycle is
                    one of the most critical decisions for a client. The billing
                    at the end of the day is the client&#39;s choice. Though
                    preferably it is monthly, if the client wants to pay us
                    annually, we accept that as well.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>
                      <HiPlus className="auto_show" />
                      <FaMinus className="after_show" />
                      How do I upgrade or downgrade my plan?
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Our plans are flexible, which is one of our best features.
                    Slow month and fewer sales? No issue! Your volume has
                    doubled, and business is booming? Great! All you have to do
                    is get in touch with your project manager and sit back. He
                    or she will help you out.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>
                      <HiPlus className="auto_show" />
                      <FaMinus className="after_show" />
                      Do I have to sign a long-term contract?
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    EZ Marketing Tech is for you and with you. We do believe in
                    our client&#39;s vision, and we don&#39;t need any contracts
                    to be signed with us.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>
                      <HiPlus className="auto_show" />
                      <FaMinus className="after_show" />
                      What are the different pricing plans offered?
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    EZ Marketing Tech has a track record of successfully
                    bringing clients&#39; websites to the top of search engine
                    results, working with businesses ranging from large
                    corporations to several SMEs. Our company offers packages
                    per your needs and in various categories, such as SEO, SMO,
                    and PPC. For further information, check us out at {""}
                    <NavLink
                      to="/social-media-marketing-packages"
                      className="price_link"
                    >
                      https://www.ezmarketingtech.com/seo-packages
                    </NavLink>
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default FaqAskQ;
