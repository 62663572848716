import React, { Fragment } from "react";

const AllAmazonPriceTable = () => {
  return (
    <Fragment>
      <section className="amazon_price_table">
        <div className="container">
          <h2> PPC Management</h2>
          <div className="table-responsive">
            <table className="table price_table_list table-bordered table-striped table-hover">
              <thead>
                <tr className="price_tr">
                  <th></th>
                  <th>
                    <h4>AMAZON - STARTER</h4>
                  </th>
                  <th>
                    <h4>AMAZON - SERIOUS</h4>
                  </th>
                  <th>
                    <h4>AMAZON - STRATEGY</h4>
                  </th>
                  <th>
                    <h4>AMAZON - STEROID</h4>
                  </th>
                </tr>
              </thead>
              <tbody className="list_body">
                <tr>
                  <td>
                    <span>Progressive Monthly Management Cost</span>
                  </td>
                  <td>
                    <b>$499 </b>
                  </td>
                  <td>
                    <b>
                      $1200/month <br />
                      or
                      <br />
                      <span className="brak_word text-center">
                        14% of ads spend, whichever is greater
                      </span>
                    </b>
                  </td>
                  <td>
                    <b>
                      $1499/month <br />
                      or
                      <br />
                      <span className="brak_word text-center">
                        12% of ads spend, whichever is greater
                      </span>
                    </b>
                  </td>
                  <td>
                    <b>
                      $2499/month <br />
                      or
                      <br />
                      <span className="brak_word text-center">
                        10% of ads spend, whichever is greater
                      </span>
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Monthly ad spend</span>
                  </td>
                  <td>Upto $5000</td>
                  <td>$5001 to $15000</td>
                  <td>$15001 to $50000</td>
                  <td>$50000+</td>
                </tr>
                <tr>
                  <td>
                    <span>Keyword Research</span>
                  </td>
                  <td>Upto 10 keywords per product</td>
                  <td>Upto 20 keywords per product</td>
                  <td>Upto 30 keywords per product</td>
                  <td>Upto 50 Keywords per product</td>
                </tr>
                <tr>
                  <td>
                    <span>Dedicated Marketing Specialist for Amazon</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Online Project Management System</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Product Advertising Suggestions</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Campaign Strategy Development</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Campaign Setup</span>
                  </td>
                  <td>New Campaign Set up- $199 -Extra</td>
                  <td>New Campaign Set up- $299 -Extra</td>
                  <td>New Campaign Set up- $399 -Extra</td>
                  <td>New Campaign Set up- $499 - Extra</td>
                </tr>
                <tr>
                  <td>
                    <span>Negative Keyword Research</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Ad Creation</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Bid Adjustments</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Sponsored Product Ads, Sponsored Brands and Product
                      Display Ads (Brand Registered clients only)
                    </span>
                  </td>
                  <td>Only Sponsored Product Ads</td>
                  <td>Only Sponsored Product Ads</td>
                  <td>All 3 types of Ads (if applicable)</td>
                  <td>All 3 types of Ads (if applicable)</td>
                </tr>
                <tr>
                  <td>
                    <span>Promotion, Coupon, Lightning Deal Management</span>
                  </td>
                  <td>NA</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Subscribe and Save Recommendations</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Management of both Automatic and Manual Campaigns
                    </span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Quarterly Competitive Analysis</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Monthly Reporting</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </table>
            <h2> STORE &amp; MARKETPLACE LAUNCH</h2>
            <table className="table price_table_list table-bordered table-striped table-hover">
              <thead>
                <tr className="price_tr">
                  <th></th>
                  <th>
                    <h4>AMAZON - STARTER</h4>
                  </th>
                  <th>
                    <h4>AMAZON - SERIOUS</h4>
                  </th>
                  <th>
                    <h4>AMAZON - STRATEGY</h4>
                  </th>
                  <th>
                    <h4>AMAZON - STEROID</h4>
                  </th>
                </tr>
              </thead>
              <tbody className="list_body">
                <tr>
                  <td>
                    <span>One Time Setup (Over two months)</span>
                  </td>
                  <td>
                    <b>$1,499 </b>
                  </td>
                  <td>
                    <b>$1,999 </b>
                  </td>
                  <td>
                    <b>$2,999 </b>
                  </td>
                  <td>
                    <b>$4,999 </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Addition of products to Amazon</span>
                  </td>
                  <td>Upto 25 Parent Products</td>
                  <td>Upto 40 Parent Products</td>
                  <td>Upto 60 Parent Products</td>
                  <td>Upto 100 Parent Products</td>
                </tr>
                <tr>
                  <td>
                    <span>Category and Subcategory Suggestions</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Store Creation and Optimization for Amazon (If client is
                      brand registered)
                    </span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Review Best Practices Guide</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Review Response Framework Developed</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Product Description Copywriting: 1 page per product
                    </span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Competitor Analysis</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Amazon Buy Box Win Support</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Store Design (Brand Registered Clients Only)</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Brand Registry Support</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Enhanced Brand Content Suggestions (Sellers Only)
                      Implementation of suggestions is an additional fee of:
                      $300 per page
                    </span>
                  </td>
                  <td>Yes/$300 Per Page</td>
                  <td>Yes/$300 Per Page</td>
                  <td>Yes/$300 Per Page</td>
                  <td>Yes/$300 Per Page</td>
                </tr>
              </tbody>
            </table>
            <h2>AMAZON SEO LISTING OPT.</h2>
            <table className="table price_table_list table-bordered table-striped table-hover">
              <thead>
                <tr className="price_tr">
                  <th></th>
                  <th>
                    <h4>AMAZON - STARTER</h4>
                  </th>
                  <th>
                    <h4>AMAZON - SERIOUS</h4>
                  </th>
                  <th>
                    <h4>AMAZON - STRATEGY</h4>
                  </th>
                  <th>
                    <h4>AMAZON - STEROID</h4>
                  </th>
                </tr>
              </thead>
              <tbody className="list_body">
                <tr>
                  <td>
                    <span>Progressive Monthly Management Cost</span>
                  </td>
                  <td>
                    <b>$749.00 </b>
                  </td>
                  <td>
                    <b>$1,249.00 </b>
                  </td>
                  <td>
                    <b>$1,749.00 </b>
                  </td>
                  <td>
                    <b>$2,599.00 </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Number of Products (including child ASINs)</span>
                  </td>
                  <td>Upto 25 Parent Products</td>
                  <td>Upto 40 Parent Products</td>
                  <td>Upto 60 Parent Products</td>
                  <td>Upto 100 Parent Products</td>
                </tr>
                <tr>
                  <td>
                    <span>Initial Product Analysis</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Comprehensive Product Analysis Report</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Competitive Product Price Analysis</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Product User Engagement Analysis</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Product Listings Keyword Mapping and Optimization
                    </span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Search Term Keyword Additions</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Product Title Optimization</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Product Imagery List (list of products that do not meet
                      Amazon recommended image number or have image errors)
                    </span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Product key features optimization</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Product Category Optimization</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Product Review Evaluation</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Video Additions (Video provided by client)</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Image Additions (Image provided by client)</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Initial campaign development &amp; strategy</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Initial inventory and backend review</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      New Products Consulting &amp; Integration into Amazon
                      Account (if new products are part of above 20 targeted
                      products)
                    </span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Existing Product Optimization Suggestions and
                      Implementations (if part of above 20 targeted products)
                    </span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      Amazon Case Management (if case arises from above 20
                      targeted products)
                    </span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>In-Stock Rate Monitoring</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Perfect Order Percentage Monitoring</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Order Defect Rate Monitoring</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Amazon Inventory Planning Monitoring</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Global Marketplace Suggestions</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Ongoing Product Optimization</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Quarterly Product Analysis</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Quarterly Competitor Analysis</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Monthly Pricing &amp; Sales Monitoring</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Monthly Amazon Reporting (2 hours included)</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </table>
            <h2> AMAZON LISTING ONLY</h2>
            <table className="table price_table_list table-bordered table-striped table-hover">
              <thead>
                <tr className="price_tr">
                  <th></th>
                  <th>
                    <h4>AMAZON - STARTER</h4>
                  </th>
                  <th>
                    <h4>AMAZON - SERIOUS</h4>
                  </th>
                  <th>
                    <h4>AMAZON - STRATEGY</h4>
                  </th>
                  <th>
                    <h4>AMAZON - STEROID</h4>
                  </th>
                </tr>
              </thead>
              <tbody className="list_body">
                <tr>
                  <td>
                    <span>One time listing set up</span>
                  </td>
                  <td>
                    <b>$249.00 </b>
                  </td>
                  <td>
                    <b>$749.00 </b>
                  </td>
                  <td>
                    <b>$1,249.00 </b>
                  </td>
                  <td>
                    <b>$2,549.00 </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Product Coverage</span>
                  </td>
                  <td>Upto 100-200 Products</td>
                  <td>Upto 201-500 Products</td>
                  <td>Upto 501 - 1000 Products</td>
                  <td>More than 1000+ products</td>
                </tr>
                <tr>
                  <td>
                    <span>Image Additions</span>
                  </td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                </tr>
                <tr>
                  <td>
                    <span>Product Category Approval</span>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Product Title Headline</span>
                  </td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                </tr>
                <tr>
                  <td>
                    <span>Product Description</span>
                  </td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                  <td>Provided by client</td>
                </tr>
                <tr>
                  <td>
                    <span>Search Term Inclusion</span>
                  </td>
                  <td>5 per product</td>
                  <td>5 per product</td>
                  <td>5 per product</td>
                  <td>5 per product</td>
                </tr>
                <tr>
                  <td>
                    <span>With Monthly Optimization</span>
                  </td>
                  <td>$1,299.00 </td>
                  <td>$2,499.00 </td>
                  <td>$3,499.00 </td>
                  <td>$4,499.00</td>
                </tr>
              </tbody>
            </table>
            <h2> AMAZON A+ PRODUCT PAGES</h2>
            <table className="table price_table_list table-bordered table-striped table-hover">
              <thead>
                <tr className="price_tr">
                  <th></th>
                  <th>
                    <h4>PER PAGES</h4>
                  </th>
                </tr>
              </thead>
              <tbody className="list-body">
                <tr>
                  <td>
                    <span>Price Per Page</span>
                  </td>
                  <td>
                    <b>$299.00 </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Creative layout suggestions</span>
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>A+ product image creation and optimization</span>
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>Comparision charts</span>
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <span>A+ page setup</span>
                  </td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AllAmazonPriceTable;
