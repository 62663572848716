import React from "react";
import { BsCheckLg } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
function LocalSeoPriceTable() {
  return (
    <section className="Packages_section">
      <div className="container">
        <h2>
          Compare Our Packages and Choose the Perfect Fit for Your Business
        </h2>
        <div className="table-responsive">
          <table className="table price_table_list table-bordered table-striped table-hover">
            <thead>
              <tr className="price_tr">
                <th></th>
                <th>
                  <h4>Starter Plan</h4>
                  <span>
                    $500<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Serious Plan</h4>
                  <span>
                    $700<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Strategy Plan</h4>
                  <span>
                    $900<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Steroid Plan</h4>
                  <span>
                    $1200<sub>/Month</sub>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="list_body">
              <tr>
                <td>
                  <strong>Citation & Page Optimization</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>Location optimized</span>
                </td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>5</td>
              </tr>
              <tr>
                <td>
                  <span>Google Business Profile – Setup</span>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Google Business Profile - Optimization</span>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Locally Optimized Webpages</span>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Embed GBP to Contact Us Page</span>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Schema for Local Business</span>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Citation Management</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Apple Map</span>
                </td>
                <td>$</td>
                <td>$</td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Yelp Listing Creation</span>
                </td>
                <td>$</td>
                <td>$</td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Additional Local Business Listing</span>
                </td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
              </tr>

              <tr>
                <td>
                  <span>Yext Paid Listing</span>
                </td>
                <td>$</td>
                <td>$</td>
                <td>$</td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Off-Page Optimization Activities</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Blog Posting</span>
                </td>
                <td>1</td>
                <td>1</td>
                <td>2</td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  <span>Profile Creation</span>
                </td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>3</td>
              </tr>
              <tr>
                <td>
                  <span>Blog Marketing</span>
                </td>
                <td>5</td>
                <td>10</td>
                <td>15</td>
                <td>12</td>
              </tr>
              <tr>
                <td>
                  <span>Classified Submission</span>
                </td>
                <td>3</td>
                <td>6</td>
                <td>10</td>
                <td>10</td>
              </tr>
              <tr>
                <td>
                  <span>PPT Submission</span>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>1</td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  <span>Paid PR</span>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>1</td>
              </tr>
              <tr>
                <td>
                  <span>PR Marketing</span>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>
                  <RxCross2 className="fa-red"/>
                </td>
                <td>10</td>
              </tr>

              <tr>
                <td>
                  <strong>Monthly Conference Calls</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>GBP Posting</span>
                </td>
                <td>4</td>
                <td>6</td>
                <td>8</td>
                <td>16</td>
              </tr>
              <tr>
                <td>
                  <strong>Reports</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Google Business Profile - Performance Report</span>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>

              <tr>
                <td>
                  <span>Google local 3- Pack Rankings</span>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/aEUbKkgFG4eK7p67sF"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/eVa15GblmfXs8ta5ky"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/eVa3dO3SU12y6l2fZd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/7sIeWw89aeTo24MbIY"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default LocalSeoPriceTable;
