import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { IoLogoYoutube } from "react-icons/io";
// import ApprochIconImgs0 from "../../ImagesComponents/all-images/approch_icon.png";
// import ApprochIconImgs1 from "../../ImagesComponents/all-images/approch_icon2.png";
// import ApprochIconImgs2 from "../../ImagesComponents/all-images/approch_icon3.png";
// import ApprochIconImgs3 from "../../ImagesComponents/all-images/approch_icon4.png";
// import ApprochIconImgs4 from "../../ImagesComponents/all-images/approch_icon5.png";
// import ApprochIconImgs5 from "../../ImagesComponents/all-images/roi_figure.png";
let ApprochIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/roi_figure.png",
];
// import NavadaImgs0 from "../../ImagesComponents/all-images/navada_icon1.png";
// import NavadaImgs1 from "../../ImagesComponents/all-images/navada_icon2.png";
// import NavadaImgs2 from "../../ImagesComponents/all-images/navada_icon3.png";
// import NavadaImgs3 from "../../ImagesComponents/all-images/navada_icon4.png";
// import NavadaImgs4 from "../../ImagesComponents/all-images/navada_icon5.png";
// import NavadaImgs5 from "../../ImagesComponents/all-images/navada_icon6.png";
let NavadaImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon6.png",
];
function LocationSeoApproch() {
  return (
    <Fragment>
      <section className="seo_approch_sec">
        <div className="container">
          <h2>
            Transform Your Online Presence & Drive Growth With Our
            <br />
            Results-Driven SEO Approaches In Nevada
          </h2>
          <p>
            In today's digital world, having a robust online presence is
            essential for any business to retain the minds of its audience and
            succeed. However, navigating the complex world of search engine
            optimization (SEO) can be challenging.
          </p>
          <p>
            That's where we come in! EZ Marketing Tech is a leading provider of
            SEO services in Nevada, offering customized solutions to help
            businesses improve their online visibility and attract more
            customers. With our results-driven approaches and commitment to
            excellence, we are dedicated to providing help to our clients so
            they can succeed online.
          </p>
          <div className="row j-center">
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[0]} alt="img" />
                <h3>
                  Improve Search Engine
                  <br />
                  Rankings
                </h3>
                <p>
                  Our team of experts uses proven techniques and best practices
                  to help improve your website's ranking in SERPs, abbreviated
                  for search engine results pages. This means that more
                  potential customers will see your website and have the
                  opportunity to engage with your business.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[1]} alt="img" />
                <h3>
                  Increase Website
                  <br />
                  Traffic
                </h3>
                <p>
                  With higher search engine rankings, your website will receive
                  more organic traffic from potential customers searching for
                  products and services like yours. This leads to increased
                  exposure and can help drive more business for your company.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col mb-5 b-left ">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[2]} alt="img" />
                <h3>
                  Boost Brand
                  <br />
                  Awareness
                </h3>
                <p>
                  By improving your website's visibility and ranking, you can
                  build a more substantial online presence and increase brand
                  awareness. This can help differentiate your business from the
                  competition and establish you as a leader in your industry.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[3]} alt="img" />
                <h3>
                  Attract More
                  <br />
                  Customers
                </h3>
                <p>
                  With more traffic to your website and a stronger online
                  presence, you'll be able to attract more customers and
                  generate more leads. This can help grow your business and
                  increase revenue over time.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[4]} alt="img" />
                <h3>
                  Enhance User
                  <br />
                  Experience
                </h3>
                <p>
                  This includes ensuring your website is easy to navigate, loads
                  quickly and is mobile-friendly. This can help improve
                  engagement, reduce bounce rates, and provide a positive user
                  experience for potential customers.
                </p>
              </div>
            </div>
          </div>
          <div className="strategies_wrap">
            <h3>
              Get Ahead Of The Game With EZ MarketingTech's Proven Strategies
            </h3>
            <p>
              The competition is fierce, and it's essential to have an effective
              SEO strategy in place to stand out from the crowd. Here we come to
              the rescue!
            </p>
            <p>
              At EZ Marketing Tech, we help businesses like yours succeed
              online. Our team of SEO experts has years of experience and a
              track record of delivering results for our clients. Our approach
              is results-driven and tailored to meet each business's needs and
              goals.
            </p>
            <p>
              Our SEO services include keyword research, on-page optimization,
              content creation, link building, and more. We use only the latest
              techniques and best practices, ensuring your website stays ahead
              of the curve and at the top of the SERPs. Our team is always
              available to answer questions, provide updates, and make changes
              as needed.
            </p>
          </div>
          <figure className="roi_figure">
            <img src={ApprochIconImgs[5]} alt="img" />
            <figcaption className="roi_caption">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5 col-lg-5"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                  <h4>
                    Get The Best ROI With Our
                    <br /> Proven SEO Services
                  </h4>
                  <p>
                    We aim to maximize your online presence and drive growth for
                    your business. Whether you're looking to increase website
                    traffic, build brand awareness, attract more customers, or
                    all of the above, we have the expertise to help you succeed.
                    Rest assured that your investment in SEO will deliver a
                    positive return!
                  </p>
                  <p>
                    Don't let your business get left behind in the competitive
                    world of online marketing. Contact us today and learn how we
                    can help you maximize your online presence and drive growth
                    for your Nevada-based business.
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </figcaption>
          </figure>
          <div className="navada_social text-center">
            <h3>Best SEO Services In Nevada</h3>
            <p>So That You Make Your Mark On</p>
            <ul className="navada_Social_list">
              {/* <li>
                <a href="/">
                  <img src={NavadaImgs0} alt="img" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://goo.gl/maps/9sbfKJ2rmYFLoJFu9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[0]} alt="img location" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ezmarketingtech?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[1]} alt="img twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ezmarketingtech/ "
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[2]} alt="img facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ezmarketingtech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[3]} alt="img instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/ez-marketing-tech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[4]} alt="img" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCU1DcAmBpnswfX88X3E7XTA "
                  rel="noreferrer"
                  target="_blank"
                >
                  <IoLogoYoutube className="you_tube_icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default LocationSeoApproch;
