import React, { Fragment, useState } from "react";
// import TabsIconImgs0 from "../../ImagesComponents/all-images/social_tabs_icon.png";
// import TabsIconImgs1 from "../../ImagesComponents/all-images/social_tabs_icon2.png";
// import TabsIconImgs2 from "../../ImagesComponents/all-images/social_tabs_icon3.png";
// import TabsIconImgs3 from "../../ImagesComponents/all-images/social_tabs_icon4.png";
let TabsIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/social_tabs_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/social_tabs_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/social_tabs_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/social_tabs_icon4.png",
];
const SocialTabs = () => {
  const [TabsToggle, SetToggleTabs] = useState(1);
  const TabsChange = (index) => {
    SetToggleTabs(index);
    console.log(index);
  };
  return (
    <Fragment>
      <section className="social_tab_sec">
        <div className="container">
          <div className="social_tabs_wrapper">
            <ul className="tabs_link_list">
              <li
                onClick={() => TabsChange(1)}
                className={
                  TabsToggle === 1
                    ? "tabs_links tabs_link_active"
                    : "tabs_links"
                }
              >
                <img src={TabsIconImgs[0]} alt="img" />
                <strong>Specific Audience Targeting</strong>
              </li>
              <li
                onClick={() => TabsChange(2)}
                className={
                  TabsToggle === 2
                    ? "tabs_links tabs_link_active"
                    : "tabs_links"
                }
              >
                <img src={TabsIconImgs[1]} alt="img" />
                <strong>Cost-Effective Promotion</strong>
              </li>
              <li
                onClick={() => TabsChange(3)}
                className={
                  TabsToggle === 3
                    ? "tabs_links tabs_link_active"
                    : "tabs_links"
                }
              >
                <img src={TabsIconImgs[2]} alt="img" />
                <strong>More Exposure</strong>
              </li>
              <li
                onClick={() => TabsChange(4)}
                className={
                  TabsToggle === 4
                    ? "tabs_links tabs_link_active"
                    : "tabs_links"
                }
              >
                <img src={TabsIconImgs[3]} alt="img" />
                <strong>Better Search Rankings</strong>
              </li>
            </ul>

            <div className="social_tabs_content">
              <div
                className={
                  TabsToggle === 1
                    ? "tabs_content tabs_content_active"
                    : "tabs_content"
                }
              >
                <p>
                  Social media platforms provide the convenience of selecting an
                  audience suited to your demographics. This eases turning
                  prospective buyers into loyal customers.
                  <br />
                  Each platform differs from one another in the medium it offers
                  to interact with your social circle. It also offers a specific
                  audience. For example, Twitter has users active in global
                  events, whereas Instagram is famous for uploading flashy and
                  entertaining reels.
                  <br />
                  Choosing the right social media platform is an excellent start
                  to your social campaigning venture!
                </p>
              </div>
              <div
                className={
                  TabsToggle === 2
                    ? "tabs_content tabs_content_active"
                    : "tabs_content"
                }
              >
                <p>
                  You can execute social media campaigns on these platforms
                  without incurring high cost. Social media marketing can be a
                  better option than digital marketing because the latter needs
                  immense patience.
                  <br /> Small business owners can get overloaded with
                  significant finances. The expenses for promoting their brand
                  can be an insult to injury. Hence, social media marketing is
                  the best thing they can turn to to promote their brand.
                </p>
              </div>
              <div
                className={
                  TabsToggle === 3
                    ? "tabs_content tabs_content_active"
                    : "tabs_content"
                }
              >
                <p>
                  It’s not a hidden fact; more users are present on social media
                  platforms than search engines like Google and Bing. The crowd
                  culture on social media platforms offers your social media ad
                  campaigns more visibility and momentum.
                  <br /> A PPC ad on a social media platform is more potent in
                  gathering users than a PPC ad on search engines like Google.
                  <br />
                  Social media marketing experts plan to integrate as many
                  social media platforms as possible across the network. It
                  catches them the attention of a vast social media herd.
                </p>
              </div>
              <div
                className={
                  TabsToggle === 4
                    ? "tabs_content tabs_content_active"
                    : "tabs_content"
                }
              >
                <p>
                  Popular and giant search engines integrate the latest posts
                  and trending topics in user searches. It makes the
                  searchability of your social media campaigns more visible.
                  <br />
                  Users share your campaign information online within their
                  social media sphere. This activity indicates significant
                  search engines about engaging campaigns.
                  <br />
                  When search engines realize the campaign is worth sharing,
                  they also join the movement to share it more with online
                  searchers. Hence, more suggestions from Google guarantees
                  heightened promotions for your brands and campaigns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SocialTabs;
