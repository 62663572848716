import React from "react";
// import cbdImgs0 from "../../ImagesComponents/all-images/page_product_img.jpg";
// import cbdImgs1 from "../../ImagesComponents/all-images/approch_icon.png";
// import cbdImgs2 from "../../ImagesComponents/all-images/approch_icon2.png";
// import cbdImgs3 from "../../ImagesComponents/all-images/approch_icon3.png";
// import cbdImgs4 from "../../ImagesComponents/all-images/approch_icon4.png";
// import cbdImgs5 from "../../ImagesComponents/all-images/approch_icon5.png";
// import cbdImgs6 from "../../ImagesComponents/all-images/technical1.png";
// import cbdImgs7 from "../../ImagesComponents/all-images/technical2.png";
// import cbdImgs8 from "../../ImagesComponents/all-images/technical3.png";
// import cbdImgs9 from "../../ImagesComponents/all-images/technical4.png";
// import cbdImgs10 from "../../ImagesComponents/all-images/technical5.png";
// import cbdImgs11 from "../../ImagesComponents/all-images/technical6.png";
// import cbdImgs12 from "../../ImagesComponents/all-images/technical7.png";
// import cbdImgs13 from "../../ImagesComponents/all-images/technical8.png";
// import cbdImgs14 from "../../ImagesComponents/all-images/technical9.png";
// import cbdImgs15 from "../../ImagesComponents/all-images/technical10.png";
// import cbdImgs16 from "../../ImagesComponents/all-images/technical11.png";
// import cbdImgs17 from "../../ImagesComponents/all-images/technical12.png";
// import PageProductMobile from "../../ImagesComponents/all-images/page_product_img_mobile.gif";
let cbdImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/page_product_img.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/approch_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical1.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical6.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical7.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical8.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical9.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical10.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical11.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/technical12.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/page_product_img_mobile.gif",
];
const PageProduct = () => {
  return (
    <>
      <section className="page_product">
        <div className="container">
          <h2>
            <strong>In-Page Product Recommendation Carousel</strong>
          </h2>
          <p>
            One of the biggest avenues for CBD marketing is affiliate marketing.
            A big part of it comprises influencers on Instagram. Instagram
            influencer marketing is an excellent way to build your brand
            visibility, get more followers, and offer a better customer
            experience. This is even more essential in the CBD industry where
            digital advertising is challenging and people are still skeptical
            about the use and effectiveness of CBD products.
            <br /> Having influencers, or even micro-influencers, discuss CBD on
            a personal level helps bridge the gap between brands and consumers
            and build awareness for a larger market share eventually.
          </p>

          <figure className="product_figure mt-5 mb-5">
            <picture>
              <source media="(max-width: 767px)" srcset={cbdImgs[18]} />
              <img src={cbdImgs[0]} alt="img" width="100%" />
            </picture>

            <figcaption className="product_caption ">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-7"></div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-5">
                  <div className="product_figure_text">
                    <h3>
                      Strategies to Market CBD
                      <br /> Business Online
                    </h3>
                    <ul>
                      <li>Get your technical SEO right.</li>
                      <li>Optimized website and landing pages with</li>
                      <li>the potential targeted keywords.</li>
                      <li>Create optimized pages.</li>
                      <li>Grow Content.</li>
                      <li>Build links to it.</li>
                      <li>Advertise with Paid ads.</li>
                    </ul>
                    <a href="/" className="know_more">
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </figcaption>
          </figure>

          <div className="approch_section text-center mb-5">
            <h3>STEPS/APPROACH TO IMPROVE PERFORMANCE</h3>
            <div className="row j-center v-sm-center">
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="approch_box">
                  <img src={cbdImgs[1]} alt="img" />
                  <h4>Technical Elements</h4>
                  <p>
                    Identify the technical elements of section of the website.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="approch_box approch_box_blue">
                  <img src={cbdImgs[2]} alt="img" />
                  <h4>On Page Elements</h4>
                  <p>
                    Redrafting the elements with the proper use of keywords in
                    the main elements.
                  </p>
                </div>
              </div>
            </div>
            <div className="row j-center">
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="approch_box approch_box_red">
                  <img src={cbdImgs[3]} alt="img" />
                  <h4>URL Revision</h4>
                  <p>Two or more URLs running same content on webpages.</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="approch_box approch_box_light_grrn">
                  <img src={cbdImgs[4]} alt="img" />
                  <h4>Content</h4>
                  <p>Content Optimization & Internal Linking Improvement.</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="approch_box approch_box_dark_blue">
                  <img src={cbdImgs[5]} alt="img" />
                  <h4>Off Page Activation</h4>
                  <p>Content based focused Off page activities.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="key_process text-left mb-5">
            <h3>Key Tasks in Campaign Process</h3>
            <div className="key_content_wrap">
              <div className="key_content">
                <div className="row mb-lg-5">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[6]} alt="img" />
                      <h4>Technical Health</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[7]} alt="img" />
                      <h4>Opportunity Analysis</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[8]} alt="img" />
                      <h4>Load Time Optimization</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[9]} alt="img" />
                      <h4>
                        Competitor Analysis
                        <br />
                        with their backlinks
                      </h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[10]} alt="img" />
                      <h4>Website Content Analysis</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[11]} alt="img" />
                      <h4>
                        WSetting Up <br />
                        Conversion Tracking
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[12]} alt="img" />
                      <h4>TKeyword Identification</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[13]} alt="img" />
                      <h4>Mapping</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[14]} alt="img" />
                      <h4>
                        Finalizing Content
                        <br />
                        Strategy
                      </h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[15]} alt="img" />
                      <h4>
                        On Page <br /> Optimization
                      </h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[16]} alt="img" />
                      <h4>
                        Off Page Content <br /> Planning
                      </h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-2 sm-mb-3 mb-md-3">
                    <div className="key_text">
                      <img src={cbdImgs[17]} alt="img" />
                      <h4>
                        Monthly Reporting <br /> & Analysis
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageProduct;
