import React, { useEffect } from "react";
import CbdBanner from "./CbdBanner";
import TableContent from "./TableContent";
import CbdIndustry from "./CbdIndustry";
import CbdMarketing from "./CbdMarketing";
import KeywordsResearch from "./KeywordsResearch";
import CbdKeywords from "./CbdKeywords";
import HighlighValue from "./HighlighValue";
import SocialBring from "./SocialBring";
import PageProduct from "./PageProduct";
import SeoActivities from "./SeoActivities";

const CbdIndex = () => {
  useEffect(() => {
    document.body.classList.add("cbd-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("cbd-layout");
    };
  }, []);
  return (
    <>
      <CbdBanner />
      <TableContent />
      <CbdIndustry />
      <CbdMarketing />
      <KeywordsResearch />
      <CbdKeywords />
      <HighlighValue />
      <SocialBring />
      <PageProduct />
      <SeoActivities />
    </>
  );
};

export default CbdIndex;
