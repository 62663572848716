import React, { Fragment } from "react";
// import ReviewBannerImgs from "../../ImagesComponents/all-images/review_banner.webp";
let ReviewBannerImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/review_banner.webp",
];
const ReviewBanner = () => {
  return (
    <Fragment>
      <section className="review_banner">
        <div className="container">
          <figure className="review_figure">
            <img src={ReviewBannerImgs[0]} alt="banner" width="100%" />
            <figcaption className="review_caption">
              <h2>
                Our clients love
                <br />
                What we do!!
              </h2>
              <p>
                Over 500+ companies use our Digital Marketing solutions
                <br /> to promote their business and the market better.
              </p>
            </figcaption>
          </figure>
        </div>
      </section>
    </Fragment>
  );
};

export default ReviewBanner;
