import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import LocationForm from "../LocationPageComponents/LocationForm";
function LocationBanner() {
  return (
    <Fragment>
      <section className="banner_section location_banner">
        <div className="container">
          <div className="location_banner_wrap">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <div className="location_banner_text">
                  <h2>
                    360° SEO Agency
                    <br />
                    Power Up Your Rankings
                    <br />
                    In Texas!
                  </h2>
                  <p>
                    Accelerate on the web with the help of the top SEO experts
                    in Texas. EZ Marketing Tech provides unbeatable results to
                    drive more organic traffic to your website.
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                <div className="location_proposal_wrap">
                  <h3>GET MY FREE PROPOSAL</h3>
                  <LocationForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default LocationBanner;
