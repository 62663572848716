import React, { Fragment, useEffect } from "react";
import ContactBanner from "./ContactBanner";
import ContsctForm from "./ContsctForm";
// import Seo from "../Seo";
import { Helmet } from "react-helmet";
function ContactUs() {
  useEffect(() => {
    document.body.classList.add("contact-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("contact-layout", "inner-page-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Contact Us | EZ Marketing Tech</title>
        <meta
          name="description"
          content="Contact EZ Marketing Tech today and speak with our SEO experts.
          We&#39;re here to answer your questions and provide tailored solutions to help your
          business succeed online."
        />
      </Helmet>
      <ContactBanner />
      <ContsctForm />
    </Fragment>
  );
}

export default ContactUs;
