import React, { Fragment, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
// import adsIconImgs0 from "../../ImagesComponents/all-images/ads_icon.png";
// import adsIconImgs1 from "../../ImagesComponents/all-images/googleAddsTabsImg.png";
// import adsIconImgs2 from "../../ImagesComponents/all-images/googleAddsTabsImg2.png";
// import adsIconImgs3 from "../../ImagesComponents/all-images/googleAddsTabsImg3.png";
// import adsIconImgs4 from "../../ImagesComponents/all-images/googleAddsTabsImg4.png";
// import adsIconImgs5 from "../../ImagesComponents/all-images/googleAddsTabsImg5.png";
// import adsIconImgs6 from "../../ImagesComponents/all-images/googleAddsTabsImg6.png";
// import adsIconImgs7 from "../../ImagesComponents/all-images/googleAddsTabsImg7.png";
// import adsIconImgs8 from "../../ImagesComponents/all-images/googleAddsTabsImg8.png";
let adsIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/ads_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/googleAddsTabsImg.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/googleAddsTabsImg2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/googleAddsTabsImg3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/googleAddsTabsImg4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/googleAddsTabsImg5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/googleAddsTabsImg6.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/googleAddsTabsImg7.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/googleAddsTabsImg8.png",
];
const PpcIdealUser = () => {
  const [ToggleTabs, SetToggleTabs] = useState(1);
  const changTab = (index) => {
    SetToggleTabs(index);
  };

  return (
    <Fragment>
      <section className="ideal_section text-center">
        <div className="container">
          <h3>Who Are the Ideal Users of PPC</h3>
          <p>
            PPC advertising is perfect for small companies as you get to
            determine how much you're ready to invest in every keyword and then
            handle the amount you'll be paying when your ads are viewed. Since
            you're more likely to draw in more customers intrigued by what you
            sell, this essentially guarantees that the funds you spend on
            advertisements aren't wasted.
          </p>

          <div className="row text-left">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
              <h4>
                google ads
                <span>
                  <img src={adsIconImgs[0]} alt="img" />
                </span>
              </h4>
              <p>
                The most popular PPC advertising network is Google Ads. You can
                use the platform to create campaigns displayed across all Google
                services. Since Google Ads ranks prospective advertisers
                depending on their ad quality, relevancy, and size, along with
                the price of the bid, selecting the appropriate ad format and
                keywords is crucial. Since Google is the most popular search
                engine, using Google Ads will get you the most impressions. When
                developing your PPC campaign using Google Ads, take into account
                the following factors:
              </p>
              <ul className="ads_list">
                <li>
                  <BsCheck2Circle className="bc_check" /> Ensure that your
                  keywords are applicable, well-liked, and likely to be
                  searched.
                </li>
                <li>
                  <BsCheck2Circle className="bc_check" /> Have a compelling
                  landing page with a clear, high-quality message.
                </li>
                <li>
                  <BsCheck2Circle className="bc_check" /> More ad clicks are
                  received at a lower cost for better quality scores.
                </li>
              </ul>
              <p>
                In conclusion, PPC advertising is a fantastic marketing tool for
                your company. It's a straightforward but powerful digital
                marketing strategy to advertise your goods, increase website
                traffic, and ultimately boost sales.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <div className="ppc_important_tabs google_adds_tabs">
                <div className="row row-sm-revrce h-100">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <ul className="ppc_important_tabs_heading">
                      <li
                        onClick={() => changTab(1)}
                        className={
                          ToggleTabs === 1
                            ? "ppc_tabs_item ppc_tabs_item_active"
                            : "ppc_tabs_item"
                        }
                      >
                        <h4>Shopping Ads</h4>
                      </li>
                      <li
                        onClick={() => changTab(2)}
                        className={
                          ToggleTabs === 2
                            ? "ppc_tabs_item ppc_tabs_item_active"
                            : "ppc_tabs_item"
                        }
                      >
                        <h4>Search Ads</h4>
                      </li>
                      <li
                        onClick={() => changTab(3)}
                        className={
                          ToggleTabs === 3
                            ? "ppc_tabs_item ppc_tabs_item_active"
                            : "ppc_tabs_item"
                        }
                      >
                        <h4>Video Ads</h4>
                      </li>
                      <li
                        onClick={() => changTab(4)}
                        className={
                          ToggleTabs === 4
                            ? "ppc_tabs_item ppc_tabs_item_active"
                            : "ppc_tabs_item"
                        }
                      >
                        <h4>Display Ads</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div
                      className={
                        ToggleTabs === 1
                          ? "ppc_important_tabs_content ppc_tabs_content_active"
                          : "ppc_important_tabs_content"
                      }
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <img src={adsIconImgs[1]} alt="img" width="100%" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <img src={adsIconImgs[2]} alt="img" width="100%" />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        ToggleTabs === 2
                          ? "ppc_important_tabs_content ppc_tabs_content_active"
                          : "ppc_important_tabs_content"
                      }
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <img src={adsIconImgs[3]} alt="img" width="100%" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <img src={adsIconImgs[4]} alt="img" width="100%" />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        ToggleTabs === 3
                          ? "ppc_important_tabs_content ppc_tabs_content_active"
                          : "ppc_important_tabs_content"
                      }
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <img src={adsIconImgs[5]} alt="img" width="100%" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <img src={adsIconImgs[6]} alt="img" width="100%" />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        ToggleTabs === 4
                          ? "ppc_important_tabs_content ppc_tabs_content_active"
                          : "ppc_important_tabs_content"
                      }
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <img src={adsIconImgs[7]} alt="img" width="100%" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <img src={adsIconImgs[8]} alt="img" width="100%" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PpcIdealUser;
