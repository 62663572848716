import React, { Fragment, useEffect } from "react";
import PpcBannerText from "./PpcBannerText";
import PpcPriceTable from "./PpcPriceTable";
import ReadyPriceForm from "../PricesComponents/ReadyPriceForm";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
import ppcPriceData from "./PpcPackagesData";
import PriceAllFaq from "../PriceAllFaq";
import { Helmet } from "react-helmet";
function PPcPrice() {
  useEffect(() => {
    document.body.classList.add("price-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("price-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>PPC (Pay-Per-Click) Package USA | Affordable PPC/SEM Plan</title>
        <meta
          name="description"
          content="Looking for expert PPC services to drive more leads and sales? Get our comprehensive Google AdWords management services that ensure maximum ROI and conversion rates."
        />
      </Helmet>
      <PpcBannerText />
      <PpcPriceTable />
      <ReadyPriceForm />

      <ReviesAll
        mainclassName="home_restimonials ppc_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
      <PriceAllFaq
        faqDetail={ppcPriceData}
        mainHeading="Frequently Asked Questions"
      />
    </Fragment>
  );
}

export default PPcPrice;
