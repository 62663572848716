import React, { Fragment, useEffect } from "react";
import ContentMarketingBanner from "./ContentMarketingBanner";
import ContentMarketingAnalysis from "./ContentMarketingAnalysis";
import ContentMarketingReputation from "./ContentMarketingReputation";
import { Helmet } from "react-helmet";

import { testimonialData } from "../PricesComponents/PriceData";
import dataContentMarketing from "./ContentMarketingAllData";
import PriceAllFaq from "../PriceAllFaq";

import ReviesAll from "../ReviesAll";
const ContentMarketingServ = () => {
  useEffect(() => {
    document.body.classList.add("ecommerce_marketing_seo", "inner-page-layout");
    return () => {
      document.body.classList.remove("ecommerce_marketing_seo");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Content Marketing Services | EZ Marketing Tech</title>
        <meta
          name="description"
          content="Elevate your brand and increase conversions with EZ Marketing
          Tech&#39;s content marketing services. Our experts will develop a customized plan to
          meet your specific business needs and goals."
        />
      </Helmet>
      <ContentMarketingBanner />

      <ContentMarketingAnalysis />
      <ContentMarketingReputation />
      {/* <FaqAskQ /> */}
      <PriceAllFaq
        faqDetail={dataContentMarketing}
        mainHeading="Frequently Asked Questions"
      />
      {/* <PriceClientReview
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
      /> */}
      <ReviesAll
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default ContentMarketingServ;
