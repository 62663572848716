import React, { Fragment } from "react";
// import ExTeamIconImgs0 from "../../ImagesComponents/all-images/ex_team.png";
// import ExTeamIconImgs1 from "../../ImagesComponents/all-images/ex_team2.png";
// import ExTeamIconImgs2 from "../../ImagesComponents/all-images/ex_team3.png";
// import ExTeamIconImgs3 from "../../ImagesComponents/all-images/ex_team4.png";
// import ExTeamIconImgs4 from "../../ImagesComponents/all-images/ex_team5.png";
let ExTeamIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/ex_team.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ex_team2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ex_team3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ex_team4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ex_team5.png",
];
function TopSeoNevada() {
  return (
    <Fragment>
      <section className="seo_nevada_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <h3>
                Soar To The
                <br />
                Top <span>seo</span> With Our
                <br />
                Unmatched Services
                <br />
                In Nevada
              </h3>
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[0]} alt="Ex Team icon" />
                  <h4>Experienced Team</h4>
                  <p>
                    From keyword optimization to content optimization and social
                    media campaigns, our team knows what it takes to get you the
                    organic traffic you're looking for. We help our clients
                    achieve their goals and drive business growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="customized_box">
                <p>
                  Are you tired of being stuck on search engine results' second
                  or third page? Do you want to enhance your online visibility
                  and drive more traffic to your website? Then it's time to
                  partner with a leading SEO agency in Nevada. Our team is
                  dedicated to delivering unmatched SEO services that will help
                  your business reach new heights online. With cutting-edge
                  techniques, you can trust us to boost your search engine
                  rankings and generate more leads.
                </p>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="experienced_seo_team text-center">
                      <div className="seo_team_wrap">
                        <img src={ExTeamIconImgs[1]} alt="Ex Team icon" />
                        <h4>Customized Strategies</h4>
                        <p>
                          We understand that every business is unique, so we
                          take a personalized approach to SEO. Our team will
                          work with you to understand your goals and challenges
                          and tailor our strategies to meet your needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="experienced_seo_team text-center">
                      <div className="seo_team_wrap">
                        <img src={ExTeamIconImgs[2]} alt="Ex Team icon" />
                        <h4>Results-Driven Approach</h4>
                        <p>
                          Our primary focus is always on delivering client
                          results. Our team stays up-to-date on the latest SEO
                          trends and best practices to ensure our strategies are
                          practical and efficient.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="experienced_seo_team text-center">
                      <div className="seo_team_wrap">
                        <img src={ExTeamIconImgs[3]} alt="Ex Team icon" />
                        <h4>Transparent Reporting</h4>
                        <p>
                          We believe in transparency and open communication with
                          our clients. Our team provides regular reporting on
                          the progress of your website and the impact of our
                          strategies, so you always know what's happening and
                          what to expect.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="experienced_seo_team text-center">
                      <div className="seo_team_wrap ">
                        <img src={ExTeamIconImgs[4]} alt="Ex Team icon" />
                        <h4>Ongoing Support</h4>
                        <p>
                          Our commitment to our clients doesn't end when our
                          work is done. We provide ongoing support to help
                          ensure your website ranks well and attracts customers
                          over time. Whether you have questions or concerns or
                          need updates to your website, we're here to help.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TopSeoNevada;
