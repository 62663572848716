import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";

const ReviewClientSliderVideo = () => {
  return (
    <Fragment>
      <section className="review_client_sec review_client_video_sec">
        <div className="container">
          <OwlCarousel
            className="rs-carousel owl-carousel review_client_carousel"
            loop={true}
            margin={10}
            nav={true}
            items={7}
            autoplay={true}
            autoplayHoverPause={true}
            video={true}
            responsive={{
              1: {
                items: 1,
              },

              768: {
                items: 1,
              },
              992: {
                items: 2,
              },
              1200: {
                items: 3,
              },
            }}
          >
            <div className="testimonial_cover cover_one">
              <div className="row w-100 m-0">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="testimonial_wrapper">
                    <iframe
                      width="100%"
                      height="353"
                      src="https://www.youtube.com/embed/ydA6LdjZI_I"
                      title="A Tabletop Discussion with EZ Marketing Tech | Dean Tida | Client&#39;s Testimonials"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial_cover cover_two">
              <div className="row w-100 m-0">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="testimonial_wrapper">
                    <iframe
                      width="100%"
                      height="353"
                      src="https://www.youtube.com/embed/SG28rmENFuk"
                      title="A Tabletop Discussion with EZ Marketing Tech | Andrew Fisher | Client&#39;s Testimonials"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial_cover cover_three">
              <div className="row w-100 m-0">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="testimonial_wrapper">
                    {/* <span className="quote_img">
                      <img src={ReviewClientImgVideoImgs[0]} alt="img" />
                    </span> */}
                    <iframe
                      width="100%"
                      height="353"
                      src="https://www.youtube.com/embed/s5V5eYCbsqk"
                      title="A Tabletop Discussion with EZ Marketing Tech | Gladys Scourtias | Client&#39;s Testimonials"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial_cover cover_one">
              <div className="row w-100 m-0">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="testimonial_wrapper">
                    <iframe
                      width="100%"
                      height="353"
                      src="https://www.youtube.com/embed/ydA6LdjZI_I"
                      title="A Tabletop Discussion with EZ Marketing Tech | Dean Tida | Client&#39;s Testimonials"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial_cover cover_two">
              <div className="row w-100 m-0">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="testimonial_wrapper">
                    <iframe
                      width="100%"
                      height="353"
                      src="https://www.youtube.com/embed/SG28rmENFuk"
                      title="A Tabletop Discussion with EZ Marketing Tech | Andrew Fisher | Client&#39;s Testimonials"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial_cover cover_three">
              <div className="row w-100 m-0">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="testimonial_wrapper">
                    <iframe
                      width="100%"
                      height="353"
                      src="https://www.youtube.com/embed/s5V5eYCbsqk"
                      title="A Tabletop Discussion with EZ Marketing Tech | Gladys Scourtias | Client&#39;s Testimonials"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
          <div className="view_more_wrapper">
            <a
              href="https://www.youtube.com/c/EZMarketingTech"
              target="_blank"
              rel="noopener noreferrer"
              className="view_more_link"
            >
              View More Reviews
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ReviewClientSliderVideo;
