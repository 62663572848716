import React, { Fragment, useEffect } from "react";
import EcommerceSeoBanner from "./EcommerceSeoBanner";
import FocusedSeo from "./FocusedSeo";
import SeoAnalysis from "./SeoAnalysis";
import EcomReputation from "./EcomReputation";
import FaqAskQ from "../PricesComponents/FaqAskQ";
// import PriceClientReview from "../PricesComponents/PriceClientReview";
import { Helmet } from "react-helmet";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
// import Seo from "../Seo";
function EcommerceSeo() {
  useEffect(() => {
    document.body.classList.add("ecommerce_seo", "inner-page-layout");
    return () => {
      document.body.classList.remove("ecommerce_seo");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>E-commerce SEO Services | E-commerce SEO Company</title>
        <meta
          name="description"
          content="Increase your online sales and revenue with EZ Marketing Tech's e-commerce SEO services. Our experts will optimize your site for high-quality traffic and conversions."
        />
      </Helmet>
      <EcommerceSeoBanner />
      <FocusedSeo />
      <SeoAnalysis />
      <EcomReputation />
      <FaqAskQ />
      {/* <PriceClientReview
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
      /> */}
      <ReviesAll
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
}

export default EcommerceSeo;
