import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

let servImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/TechnicalSEO.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/Off-PageSEO.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/AmazonSEO.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/LocalSEO.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/ECommerceSEO.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/On-PageSEO.svg",
];
const HomeScoreCheck = () => {
  return (
    <Fragment>
      <section className="rs-seo-website style1 modify1 serv_report_all">
        <div className="container">
          <div className="report_wrapper">
            <div className="row">
              <div className="col-12 col-sm-8 col-md-8 col-lg-8 ">
                <h3>
                  Get in touch with EZ Marketing Tech <br /> for a free SEO
                  Report!
                </h3>
                <p>
                  We'll send your a bespoke report that examines how well
                  <br /> your business is performing online.
                </p>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4 ai-center">
                <a className="btn-report" href="#get_going">
                  Let’s Get Started
                </a>
              </div>
            </div>
          </div>
          <article className="seo_service_all">
            <h2>Our SEO Services</h2>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
                <div className="all_service_wrapper">
                  <img src={servImgs[0]} alt="img" width="80" height="80" />
                  <h3>Technical SEO</h3>
                  <p>
                    Rankings can improve with optimizations on your website’s
                    back-end, such as improving page speed. By fixing these
                    errors, it makes it easier on search engines when they crawl
                    your website. And when you make search engines happy, your
                    rankings can make you even happier!
                  </p>

                  <HashLink
                    className="all_serv_more"
                    smooth
                    to="/seo-services/#seo_reason"
                  >
                    read more...
                  </HashLink>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
                <div className="all_service_wrapper">
                  <img src={servImgs[1]} alt="img" width="80" height="80" />
                  <h3>Off-Page SEO</h3>
                  <p>
                    Off-page optimization is crucial in establishing brand
                    credibility and increasing online exposure. Partner with our
                    SEO company and let us help you demonstrate your industry
                    expertise. We leverage social media marketing, link building
                    and influencer marketing to generate qualified links and
                    positive ratings.
                  </p>
                  {/* <NavLink className="all_serv_more">read more...</NavLink> */}
                  <HashLink
                    className="all_serv_more"
                    smooth
                    to="/seo-services/#seo_reason"
                  >
                    read more...
                  </HashLink>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
                <div className="all_service_wrapper">
                  <img src={servImgs[2]} alt="img" width="80" height="80" />
                  <h3>Amazon SEO</h3>
                  <p>
                    Differentiate your brand on Amazon and get found by millions
                    of customers who are ready to convert. We optimize your
                    Amazon product listings, maximize ad placements and
                    implement goal-oriented Amazon marketing services (AMS) SEO
                    solutions to promote your products to the right customers.
                  </p>
                  <NavLink to="/amazon-seo-services" className="all_serv_more">
                    read more...
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
                <div className="all_service_wrapper">
                  <img src={servImgs[3]} alt="img" width="80" height="80" />
                  <h3>Local SEO</h3>
                  <p>
                    Statistics show that 64 percent of customers search for
                    local businesses online. Improve your company’s local SEO
                    rankings with Google Business Profile or Google My Business
                    (GMB) optimization and attract high-intent customers. We
                    optimize your GMB profile and manage your online reputation
                    to grow your local following.
                  </p>
                  <HashLink
                    className="all_serv_more"
                    smooth
                    to="/seo-services/#seo_reason"
                  >
                    read more...
                  </HashLink>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
                <div className="all_service_wrapper">
                  <img src={servImgs[4]} alt="img" width="80" height="80" />
                  <h3>eCommerce SEO</h3>
                  <p>
                    Provide your customers with a 24/7 convenient shopping
                    experience and increase your client retention rate. We
                    launch targeted email marketing campaigns and optimize your
                    site for mobile and voice search to create personalized
                    brand experiences. We also offer Walmart Marketplace
                    integrations to increase your sales volume.
                  </p>
                  <NavLink
                    to="/ecommerce-seo-services"
                    className="all_serv_more"
                  >
                    read more...
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
                <div className="all_service_wrapper">
                  <img src={servImgs[5]} alt="img" width="80" height="80" />
                  <h3>On-Page SEO</h3>
                  <p>
                    Enhance your online visibility and earn high-quality
                    traffic. At Thrive, we ensure our SEO services are aligned
                    with search engines’ best practices to boost your website’s
                    trustworthiness. Our SEO experts publish unique, value-rich
                    content, optimize your headlines and HTML tags and utilize
                    high-resolution images.
                  </p>
                  <HashLink
                    className="all_serv_more"
                    smooth
                    to="/seo-services/#seo_reason"
                  >
                    read more...
                  </HashLink>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </Fragment>
  );
};

export default HomeScoreCheck;
