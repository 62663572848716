import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
// import ppcBannerImgs0 from "../../ImagesComponents/all-images/ppc_marketing_banner.jpg";
// import ppcBannerImgs1 from "../../ImagesComponents/all-images/ppc_management_items.png";
// import ppcBannerImgs2 from "../../ImagesComponents/all-images/ppc_management_items2.png";
// import ppcBannerImgs3 from "../../ImagesComponents/all-images/ppc_management_items3.png";
// import ppcBannerImgs4 from "../../ImagesComponents/all-images/ppc_management_items4.png";
let ppcBannerImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/ppc_marketing_banner.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/ppc_management_items.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ppc_management_items2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ppc_management_items3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ppc_management_items4.png",
];
const PpcmarketingBanner = () => {
  return (
    <Fragment>
      <secition className="ppc_marketing_banner">
        <figure className="ppc_marketing_figure">
          <img src={ppcBannerImgs[0]} alt="banner img" width="100%" />
          <figcaption className="ppc_marketing_caption">
            <div className="container-fluid pl-0 pr-0 h-100">
              <div className="row mr-0 ml-0 h-100">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 pl-0 pr-0">
                  <div className="row mr-0 ml-0 h-100 bg-color">
                    <div className="col-0 col-hide-xs col-sm-0 col-md-0 col-lg-0 col-xl-1 "></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                      <div className="ppc_marketing_caption_overlay">
                        <h2>
                          Gain the Ultimate Brand
                          <br />
                          Exposure with PPC Marketing
                        </h2>
                        <h4>
                          Are you experiencing difficulty persuading a client or
                          your manager
                          <br /> of the perks of PPC advertising? Learn the
                          basics of PPC and how
                          <br /> to incorporate it into your brand management
                          with us.
                        </h4>
                        <p>
                          Can we be frank with each other for a brief moment,
                          marketers? How well do you truly comprehend the
                          concept of paid advertising on a scale of 1 to 10?
                          Even though 45% of small businesses use online
                          advertising in some capacity, pay-per-click is still a
                          concept that many of us find difficult to understand.
                          You've come to the right place if you've heard a
                          little about PPC marketing and want to learn more
                          about it or know that you want to use PPC to market
                          your company but aren't sure where to begin.
                        </p>
                        <p>
                          Pay-per-click, or PPC, is a form of internet marketing
                          where advertisers are charged a fee each time one of
                          their ads is clicked. It essentially involves 'buying'
                          website visitors in addition to generating them
                          naturally. In essence, you only pay for advertising
                          when a user clicks on your ad. Our specialty, Google
                          Shopping, illustrates how PPC advertising can increase
                          visibility and profitability. But here's what it is in
                          a bit more detail:
                        </p>
                        <button className="process_start_btn">
                          {" "}
                          <NavLink to="/ppc-packages">get started</NavLink>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>
        <section className="ppc_managment_service text-center">
          <div className="container">
            <h3>PPC Services: Drive Sales With PPC Management Services</h3>
            <p>
              EZ Marketing Tech is a leading PPC management company that uses
              paid search to drive sales for your business. Whether you want to
              increase leads, website traffic, or both, our tech-enabled PPC
              services will help you achieve your goals, plus
              <br /> measure and maximize your ROI from PPC.
            </p>
            <form className="send_proposal">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    type="url"
                    name="url"
                    placeholder="Enter your website url"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <button type="submit" className="btn_submit">
                    Send Me a Proposal
                  </button>
                </div>
              </div>
            </form>
            <ul className="ppc_management_list">
              <li className="col-mb-md">
                <div className="ppc_management_list_cover">
                  <div className="list_icon_cover">
                    <img src={ppcBannerImgs[1]} alt="img" />
                  </div>

                  <h4>
                    Get a Seasoned
                    <br />
                    Partner That Manages
                  </h4>
                  <strong>
                    $120+
                    <small>Million</small>
                  </strong>
                  <h4>In Annual AD Spend</h4>
                </div>
              </li>
              <li className="col-mb-md">
                <div className="ppc_management_list_cover">
                  <div className="list_icon_cover">
                    <img src={ppcBannerImgs[2]} alt="img" />
                  </div>
                  <h4>
                    Fuel your PPC
                    <br />
                    Performance with
                  </h4>
                  <strong>$150+</strong>
                  <h4>In-house Certified Google Ads Experts</h4>
                </div>
              </li>
              <li className="col-mb-xs">
                <div className="ppc_management_list_cover">
                  <div className="list_icon_cover">
                    <img src={ppcBannerImgs[3]} alt="img" />
                  </div>
                  <h4>
                    Get a Google Premier
                    <br />
                    Partner with
                  </h4>
                  <strong>FIVE</strong>
                  <h4>Agency Specializations</h4>
                </div>
              </li>
              <li>
                <div className="ppc_management_list_cover">
                  <div className="list_icon_cover">
                    <img src={ppcBannerImgs[4]} alt="img" />
                  </div>
                  <h4>
                    Upgrade your PPC
                    <br />
                    results by
                  </h4>
                  <strong>20%</strong>
                  <h4>With Marketingcloudfx</h4>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </secition>
    </Fragment>
  );
};

export default PpcmarketingBanner;
