import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { BsArrowUpSquare } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";
import FooterLogo from "../../ImagesComponents/logo_secondary.png";
import nounCall from "../../ImagesComponents/bannerImg/noun-call.png";
import { MdClose } from "react-icons/md";
let envelope = [
  "https://ezmarketingtech.s3.amazonaws.com/img/email-envelope.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/footer-shaps.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/footer-shaps2.png",
];

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);
  const [addClass, setaddClass] = useState(" ");
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Fragment>
      <footer id="rs-footer" className="rs-footer style1 modify3 main-footer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="250 0 1000 150"
          className="footer_svg"
        >
          <path
            fill="#000214"
            fill-opacity="1"
            d="M0,192L60,170.7C120,149,240,107,360,85.3C480,64,600,64,720,80C840,96,960,128,1080,117.3C1200,107,1320,53,1380,26.7L1440,0L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
        <div className="subscribe-bg">
          <img
            src={envelope[1]}
            alt="overlay-img"
            className="footer_overlay footer_overlay1"
          />
          <img
            src={envelope[2]}
            alt="overlay-img"
            className="footer_overlay footer_overlay2"
          />
          <div className="container">
            <div className="subscribe-top">
              <div className="footer-logo md-mb-30">
                <NavLink to="/" className="sec-logo-wrap">
                  <img
                    src={FooterLogo}
                    alt="Logo Secondary"
                    height="70"
                    width="125"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top ">
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-3 col-md-6 col-sm-6  col-12 md-mb-30">
                <h3 className="widget-title">Featured Services</h3>
                <ul className="site-map">
                  <li>
                    <NavLink to="/seo-services">
                      Search Engine Optimization
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/amazon-seo-services">Amazon SEO</NavLink>
                  </li>
                  <li>
                    <NavLink to="/ppc-marketing">
                      Pay Per Click / Adwords
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/content-marketing-services">
                      Content Marketing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/social-media-marketing">
                      Social Media Marketing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/reputation-management-services">
                      Reputation Management
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6  col-12 md-mb-30">
                <h3 className="widget-title">Helpful Links</h3>
                <ul className="site-map">
                  <li>
                    <NavLink to="/seo-packages">Pricing</NavLink>
                  </li>
                  <li>
                    <NavLink to="/who-we-are">Who are we?</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/blog">Blog</NavLink>
                  </li> */}
                  <li>
                    <a href="https://ezmarketingtech.com/blog/">Blog</a>
                  </li>
                  <li>
                    <NavLink to="/reviews">Reviews</NavLink>
                  </li>
                  <li>
                    <NavLink to="/ecommerce-seo-services">
                      E-Commerce SEO
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/cbd">CBD Marketing</NavLink>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6  col-12 md-mt-3">
                <h3 className="widget-title">Location</h3>
                <ul className="site-map">
                  {/* <li>
                    <NavLink to="/main-service">serv</NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/seo-company-nevada">Nevada</NavLink>
                  </li>
                  <li>
                    <NavLink to="/seo-company-california">California</NavLink>
                  </li>
                  <li>
                    <NavLink to="/seo-company-colorado">Colorado</NavLink>
                  </li>
                  <li>
                    <NavLink to="/seo-company-maryland">Maryland</NavLink>
                  </li>
                  <li>
                    <NavLink to="/seo-company-texas">Texas</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6  col-12 md-mt-3 col-sm-nb-5">
                <h3 className="widget-title">Contact Us Now</h3>
                <address className="web_add">
                  EZ Marketing Tech
                  <br />
                  8605 Santa Monica,
                  <br />
                  Blvd #15749, West Hollywood,
                  <br />
                  California 90069-4109 US
                </address>
                <a
                  exact
                  href="tel:+1 (855) 781-2099"
                  className="info-text contact_secondary"
                >
                  +1 (855) 781-2099
                </a>
                <br />
                <a
                  exact
                  href="mailto:sales@ezmarketingtech.com"
                  className="info-text mail_secondary"
                >
                  sales@ezmarketingtech.com
                </a>
                <br />
                <ul className="footer-social md-mb-30">
                  <li>
                    <a
                      href="https://www.facebook.com/ezmarketingtech/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <FaFacebookF />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/ez-marketing-tech"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <GrLinkedinOption />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/ezmarketingtech?lang=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <GrTwitter />
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCU1DcAmBpnswfX88X3E7XTA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <GrYoutube />
                      </span>
                    </a>
                  </li>
                </ul>
                <NavLink to="contact-us" className="get_in_btn">
                  Get in Touch
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right  order-last">
                <ul className="copy-right-menu">
                  <li>
                    <NavLink to="/cancellation-policy">
                      Cancellation and Refund Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="copyright">
                  <p>
                    &copy;2023 <NavLink to="/"> EZ Marketing Tech</NavLink> |
                    <NavLink to="/"> All Rights Reserved.</NavLink>
                  </p>
                </div>
              </div>
            </div>

            <div className="expand-btn-inner  quote_overlay">
              <ul className="info_menu">
                <li className="rsphone">
                  <a exact href="tel:+1 (855) 781-2099">
                    <img src={nounCall} alt="img" />
                    <span>+1 (855) 781-2099</span>
                  </a>
                </li>
                <br />

                <li className="btn-quote">
                  <NavLink onClick={() => setaddClass("openForm")}>
                    <img src={envelope[0]} alt="envelope" />
                    <span>Get A Quote</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {isVisible && (
          <span onClick={scrollToTop} className="back_to_top">
            <BsArrowUpSquare />
          </span>
        )}
        {/* <span onClick={scrollToTop} className="back_to_top">
          <BsArrowUpSquare />
        </span> */}
      </footer>
      <div className="quote_form_wrapper" id={addClass}>
        <form
          className="quote_form"
          action="https://beta.ezmarketingtech.com/get-quote"
          method="POST"
        >
          <span className="close-form" onClick={() => setaddClass(" ")}>
            <MdClose />
          </span>
          <div className="row mx-6 get-quote-home">
            <input
              className="from-control"
              type="hidden"
              id="key"
              name="key"
              value="get-quote"
            />

            <div className="col-12 col-sm-12 col-md-6 mb-3 px-6">
              <input
                type="text"
                name="name"
                className="form-controll"
                placeholder="Your Name"
                required={true}
              />
            </div>

            <div className="col-12 col-sm-12 col-md-6 mb-3 px-6">
              <input
                type="tel"
                name="phone"
                className="form-controll"
                placeholder="Phone Number"
                required={true}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 mb-3 px-6">
              <input
                type="email"
                name="email"
                className="form-controll"
                placeholder="Email"
                required={true}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 mb-3 px-6">
              <input
                type="text"
                name="subject"
                className="form-controll"
                placeholder="Website URL"
                required={true}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 px-6">
              <textarea
                name="message"
                width="100%"
                rows="6"
                placeholder="Message"
                required={true}
              ></textarea>
            </div>
            <div className="get-quote-btn">
              <div className="form recaptcha_group mb-0">
                {/* <ReCAPTCHA
                  sitekey="6LfYM_oUAAAAAAkkme8vL33ls2GEqWhU3ZRCNX4C"
                  onChange={setTimeout(onChange, 2000)}
                  className="recaptcha"
                  ref={captchaRef}
                /> */}
              </div>
            </div>
            <button
              // disabled={!isCaptchaSuccessful}
              className="readon submit new"
            >
              Submit Now
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Footer;
