import React, { Fragment } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { BsArrowUpRight } from "react-icons/bs";
const PpcCampaign = () => {
  return (
    <Fragment>
      <section className="ppc_build_sec">
        <div className="container">
          <h2 className="text-center">How to Build a PPC Campaign</h2>
          <p className="text-center">
            Now that you know the advantages of PPC and have identified your key
            phrases, let's start creating a strong PPC marketing plan on Google
            AdWords or another platform. Although you don't have to deal with
            these issues one at a time, you must work through each to ensure
            your marketing campaign succeeds.
          </p>

          <h3>
            1. Set Parameters <BsArrowUpRight className="bc_check" />
          </h3>
          <p>
            Consider how your paid pay-per-click digital marketing campaigns
            will help you achieve your objectives. Next, consider what you want
            your advertising to achieve, such as visits, sales, brand
            recognition, or something else, and how much you're willing to spend
            to get there. You should include the following in your ads:
          </p>
          <ul className="buile_list">
            <li>
              <BsCheck2Circle className="bc_check" /> Whom do you wish to
              target?
            </li>
            <li>
              <BsCheck2Circle className="bc_check" /> Your campaign's theme.
            </li>
            <li>
              <BsCheck2Circle className="bc_check" /> How you plan to assess
              success.
            </li>
            <li>
              <BsCheck2Circle className="bc_check" /> What kind of campaign will
              you run?
            </li>
          </ul>
          <h3>
            2. Set Objectives and Goal Metrics
            <BsArrowUpRight className="bc_check" />
          </h3>
          <p>
            As long as you choose the metrics, you'll use to gauge your
            campaign's success, you'll have something to show for your
            pay-per-click ad investment.
          </p>
          <h3>
            3. Setup and Track Google Analytics
            <BsArrowUpRight className="bc_check" />
          </h3>
          <p>
            You should install Google Analytics on your website because it is
            free to use. The tool gives you insights into how well your website
            is doing, how visitors interact with your pages, and what kind of
            content draws them in. Google Analytics data can be utilized for PPC
            and other purposes.
          </p>
          <h3>
            4. Select Your "Ideal Type" of Campaign
            <BsArrowUpRight className="bc_check" />
          </h3>
          <p>
            Not only must you decide where to advertise, but also how. The type
            of paid PPC marketing campaign you choose will depend on how you
            reach your target audience. The types include:
          </p>
          <ul className="buile_list">
            <li>
              <BsCheck2Circle className="bc_check" /> Search Ads represent the
              most popular type of PPC. They refer to the text ads that appear
              on search engine results pages.
            </li>
            <li>
              <BsCheck2Circle className="bc_check" /> With display ads, you can
              post advertisements (typically image-based) on third-party
              websites, such as social media. Display ads can be purchased in
              various ways, including through the Google Display Network (GDN)
              and other ad networks.
            </li>
            <li>
              <BsCheck2Circle className="bc_check" /> Social refers to any
              advertisements you see on social media, such as Facebook,
              LinkedIn, Twitter, and Instagram. Depending on the platform, you
              can pay to appear in your target audience's social feed or another
              area of their profile.
            </li>
            <li>
              <BsCheck2Circle className="bc_check" /> Remarketing is a method of
              locating customers who have previously interacted with your
              business by taking some sort of action. It can use cookies or a
              list of contacts that you upload.
            </li>
            <li>
              <BsCheck2Circle className="bc_check" /> Google Shopping works best
              on e-commerce websites. Based on your target keywords, a carousel
              with your ad, complete with an image, price, and a succinct
              product description, will appear on the search results page.
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
};

export default PpcCampaign;
