import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { IoLogoYoutube } from "react-icons/io";
// import ApprochIconImgs0 from "../../ImagesComponents/all-images/colorado_icon.png";
// import ApprochIconImgs1 from "../../ImagesComponents/all-images/taxas_rang_icon.png";
// import ApprochIconImgs2 from "../../ImagesComponents/all-images/colorado_icon3.png";
// import ApprochIconImgs3 from "../../ImagesComponents/all-images/taxas_target_icon.png";
// import ApprochIconImgs4 from "../../ImagesComponents/all-images/talk_rio_img.png";
let ApprochIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/taxas_rang_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/taxas_target_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/talk_rio_img.png",
];
// import NavadaImgs1 from "../../ImagesComponents/all-images/navada_icon2.png";
// import NavadaImgs2 from "../../ImagesComponents/all-images/navada_icon3.png";
// import NavadaImgs3 from "../../ImagesComponents/all-images/navada_icon4.png";
// import NavadaImgs4 from "../../ImagesComponents/all-images/navada_icon5.png";
// import NavadaImgs5 from "../../ImagesComponents/all-images/navada_icon6.png";
let NavadaImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon6.png",
];
function LocationSeoApproch() {
  return (
    <Fragment>
      <section className="seo_approch_sec">
        <div className="container">
          <h2>
            Get Found, Get Seen!
            <br />
            Make Your Texas Business Stand Out With SEO
          </h2>
          <p>
            In today's digital world, having a robust online presence is
            essential for any business to retain the minds of its audience and
            succeed. However, navigating the complex world of search engine
            optimization (SEO) can be challenging.
          </p>
          <p>
            That's where we come in! EZ Marketing Tech is a leading provider of
            SEO services in Texas, offering customized solutions to help
            businesses improve their online visibility and attract more
            customers. With our results-driven approaches and commitment to
            excellence, we are dedicated to providing help to our clients so
            they can succeed online.
          </p>
          <div className="row j-center mt-5">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 h-100">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[0]} alt="img" />
                <h3>Increase Visibility</h3>
                <p>
                  Our team of experts uses proven techniques and best practices
                  to help improve your website's ranking in SERPs, abbreviated
                  for search engine results pages. This means that more
                  potential customers will see your website and have the
                  opportunity to engage with your business.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 h-100 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[1]} alt="img" />
                <h3>
                  Rank Above Your
                  <br />
                  Competitors
                </h3>
                <p>
                  With higher search engine rankings, your website will receive
                  more organic traffic from potential customers searching for
                  products and services like yours. This leads to increased
                  exposure and can help drive more business for your company.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-mb-3 h-100 b-left ">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[2]} alt="img" />
                <h3>Establish Credibility</h3>
                <p>
                  With more traffic to your website and a stronger online
                  presence, you'll be able to attract more customers and
                  generate more leads. This can help grow your business and
                  increase revenue over time.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 h-100 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[3]} alt="img" />
                <h3>Target Your Market</h3>
                <p>
                  This includes ensuring your website is easy to navigate, loads
                  quickly and is mobile-friendly. This can help improve
                  engagement, reduce bounce rates, and provide a positive user
                  experience for potential customers.
                </p>
              </div>
            </div>
          </div>
          <div className="strategies_wrap">
            <h3>
              Turbocharge Your Website’s Performance With EZ Marketing Tech
            </h3>
            <p>Turbocharge Your Website’s Performance With EZ Marketing Tech</p>
            <p>
              By leveraging data-driven insights and experienced search engine
              marketers, EZ Marketing Tech makes it easier to outrank your
              competitors in Texas, and improve rankings in local listings.
            </p>
            <p>
              Our services will deliver measurable results that include higher
              website traffic, greater visibility, and increased lead
              generation. With a powerful strategy and tailored approach to fit
              your budget, we provide you with the tools and expertise needed to
              win the ever-changing world of online marketing.
            </p>
            <p>
              When you work with EZ Marketing, you get access to leading SEO
              tactics and techniques and gain an experienced partner in success.
              As your website gets discovered and traffic grows, we continue to
              refine our efforts to deliver outstanding results.
              <br />
              Maximize the potential of your business today by starting with SEO
              today to give your business the boost it needs!
            </p>
          </div>
          <figure className="roi_figure">
            <img src={ApprochIconImgs[4]} alt="img" />
            <figcaption className="roi_caption">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5 col-lg-5"></div>
                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                  <h4>
                    Why Do You Need To Partner
                    <br /> With A Top SEO Agency In Texas?
                  </h4>
                  <p>
                    An amazing website, without proper SEO, is all in vain.
                    Search engine optimization helps your website to appear in
                    search results, leading to more traction and potential
                    revenue for your business from top cities like Dallas and
                    Houston. It can make your site not only user-friendly but
                    search engine friendly too, leading to a maximum number of
                    viewers.
                  </p>
                  <p>
                    Getting SEO services in Texas means increased website
                    traffic, more conversions, and overall business growth. SEO
                    is not a one-time investment; it requires an ongoing
                    commitment for the long haul. It will help your business to
                    become more profitable and successful in the long run.
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </figcaption>
          </figure>
          <div className="navada_social text-center">
            <h3>
              Unparalleled SEO Services In Texas
              <br />
              So That You Rule Over:
            </h3>

            <ul className="navada_Social_list location_Social_list">
              {/* <li>
                <a href="/">
                  <img src={NavadaImgs0} alt="img" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://goo.gl/maps/9sbfKJ2rmYFLoJFu9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[0]} alt="img location" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ezmarketingtech?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[1]} alt="img twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ezmarketingtech/ "
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[2]} alt="img facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ezmarketingtech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[3]} alt="img instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/ez-marketing-tech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[4]} alt="img" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCU1DcAmBpnswfX88X3E7XTA "
                  rel="noreferrer"
                  target="_blank"
                >
                  <IoLogoYoutube className="you_tube_icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default LocationSeoApproch;
