import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { serviceIntroData, serviceSocialData } from "./HomeData";
function bestService(val) {
  return (
    <div className="sec-title">
      {/* <span className="sub-text">{val.subText}</span> */}
      <h2 className="title pb-13">{val.titleText}</h2>
      <div className="description pr-36">{val.contentText}</div>
      <div className="btn-part home_serv_btn ">
        <NavLink className="readon started-now sss" to="/seo-services">
          {val.textlink}
        </NavLink>
        <NavLink className="readon started-now get-in" to="/contact-us">
          <span>{val.textlink2}</span>
        </NavLink>
      </div>
    </div>
  );
}
function serviceSocialMarketing(val) {
  return (
    <div className="col-lg-12 col-md-6 mb-40">
      <div className="services-item">
        <div className="services-icon">
          <img src={val.socialMediaImg} alt="images" />
        </div>
        <div className="services-text">
          <div className="services-title">
            <h4 className="title">{val.socialMediaLink}</h4>
          </div>
          <p className="services-txt">{val.socialMediaLinkText}</p>
          {/* <div className="blog-button services">
            <a href="social-media-marketing.html">{val.readMorelink}</a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
const HomeService = () => {
  return (
    <Fragment>
      <section
        id="rs-services"
        className="rs-services sec_home_rs_service style10 "
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 md-mb-50">
              {serviceIntroData.map(bestService)}
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="row">
                {serviceSocialData.map(serviceSocialMarketing)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default HomeService;
