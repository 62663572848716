import React from "react";
// import cbdBannerImgs0 from "../../ImagesComponents/all-images/ppt_banner_overlay.png";
// import cbdBannerImgs1 from "../../ImagesComponents/all-images/ppt_banner_circile.png";
let cbdBannerImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/ppt_banner_overlay.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/ppt_banner_circile.png",
];
const CbdBanner = () => {
  return (
    <>
      <section className="ppt_banner_Section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <div className="banner_over_img">
                <img src={cbdBannerImgs[0]} alt="img" width="100%" />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="banner_over_text">
                <img src={cbdBannerImgs[1]} alt="img" />
                <h2>Marketing Strategy for</h2>
                <h1>
                  CBD or
                  <br />
                  Cannabis
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CbdBanner;
