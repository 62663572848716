import parse from "html-react-parser";

let studySliderImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/study_slide_img.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/study_slide_img_over.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/study_slide_img_over2.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/study_slide_img_over3.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/study_slide_img_over4.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/study_slide_img_over5.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/study_slide_img_over6.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/study_slide_img_over7.webp",
];
let ConverSionImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/conversion1.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/conversion4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/conversion3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/conversion2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/conversion5.png",
];

let singleDetailImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/c-studies-single-page-img.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/c-studies-single-page-img2.png",
];

let proledgeImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/proledge_GSC.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/proledge_Organic.JPG",
  "https://ezmarketingtech.s3.amazonaws.com/img/proledge_Overall.JPG",
];

let redrunriteImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/redrunrite_GSC.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/redrunrite_Organic_Traffic.JPG",
  "https://ezmarketingtech.s3.amazonaws.com/img/redrunrite_Overall_Traffic.JPG",
  "https://ezmarketingtech.s3.amazonaws.com/img/redrunrite_Referral.JPG",
  "https://ezmarketingtech.s3.amazonaws.com/img/redrunrite_Social_traffic.JPG",
];

let eldoradoImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/eldorado_Direct.JPG",
  "https://ezmarketingtech.s3.amazonaws.com/img/eldorado_GSC.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/eldorado_Overall.JPG",
  "https://ezmarketingtech.s3.amazonaws.com/img/eldorado_Organic.JPG",
];

let BushingFixImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/BushingFix_Organic.JPG",
  "https://ezmarketingtech.s3.amazonaws.com/img/BushingFix_Overall.JPG",
  "https://ezmarketingtech.s3.amazonaws.com/img/BushingFix_Social _edia.JPG",
];

let treeworldgeImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/treeworld_GSC.jpg",
];
const SlideData = [
  {
    id: 1,
    slideImg: studySliderImgs[0],
    slideOverImg: studySliderImgs[1],
    SlideHeading: "About Hillcrest",
    ShortHeading: "Hillcrest Ski & Sports",
    weburl: "https://hillcrestsports.com/",
    SlideText:
      "Hillcrest Ski & Sports is a premier destination for skiing, skating, snowboarding, and water sports enthusiasts. It offers an extensive selection of top-notch products from renowned brands, ensuring customers access to high-quality equipment and gear.",
    CaseStudiesDetail: parse(` <div>
    <h4>  <strong>Industry: </strong> E-commerce (Sports Carries Option with wide range)</h4>
    <h4><strong>Opportunity/Challenge: </strong></h4>
    <p>Hillcrest Ski & Sports approached EZ Marketing Tech to boost their SEO efforts. Recognizing the
    increasing significance of search engine optimization in the business world, they wanted to stay
    ahead in the industry by improving their website&#39;s visibility and search rankings. By
    collaborating with EZ Marketing Tech, they aimed to enhance their online presence, attract
    organic traffic, and secure stronger positions for relevant keywords.</p><br/>
    <p>This was a seasional bussiness by which we faced traffic and conversion issues in the out of
    session time.</p><br/>
    <h4><strong>Solution: Search Engine Optimization</strong></h4>
    <p>EZ Marketing Tech has provided various services to Hillcrestsports, boosting its website, helping it
    generate more traffic, and eventually enhancing business outcomes. What EZ Marketing Tech did
    with this website included the following:</p><br/>
    <p>Studying the website and examining areas that can be improved through SEO.</p><br/>
    <p>Conducting in-depth research to identify relevant and trending keywords and phrases that
    potential visitors may use when searching for products, services, or information related to the
    website.</p>
    <p>Optimizing various on-page elements of the website, such as meta tags, headings, content
    structure, URL structure, internal linking, and keyword placement, to enhance its relevancy and
    crawl-ability for search engines.</p>
    <p>Assessing and improving technical aspects of the website, including website speed, mobile-
    friendliness, crawl-ability, indexability, XML sitemaps, robots.txt, and error handling, to ensure a
    search engine can easily access and understand the website.</p>
    <p>Implementing strategies to acquire high-quality backlinks from reputable and relevant websites.
    This includes outreach, content promotion, guest blogging, social media engagement, and
    fostering relationships with influencers or industry experts.</p>
    <p>Enhancing the website&#39;s visibility in local search results by optimizing for location-specific
    keywords.</p>
    <p>Staying updated with algorithm changes and industry trends to ensure the SEO strategies
    remain effective and compliant with best practices.</p>
    <p>Monitoring website analytics tools like Google Analytics to track organic traffic, user behavior,
    conversions, and other valuable metrics. It also uses data to make informed decisions and
    optimize SEO campaigns, and</p>
    <p>Regularly monitoring the website&#39;s performance, analyzing data, and adjusting SEO strategies
    accordingly. This includes refining keyword targeting, updating content, improving user
    experience, and adapting to changes in search engine algorithms.</p>

    
    <p><strong>Results:</strong><br/> Below are the performance graph for your reference.</p>
  
    </div> `),
    ConverSion: "Conversion has increased by 1,366.35% within 1 Year 4 months.",
    imgConvert: ConverSionImg[0],
    overallText:
      "Overall Traffic has increased by 320.18% within 1 Year 4 months.",
    overallImg: ConverSionImg[1],
    organicText:
      "Organic Traffic has increased by 438.76 within 1 Year 4 months.",

    organicImg: ConverSionImg[2],
    directText:
      "Direct Traffic has increased by 189.55% within 1 Year 4 months.",
    directImg: ConverSionImg[3],
    socialText:
      "Social Traffic has increased by 686.96% within 1 Year 4 months.",
    socialImg: ConverSionImg[4],
    GraphImg1: singleDetailImgs[0],
    GraphImg2: singleDetailImgs[1],
    metaURL: "https://ezmarketingtech.com/case-study/hillcrest-ski-&-sports",
    metaTitle: "Seo Case Study - Hillcrest Ski & Sports",
    metaDescription:
      "After taking SEO Services from EZ Marketing Tech Traffic is increasing on website each month, Now in April-23 Organic Traffic has increased by 438.76 within 1 Year 4 months.",
  },
  {
    id: 2,
    slideImg: studySliderImgs[0],
    slideOverImg: studySliderImgs[2],
    SlideHeading: "About proledge",
    ShortHeading: "ProLedge, Inc.",
    weburl: "https://www.proledge.com/",
    SlideText:
      "Proledge, Inc is a trusted provider of professional and affordable bookkeeping services and QuickBooks assistance to small businesses across the USA. With a focus on catering to companies employing 0 to 50 individuals, ProLedge helps businesses thrive. ",
    CaseStudiesDetail: parse(` <div>
    <p>Proledge, Inc is a trusted provider of professional and affordable bookkeeping services and QuickBooks assistance to small businesses across the USA. With a focus on catering to companies employing 0 to 50 individuals, ProLedge helps businesses thrive. </p>
    <h4>  <strong>Industry: </strong> Bookkeeping Services and QuickBooks Help</h4>
    <h4><strong>Client’s journey with EZ Marketing Tech</strong></h4>
    <h4><strong>Opportunity/Challenge</strong></h4>
    <p><strong>EZ Marketing Tech </strong> assisted Proledge Inc in overcoming the challenge of low traffic during certain months attributed to the seasonal nature of their tax-related business. EZ Marketing Tech identified the significance of addressing this issue and devised a strategic plan to boost Proledge’s online presence. </p><br/>
    <p>We recognized that improving the page speed insights would be instrumental in boosting Proledge’s online visibility and attracting steady traffic throughout the year.</p><br/>
    <h4><strong>Solution: Search Engine Optimization</strong></h4>
    <p>With a targeted objective, EZ Marketing Tech implemented a holistic Search Engine Optimization (SEO) strategy tailored to Proledge’s specific needs. This involved optimizing Proledge’s website structure, enhancing the overall user experience, and conducting thorough keyword research to target the right audience.
    </p><br/>
    <p><strong>Project Start Date: </strong> March 2022</p>
    <p><strong>Keywords on the 1st Page:</strong> Bookkeeper Austin Texas, Bookkeeper Dallas, Bookkeepers Austin, Bookkeeping Houston TX, Bookkeeping services Houston, Bookkeeping services in Houston, Dallas, Bookkeeping services, Houston bookkeepers, Houston bookkeeping, QuickBooks training in Houston.</p><br/>
    <p>EZ Marketing Tech’s experienced SEO professionals meticulously optimized Proledge’s website content, ensuring that relevant keywords were strategically incorporated. </p>
    <p><strong>Results:</strong> The collaborative efforts of EZ Marketing Tech and Proledge yielded noticeable improvements in traffic performance, showcasing the effectiveness of their partnership:</p>
    
    
    
    </div> `),
    ConverSion: " ",
    imgConvert: proledgeImg[0],
    overallText: parse(
      `<p><strong>Overall Traffic:</strong> Within the first six months, Proledge experienced an impressive increase of 35.75% in overall traffic. This substantial growth can be attributed to the diligent implementation of SEO strategies by EZ Marketing Tech.</p>`
    ),
    overallImg: proledgeImg[2],
    organicText: parse(
      `<p><strong>Organic Traffic:</strong> Proledge witnessed a significant boost of 28.58% in organic traffic over six months. EZ Marketing Tech successfully attracted organic visitors interested in Proledge’s bookkeeping services by optimizing their website’s visibility in search engine results pages.</p>`
    ),

    organicImg: proledgeImg[1],

    directText:
      "The tailored solutions offered by EZ Marketing Tech played a pivotal role in enabling Proledge to overcome its traffic challenges and improve its online visibility. ",
    directImg: ConverSionImg[3],
    socialText:
      "Social Traffic has increased by 686.96% within 1 Year 4 months.",
    socialImg: ConverSionImg[4],
    GraphImg1: singleDetailImgs[0],
    GraphImg2: singleDetailImgs[1],
    metaURL: "https://ezmarketingtech.com/case-study/proledge,-inc.",
    metaTitle: "Best Case Study - ProLedge, Inc",
    metaDescription:
      "Proledge witnessed a significant boost of 28.58% in organic traffic over six months. EZ Marketing Tech successfully attracted organic visitors interested in Proledge’s bookkeeping services by optimizing their website’s visibility in search engine results pages.",
  },
  {
    id: 3,
    slideImg: studySliderImgs[0],
    slideOverImg: studySliderImgs[3],
    SlideHeading: "About gearstar",
    ShortHeading: "Gearstar",
    weburl: "https://gearstar.com/",
    SlideText:
      "Gearstar is the go-to destination for custom-built, high-performance transmissions and torque converters. With their unrivaled expertise and a legacy of over 100 years of combined experience, Gearstar has earned a stellar reputation as the preferred choice among automotive enthusiasts.",
    CaseStudiesDetail: parse(` <div>
    <h4>  <strong>Industry: </strong> E-commerce (Automotive)</h4>
    <h4><strong>Client’s Journey With EZ Marketing Tech</strong></h4>
    <h4><strong>Opportunity/Challenge:</strong></h4>
    <p>When EZ Marketing Tech first partnered with Gearstar, they faced a daunting challenge. Their website suffered from low traffic, struggling to secure higher rankings for their targeted keywords in search results. As a result, Gearstar’s conversion rates were low, which impeded their business growth.
    </p><br/>
    
    <h4><strong>Solution: SEO & PPC</strong></h4>
    <p>EZ Marketing Tech employed its digital expertise to swiftly devise a comprehensive strategy to overcome the obstacles faced by Gearstar. By harnessing the power of Search Engine Optimization (SEO) and implementing strategic Pay-Per-Click (PPC) advertising campaigns, EZ Marketing Tech set out to propel Gearstar to new heights.</p><br/>
    <h4><strong>Project Start Date:</strong> December 2019</h4>
    <p><strong>Keywords on 1st Page: </strong> 4R70W Ford transmissions, 4L65E transmission, 6L90E transmission, GM 6L90E transmission, performance transmission, 4L60E transmission, racing transmissions, NAG1 transmission, Custom built transmissions, Ford auto transmission, AOD transmission, Ford AOD automatic transmission, Turbo 350 transmission, C4 racing transmission, 700R4 transmission, and 400 transmissions.
    </p><br/>
    <p>The collaboration between Gearstar and EZ Marketing Tech yielded exceptional results in optimizing the website for various crucial keywords.</p>
    <h4><strong>Results  </strong></h4>
    <p>The tangible improvements witnessed by the strategic efforts of EZ Marketing Tech in traffic performance aided the growth of Gearstar. The results are listed below:</p>
    <p><strong>Overall Traffic:</strong> For 3 years and 6 months, Gearstar witnessed an increase in overall website traffic, achieving traffic growth of 547.48%. This increase showcases the impactful strategies employed by EZ Marketing Tech.
    </p>
    <p><strong>Organic Traffic:</strong> EZ Marketing Tech’s diligent application of SEO techniques yielded extraordinary results, pushing Gearstar’s organic traffic to traffic improvement of 401.40% within three and a half years. Gearstar experienced a significant influx of qualified, organic traffic through enhanced visibility in search engines.</p>
    <p><strong>Direct Traffic:</strong> The strategic efforts by EZ Marketing Tech led to a monumental leap in direct traffic, increased by an impressive 1,031.69% within the stipulated time frame. This increase in direct traffic demonstrates the enhanced brand recognition and customer loyalty fostered by collaboration.</p>
    <p><strong>Social Traffic:</strong> EZ Marketing Tech’s expertise extended to utilizing the power of social media platforms. As a result, Gearstar’s social traffic experienced a phenomenal traffic improvement of 4890.62% within the set duration. </p>
    <p><strong>Paid Traffic:</strong> EZ Marketing Tech’s meticulously crafted PPC campaigns were pivotal in driving targeted traffic to Gearstar’s website. The carefully optimized ads resulted in an increase of 674.59% in paid traffic. By targeting the right audience, EZ Marketing Tech delivered tangible results and maximized Gearstar’s return on investment.</p>

    
    <p>Through the unwavering dedication and cutting-edge strategies of EZ Marketing Tech, Gearstar Performance Transmissions witnessed an extraordinary transformation in its online presence. </p>
  
    </div> `),
    ConverSion: "Conversion has increased by 1,366.35% within 1 Year 4 months.",
    imgConvert: ConverSionImg[0],
    overallText:
      "Overall Traffic has increased by 320.18% within 1 Year 4 months.",
    overallImg: ConverSionImg[1],
    organicText:
      "Organic Traffic has increased by 438.76 within 1 Year 4 months.",

    organicImg: ConverSionImg[2],
    directText:
      "Direct Traffic has increased by 189.55% within 1 Year 4 months.",
    directImg: ConverSionImg[3],
    socialText:
      "Social Traffic has increased by 686.96% within 1 Year 4 months.",
    socialImg: ConverSionImg[4],
    GraphImg1: singleDetailImgs[0],
    GraphImg2: singleDetailImgs[1],
    metaURL: "https://ezmarketingtech.com/case-study/gearstar",
    metaTitle: "Best Result Case Study - Gearstar Performance Transmissions",
    metaDescription:
      "Pushing Gearstar’s organic traffic to traffic improvement of 401.40% within three and a half years. The carefully optimized ads resulted in an increase of 674.59% in paid traffic.",
  },
  {
    id: 4,
    slideImg: studySliderImgs[0],
    slideOverImg: studySliderImgs[4],
    SlideHeading: "About treeworld",
    ShortHeading: "Treeworld Wholesale",
    weburl: "https://www.treeworldwholesale.com/",
    SlideText:
      "Treeworld Wholesale is the leading provider of native, aromatic, and floral trees, shrubs, and palms in South Florida. They have a vast selection of over 300+ species and specialize in landscaping projects.",
    CaseStudiesDetail: parse(` <div>
    <h4>  <strong>Industry: </strong>Wholesaler of Trees, Shrubs, and Palms </h4>
    <h4>  <strong>Client’s Journey With EZ Marketing Tech </strong> </h4>
    <h4><strong>Opportunity/Challenge: </strong></h4>
    <p>Treeworld Wholesale sought EZ Marketing Tech’s assistance to overcome initial traffic, conversion rates, and keyword rankings challenges. As a result, Treeworld Wholesale turned to EZ Marketing Tech for effective solutions.</p><br/>

    <h4><strong>Solution:</strong> SEO (Search Engine Optimization) & PPC</h4>
    <p>We employed a comprehensive strategy to address the challenges faced by Treeworld Wholesale. This included implementing Search Engine Optimization (SEO) techniques and Pay-Per-Click (PPC) advertising to enhance visibility and drive targeted traffic to the company’s website.
    </p><br/>
    <p><strong>Project Start Date: </strong> The collaboration between Treeworld Wholesale and EZ Marketing began in October 2020, marking the start of an exciting partnership focused on achieving outstanding results.</p><br/>
    <p><strong>Keywords on 1st Page: </strong> Best Caribbean tree FL, Spanish stopper shrub, Stemmadenia Litoralis, Calophyllum Brasiliensis, Dypsis Cabadae, Red trunk-belize Bursera simaruba, Bayrum trees in South Florida, Caesalpinia Granadillo Bridalveil in Florida, Ficus Benghalensis, and Guiacum Offincinale.</p>
    <p>Our team of experts employed their knowledge and skills to optimize Treeworld Wholesale’s online presence. As a result, the keywords achieved higher rankings.</p>
    <p><strong>Overall Traffic Performance Improvement: </strong> Over the course of one year and three months, Treeworld Wholesale experienced a substantial improvement in overall website traffic, with an increase of 202.79%. This gradual improvement in traffic reflects the effectiveness of the strategies implemented by EZ Marketing Tech.
    </p>
    <p><strong>Organic Traffic Boost: </strong> Treeworld Wholesale’s organic traffic significantly improved by an astounding 373.31% within the stipulated time frame. This notable achievement is a testament to the successful implementation of SEO techniques and the enhanced visibility of the company’s website. </p><br/>
    <p>EZ Marketing Tech’s partnership with Treeworld Wholesale has addressed the initial problems faced by the company that resulted in notable improvements in website traffic and organic reach.   </p><br/>
    <p>With a systematic strategy in place, Treeworld Wholesale can now effectively address the client’s requirements. It helps them continue their growth trajectory in South Florida.</p>
    <p><strong>Results:</strong> The collaboration between Treeworld Wholesale and EZ Marketing yielded impressive outcomes:</p>
    
  
    </div> `),
    imgConvert: treeworldgeImg[0],

    ConverSion: "Conversion has increased by 1,366.35% within 1 Year 4 months.",

    overallText:
      "Overall Traffic has increased by 320.18% within 1 Year 4 months.",
    overallImg: ConverSionImg[1],
    organicText:
      "Organic Traffic has increased by 438.76 within 1 Year 4 months.",

    organicImg: ConverSionImg[2],
    directText:
      "Direct Traffic has increased by 189.55% within 1 Year 4 months.",
    directImg: ConverSionImg[3],
    socialText:
      "Social Traffic has increased by 686.96% within 1 Year 4 months.",
    socialImg: ConverSionImg[4],
    GraphImg1: singleDetailImgs[0],
    GraphImg2: singleDetailImgs[1],
    metaURL: "https://ezmarketingtech.com/case-study/treeworld-wholesale",
    metaTitle: "Real Result Case Study - Treeworld Wholesale",
    metaDescription:
      "Treeworld Wholesale’s organic traffic significantly improved by an astounding 373.31% within the stipulated time frame. This notable achievement is a testament to the successful implementation of SEO techniques and the enhanced visibility of the company’s website.",
  },
  {
    id: 5,
    slideImg: studySliderImgs[0],
    slideOverImg: studySliderImgs[5],
    SlideHeading: "About redrunrite",
    ShortHeading: "Redrunrite",
    weburl: "https://www.redrunrite.com/",
    SlideText:
      "Redrunrite specializes in repairing IH (International Harvester) and Farmall tractors. With over 30 years of experience, the company offers reliable troubleshooting and a wide range of standard and hard-to-find parts, including front-end parts, torque amplifiers, clutches, hydraulic pumps, injectors, engine parts, radiators, and brakes.",
    CaseStudiesDetail: parse(` <div>
    <h4>  <strong>Industry: </strong> E-commerce (Automotive)</h4>
    <h4>  <strong>Client’s journey with EZ Marketing Tech</strong> </h4>
    <h4><strong>Opportunity/Challenge: </strong></h4>
    <p>Redrunrite struggled to improve its website traffic, achieve higher rankings for its targeted keywords, and generate substantial revenue. Recognizing the need for professional assistance, the company reaches out to EZ Marketing Tech to help address these issues and drive significant improvements in both traffic and revenue.
    </p><br/>

    <h4><strong>Solution:</strong></h4>
    <p>EZ Marketing Tech implemented a comprehensive solution for Redrunrite by utilizing both Search Engine Optimization (SEO) and Pay-Per-Click (PPC) advertising strategies.</p><br/>
    <p>After extensive research, our experts identified high-performing keywords for IH tractor parts and solutions. They strategically incorporated keywords into on-page elements such as meta tags, headings, content structure, and URLs to optimize the website. Also, they crafted compelling ad copy using relevant keywords for a targeted PPC advertising campaign to maximize click-through rates (CTRs).</p><br/>
    <h4><strong> Project Start Date:</strong> April-18 </h4>
    <p><strong>Keywords on 1st Page : </strong> - IH 3-point hitch, IH Farmall tractor parts online, Diesel injection and turbos, IH tractor parts, Buy IH injector USA, IH hydraulic pump, IH torque amplifier, Farmall h starter, IH relief valve, and IH Farmall tractor parts. </p>
    <h4><strong>Results: </strong> </h4>
    <p>The traffic performance for Redrunrite has shown significant improvements over the span of five years. There has been a boost in traffic by 253.62%. The organic traffic, which comes from search engines, has seen a growth of 273.07%. Social traffic originating from social media platforms has witnessed an increase of 463.64%. Additionally, referral traffic from external websites has also experienced a considerable rise of 224.30%.</p><br/>
    <p>These outcomes have helped Redrunrite establish a strong digital presence and improve business outcomes in the IH tractor parts market.</p>
    </div> `),
    ConverSion: "Conversion has increased by 1,366.35% within 1 Year 4 months.",
    imgConvert: redrunriteImg[0],
    overallText: "Overall Traffic has increased by 253.62% within 5 Years.",
    overallImg: redrunriteImg[2],
    organicText: "Organic Traffic has increased by 273.07% within 5 Years.",

    organicImg: redrunriteImg[1],
    directText: "Social  Traffic has increased by 463.64% within 5 Years.",
    directImg: redrunriteImg[4],
    socialText: "Referral Traffic has increased by 224.30% within 5 Years.",
    socialImg: redrunriteImg[3],
    GraphImg1: singleDetailImgs[0],
    GraphImg2: singleDetailImgs[1],
    metaURL: "https://ezmarketingtech.com/case-study/redrunrite",
    metaTitle: "SEO Case Study for Redrunrite",
    metaDescription:
      "After taking SEO Services from EZ Marketing Tech Traffic is increasing on website each month, Now in April-23 Organic Traffic has increased by 273.07%.",
  },
  {
    id: 6,
    slideImg: studySliderImgs[0],
    slideOverImg: studySliderImgs[6],
    SlideHeading: "About Visit El Dorado",
    ShortHeading: "Visit El Dorado",
    weburl: "https://visit-eldorado.com/ ",
    SlideText:
      "Visit El Dorado is travelers' ultimate guide to everything they need to know for their trip to El Dorado County. From comprehensive information on accommodations and exciting things to do to recreation options, their website is a go-to resource for an unforgettable experience. ",
    CaseStudiesDetail: parse(` <div>
    <h4>  <strong>Industry: </strong> Travel</h4>
    <h4>  <strong>Client's journey with EZ Marketing Tech </strong> </h4>
    <h4><strong>Opportunity/Challenge: </strong></h4>
    <p>Visit El Dorado faced a significant decline in website traffic and user session duration. As an event-based business, they encountered challenges in attracting and engaging visitors consistently on their website. </p><br/>

    <h4><strong>Solution:</strong></h4>
    <p>EZ Marketing Tech employed a Search Engine Optimization and Pay-Per-Click solution to overcome Visit El Dorado's challenge.</p><br/>
    <h4><strong> SEO Approach:</strong></h4>
    <p>Our team of experts identified high-traffic keywords and optimized the website's on-page elements. It improved their relevancy and visibility in search engine results. They also developed a content strategy to offer visitors valuable information about upcoming events and activities in El Dorado County.</p><br/>
    <h4><strong>PPC Approach: </strong></h4>
    <p>Our experts used platforms like social media advertising and Google Ads for highly-targeted PPC ad campaigns. They utilized keywords, demographics, and interests to craft ads to reach potential visitors. Engaging ad copies intrigued users and led them to specially-designed landing pages. After monitoring and analyzing metrics like click-through rates and cost per click, we used data insights to make necessary adjustments. It optimized campaigns for optimal performance and effectiveness. </p>
    <h4><strong>Project Start Date:</strong> May-2017 </h4>
    <p><strong>Keywords on 1st Page: </strong> Apple Hill, Apple Hill pumpkin patch, Apple Hill wineries, El Dorado wine country, El Dorado wineries, Fair Play wineries, Gold Mine Placerville, Gold panning Placerville, Panning for gold American River, Placerville wineries, Downtown Placerville.</p><br/>
    <h4><strong>Results </strong> </h4>
    <p>The website has shown remarkable improvement in traffic performance over the past five years. </p>
    </div> `),
    ConverSion:
      "Overall, visitors have increased by 486.60%, indicating nearly a five-fold growth. ",
    imgConvert: eldoradoImg[4],
    overallText:
      "Organic traffic has experienced a rise of 631.07%, suggesting that more people find the website through search engine results. ",
    overallImg: eldoradoImg[1],
    organicText:
      "Direct traffic has seen a notable increase of 687.56%, indicating that many visitors directly access the website through bookmarks or saved links. ",
    organicImg: eldoradoImg[0],
    directText:
      "Referral traffic has grown substantially by 309.32%, displaying improved attention and referrals from other websites or online platforms. ",
    directImg: eldoradoImg[3],
    socialText:
      "The significant growth in organic, direct, and referral traffic showcased the effectiveness of the implemented strategies by EZ Marketing Tech in attracting visitors and driving the success of Visit El Dorado. ",
    socialImg: ConverSionImg[4],
    GraphImg1: singleDetailImgs[0],
    GraphImg2: singleDetailImgs[1],
    metaURL: "https://ezmarketingtech.com/case-study/visit-el-dorado",
    metaTitle: "SEO Success Case Study - Visit El Dorado",
    metaDescription:
      "Organic traffic has experienced a rise of 631.07%, suggesting that more people find the website through search engine results.",
  },
  {
    id: 7,
    slideImg: studySliderImgs[0],
    slideOverImg: studySliderImgs[7],
    SlideHeading: "About BushingFix",
    ShortHeading: "BushingFix",
    weburl: "https://www.bushingfix.com/",
    SlideText:
      "BushingFix® is a renowned company that specializes in designing & manufacturing top-notch automotive parts. Their engineers develop superior replacement parts and installation systems for common part failures.",
    CaseStudiesDetail: parse(` <div>
    <h4>  <strong>Industry: </strong> E-commerce (Automotive) </h4>
    <h4>  <strong>Client’s journey with EZ Marketing Tech: </strong> </h4>
    <h4><strong>Opportunity/Challenge: </strong></h4>
    <p>BushingFix® faced a considerable shortfall in organic traffic, impacting their online visibility and customer reach. Overcoming this obstacle required professional assistance to attract visitors and generate leads. </p><br/>

    <h4><strong>Solution:</strong></h4>
    <p>EZ MarketingTech addressed the challenge of low organic traffic through Search Engine Optimization (SEO). </br>
    By implementing effective strategies, we helped BushingFix® increase its organic visibility, attract targeted traffic, and generate valuable leads, improving its overall digital presence. We conducted a thorough website analysis, identified improvement areas, and implemented strategic SEO techniques. It included optimizing keywords, improving on-page content, enhancing website structure and navigation, and building quality backlinks. 
    </p><br/>
    <h4><strong> Project Start Date:</strong> April 2015</h4>
    <p><strong>Keywords on 1st Page: </strong> Cadillac shift cable, Envoy shift cable bushing, Ford expedition shift cable, Hummer h2 shift cable, Pontiac shift cable, Saturn shift cable bushing, Shift cable bushing, Shift cable bushing kit, Shift cable grommet, Shift linkage repair.
    </p><br/>
    <h4><strong>Results </strong> </h4>
    <p>Strategic SEO techniques by EZ Marketing Tech achieved incredible results within six years. </p>
    </div> `),
    ConverSion:
      "An impressive increase of 149.87% in overall traffic indicated increased website visitors and engagement.",
    imgConvert: BushingFixImg[1],
    overallText:
      "A substantial growth of 210.82% in organic traffic suggested a successful optimization of search engine rankings.",
    overallImg: BushingFixImg[0],
    organicText:
      "A significant rise of 4874.14% in social traffic showcased social media marketing efforts and improved brand visibility across social platforms.",

    organicImg: BushingFixImg[2],
    directText:
      "These impressive improvements in traffic metrics highlight the success of EZ Marketing Tech's SEO campaign for BushingFix®, ultimately leading to enhanced online visibility and overall website traffic.",
    directImg: ConverSionImg[3],
    socialText:
      "Social Traffic has increased by 686.96% within 1 Year 4 months.",
    socialImg: ConverSionImg[4],
    GraphImg1: singleDetailImgs[0],
    GraphImg2: singleDetailImgs[1],
    metaURL: "https://ezmarketingtech.com/case-study/bushingfix",
    metaTitle: "Effective Case Study - BushingFix",
    metaDescription:
      "A substantial growth of 210.82% in organic traffic suggested a successful optimization of search engine rankings. An impressive increase of 149.87% in overall traffic indicated increased website visitors and engagement.",
  },
];

export default SlideData;
