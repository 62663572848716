import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
const ContactBanner = () => {
  return (
    <Fragment>
      <div className="rs-breadcrumbs img1 gray-bg banner_all contact_banner">
        <div className="breadcrumbs-inner text-center">
          <h1 className="page-title">Contact</h1>
          <ul>
            <li title="Go to Topkie - SEO & Digital Agency HTML Template">
              <NavLink className="active" to="/">
                Home
              </NavLink>
            </li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactBanner;
