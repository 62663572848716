import React, { Fragment } from "react";
// import ToolImgImgs0 from "../../ImagesComponents/all-images/tool_img.png";
// import ToolImgImgs1 from "../../ImagesComponents/all-images/tool_img2.png";
// import ToolImgImgs2 from "../../ImagesComponents/all-images/tool_img3.png";
// import ToolImgImgs3 from "../../ImagesComponents/all-images/tool_img4.png";
// import ToolImgImgs4 from "../../ImagesComponents/all-images/tool_img5.png";
let ToolImgImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/tool_img5.png",
];
function LocationTools() {
  return (
    <Fragment>
      <section className="tools_section text-center">
        <div className="container">
          <h3>Platforms & Tools We Specialize In:</h3>
          <ul className="tool_list row">
            <li>
              <img src={ToolImgImgs[0]} alt="" />
            </li>
            <li>
              <img src={ToolImgImgs[1]} alt="" />
            </li>
            <li>
              <img src={ToolImgImgs[2]} alt="" />
            </li>
            <li>
              <img src={ToolImgImgs[3]} alt="" />
            </li>
            <li>
              <img src={ToolImgImgs[4]} alt="" />
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
}

export default LocationTools;
