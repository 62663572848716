import ServiceMainebanner from "./ServiceMainebanner"
import DmService from "./DmService"
import BestSellingServices from "./BestSellingServices"
const MainService =()=>{
    return(
        <>
        <ServiceMainebanner/>
        <DmService/>
        <BestSellingServices/>
        </>
    )
}

export default MainService