import React, { Fragment, lazy, Suspense } from "react";
//import BannerOverlayContent from "./BannerOverlayContent";
import bannerNewImg from "../../ImagesComponents/bannerImg/bannerNew.webp";
import MobileBannerImg from "../../ImagesComponents/bannerImg/mobile_banner.webp";

const BannerOverlayContent = lazy(() => import("./BannerOverlayContent"));
const BannerFigure = () => {
  return (
    <Fragment>
      <figure className="banner_figure">
        <picture>
          <source
            media="(max-width: 767px)"
            srcset={MobileBannerImg}
            height="850"
            width="767"
            className="mobile_banner"
          />
          <img src={bannerNewImg} alt="banner" height="1080" width="1920" />
        </picture>

        <Suspense fallback={<div>loading...</div>}>
          <BannerOverlayContent />
        </Suspense>
      </figure>
    </Fragment>
  );
};

export default BannerFigure;
