import React, { Fragment, useEffect } from "react";
import SeoTrafficBanner from "./SeoTrafficBanner";
import SeoDriveTraffic from "./SeoDriveTraffic";
import SeoProcess from "./SeoProcess";
import SeoSearchEngines from "./SeoSearchEngines";
import SeoConcern from "./SeoConcern";
import FoundationalPillars from "./FoundationalPillars";
import SeoStrategy from "./SeoStrategy";
import WhyWeBatter from "./WhyWeBatter";
import SeoResonForBest from "./SeoResonForBest";
import AllRoundSteps from "./AllRoundSteps";
import SeoFaqTabs from "./SeoFaqTabs";
import { testimonialData } from "../HomeComponents/HomeData";
import ReviesAll from "../ReviesAll";
import { Helmet } from "react-helmet";

const SeoTraffic = () => {
  useEffect(() => {
    document.body.classList.add("seo-traffic-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("seo-traffic-layout");
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>
          SEO Services Company | SEO Company USA | EZ Marketing Tech
        </title>
        <meta
          name="description"
          content="Increase your website traffic and achieve long-term success with EZ Marketing Tech's comprehensive SEO services. Our team will optimize your site for high-quality leads and conversions."
        />
      </Helmet>
      <SeoTrafficBanner />
      <SeoDriveTraffic />
      <SeoProcess />
      <SeoSearchEngines />
      <SeoConcern />
      <FoundationalPillars />
      <SeoStrategy />
      <WhyWeBatter />
      <SeoResonForBest />
      <AllRoundSteps />
      <SeoFaqTabs />

      <ReviesAll
        mainclassName="home_restimonials seo_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default SeoTraffic;
