import React, { Fragment } from "react";
import { AiFillFire } from "react-icons/ai";
function LocalSeoBannerText() {
  return (
    <Fragment>
      <section className="price_table_banner">
        <div className="container">
          <div className="services_heading_wrapper">
            <h2>Unlock Impressive Packages We Have For You</h2>
            <p>
              With our Local SEO packages, you can expect comprehensive
              solutions tailored specifically to optimize your online presence
              for local search results. No matter the size or industry of your
              business, we have the right package to meet your requirements.
              Check the packs below!
            </p>
          </div>
          <div className="price_plane_wrapper">
            <div className="row row_scroll m-0">
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>Starter Plan</h3>
                      <p>
                        This package offers essential local SEO optimizations
                        such as local keyword research, on-page optimization,
                        local directory listings, and more.
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$500</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/aEUbKkgFG4eK7p67sF"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="avail_content">
                    <h4>
                      <AiFillFire /> Most Recommended
                    </h4>
                    <table className="table table-responsive">
                      <thead>
                        <th>
                          <h3>Periods*</h3>
                        </th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>No. of Keywords</span>
                          </td>
                          <td>
                            <span>10</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>No. of Hours (per channel)</span>
                          </td>
                          <td>
                            <span>15</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>Serious Plan</h3>
                      <p>
                        This plan includes advanced local SEO strategies such as
                        comprehensive local keyword analysis, optimized on-page
                        elements, local link building, review management, and
                        more. Increase online visibility and outshine your local
                        competitors with this plan!
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$700</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/eVa15GblmfXs8ta5ky"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="avail_content">
                    <h4>
                      <AiFillFire /> Most Recommended
                    </h4>
                    <table className="table table-responsive">
                      <thead>
                        <th>
                          <h3>Periods*</h3>
                        </th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>No. of Keywords</span>
                          </td>
                          <td>
                            <span>15</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>No. of Hours (per channel)</span>
                          </td>
                          <td>
                            <span>15</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>Strategy Plan</h3>
                      <p>
                        This package is designed for businesses seeking a
                        comprehensive and targeted approach. It includes
                        comprehensive competitor analysis, strategic content
                        creation, and local schema markup implementation, among
                        others!
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$900</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/eVa3dO3SU12y6l2fZd"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="avail_content">
                    <table className="table table-responsive">
                      <thead>
                        <th>
                          <h3>Periods*</h3>
                        </th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>No. of Keywords</span>
                          </td>
                          <td>
                            <span>25</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>No. of Hours (per channel)</span>
                          </td>
                          <td>
                            <span>25</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>Steroid Plan</h3>
                      <p>
                        With this plan, we can help you achieve outstanding
                        local SEO results and establish a dominant presence in
                        your local market.
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$1200</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/7sIeWw89aeTo24MbIY"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="avail_content">
                    <h4>
                      <AiFillFire /> Most Recommended
                    </h4>
                    <table className="table table-responsive">
                      <thead>
                        <th>
                          <h3>Periods*</h3>
                        </th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>No. of Keywords</span>
                          </td>
                          <td>
                            <span>35</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>No. of Hours (per channel)</span>
                          </td>
                          <td>
                            <span>35</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default LocalSeoBannerText;
