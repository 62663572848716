import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import LocationForm from "./LocationForm";
function LocationBanner() {
  return (
    <Fragment>
      <section className="banner_section location_banner">
        <div className="container">
          <div className="location_banner_wrap">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                <div className="location_banner_text">
                  <h2>
                    Unlock Your Business's Full
                    <br />
                    Potential With Our Customized SEO
                    <br />
                    Solutions In Nevada!
                  </h2>
                  <p>
                    Are you looking to make a more significant impact with your
                    Nevada-based business?
                    <br />
                    If so, you've come to the right place! We specialize in
                    providing results-driven strategies to help you increase
                    your rankings and convert more leads today!
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact us
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                <div className="location_proposal_wrap">
                  <h3>GET MY FREE PROPOSAL</h3>
                  <LocationForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default LocationBanner;
