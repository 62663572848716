import React, { Fragment } from "react";
import SocialTabs from "./SocialTabs";
import ServiceForm from "../ECommerceSeoComponents/ServiceForm";
const ContentMarketingBanner = () => {
  return (
    <Fragment>
      <section className="ecom_seo_banner content_ecom_seo_banner">
        <h2>
          Supercharge Your Social Media Presence
          <br /> with Targeted <span>Social Media Marketing </span> Services!
        </h2>
        <p>
          Social gatherings are an excellent way of communicating and exchanging
          information. Similarly, you can use the same essence of a virtual
          social network to promote your brand online and enhance brand
          awareness.
        </p>
        <p>
          Social Media Marketing is a digital marketing technique that uses
          prominent social media platforms like Facebook, Instagram, Twitter etc
          to promote your brand among prospective buyers by providing them
          useful, entertaining, and trending information.
        </p>
        <p>
          Social media marketing has become the leading practice that global
          brands and businesses are using to stay ahead of the competition. With
          the addition of newer social media platforms, its power as a marketing
          tool is intensifying.
          <br /> Take advantage of our social media marketing experts at EZ
          Marketing Tech and boost your social media presence multifold.
        </p>

        <div className="ecom_form_wrapper">
          {/* <img src={FormImg} alt="img" /> */}
          <h3>REQUEST A QUOTE</h3>
          <ServiceForm />
        </div>
        <div className="ecomm_concern social_media_ecomm_concern">
          <h3>Solidify Your Presence with Social Media</h3>
          <p className="text-center">
            Social media marketing is more effective in creating brand
            recognition than any other marketing tool. It is more interactive
            and engages people with the least effort. A brand gains loyalty and
            trust when its recommendation comes from a social circle.
          </p>
          <strong>Why Use Social Media Marketing</strong>
          <p className="text-center">
            Social media marketing has emerged as a solid strategy to encourage
            your audience to connect with your brand. Where other strategies
            take more time and effort, a social media campaign is inflammable,
            spreading like a wildfire! Here’s how social media marketing
            campaigns can boost your brand
          </p>
          <SocialTabs />
          <h4>
            Our World-class Social Media Marketing Services Will Intensify Your
            Brand Recognition
          </h4>
        </div>
      </section>
    </Fragment>
  );
};

export default ContentMarketingBanner;
