import React from "react";

const TableContent = () => {
  return (
    <>
      <section className="content_table_section">
        <div className="container">
          <h3>
            <strong>Table of Content</strong>
          </h3>
          <div className="table-responsive">
            <table className="ppt_table">
              <tr>
                <td>About CBD Industry</td>
                <td>Steps/Approach to Improve Performance</td>
              </tr>
              <tr>
                <td>Challenges of CBD Marketing </td>
                <td>Key Tasks in Campaign Process</td>
              </tr>
              <tr>
                <td>Why is the CBD Industry Growing</td>
                <td>SEO Activities – Monthly Optimization</td>
              </tr>
              <tr>
                <td>Target Audience</td>
                <td>Content Reach</td>
              </tr>
              <tr>
                <td>Website Audit – Page Speed Insights</td>
                <td>Content for Multiple Platforms</td>
              </tr>
              <tr>
                <td>Website Audit – Mobile Readiness</td>
                <td>Team & Escalation Matrix</td>
              </tr>
              <tr>
                <td>Keyword Targeting & Research Idea</td>
                <td>Tool for Better Reporting & Analysis</td>
              </tr>
              <tr>
                <td>Strategies to Market CBD Business Online</td>
                <td>Case Studies</td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default TableContent;
