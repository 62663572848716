import React, { Fragment } from "react";

const ContentMarketingReputation = () => {
  return (
    <Fragment>
      <section className="reputation_section content_reputation_section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <div className="clients_text_wrap">
                <h3>
                  We Help You Generate
                  <br /> More Leads!
                </h3>
                <p>
                  If generating leads is the dream you yearn for, then hire a
                  content marketing agency that holds brilliant prominence in
                  online marketing. Our compelling content subtly influences
                  visitors to take minimal action. These actions may include
                  filling out a form, submitting feedback, subscribing to
                  newsletters, reading a blog, or watching a valuable and
                  relevant video.
                </p>
                <p>
                  Our content promotion services are headstrong about publishing
                  interesting content on relevant platforms hence, diverting
                  more organic traffic to your website.
                </p>
                <p>
                  Our competitive content strategy services promise to generate
                  abundant organic and unpaid traffic. We make it possible with
                  strategic skills in producing content.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 border-left">
              <div className="clients_text_wrap">
                <h3>
                  Our Dedication to Turn Your
                  <br /> Leads Into Prospective Buyers!
                </h3>
                <p>
                  Generating leads is not enough. To be dominant globally, you
                  should have the expertise to turn leads into buyers. Immersive
                  content helps you do just that.
                </p>
                <p>
                  At EZ Marketing Tech, you can hire content marketers to create
                  content with optimized keywords and juicy material to hook
                  viewers.
                </p>
                <p>
                  Our SEO content marketing services follow a user-oriented
                  strategy. The strategy is simple: to develop more content for
                  your users.
                </p>
                <p>
                  Site visitors need a potential element in the content to feel
                  connected to your brand. It should give them something to
                  learn about how your business may serve their needs in the
                  future. And this is what our personalized content marketing
                  services deliver. A highly competitive and professional SEO
                  content marketing agency like ours is all you need!
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 border-left">
              <div className="clients_text_wrap ">
                <h3>
                  Constant Content
                  <br /> Updation is Our Priority!
                </h3>
                <p>
                  We keep your site content fresh and updated. Our content
                  marketing specialists ensure the content is verified and stays
                  relevant to your visitors. We make even minor changes to the
                  content that may be critical to the site visitors.
                  <br /> Our SEO content marketing agency channels out
                  captivating content on all your profiles across the network.
                </p>
                <p>
                  Content is the only way to communicate with users online.
                  Hence our content marketing solutions make users feel unique
                  with occasional wishing on festivals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ContentMarketingReputation;
