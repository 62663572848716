import React, { Fragment } from "react";
// import BrandImgs0 from "../../ImagesComponents/all-images/opportunities_img.png";
// import BrandImgs1 from "../../ImagesComponents/all-images/brand_img.jpg";
// import BrandImgs2 from "../../ImagesComponents/all-images/brand_img2.jpg";
let BrandImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/opportunities_img.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/brand_img.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/brand_img2.jpg",
];
const SearchOpportunities = () => {
  return (
    <Fragment>
      <section className="search_opportunities_sec">
        <div className="container">
          <h2 className="text-center">
            Profit From Consumer Search Opportunities
            <br />
            By Employing PPC Marketing
          </h2>
          <div className="row mb-5">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <figure className="opportunities_figure">
                <img src={BrandImgs[0]} alt="img" width="100%" />
              </figure>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="opportunities_text">
                <p>
                  <strong>PPC advertising</strong> has proved to be a dependable
                  and profitable conduit for many B2B, B2C, nonprofit, and other
                  businesses looking for quick, high-quality traffic and
                  conversions. Given PPC's advantages, there is little risk in
                  experimenting with it to see where it can make a difference
                  and gain a wealth of insightful information to guide your
                  other marketing and optimization initiatives. Now, are you
                  looking for <strong> PPC marketing </strong> services
                  <strong> near me?</strong>
                </p>
                <p>
                  EZ Marketing Tech is a reputable industry pioneer in
                  <strong> PPC marketing services.</strong> As a top PPC
                  marketing company, we help our clients increase leads, build
                  brand awareness, and accomplish their objectives. Our
                  pay-per-click advertising company collaborates with various
                  business stakeholders and marketing experts. Being an
                  established pay-per-click marketing firm, we have served more
                  than 1000 clients globally. We have helped growth-minded
                  businesses interact socially, increase traffic, and stay one
                  step ahead of their rivals in terms of generating leads and
                  sales. In addition to monitoring your keyword rankings, our
                  multi-dimensional analytics approach gives you an overview of
                  our work's effects on your website's traffic and usage. Our
                  <strong> pay-per-click marketing agency</strong> is here to
                  help if you're still unsure whether
                  <strong> pay-per-click marketing</strong> is the best approach
                  for your business.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-max">
          <div className="row j-center brand_row">
            <h2>What's The Smarter Move for Your Brand, SEO Or PPC?</h2>
            <p>
              Understanding and assessing your target demographics' online
              behavior is critical for determining whether PPC, organic search,
              or a combined effect of these digital marketing methods is the
              right strategy for your company.
            </p>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <figure className="brand_figure">
                <img src={BrandImgs[1]} alt="img" width="100%" />
                <figcaption className="brand_figure_caption">
                  <div className="container">
                    <div className="brand_text">
                      <h4>Search Engine Optimization</h4>
                      <p>
                        SEO successfully increases organic traffic, creates
                        brand awareness, establishes online authority, and wins
                        over customers. However, many SEO agencies will advise
                        you that it takes at least four to six months to start
                        seeing results from your campaign. Only 5.7% of web
                        pages rank in the top 10 organic search results for a
                        single keyword within a year of publication, according
                        to an AHREFS study. Additionally, it took even these
                        top-performing websites two to six months to enter the
                        top 10.
                      </p>
                      <p>
                        SEO requires time. Because of this, many online
                        marketers relying heavily on organic search frequently
                        fall short of meeting their marketing objectives on
                        schedule. Pay-per-click marketing is the way to go if
                        you're looking for an online marketing strategy that
                        offers total control and flexibility and promises
                        immediate results.
                      </p>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <figure className="brand_figure">
                <img src={BrandImgs[2]} alt="img" width="100%" />
                <figcaption className="brand_figure_caption brand2_figure_caption">
                  <div className="container">
                    <div className="brand_text">
                      <h4>Pay-Per-Click</h4>
                      <p>
                        Pay-per-click marketing has established itself as one of
                        the best and most affordable online marketing techniques
                        over the years. PPC advertising strategies enable
                        companies to control their ad spending and place their
                        brand offerings in front of the appropriate demographic
                        at the right moment and location. Pay-per-click
                        advertising gives you laser-focused visibility, which
                        quickens the purchasing process and increases your
                        conversion rate.
                      </p>
                      <p>
                        In a perfect world, combined SEO and PPC advertising
                        strategies are the most effective at generating targeted
                        results. Integrated SEO and PPC campaigns provide
                        maximum visibility, attract targeted traffic, and
                        provide lasting advantages. Pay-per-click internet
                        marketing, however, might be your best option if you
                        only have a certain time frame to promote your brand
                        offerings and have an impact on your niche market.
                      </p>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SearchOpportunities;
