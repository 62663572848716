import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
function PpcPriceTable() {
  return (
    <section className="Packages_section">
      <div className="container">
        <h2>
          Compare Our Packages and Choose the Perfect Fit for Your Business
        </h2>
        <div className="table-responsive">
          <table className="table price_table_list table-bordered table-striped table-hover">
            <thead>
              <tr className="price_tr">
                <th></th>
                <th>
                  <h4>PPC-Starter</h4>
                  <span>
                    $399<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>PPC-Serious</h4>
                  <span>
                    $599<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>PPC-Strategy</h4>
                  <span>
                    $899<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>PPC-Steroid</h4>
                  <span>
                    $1199<sub>/Month</sub>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="list_body">
              <tr>
                <td>
                  <strong>Period</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>One Time setup fee(Additional)</span>
                </td>
                <td>$350</td>
                <td>$500</td>
                <td>$750</td>
                <td>$1000</td>
              </tr>
              <tr>
                <td>
                  <span>Budget</span>
                </td>
                <td>$0 – $1499 / month</td>
                <td>$1500 – $2,999 / month</td>
                <td>$3000 – $4999/ month</td>
                <td>Above $5000</td>
              </tr>
              <tr>
                <td>
                  <span>Full Optimizations</span>
                </td>
                <td>1 / month</td>
                <td>2 / month</td>
                <td>3 / month</td>
                <td>5 / month</td>
              </tr>
              <tr>
                <td>
                  <span>Ad Groups</span>
                </td>
                <td>Up To 5</td>
                <td>Up To 10</td>
                <td>Up To 20</td>
                <td>Up To 40</td>
              </tr>
              <tr>
                <td>
                  <span>Ads per Ad Group</span>
                </td>
                <td>
                  Up To 2 Ads per Ad Group
                  <br />
                  (No A/B Testing)
                </td>
                <td>
                  Up To 3 Ads per Ad Group
                  <br />
                  (No A/B Testing)
                </td>
                <td>Up To 4 Ads per Ad Group</td>
                <td>Up To 6 Ads per Ad Group</td>
              </tr>
              <tr>
                <td>
                  <span>Keywords</span>
                </td>
                <td>Up to 50</td>
                <td>Up to 100</td>
                <td>Up to 200</td>
                <td>Up to 750</td>
              </tr>
              <tr>
                <td>
                  <span>Negative Keywords</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>

              {/* <tr>
                <td>
                  <strong>Negative Keywords</strong>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr> */}
              <tr>
                <td>
                  <span>Conversion Tracking</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Monthly Reporting</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>No Contracts</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Ad Networks</span>
                </td>
                <td>Google Network</td>
                <td>Google Network</td>
                <td>Google/Bing</td>
                <td>Google/Bing/FB</td>
              </tr>
              <tr>
                <td>
                  <span>Mobile Ads</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Call Extensions</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Sitelink Extensions</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Remarketing</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>

              {/* <tr>
                <td>
                  <strong>Customer Support</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr> */}
              <tr>
                <td>
                  <span>Monthly Conference Calls</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Display / Image Ads</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>Upto 2 Ads per month</td>
              </tr>

              <tr>
                <td></td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/bIYg0A9de3aG10I6ow"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/dR68y84WY5iO5gY8wF"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/dR601C0GI4eK38Q3cm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/4gw29K4WYcLgbFm4gr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default PpcPriceTable;
