import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
// import TalkImgs from "../../ImagesComponents/all-images/talk_img.png";
let TalkImgs = ["https://ezmarketingtech.s3.amazonaws.com/img/talk_img.png"];
function FocusedSeo() {
  return (
    <Fragment>
      <section className="focused_section">
        <div className="container">
          <div className="row row-md-reverce">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="focused_text">
                <h3>
                  Transform Your Website’s
                  <br />
                  Architecture with Focused SEO
                  <br />
                  for eCommerce
                </h3>
                <p>
                  An optimally structured and SEO-optimized eCommerce website
                  will assure search engines of your credibility and Business
                  intent. Search engines hunt for websites that are easily
                  wayfinding and mobile-friendly.
                  <br /> It becomes a critical and deciding factor for the
                  visibility of your website to be on Top Search Engine Results
                  Pages (SERPs). A higher rank in SERPs increases your odds of
                  attracting the right customers to your products and pages.
                </p>
                <p>
                  Our eCommerce SEO services and SEO technicians target the
                  basic structure of your website's HTML because that's where
                  the magic happens. SEO for eCommerce ensures that the HTML
                  structure of your eCommerce platform is optimized and clearly
                  indicates your business intent, such as products and services.
                  <br />
                  Search engines analyses your website’s optimization and
                  fundamental HTML structure to render more information about
                  your eCommerce website.
                </p>
                <p>
                  EZ Marketing Tech regularly audits security, sitemaps, and
                  URLs to maintain top search engine rankings for your online
                  store. We strive to enhance your store's visibility and
                  usability. Once the search engine marks your business as
                  qualified and confirms the originality of your eCommerce store
                  to be displayed in SERPs, your eCommerce website becomes
                  invincible.
                </p>
                <NavLink className="talk_text" to="/contact-us">
                  let's talk
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <figure className="talk_figure">
                <img src={TalkImgs[0]} alt="img" width="100%" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default FocusedSeo;
