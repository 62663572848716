const dataReputation = [
  {
    id: 1,
    packageHeding:
      "What Is The Need for ORM When My Products are Genuine and High-quality?",
    packageText:
      "It doesn’t matter how brilliant your product and services are. Your rivals will always find a way to drag you down and influence your existing clients. Soon, the customers lose interest in your brands and products because of the hearsay about your products. It’s always advised to stay one step ahead and approach an expert ORM company to offer you the best reputation repair services.",
  },
  {
    id: 2,
    packageHeding:
      "What Are the Downsides of Not Taking Reputation Management Services?",
    packageText:
      "If you do not approach a professional ORM agency at the right time, you will lose a lot to negativity. Your sales will decrease and as a result, prominent search engines will mark you as unworthy of high ranks in the SERPs. Also, due to decrease in SERPs rankings, your Return on Investment will decrease. You will find it challenging to pay employees because of low revenue generation due to fabricated and malicious reviews.",
  },
  {
    id: 3,
    packageHeding: "Where Can I Get Cost-effective ORM Services?",
    packageText:
      "EZ Marketing Tech is a competitive ORM-providing company offering comprehensive online reputation repair services. You can approach their ORM technicians to get a quote and understand which package will be the most suitable for your online business.",
  },
];

export default dataReputation;
