const TabsData = [
  {
    id: 1,
    TabsButton: "Specific Audience Targeting",
    TabsText1:
      "Social media platforms provide the convenience of selecting an audience suited to  your demographics. This eases turning prospective buyers into loyal customers. ",
    TabsText2:
      "Each platform differs from one another in the medium it offers to interact with your social circle. It also offers a specific audience. For example, Twitter has users active in global events, whereas Instagram is famous for uploading flashy and entertaining reels.     Choosing the right social media platform is an excellent start to your social campaigning venture!",
  },
  {
    id: 2,
    TabsButton: "Cost-Effective Promotion",
    TabsText1:
      "Social media platforms provide the convenience of selecting an audience suited to  your demographics. This eases turning prospective buyers into loyal customers. ",
    TabsText2:
      "Each platform differs from one another in the medium it offers to interact with your social circle. It also offers a specific audience. For example, Twitter has users active in global events, whereas Instagram is famous for uploading flashy and entertaining reels.     Choosing the right social media platform is an excellent start to your social campaigning venture!",
  },
  {
    id: 3,
    TabsButton: "More Exposure",
    TabsText1:
      "Social media platforms provide the convenience of selecting an audience suited to  your demographics. This eases turning prospective buyers into loyal customers. ",
    TabsText2:
      "Each platform differs from one another in the medium it offers to interact with your social circle. It also offers a specific audience. For example, Twitter has users active in global events, whereas Instagram is famous for uploading flashy and entertaining reels.     Choosing the right social media platform is an excellent start to your social campaigning venture!",
  },
  {
    id: 4,
    TabsButton: "Better Search Rankings",
    TabsText1:
      "Social media platforms provide the convenience of selecting an audience suited to  your demographics. This eases turning prospective buyers into loyal customers. ",
    TabsText2:
      "Each platform differs from one another in the medium it offers to interact with your social circle. It also offers a specific audience. For example, Twitter has users active in global events, whereas Instagram is famous for uploading flashy and entertaining reels.     Choosing the right social media platform is an excellent start to your social campaigning venture!",
  },
];
const DataFaq = [
  {
    id: 5,
    packageHeding:
      "How do I decide which social media platform is best for my brand marketing?",
    packageText:
      "It depends on your brand and the audience you wish to target. Since each social media platform has a specific audience, it requires a complete analysis of these platforms. If it seems complicated, consulting a professional social media marketing company is the best decision. ",
  },
  {
    id: 6,
    packageHeding: "Are social media PPC ad campaigns effective?",
    packageText:
      "Yes, social PPC ad campaigns market your brands with more intensity. Social media platforms are flocked with users daily. Hence it is an outstanding technique for gathering more user attention.",
  },
  {
    id: 7,
    packageHeding:
      "How does Google interact with social media platforms to increase your search rankings?",
    packageText:
      "Google integrates your social media campaigns that are trending and popular among social media users. Famous campaigns catch the attention of prominent search engines like Google and Bing. These search engines showcase prospective users about these campaigns in the hope that they may also like it. Hence, your brands climb up in Google and Bing SERPs swiftly.",
  },
];

export default TabsData;
export { DataFaq };
