import React, { Fragment, useEffect } from "react";
import SmoBannerText from "./SmoBannerText";
import SmoPriceTable from "./SmoPriceTable";
import ReadyPriceForm from "../PricesComponents/ReadyPriceForm";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
import SmoPriceData from "./SmoData";
import PriceAllFaq from "../PriceAllFaq";
import { Helmet } from "react-helmet";
function SmoPrice() {
  useEffect(() => {
    document.body.classList.add("price-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("price-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>SMO Service Packages | Social Media Advertising Prices </title>
        <meta
          name="description"
          content="Ready to take your social media to the next level? Check out EZ Marketing Tech's customizable social media packages and start seeing results."
        />
      </Helmet>
      <SmoBannerText />
      <SmoPriceTable />
      <ReadyPriceForm />

      <ReviesAll
        mainclassName="home_restimonials ppc_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />

      <PriceAllFaq
        faqDetail={SmoPriceData}
        mainHeading="Frequently Asked Questions"
      />
    </Fragment>
  );
}

export default SmoPrice;
