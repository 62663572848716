import React, { Fragment } from "react";
// import { NavLink } from "react-router-dom";
// import journeyImgs0 from "../../ImagesComponents/all-images/journeyImg.png";
// import journeyImgs1 from "../../ImagesComponents/all-images/journeyImg_overlay.png";
let journeyImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/journeyImg.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/journeyImg_overlay.png",
];
function WhoWeJourney() {
  return (
    <Fragment>
      <section className="our_journey_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 ai-center">
              <div className="our_journey_text">
                <h3>Our Journey</h3>
                <p>
                  EZ Marketing Tech was started as a SEO consultant services
                  with a team of no more than eight people in West Hollywood.
                  Later, it emerged into a mid-sized, full-service Digital
                  Marketing company, owing to its hundreds of successful SEO
                  campaigns.
                </p>
                <p>
                  With a tremendous demand for outcome-driven SEO services, we
                  grew our team, and by 2014, the strength of our team doubled.
                  Though fairly new, we have built a stellar reputation for
                  ourselves by helping our clients succeed through impeccable
                  and customized SEO solutions,{" "}
                  <a
                    href="https://www.ezmarketingtech.com/reviews"
                    target="_blank"
                    rel="noreferrer"
                    className="go_url"
                  >
                    top-notch customer experience,
                  </a>
                  and long-term engagements.
                </p>
                <p>
                  Your vision is our passion, and we know how to convert ideas
                  into reality. Partner with us to know what best we can do for
                  you, and let us be a part of your success story.
                </p>
                {/* <NavLink className="call_us">Get A Quote</NavLink> */}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <div className="our_journey_img">
                <img
                  className="journey_overlay"
                  src={journeyImgs[1]}
                  alt="Jorney img"
                  width="100%"
                />
                <img src={journeyImgs[0]} alt="Jorney img" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default WhoWeJourney;
