import React, { Fragment } from "react";
// import strategyImgs0 from "../../ImagesComponents/all-images/strategy.png";
// import strategyImgs1 from "../../ImagesComponents/all-images/strategy2.png";
// import strategyImgs2 from "../../ImagesComponents/all-images/strategy3.png";
// import strategyImgs3 from "../../ImagesComponents/all-images/strategy4.png";
let strategyImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/strategy.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/strategy2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/strategy3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/strategy4.png",
];
function SeoStrategy() {
  return (
    <Fragment>
      <section className="seo_strategy">
        <div className="container">
          <h2>Creating an Innovative SEO Strategy</h2>
          <p>
            Search engines gather signals from your website, from other
            websites, and visitor behavior. How well-versed are you in Maslow's
            hierarchy of needs? If not, don't worry! It is a psychological
            theory that prioritizes basic human needs over more complex ones,
            such as having access to clean water, air and physical safety (like
            esteem and social belonging). The theory holds that to satisfy the
            needs at the top, you must first satisfy the more fundamental needs.
            Food insecurity renders love meaningless. This is how it appears:
          </p>
          <p>
            As you can see, ensuring crawl accessibility is the first step in
            building a solid SEO strategy, and it progresses from there. Both
            on-site and off-site SEO activities are possible. This is why
            "on-page" and "off-page" categories for SEO are frequently used. In
            actuality, SEO typically entails the following:
          </p>
          <ul className="seo_strategy_lisr">
            <li>
              <div className="stratagy_figure">
                <img src={strategyImgs[0]} alt="img" />
              </div>

              <h4>Keyword Analysis</h4>
            </li>
            <li>
              <div className="stratagy_figure">
                <img src={strategyImgs[1]} alt="img" />
              </div>
              <h4>Creation and Promotion of content</h4>
            </li>
            <li>
              <div className="stratagy_figure">
                <img src={strategyImgs[2]} alt="img" />
              </div>
              <h4>Technical Optimization</h4>
            </li>
            <li>
              <div className="stratagy_figure">
                <img src={strategyImgs[3]} alt="img" />
              </div>
              <h4>Link Building</h4>
            </li>
          </ul>
          <p>
            Now you know that SEO aids in naturally increasing site traffic as
            customers, when looking for a service or product online, are more
            likely to choose one of the top 10 results from a search engine. And
            those top 10 results are chosen because they are excellently written
            and fully SEO optimized. SEO can help websites rank higher on search
            engine result pages in one of two ways.
          </p>
          <div className="seo_hat">
            <h3>
              White Hat SEO & Black Hat SEO
              <span>Below are each's advantages and a brief description.</span>
            </h3>
            <h4>White-Hat SEO: The Perky Strategy</h4>
            <p>
              When someone uses the term "white-hat SEO," they refer to SEO
              techniques compliant with Google and other major search engines'
              terms and conditions. While preserving the virtue of your website
              in accordance with the terms of service of the search engine,
              white-hat SEO raises your search engine ranking on the SERPs. The
              best way to build a successful website is to use white-hat SEO
              techniques. Observe the following white-SEO guidelines to the
              letter:
            </p>
            <ul className="seo_hat_list">
              <li>Use descriptive meta tags with the appropriate keywords.</li>
              <li>
                Ensure that the website's visitors receive high-quality services
                and content.
              </li>
              <li>Make it simple to navigate your website.</li>
              <li>Link Building from high-authority websites.</li>
            </ul>
            <h4>Black-Hat SEO: The Loophole Strategy</h4>
            <p>
              In order to rank higher in Google's search results, black-hat SEO
              takes advantage of the company's search algorithm's flaws.
              Numerous black hat techniques can seem alluring to SEO newcomers,
              such as adding invisible text to core pages or participating in
              link schemes. But take note: These actions do not come without
              repercussions. They frequently contain techniques that might raise
              a website's SERP rankings, but they are unethical and go against
              search engines' policies. There is a significant chance that this
              approach will result in search engine penalties. Your brand
              shouldn't employ the following black hat strategies if you want to
              make sure that your marketing budget is being used wisely.
            </p>
            <ul className="seo_hat_list mb-0">
              <li>Blogging spam.</li>
              <li>Making and utilizing doorway pages.</li>
              <li>Hidden or indistinct text.</li>
              <li>
                Stuffing pages, meta descriptions, and titles with keywords
              </li>
              <li>Ineffective external links.</li>
              <li> Manipulated or spammy guest posts, or both.</li>
              <li>Duplicating or scraping content</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="batter_seo text-center">
        <div className="container">
          <h3>Which Is Better: White Hat SEO or Black Hat SEO?</h3>
          <p>
            If it wasn't clear from the summaries above, white hat SEO is the
            way to go when creating a long-term SEO strategy. Following white
            hat tactics instead of black hat ones will help your website grow in
            rankings, traffic, and user trust without you ever having to worry
            about losing Google's favor.
          </p>
        </div>
      </section>
    </Fragment>
  );
}

export default SeoStrategy;
