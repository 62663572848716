import React, { Fragment } from "react";
import { FaFire } from "react-icons/fa";
function SmoBannerText() {
  return (
    <Fragment>
      <section className="price_table_banner">
        <div className="container">
          <div className="services_heading_wrapper">
            <h2>Choose A Plan That Works Best For You</h2>
            <p>
              EZ Marketing Tech understands the importance of effective social
              media promotion for your business. Choose from our range of
              customized packages to enhance your brand's online presence.
            </p>
          </div>

          <div className="price_plane_wrapper">
            <div className="row row_scroll m-0">
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>One Time Set-Up</h3>
                      <p>
                        This package offers basic social media setup, content
                        creation, and regular posting to establish your online
                        brand presence and engage with your audience
                        effectively.
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$249</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/fZeaGg3SUcLg4cU004"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="avail_content">
                    <table className="table table-responsive">
                      <thead>
                        <th>
                          <h3>Periods*</h3>
                        </th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>No. of Hours (per channel)</span>
                          </td>
                          <td>
                            <span>15</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>Serious Plan</h3>
                      <p>
                        This plan includes advanced content creation, social
                        media advertising, audience targeting, and performance
                        analytics to drive engagement, expand your reach, and
                        achieve measurable results.
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$199</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/14kg0AgFG4eK4cUdQV"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="avail_content">
                    <h4>
                      <FaFire /> Most Recommended
                    </h4>
                    <table className="table table-responsive">
                      <thead>
                        <th>
                          <h3>Periods*</h3>
                        </th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>No. of Hours (per channel)</span>
                          </td>
                          <td>
                            <span>15</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>Strategy Plan</h3>
                      <p>
                        Tailored for businesses seeking a comprehensive
                        approach, this package includes in-depth audience
                        research, strategic content planning, social media
                        campaigns, detailed performance analysis, and more!
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$249</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/3cs15G1KM5iO38QbIO"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="avail_content">
                    <table className="table table-responsive">
                      <thead>
                        <th>
                          <h3>Periods*</h3>
                        </th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>No. of Hours (per channel)</span>
                          </td>
                          <td>
                            <span>40</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-price_table p-8">
                <div className="price-detail ppc_price_detail">
                  <div className="price_content">
                    <div className="plane_wrap">
                      <h3>Steroid Plan</h3>
                      <p>
                        This package is designed to accelerate your social media
                        success. Enjoy priority support and comprehensive social
                        media promotion solutions!
                      </p>
                    </div>

                    <div className="price_amount">
                      <h4>Starts at</h4>
                      <div className="price_wrap">
                        <strong>$399</strong>
                        <span>Per Month*</span>
                      </div>
                      <button className="by_now">
                        <a
                          href="https://buy.stripe.com/eVag0Ablm7qWaBi7sz"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>buy now</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="avail_content">
                    <table className="table table-responsive">
                      <thead>
                        <th>
                          <h3>Periods*</h3>
                        </th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>No. of Hours (per channel)</span>
                          </td>
                          <td>
                            <span>60</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SmoBannerText;
