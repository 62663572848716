import React from "react";
// import SocialBringImgs0 from "../../ImagesComponents/all-images/social_bring_img.jpg";
// import SocialBringImgs1 from "../../ImagesComponents/all-images/social_bring_img2.jpg";
// import SocialBringImgs2 from "../../ImagesComponents/all-images/social_bring_img3.jpg";
// import SocialBringImgs3 from "../../ImagesComponents/all-images/popular_img.jpg";
let SocialBringImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/social_bring_img.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/social_bring_img2.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/social_bring_img3.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/popular_img.jpg",
];
const SocialBring = () => {
  return (
    <>
      <section className="social_bring">
        <figure className="social_bring_figure">
          <img src={SocialBringImgs[0]} alt=" img" />
          <figcaption className="social_bring_caption">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6"></div>
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="social_bring_overlay pl-5">
                    <h3>Bring in Social Proof</h3>
                    <p>
                      In an industry like CBD where trust and product quality is
                      everything, social proof works wonders. It not only builds
                      trust in the brand but also in the industry as a whole.
                      <br />
                      <br />
                      There are many ways to make this happen:
                      <br /> Positive social media conversations
                      <br /> 5-Star product ratings on the website
                      <br />
                      Quality testimonials on marketplaces
                      <br /> Persuasion triggers with social proof
                      <br /> Celebrity endorsements and influencer marketing{" "}
                      <br />
                      <br />
                      All of these strategies build credibility for the brand,
                      product, and industry. They also help drive conversions
                      and increase traffic and visibility for the brand.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>
        <figure className="social_bring_figure text-right">
          <img src={SocialBringImgs[1]} alt=" img" />
          <figcaption className="social_bring_caption">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="social_bring_overlay pr-70">
                    <h3>Have options for Pets</h3>
                    <p>
                      CBD and animal care: An amalgamation that makes you drool
                      with excitement.
                      <br />
                      <br /> At a time when the pet population in the US is
                      growing rapidly, more people than ever have been looking
                      for different ways to keep their pets healthy and happy.
                      <br />
                      <br />
                      Many have found that CBD is a natural and effective way to
                      offer their pets with the best health care without the use
                      of harmful drugs or procedures.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6"></div>
              </div>
            </div>
          </figcaption>
        </figure>
        <figure className="social_bring_figure mb-5">
          <img src={SocialBringImgs[2]} alt=" img" />
          <figcaption className="social_bring_caption">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6"></div>
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="social_bring_overlay pl-5">
                    <h3>Run Instagram influencer marketing</h3>
                    <p>
                      One of the biggest avenues for CBD marketing is affiliate
                      marketing. A big part of it comprises influencers on
                      Instagram. Instagram influencer marketing is an excellent
                      way to build your brand visibility, get more followers,
                      and offer a better customer experience.
                      <br /> This is even more essential in the CBD industry
                      where digital advertising is challenging and people are
                      still skeptical about the use and effectiveness of CBD
                      products.
                      <br /> Having influencers, or even micro-influencers,
                      discuss CBD on a personal level helps bridge the gap
                      between brands and consumers and build awareness for a
                      larger market share eventually.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>
        <div className="populer_text pb-5">
          <div className="container ">
            <div className="row ai-center popular_row mb-3">
              <div className="col-12 col-sm-12 col-md-4">
                <h3>
                  Most Popular type of CBD product sold Great to Target To Make
                  Conversion Big
                </h3>
              </div>
              <div className="col-12 col-sm-12 col-md-8">
                <img src={SocialBringImgs[3]} alt="img" width="100%" />
              </div>
            </div>
            <p>
              Since there are many variations of CBD products, knowing which
              ones are the best sellers can go a long way. If you are not
              selling CBD oils and gummies, you’re losing out a lot. <br /> We
              recommend looking at not just which products sell best, but also
              which ones drive more customer loyalty and featuring those loyalty
              drivers more prominently on your website.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SocialBring;
