import parse from "html-react-parser";
const ppcPriceData = [
  {
    id: 1,
    packageHeding: "What is PPC advertising?",
    packageText:
      "Pay-per-click, or PPC, is a kind of digital advertising where advertisers are charged a fee each time one of their ad campaigns is clicked. Simply put, you just incur advertising costs when someone opens your advertisement. PPC advertising is most frequently done through search engines like Google Ads, where marketers bid on search terms and their ads show at the very top of search engine results pages (SERPs) if those search terms are browsed for. PPC advertising is also possible on social media sites like Facebook and Instagram, as well as online with display ads.",
  },
  {
    id: 2,
    packageHeding: "What is PPC Ad Spend?",
    packageText:
      "PPC Ad Spend is an estimate of how much money websites paid for PPC keywords during the period under consideration. If PPC is working correctly, the return on ad spend (ROAS) should be significant because a visit to your webpage is worth more than the price you paid to get it. You use this ad spend estimation to determine your PPC budget. This budget is generally described as the amount of money devoted to web traffic acquisition efforts because advertising charges are only incurred after a potential customer clicks on your advertisement.",
  },
  {
    id: 3,
    packageHeding: "Where can you advertise with PPC ads?",
    packageText:
      parse(`<p>Many well-known platforms provide PPC advertising options. The most well-known include Google, Microsoft Bing, and Facebook. Here are some more PPC ad networks:
    </p>
    <ul>
    <li>YouTube</li>
    <li>Instagram</li>
    <li>TikTok</li>
    <li>LinkedIn</li>
    <li>Twitter</li>
    <li>Pinterest</li>
    <li>Amazon</li>
    <li>Etsy </li>
    <li>Yelp</li></ul>
    <p>You probably see PPC ads nearly every day when you're on a computer or cell phone, irrespective of the ad platform. Paid search advertisements, like Google Ads, are displayed as sponsored content at the beginning of search results. Paid social, which includes shopping ads, is typically integrated into other types of content, like an Instagram feed or an Etsy product listing.</p>`),
  },
  {
    id: 4,
    packageHeding: "Do people really click on online PPC ads?",
    packageText: parse(
      `<p>It's a common misconception that users prefer organic search results to paid advertisements. As a result, when someone searches on Google, they don't see the paid results and instead land on an organic result. The answer, however, is that users do click on PPC ads on Google. Only 28% of people believe organic results are more trustworthy than ads, according to <a target="_blank" href="https://www.smartinsights.com/search-engine-optimisation-seo/seo-strategy/organic-vs-ppc-view-consumer/">Smart Insights.</a>  There are millions of clicks on PPC ads every day, even though some users ignore them. <a target="_blank" href="https://www.smallbizgenius.net/by-the-numbers/ppc-stats/%23gref/">65% of people</a> click on these ads when they're trying to buy something. If your advertisement matches their search criteria, you could have the opportunity to receive the click-through and the sale. PPC advertisements have a potential ROI of $2 on average for every $1 spent.</p>`
    ),
  },
  {
    id: 5,
    packageHeding: "How much does a PPC ad campaign cost?",
    packageText:
      "It's a legitimate question that we frequently get, especially from people new to paid search. After all, you'll need to make sure you have the money to pay for it. It's a good thing that you can. PPC pricing, however, is affected by an array of factors, including the size of the company you run, the PPC manager you choose, and the ad network you prefer. According to WebFX, businesses should budget $300 to $100 million (or more) per month for PPC management, with an average PPC cost of $2.59 for each click and $3.12 for every 1000 impressions.",
  },
  {
    id: 6,
    packageHeding: "What factors determine my PPC costs?",
    packageText:
      "PPC advertising enables you to quickly direct traffic into your marketing systems, yet if you're not watchful, your costs could quickly soar. Truth be told, a balance between your bidding strategy, keyword competition, quality score, and a handful of other factors like geographic area, conversion rate, etc. can be best to achieve your dream deal with keyword price, or cost per click (CPC).",
  },
  {
    id: 7,
    packageHeding: "What factors determine my PPC costs?",
    packageText:
      "PPC advertising enables you to quickly direct traffic into your marketing systems, yet if you're not watchful, your costs could quickly soar. Truth be told, a balance between your bidding strategy, keyword competition, quality score, and a handful of other factors like geographic area, conversion rate, etc. can be best to achieve your dream deal with keyword price, or cost per click (CPC).",
  },
  {
    id: 8,
    packageHeding: "What businesses can (and should) advertise online?",
    packageText:
      "Digital advertising networks like social media ads, display ads, and paid search ads are becoming more popular as they are affordable and trackable. This transformation is democratizing the sector. Online advertising, therefore, ought to be your first step if you want to expand your company. There's no “industry limit” to this. However, you can accurately determine the “opportunity window” by doing a few quick searches related to your products or services, which can show whether PPC is a necessary tactic for your industry.  ",
  },
  {
    id: 9,
    packageHeding: "How does PPC advertising work?",
    packageText:
      "In the pay-per-click model, keywords play a major role. Advertisers place bids on keywords that are pertinent to their businesses as part of a bidding process for ads known as the 'Ad Auction.' For this reason, make use of keyword research tools to discover relevant keywords with the proper volume and average clickthrough rate. Create an advertisement and set it up on the search engine platform once you have the applicable keywords. Add the keywords you want to trigger the ads to run on.",
  },
];

export default ppcPriceData;
