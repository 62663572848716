import React, { Fragment } from "react";
// import weBattericonImgs0 from "../../ImagesComponents/all-images/we_batter.png";
// import weBattericonImgs1 from "../../ImagesComponents/all-images/weBattericonNew.png";
// import weBattericonImgs2 from "../../ImagesComponents/all-images/weBattericon2.png";
// import weBattericonImgs3 from "../../ImagesComponents/all-images/weBattericon3.png";
// import weBattericonImgs4 from "../../ImagesComponents/all-images/weBattericon4.png";
// import weBattericonImgs5 from "../../ImagesComponents/all-images/weBattericon5.png";
// import weBattericonImgs6 from "../../ImagesComponents/all-images/weBattericon6.png";
let weBattericonImgs = [
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/we_batter.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/weBattericonNew.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/weBattericon2.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/weBattericon3.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/weBattericon4.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/weBattericon5.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/weBattericon6.png",
];
function WhyWeBatter() {
  return (
    <Fragment>
      <section className="WhyWeBatter_section">
        <div className="container">
          <div className="row align-center mb-6">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
              <div className="we_batter_ribun col-mb-5">
                <h3>Why are we Better than Other Companies?</h3>
                <span>
                  <img src={weBattericonImgs[0]} alt="img" />
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-8">
              <div className="we_batter_text">
                <h4>White Hat SEO By EZ Marketing Tech</h4>
                <p>
                  Stagnant in a cycle of ineffective optimizations? EZ Marketing
                  Tech provides the All-In-One SEO Solution You Need To Perform
                  Better Than Your Competition.
                </p>
                <ul>
                  <li> Out-of-the-box Thinking.</li>
                  <li>World-Class SEO Experts and Researchers.</li>
                  <li>Result-Driven Strategies.</li>
                </ul>
                <p>
                  We are also a customer-first brand prepared to meet all of
                  your needs. Here are a few characteristics that set us apart
                  from the competition.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
              <div className="batter_serv">
                <h4>
                  <img src={weBattericonImgs[1]} alt="img" />
                  Client First
                </h4>
                <p>
                  Our top priority is making you happy. We strive to produce the
                  best results while also being a pleasure to work with.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
              <div className="batter_serv">
                <h4>
                  <img src={weBattericonImgs[2]} alt="img" />
                  Digital Era Experts
                </h4>
                <p>
                  Our SEO experts can help you reach the top of Google Search by
                  partnering with years of experience and world-class online
                  marketing capabilities.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5">
              <div className="batter_serv">
                <h4>
                  <img src={weBattericonImgs[3]} alt="img" />
                  Efficiency
                </h4>
                <p>
                  Proven effectiveness and quality-driven development
                  methodology developed over millions of person-hours.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-md-mb-5">
              <div className="batter_serv">
                <h4>
                  <img src={weBattericonImgs[4]} alt="img" />
                  Right Size
                </h4>
                <p>
                  We are a search engine optimization company that offers
                  services with the strength of a big company and the
                  flexibility of a young start-up. The best of both worlds is
                  yours to enjoy.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-md-mb-5">
              <div className="batter_serv">
                <h4>
                  <img src={weBattericonImgs[5]} alt="img" />
                  Strategic Partnerships
                </h4>
                <p>
                  Leading businesses use our services and depend on our SEO
                  know-how to grow and generate income over the long term.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-md-mb-5">
              <div className="batter_serv">
                <h4>
                  <img src={weBattericonImgs[6]} alt="img" />
                  Excellent Support
                </h4>
                <p>
                  As your dependable SEO company, we can always offer exciting
                  possibilities throughout each project stage. As your
                  dependable SEO company, we can always offer exciting
                  possibilities throughout each project stage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default WhyWeBatter;
