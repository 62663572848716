import React from "react";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class ReadyPriceForm extends React.Component {
  createNotification = (type) => {
    return () => {
      switch (type) {
        case "success":
          NotificationManager.success("Successfully Submitted");
          break;
        default:
        case " ":
      }
    };
  };
  render() {
    return (
      <section className="get_form_section  get_price_ready_form">
        <div className="container">
          <div className="ranking_form_wrapper">
            <h2>Empower Your Website and Boost Your Online Presence with Us</h2>
            <p>
              A Strong team of digital marketing experts are ready and waiting
              to transform your business.
            </p>
            <form
              className="banner_form"
              action="https://beta.ezmarketingtech.com/lets-get-started"
              method="POST"
              onSubmit={this.createNotification("success")}
            >
              <div className="form-group row">
                <input
                  className="from-control"
                  type="hidden"
                  id="key"
                  name="key"
                  value={window.location.pathname.slice(1)}
                />
                <div className="col-12 col-sm-12 col-sm-mb-3 col-md-4 col-lg-3">
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    placeholder="Enter your website"
                    required={true}
                  />
                </div>
                <div className="col-12 col-sm-12 col-sm-mb-3 col-md-4 col-lg-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter your e-mail"
                    required={true}
                  />
                </div>
                <div className="col-12 col-sm-12 col-sm-mb-3 col-md-4 col-lg-3">
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    placeholder="Enter your phone no."
                    required={true}
                  />
                </div>
                <NotificationContainer />
                <div className="col-12 col-sm-12  col-md-12 col-lg-3 d-flex item-center j-center">
                  <button type="submit" className="btn btn-md banner_r_btn">
                    Let’s Get Started
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default ReadyPriceForm;
