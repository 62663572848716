import React, { Fragment } from "react";
// import { NavLink } from "react-router-dom";
// import { HiOutlineArrowNarrowRight } from "react-icons/hi";
const ReputationManagementAnalysis = () => {
  return (
    <Fragment>
      <section className="seo_analysis_sec content_reputation_section">
        <div className="container">
          <h3>
            When Your Competitors Put You Down,
            <br /> Upscale Your Reputation!
          </h3>
          <p className="text-left">
            Our online reputation managers upscale your reputation step by step,
            online day and night. We put constant efforts into finding the
            negative and defaming statements. We eradicate those statements
            through search engine optimization strategies or (if required) legal
            actions. Either way, we are headstrong toward keeping the positivity
            alive.
          </p>
          <p className="text-left">
            Our trained ORM experts search online forums and consumer portals to
            analyze what users are talking about behind your back. Our trained
            ORM professionals dive into the customer reviews pool and pick
            positive ones to highlight your brand quality. Restore your online
            reputation with our chiseled online reputation management strategy.
            We cover all online platforms where negativity lies and block them
            from wearing down your image!
          </p>
        </div>
      </section>
      <section className="reputation_section content_reputation_section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-5 mb-5">
              <div className="clients_text_wrap clients_text_bold">
                <h2>
                  Take Advantage of Our
                  <br /> High-rated Online Reputation
                  <br /> Management Services
                </h2>
                {/* <NavLink className="know_more">
                  know more <HiOutlineArrowNarrowRight />
                </NavLink> */}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 mb-5 ">
              <div className="clients_text_wrap clients_text_regular">
                <h3>Legal Removals</h3>
                <p>
                  We take defamatory statements head-on. Stay positive with our
                  legal framework. Our ORM analysts are proficient in the nexus
                  of legal resolution. We legally challenge libelous statements
                  and malign intentions from the internet.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-5 ">
              <div className="clients_text_wrap clients_text_regular">
                <h3>Profile Building</h3>
                <p>
                  Our ORM team maintains your many brand profiles across the
                  network. We intermittently infuse positive reviews about your
                  brands. This strategy is infallible in creating and generating
                  more online positivity about your brand and product.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <div className="clients_text_wrap clients_text_regular">
                <h3>Using Social Media</h3>
                <p>
                  Social media is a powerful tool to influence users.
                  Competitors use social media platforms to dismantle your
                  online reputation and shut your business down. Our ORM experts
                  forage social media platforms like Facebook and Instagram to
                  inspect negative reviews. These reviews drag your image down
                  and vanish your popularity. Our creative ORM consultants
                  uphold your online image by countering these claims.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 ">
              <div className="clients_text_wrap clients_text_regular">
                <h3>
                  Reckless Rounds to <br />
                  Detect Negativity
                </h3>
                <p>
                  We scan online forums and search engine reviews for negative
                  comments. The scan assists us in locating the site for
                  negative statements and their origin. Our proficient ORM
                  analysts identify the source of negativity and confront it
                  outrightly. Not only negativity from rivals, but we also defy
                  negativity from buyers with ungrateful intentions.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 ">
              <div className="clients_text_wrap clients_text_regular">
                <h3>
                  Constant Positive <br />
                  Reviews
                </h3>
                <p>
                  Our ORM experts consistently work for positive reviews to
                  increase their proportion. It works opposite to the intentions
                  of competitors who have teams hired to brainwash your precious
                  customers/buyers.
                </p>
              </div>
            </div>
            <h3 className=" amp-review text-center mt-5 mb-5">
              “Amp Up Your Brand’s Reputation and Turn the Tables Around with
              Our Responsible ORM Services”
            </h3>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ReputationManagementAnalysis;
