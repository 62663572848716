import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import { BsFillStarFill } from "react-icons/bs";

const ReviesAll = (props) => {
  return (
    <Fragment>
      <section className={props.mainclassName}>
        <div className="container">
          <span className="sub-text">{props.heading}</span>
          <h2 className="title title2">{props.SubHeading}</h2>

          <OwlCarousel
            className="rs-carousel owl-carousel"
            loop={true}
            margin={10}
            nav={true}
            items={7}
            autoplay={true}
            autoplayHoverPause={true}
            responsive={{
              1: {
                items: 1,
              },

              768: {
                items: 2,
              },
              992: {
                items: 3,
              },
              1199: {
                items: 3,
              },
              1300: {
                items: 3,
              },
            }}
          >
            {props.details.map((value, index) => (
              <div className="testimonial_cover" key={props.id}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="testimonial_wrapper">
                      <span className="quote_img ">
                        <img src={value.revireImg} alt="img" />
                      </span>
                      <ul className="star_list">
                        <li>
                          <BsFillStarFill className="ico" />
                          <BsFillStarFill />
                          <BsFillStarFill />
                          <BsFillStarFill />
                          <BsFillStarFill />
                        </li>
                      </ul>
                      <p>{value.clientReview}</p>
                      <h3>{value.clientName}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>
    </Fragment>
  );
};

export default ReviesAll;
