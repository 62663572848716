import React, { Fragment } from "react";
// import memorableIconImgs0 from "../../ImagesComponents/all-images/memorable_icon.png";
// import memorableIconImgs1 from "../../ImagesComponents/all-images/memorable_icon2.png";
// import memorableIconImgs2 from "../../ImagesComponents/all-images/memorable_icon3.png";
let memorableIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/memorable_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/memorable_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/memorable_icon3.png",
];

function MemorableContent() {
  return (
    <Fragment>
      <section className="memorable_sec">
        <div className="container">
          <div className="memorable_sec_wrapper">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-center">
                <div className="memorable_content">
                  <img src={memorableIconImgs[0]} alt="Memorable icon" />
                  <h4>
                    Creating Memorable
                    <br />
                    Experiences
                  </h4>
                  <p>
                    We understand the needs of your customers; therefore, we
                    devise tools that offer them an experience they can never
                    forget.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-center">
                <div className="memorable_content border-left">
                  <img src={memorableIconImgs[1]} alt="Memorable icon" />
                  <h4>
                    Welcoming
                    <br />
                    Opportunities
                  </h4>
                  <p>
                    By studying your audience, we open the gates to new
                    opportunities.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-center">
                <div className="memorable_content border-left">
                  <img src={memorableIconImgs[2]} alt="Memorable icon" />
                  <h4>
                    Digital
                    <br />
                    Innovation
                  </h4>
                  <p>
                    We empower our clients by offering the best SEO solutions
                    and help them get ready for the future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default MemorableContent;
