let DmServiceImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/ecome_service_icon.gif",
  "https://ezmarketingtech.s3.amazonaws.com/img/relation_design_service_icon.gif",
  "https://ezmarketingtech.s3.amazonaws.com/img/social_media_service_icon.gif",
  "https://ezmarketingtech.s3.amazonaws.com/img/content_marketing_service_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/per_click_service_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/seo_service_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/web_design_service_icon.gif",
];
const DmService = () => {
  return (
    <>
      <section className="main_serv_list">
        <div className="container">
          <h3>
            <span>Digital Marketing Services</span>
          </h3>
          <div className="row mb-3 j-center">
            <div className="col-12 col-sm-12 col-md-3">
              <div className="marketing_service_card">
                <img src={DmServiceImg[5]} alt="img" />
                <h4>Amazon SEO</h4>
                <ul className="marketing_service_list">
                  <li>WordPress Design</li>

                  <li>Magento Design</li>
                  <li>Shopify Design</li>
                  <li>Custom Development</li>

                  <li>Site Maintenance</li>
                  <li>
                    <a href="/">Read More...</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3">
              <div className="marketing_service_card">
                <img src={DmServiceImg[4]} alt="img" />
                <h4>Pay Per Click/ Adwords</h4>
                <ul className="marketing_service_list">
                  <li>WordPress Design</li>

                  <li>Magento Design</li>
                  <li>Shopify Design</li>
                  <li>Custom Development</li>
                  <li>Site Maintenance</li>
                  <li>
                    <a href="/">Read More...</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3">
              <div className="marketing_service_card">
                <img src={DmServiceImg[3]} alt="img" />
                <h4>Content Marketing</h4>
                <ul className="marketing_service_list">
                  <li>WordPress Design</li>

                  <li>Magento Design</li>
                  <li>Shopify Design</li>
                  <li>Custom Development</li>
                  <li>Site Maintenance</li>
                  <li>
                    <a href="/">Read More...</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3">
              <div className="marketing_service_card">
                <img src={DmServiceImg[2]} alt="img" />
                <h4>Social Media Marketing</h4>
                <ul className="marketing_service_list">
                  <li>WordPress Design</li>

                  <li>Magento Design</li>
                  <li>Shopify Design</li>
                  <li>Custom Development</li>
                  <li>Site Maintenance</li>
                  <li>
                    <a href="/">Read More...</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row j-center">
            <div className="col-12 col-sm-12 col-md-3">
              <div className="marketing_service_card">
                <img src={DmServiceImg[6]} alt="img" />
                <h4>Web Designing</h4>
                <ul className="marketing_service_list">
                  <li>WordPress Design</li>

                  <li>Magento Design</li>
                  <li>Shopify Design</li>
                  <li>Custom Development</li>
                  <li>Site Maintenance</li>
                  <li>
                    <a href="/">Read More...</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3">
              <div className="marketing_service_card">
                <img src={DmServiceImg[1]} alt="img" />
                <h4>Reputation Management</h4>
                <ul className="marketing_service_list">
                  <li>WordPress Design</li>

                  <li>Magento Design</li>
                  <li>Shopify Design</li>
                  <li>Custom Development</li>
                  <li>Site Maintenance</li>
                  <li>
                    <a href="/">Read More...</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3">
              <div className="marketing_service_card">
                <img src={DmServiceImg[0]} alt="img" />
                <h4>E-commerce SEO</h4>
                <ul className="marketing_service_list">
                  <li>WordPress Design</li>

                  <li>Magento Design</li>
                  <li>Shopify Design</li>
                  <li>Custom Development</li>
                  <li>Site Maintenance</li>
                  <li>
                    <a href="/">Read More...</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DmService;
