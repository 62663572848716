import React from "react";
// import keywordImgs0 from "../../ImagesComponents/all-images/keyword_img.jpg";
// import keywordImgs1 from "../../ImagesComponents/all-images/keyword_img2.jpg";
let keywordImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/keyword_img.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/keyword_img2.jpg",
];
const KeywordsResearch = () => {
  return (
    <>
      <section className="keywords_research">
        <div className="container">
          <h2>
            <strong>Idea About Keywords Research As Per CBD Industry</strong>
          </h2>
          <p className="mb-5">
            <strong> Search Intent- </strong> The first parameter to analyze
            while selecting CBD keywords is search intent. If the content
            doesn’t include the keyword that matches the intent of users, users 
            will immediately close the site. So, you need to include key phrases
            that serve signals that the users are ready to buy. Here are some
            examples:{" "}
          </p>
          <p>
            <strong>“Best CBD Oil”</strong>- This phrase is used by those who
            are interested in buying the best CBD oils. Hence, the chance that
            this user is interested in purchasing CBD oil is quite high.{" "}
          </p>{" "}
          <p>
            {" "}
            <strong>“What is CBD Oil” </strong> - As you can see from the
            phrase, the user does not know what CBD oil is. Therefore, this user
            is interested in CBD oil, however, may not be ready to buy it.
            Attracting such users might need some extra efforts— they might
            become buyers later.{" "}
          </p>{" "}
          <p className="mb-5">
            <strong>“How long does cannabis stay in the body” </strong> - the
            user has already acquired marijuana and needs information. There is
            even a probability that they will not want to use cannabis products
            in the near future. It is a good keyword for a blog, but not for a
            commercial page. Informational blog pages are crucial to generate
            quality backlinks, which will improve your domain rating (DR).{" "}
          </p>
          <p>
            <strong> Search Volume </strong>- This parameter is based on the
            search statistic for particular key phrases. It is necessary to
            select the best CBD keywords with a high search volume and include
            them in your content to rank your website higher. But everything is
            not that simple in SEO, especially if you are working in the
            cannabis business.
          </p>
          <img src={keywordImgs[0]} alt="img" height="562px" width="1280px" />
          <p>
            <strong>Keyword Difficulty </strong>- SEO experts in the CBD niche
            know how to select relevant SEO keywords that might help a CBD
            business thrive, irrespective of the general SEO optimization rules.
            Even though such keywords are hard to find, if you manage to find
            them, they will bring you qualified leads. These search terms have
            high search volume among users; however, their keyword-difficulty
            parameter is low. Getting help from a real professional might also
            help find these keywords.
          </p>
          <img src={keywordImgs[1]} alt="img" height="562px" width="1280px" />
        </div>
      </section>
    </>
  );
};

export default KeywordsResearch;
