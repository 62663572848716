import React, { Fragment } from "react";
import BannerFigure from "./BannerFigure";
// const BannerFigure = lazy(() => import("./BannerFigure"));
const HomeNewBanner = () => {
  return (
    <Fragment>
      <section className="banner-section home_banner_sec">
        <BannerFigure />
      </section>
    </Fragment>
  );
};

export default HomeNewBanner;
