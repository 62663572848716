import React, { Fragment } from "react";
// import { NavLink } from "react-router-dom";
// import { HiOutlineArrowNarrowRight } from "react-icons/hi";
const SocialMediaAnalysis = () => {
  return (
    <Fragment>
      <section className="seo_analysis_sec content_reputation_section">
        <div className="container">
          <h3>Social Media Marketing Solutions We Provide to Our Clients! </h3>
          {/* <p className="text-center">
            Our personalized content marketing services are over the top and
            ensure that you gain significant user loyalty. We create a perfect
            content marketing strategy suited to your brand voice, tone, and
            image.
          </p>
          <p className="text-center">
            Our content agency produces content, whether moving or static, that
            goes parallel to your target audience’s interests. The content not
            only engages your visitors but also makes them come back for more!
          </p> */}
        </div>
      </section>
      <section className="reputation_section content_reputation_section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 mb-5">
              <div className="clients_text_wrap clients_text_bold">
                <h2>
                  Here’s
                  <br /> How We
                  <br /> Do The
                  <br /> Magic
                </h2>
                {/* <NavLink className="know_more">
                  know more <HiOutlineArrowNarrowRight />
                </NavLink> */}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-5 mb-5 ">
              <div className="clients_text_wrap clients_text_regular">
                <h3>
                  Social Media Profiles
                  <br /> Management
                </h3>
                <p>
                  Our Social media managers manage your social media account on
                  various platforms. This management includes creating,
                  managing, and updating your social media account regularly
                  using posts, videos, and crucial information you wish to share
                  with your followers.
                </p>
                <p>
                  Our dedicated social media managers do not leave any platform
                  behind. <br />
                  From building your presence on Facebook to making you seen on
                  Youtube through in-stream ads, they do all the legwork for
                  you.
                </p>
                <p>
                  Our professional goal for our clients is to cover each nook
                  and corner of the social media space.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-5 mb-5 ">
              <div className="clients_text_wrap clients_text_regular">
                <h3>
                  Social Media Brand
                  <br /> Management
                </h3>
                <p>
                  We hire a selective team for holistic social media brand
                  management.
                  <br /> The management involves updating content like videos,
                  text, and images. Our social media specialists optimize your
                  social media profiles timely without any gaps. They are always
                  engaged in creating and distributing immersive content among
                  social crowds.
                </p>
                <p>
                  We also take special care of your audience. As a full-service
                  social media marketing company, we ensure users stay connected
                  with your branding channels. These channels can be anywhere on
                  the social media network.
                  <br /> It also includes wishing your loyal followers on
                  special occasions to make them feel valued.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <div className="clients_text_wrap clients_text_regular">
                <h3>
                  Social Media <br /> Advertisement
                </h3>
                <p>
                  Our social media experts follow a keen strategy to put your
                  best foot forward to a massive audience.
                </p>
                <p>
                  Our social media experts display your advertisement in all
                  digital formats. These formats include texts, videos, and
                  images. We guarantee its presence on Facebook, YouTube,
                  Pinterest,
                  <br /> Instagram, and other platforms. Our endless endeavor is
                  to make your social media ads and campaigns worth sharing
                  among friends.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 ">
              <div className="clients_text_wrap clients_text_regular">
                <h3>
                  Social Media <br />
                  Campaign Reports
                </h3>
                <p>
                  Our social media company produces regular campaign reports to
                  provide insights into the ongoing campaign.
                </p>
                <p>
                  These summarized reports highlight the factors behind the
                  performance of your campaign. These factors elaborate on the
                  path ahead for your campaign.
                </p>
                <p>
                  Our in-depth analysis helps us gain more information about
                  user behavior toward your brand and how to make the campaign
                  more captivating!
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 ">
              <div className="clients_text_wrap clients_text_regular">
                <h3>
                  Market
                  <br /> Analyses
                </h3>
                <p>
                  Our social media marketing specialists analyze market trends
                  that benefit similar brands as yours.
                </p>
                <p>
                  These analyses highlight more effective strategies your
                  competitors use. These techniques can also help gather more
                  social groups and popularity for your campaign. These
                  strategies can unveil an extraordinary potential hidden in
                  your brand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SocialMediaAnalysis;
