import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
// import StoryBoxImgs from "../../ImagesComponents/all-images/story_box_img.png";
let StoryBoxImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/story_box_img.png",
];
function SeoAmazonStrategies() {
  return (
    <Fragment>
      <section className="strategies_section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="strategie_content">
                <h3>
                  Our Other <span>Amazon </span> <br />{" "}
                  <span>Marketing Services</span> <br />{" "}
                  <span> & Strategies</span>
                </h3>
                <p>
                  Other than major Amazon marketing services, our supplementary
                  SEO-oriented and digital marketing services give a lightning
                  thrust to your brand leverage. Strategic marketing is the key
                  essential of the mentioned services. Other than listing
                  services on Amazon and raising Amazon suspension appeal, we
                  stay focused on the SEO and other props for advertising.{" "}
                </p>
                <button className="process_start_btn">
                  {" "}
                  <NavLink to="/ppc-marketing"> let's started </NavLink>
                </button>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="row mx-0">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-0">
                  <div className="strategie_box_img">
                    <img src={StoryBoxImgs[0]} alt="100%" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-0 bl-1">
                  <div className="strategie_box_content">
                    <h4>
                      Amazon <span>SEO</span>
                    </h4>
                    <p>
                      We provide Amazon SEO services to elevate your eCommerce
                      business. Extensive keyword research, better conversion
                      rates, competitor analysis, Amazon account audit, and
                      product descriptions by listing optimization for Amazon
                      are some favorable services. Our ardent Amazon product
                      listing services with highly pertinent keywords will
                      assure more organic traffic to your store.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-0 bt-1">
                  <div className="strategie_box_content">
                    <h4>
                      Amazon <span>Advertising</span>
                      <br />
                      Pay Per Click
                    </h4>
                    <p>
                      Distinguished Amazon sellers pay to utilize the
                      Pay-Per-Click campaign. Our expert consultants for
                      marketing management for Amazon will assist in integrated
                      brand advertising on Ad platforms. We will advise on
                      strategies for balancing sponsored products, brands, and
                      displays. EZ Marketing Tech will escort you to post video
                      advertisements on Amazon Seller Central as part of PPC
                      drive.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-0 bl-1 bt-1">
                  <div className="strategie_box_content">
                    <h4>
                      Amazon
                      <span>Reporting</span>
                      <br />
                      and Analytics
                    </h4>
                    <p>
                      Sales monitoring and analytics are as pivotal as brand
                      performance. Regular reports helps you to track your
                      eCommerce business. With an understanding of where your
                      business lags behind, recurring reports are beneficial. We
                      as an accomplished Amazon marketing agency, provide
                      monthly Amazon Advertising reports. Our reports reflect on
                      Advertising Cost of Sales (ACoS), Return on Ad Spend
                      (ROAS), Pay Per Click data, and Fulfillment Reports
                      generated on Amazon Seller Central.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_investing_content">
        <div className="container">
          <h4>
            Ready to invest in Amazon Marketing Services
            <br /> Before investing in Amazon eCommerce Business?
            <br /> Join Hands to Gain more.
          </h4>
          <button className="process_start_btn">
            {" "}
            <NavLink to="/ppc-marketing">let's started</NavLink>{" "}
          </button>
        </div>
      </section>
    </Fragment>
  );
}

export default SeoAmazonStrategies;
