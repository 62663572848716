import React, { Fragment } from "react";

function SeoAnalysis() {
  return (
    <Fragment>
      <section className="seo_analysis_sec">
        <div className="container">
          <h3>SEO for eCommerce Website and Competitive Analysis</h3>
          <p>
            We at EZ Marketing Tech focus on the strategies your competitors are
            using to analyse what you are lagging on. We ensure that your
            eCommerce platform is abreast with all the advanced technological
            development.
          </p>
          <p>
            Our competitive analysis looks at your competitors to figure out
            what makes them successful and how to improve your performance.
            eCommerce SEO and Email Marketing Automation
          </p>
          <p>
            We provide all it needs to drive more sales and organic traffic to
            your eCommerce website. We craft personalized emails that convey
            straight expectations to buyers, such as their past purchases and
            shopping interests.
          </p>
          <p>
            Our eCommerce SEO specialists endeavour to retain your loyal
            customers by maintaining persistent communication with them. Our
            team of skilled professionals is proficient in designing
            user-responsive marketing emails and testing email marketing
            campaigns intermittently to understand Market trends
          </p>
        </div>
      </section>
    </Fragment>
  );
}

export default SeoAnalysis;
