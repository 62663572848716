const sliderPpc = [
  {
    id: 1,
    slideHeading: "PPC AUDIT ",

    slideTextContent:
      "Get a free PPC audit from our experienced team to learn how to improve the performance of your ad campaigns and strategy.Get a free PPC audit from our experienced team to learn how to improve the performance of your ad campaigns and strategy.",
    slidebtn: "Audit Your Strategy",
  },
  {
    id: 2,
    slideHeading: "REMARKETING",

    slideTextContent:
      "Use this PPC service to reconnect with past website visitors using targeted ads that motivate users to call, visit, or make a purchase.",
    slidebtn: "Reconnect With Shoppers",
  },
  {
    id: 3,
    slideHeading: "FACEBOOK AD ",
    slideTextContent:
      "Take full advantage of Facebook advertising with a custom campaign that drives brand awareness, likes, follows, and more.",
    slidebtn: "Get Noticed on Facebook",
  },
  {
    id: 4,
    slideHeading: "GEOFENCING AD ",
    slideTextContent:
      "Generate custom remarketing audiences using geofences to target conferences, competitor locations, and more.",
    slidebtn: "Use Geofencinga",
  },
];

export default sliderPpc;
