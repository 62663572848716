import quoteImg from "../../ImagesComponents/bannerImg/quote.png";
import parse from "html-react-parser";

let AboutAnimateIcon = [
  "https://ezmarketingtech.s3.amazonaws.com/img/about_animate1.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/about_animate2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/about_animate3.png",
];

let HomeImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/about.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/WebsiteAnalysis.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/SEOStrategy.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/CompetitorAnalysis.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/TechnicalSEO.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/quote.png",
];

//////////////////// this is a home page  all data ///////////////////////

////////////////////  home page  all data increase month and yearly ///////////////////////
const HomeElementorData = [
  {
    id: 2,
    textClass: "services-item incr_service",
    incrNumber: 7.33,
    incTeaxt: "Increase in Monthly",
  },
  {
    id: 3,
    textClass: "services-item orange-bg",
    incrNumber: 25.33,
    incTeaxt: "Increase in Yearly",
  },
  {
    id: 4,
    incrNumber: 1.75,
    textClass: "services-item pink-bg",
    incTeaxt: "Decrease in Yearly",
  },
];
////////////////////  home page  all About  data ///////////////////////
const aboutData = [
  {
    id: 5,
    ImgSrc: HomeImgs[0],
    titleTeaxtHeading: "About Us",
    mainHeading: "EZ Marketing Tech",
    subHeading: parse(
      `EZ Marketing Tech is a company that provides end-to-end <a href="https://ezmarketingtech.com/seo-services" target="_blank" rel="noreferrer">SEO solutions</a>  that helps businesses unlock their full potential and make a positive impact in the business world. `
    ),
    textContent: parse(
      `<p>We build meaningful partnerships and implement our proven SEO marketing strategies customized to your business goals to drive more traffic, turn that traffic into qualified leads, and place your website at the top of major search engines. Our clients can expect  <a href="https://ezmarketingtech.com/seo-packages" target="_blank" rel="noreferrer">clear and actionable plans</a>  backed by extensive resources and deliverables essential to realizing their business objectives.</p>`
    ),

    textLink: "Learn More Now",
  },
];
const aboutDataAnimate = [
  {
    id: 6,
    ImgSrc: AboutAnimateIcon[0],
    classForAnimate: "spiner triangle",
    classForImg: "rotated-style",
  },
  {
    id: 7,
    ImgSrc: AboutAnimateIcon[1],
    classForAnimate: "rs-image yes spiner ball",
    classForImg: "dance2",
  },
  {
    id: 8,
    ImgSrc: AboutAnimateIcon[2],
    classForAnimate: "rs-image yes spiner cross",
    classForImg: "pulse_style",
  },
];

////////////////////  home page  all Services data ///////////////////////
const serviceIntroData = [
  {
    id: 9,
    subText: "Services",
    titleText: "SEO Services",
    contentText:
      "Helping Businesses Build A Strong Brand Identity With Our Unique Digital Marketing & SEO Solutions",
    textlink: "Know More",
    textlink2: "Get in Touch",
  },
];
const serviceSocialData = [
  {
    id: 10,
    socialMediaImg: HomeImgs[1],
    socialMediaLink: "Website Analysis",
    socialMediaLinkText:
      "Optimizing your website’s SEO begins with identifying problem areas. We leave no link unopened to have a thorough understanding of your website and aspects that need improvement.",
    // readMorelink: "Read More",
  },
  {
    id: 11,
    socialMediaImg: HomeImgs[2],
    socialMediaLink: "SEO Strategy",
    socialMediaLinkText: parse(
      `<p>As a leading <a href="https://ezmarketingtech.com/who-we-are" target="_blank" rel="noreferrer" >SEO consulting company,</a> our blueprint for organic growth focuses on on-page content and keyword optimization, link building, outside presence through off-page SEO, and much more.</p>`
    ),

    // readMorelink: "Read More",
  },
  {
    id: 12,
    socialMediaImg: HomeImgs[3],
    socialMediaLink: "Competitor Analysis",
    socialMediaLinkText:
      "Outperforming competition demands delving deep into what your competitors are doing in the digital space. Our competitor analysis helps you stay ahead of the curve.",
    // readMorelink: "Read More",
  },
  {
    id: 13,
    socialMediaImg: HomeImgs[4],
    socialMediaLink: "Technical SEO",
    socialMediaLinkText:
      "SEO is an ongoing process. So, we regularly evaluate your website’s SEO performance and send you an easy-to-understand SEO report to let you know the efforts we put in and the results you are getting.",
    // readMorelink: "Read More",
  },
];
////////////////////  home page  all Counter data ///////////////////////
const serviceCounterData = [
  {
    id: 16,
    countValue: 550,
    counterHeading: "Happy Clients",
  },
  {
    id: 17,
    countValue: 433,
    counterHeading: "Project Completed",
  },
  {
    id: 18,
    countValue: 73,
    counterHeading: "Expert Advisors",
  },
  {
    id: 19,
    countValue: 750,
    counterHeading: "Global Presence",
  },
];
////////////////////  home page  all Working Process data ///////////////////////
const workingProcess = [
  {
    id: 20,
    subText: "Working Process",
    processTitle: "How We Complete Work",
  },
];
const optimizationProcess = [
  {
    id: 21,
    optimizaSubHeading: "Page Optimization",
    optimizeClass: "process-item mb-340",
    optimizaText:
      " At vero eos et accusamus etiuodio means praesent ium accusetiusto seo digital or blinded desire agency",
  },
  {
    id: 22,
    optimizaSubHeading: "Keyword Research",
    optimizeClass: "process-item blue-border md-mb-30",
    optimizaText:
      "At vero eos et accusamus etiuodio means praesent ium accusetiusto seo digital or blinded desire agency",
  },
];
const optimizationBuilding = [
  {
    id: 23,
    optimizaSubHeading: "Content Building",
    optimizeClass: "process-item blue-border mb-340",
    optimizaText:
      " At vero eos et accusamus etiuodio means praesent ium accusetiusto seo digital or blinded desire agency",
  },
  {
    id: 24,
    optimizaSubHeading: "Reporting & Analysis",
    optimizeClass: "process-item md-mb-30",
    optimizaText:
      "At vero eos et accusamus etiuodio means praesent ium accusetiusto seo digital or blinded desire agency",
  },
];

//////////////////// this is a home page all data ///////////////////////

////////////////////  home page  Testimonial  data ///////////////////////
const testimonialData = [
  {
    id: 25,
    revireImg: quoteImg,
    clientReview:
      "Very professional and hardworking team! They go above and beyond to ensure that your online business sees the growth that it deserves. Their work ethic and industry knowledge are remarkable. Cannot recommend them enough!",
    clientName: "Fred Allen",
  },
  {
    id: 26,
    revireImg: quoteImg,
    clientReview:
      "EZ Marketing Tech has really been an asset for SEO. Very organized, prompt, and knowledgeable. I highly recommend!",
    clientName: "Haley Rochelle",
  },
  {
    id: 27,
    revireImg: quoteImg,
    clientReview:
      "CHANDAN AND HIS TEAM ARE THE BEST I HAVE EVER COME ACROSS!! I WAS VERY FOCUSED ON MY NEEDS WITHIN 30 DAYS THEY NAILED IT #1 SPOT ON GOOGLE AND MUCH MORE!I ALWAYS SAY ITS ABOUT TRUST YOU CAN TRUST HIM! PORSCHE AT ITS BEST!",
    clientName: "Robert L Neal",
  },
  {
    id: 28,
    revireImg: quoteImg,
    clientReview:
      "I have worked with some very professional SEO people with EZ Marketing. Vani is the most persistent and caring of them all.",
    clientName: "Dean Nida",
  },
  {
    id: 29,
    revireImg: quoteImg,
    clientReview:
      "Ez Marketing Tech is very knowledgeable and easy to work with. I would recommend them to anyone looking to better their website and SEO campaign.",
    clientName: "Mason Houston",
  },
];

export default HomeElementorData;
export {
  aboutData,
  aboutDataAnimate,
  serviceIntroData,
  serviceSocialData,
  serviceCounterData,
  workingProcess,
  optimizationProcess,
  optimizationBuilding,
  testimonialData,
};
