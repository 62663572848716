import React from "react";
// import PrivacyPolicyImgs from "../../ImagesComponents/all-images/seo_traffic_bg.jpg";
let PrivacyPolicyImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/seo_traffic_bg.jpg",
];
const PrivacyPolicy = () => {
  return (
    <>
      <section className="privacy_policy_sec">
        <figure className="policy_figure">
          <img src={PrivacyPolicyImgs[0]} alt="img" width="100%" />
        </figure>
        <div className="policy_detail privacy_policy_detail">
          <div className="container">
            <h2>Privacy Policy</h2>
            <p>
              This privacy policy has been compiled to better serve those who
              are concerned with how their ‘Personally Identifiable Information’
              (PII) is being used online. PII is information that can be used on
              its own or with other information to identify, contact, or locate
              a single person, or to identify an individual in context. Please
              read our privacy policy carefully to get a clear understanding of
              how we collect, use, protect or otherwise handle your Personally
              Identifiable Information in accordance with our website.
            </p>
            <strong>
              What personal information do we collect from the people that visit
              our blog, website or app?
            </strong>
            <p>
              We only collect basic personal data about you which includes your
              name, address, phone number, email etc. We also collect the
              location of your IP just to assign the right representative to get
              in touch with you.
            </p>
            <p>
              We collect information from you when you fill a form on our
              website, subscribe to our blogs, use Live Chat feature or enter
              information on our site.
              <br /> How do we use your information? <br /> We may use the
              information we collect from you when you fill a form, sign up for
              our blog, surf the website, or use certain other site features in
              the following ways:
            </p>
            <ul className="policy_list">
              <li>
                {" "}
                To personalise your experience and to allow us to deliver the
                type of content and product offerings in which you are most
                interested.
              </li>
              <li>To improve our website to better serve you.</li>{" "}
              <li>
                To allow us to better service you in responding to your customer
                service requests.{" "}
              </li>{" "}
              <li>To ask for ratings and reviews of services.</li>{" "}
              <li>
                To follow up with you after correspondence (live chat, email or
                phone inquiries)
              </li>
            </ul>
            <strong>How do we share information we collect ?</strong>
            <p>
              We do not use vulnerability scanning and/or scanning to PCI
              standards. We use regular Malware Scanning. Your personal
              information is contained behind secured networks and is only
              accessible by a limited number of persons who have special access
              rights to such systems, and are required to keep the information
              confidential. In addition, all sensitive/credit information you
              supply is encrypted via Secure Socket Layer (SSL) technology.
            </p>{" "}
            <p>
              {" "}
              We implement a variety of security measures when a user enters,
              submits, or accesses their information to maintain the safety of
              your personal information. All transactions are processed through
              a gateway provider and are not stored or processed on our servers.{" "}
            </p>
            <p>
              However, no security procedures or protocols are ever guaranteed
              to be 100% secure and so we encourage you to take care when
              disclosing personal information online and to use readily
              available tools, such as Internet firewalls, anti-virus and
              anti-spyware software, and similar technologies to protect
              yourself online.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
