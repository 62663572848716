import React, { Fragment } from "react";
import HomeForm from "./HomeForm";

const HomeReadyForm = () => {
  return (
    <Fragment>
      <section className="get_form_section get_ready_form" id="get_going">
        <div className="container">
          <div className="ranking_form_wrapper">
            <h2>Ready to Get Going?</h2>
            <p>
              A Strong team of digital marketing experts are ready and waiting
              to transform your business.
            </p>
            <HomeForm/>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default HomeReadyForm;
