import React, { Fragment, useEffect } from "react";
import LocationCaliforniaBanner from "./LocationCaliforniaBanner";
import LocationSeoApproch from "./LocationSeoApproch";
import TopSeoNevada from "./TopSeoNevada";
import LocationTools from "./LocationTools";
// import LocationFaq from "./LocationFaq";
// import PriceClientReview from "../PricesComponents/PriceClientReview";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
// import Seo from "../Seo";
import { Helmet } from "react-helmet";
const LocationCalifornia = () => {
  useEffect(() => {
    document.body.classList.add("location-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("location-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Best SEO Company California, USA</title>
        <meta
          name="description"
          content="Looking for the best SEO company in California? Look no further than EZ Marketing Tech. Our experts will help you achieve top rankings and drive more traffic to your site."
        />
      </Helmet>
      <LocationCaliforniaBanner />
      <LocationSeoApproch />
      <TopSeoNevada />
      <LocationTools />
      {/* <LocationFaq /> */}
      {/* <PriceClientReview
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
      /> */}
      <ReviesAll
        mainclassName="home_restimonials price_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default LocationCalifornia;
