import React, { Fragment, useEffect } from "react";
import PpcmarketingBanner from "./PpcmarketingBanner";
import PpcImportant from "./PpcImportant";
import PpcServiceSlider from "./PpcServiceSlider";
import sliderPpc from "./PpcMarketingData";
import PpcIdealUser from "./PpcIdealUser";
import PpcCampaign from "./PpcCampaign";
import SearchOpportunities from "./SearchOpportunities";
import ServicesInclude from "./ServicesInclude";
import ReviesAll from "../ReviesAll";
// import Seo from "../Seo";
import { Helmet } from "react-helmet";
import { testimonialData } from "../HomeComponents/HomeData";
const PpcMarketing = () => {
  useEffect(() => {
    document.body.classList.add("ppc-marketing", "inner-page-layout");
    return () => {
      document.body.classList.remove("ppc-marketing");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>
          PPC Marketing Services | Maximize Your Ad Performance and ROI
        </title>
        <link rel="canonical" href="/ppc-marketing" />
        <meta
          name="description"
          content="Achieve higher conversions and revenue with EZ Marketing Tech's PPC marketing services that create effective ads and optimize your campaigns for maximum impact."
        />
      </Helmet>
      <PpcmarketingBanner />
      <PpcImportant />
      <PpcServiceSlider details={sliderPpc} />
      <PpcIdealUser />
      <PpcCampaign />
      <SearchOpportunities />
      <ServicesInclude />
      <ReviesAll
        mainclassName="home_restimonials seo_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default PpcMarketing;
