const dataContentMarketing = [
  {
    id: 1,
    packageHeding: "Why do I need Content Marketing Services?",
    packageText:
      "Content marketing services are essential. Because content, whether static or dynamic, is the only way to influence users on the internet. Content marketing services are imperative to emerging businesses. It brings more traffic to your site through interesting loyalty.",
  },
  {
    id: 2,
    packageHeding: "What Types of Content Does Content Marketing Include?",
    packageText:
      "Content marketing includes all types of content. This content can be text like articles and blog posts or moving such as videos or podcasts. Essentially, it encompasses all domains in content that interact with users.",
  },
  {
    id: 3,
    packageHeding:
      "What is the role of a digital marketer in online marketing?",
    packageText:
      "A digital marketer is the leader of the content marketing campaign. These professionals study and analyze keywords that are relevant to your brand. They also keep a close watch over other crucial factors in content marketing such as irrelevant keywords, target audience, leads generated, and other parameters that influence content marketing ventures.",
  },
  {
    id: 3,
    packageHeding: "How does content marketing drive more revenue?",
    packageText:
      "Content marketing promotes your content online. This increased visibility pulls more online visitors and prospective buyers. An effective and personalized content marketing strategy attracts specific users and customers whose searches match your promises. Hence, a guided and influential content marketing strategy always increases your revenue by increasing online sales.",
  },
];

export default dataContentMarketing;
