import React from "react";
import { RxCross2 } from "react-icons/rx";
import { BsCheckLg } from "react-icons/bs";
function SmoPriceTable() {
  return (
    <section className="Packages_section">
      <div className="container">
        <h2>
          Compare Our Packages and Choose the Perfect Fit for Your Business
        </h2>
        <div className="table-responsive">
          <table className="table price_table_list  table-bordered table-striped table-hover">
            <thead>
              <tr className="price_tr">
                <th></th>
                <th>
                  <h4>One Time Set-Up</h4>
                  <span>
                    $249<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Serious Plan</h4>
                  <span>
                    $199<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Strategy Plan</h4>
                  <span>
                    $249<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Steroid Plan</h4>
                  <span>
                    $399<sub>/Month</sub>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="list_body">
              <tr>
                <td>
                  <strong>Organic Promotion</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>Competitor analysis</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Strategy formation</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Hashtag creation and promotion</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Monthly creative creation</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>4</td>
                <td>8</td>
                <td>16</td>
              </tr>
              <tr>
                <td>
                  <span>Monthly postings</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>4</td>
                <td>8</td>
                <td>16</td>
              </tr>
              <tr>
                <td>
                  <span>Keywords</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Negative Keywords</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>

              {/* <tr>
                <td>
                  <strong>Negative Keywords</strong>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
                <td>
                  <BsCheckLg/>
                </td>
              </tr> */}
              <tr>
                <td>
                  <span>Engagement with active communities and groups</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Network build-up</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Engagement with third-party posts</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Content optimization</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Analysis of social media set-up</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Posts analysis</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Audience behaviour analysis</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Recommendations</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Building social media strategy</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Paid Promotion</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Identifying suitable objectives</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Identifying target audience</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Creation of ad accounts</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>

              <tr>
                <td>
                  <span>Budget estimate</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Setting up campaigns</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>

              <tr>
                <td>
                  <span>Ad creative creation</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Daily account optimization</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Setting up sales funnel for Conversion objective</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>

              <tr>
                <td>
                  <span>Conversion tracking assisted By Google Analytics</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Remarketing</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Identifying suitable objectives</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Identifying target audience</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Creation of ad accounts</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Budget estimate</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Setting up campaigns</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Ad creative creation</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Daily account optimization</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Setting up sales funnel for conversion objective</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Conversion tracking assisted by Google Analytics</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Customer Support</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Email</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Phone</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Chat</span>
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/fZeaGg3SUcLg4cU004"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/14kg0AgFG4eK4cUdQV"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/3cs15G1KM5iO38QbIO"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/eVag0Ablm7qWaBi7sz"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default SmoPriceTable;
