import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import { NavLink } from "react-router-dom";
import SlideData from "./WhoWeAreData";
// import { Helmet } from "react-helmet";

const WhoWeCaseStudy = (props) => {
  function SlideOverlay(val) {
    return (
      <>
        <div className="blog-item">
          <div className="image-wrap">
            <img src={val.slideImg} width="100%" alt="img" height="390" />
            <img
              src={val.slideOverImg}
              height="448"
              width="730"
              alt="img"
              className="img-design"
            />

            <span className="title_wrapper">
              {val.ShortHeading}
              <br />
              <span>{val.weburl}</span>
            </span>

            <div
              className="img_overlay"
              id={"img_overlay" + val.id}
              tabindex="1"
            >
              <h5>{val.SlideHeading}</h5>
              <p>{val.SlideText}</p>
              {val.SlideList}
              <NavLink
                to={`/case-study/${val.ShortHeading.replace(
                  /\s+/g,
                  "-"
                ).toLowerCase()}`}
              >
                Read More...
              </NavLink>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <Fragment>
      <>
        <section className={props.addList} id={props.addid}>
          <div id="fullbox"></div>
          <div className="container-fluid">
            <h2>Case Studies</h2>

            <OwlCarousel
              className="rs-carousel owl-carousel"
              center={false}
              loop={true}
              navigation={true}
              margin={10}
              items={3}
              autoplay={true}
              autoplayHoverPause={true}
              responsive={{
                1: {
                  items: 1,
                },
                576: {
                  items: 2,
                },
                768: {
                  items: 3,
                },
                992: {
                  items: 3,
                },

                1350: {
                  items: 3,
                },
              }}
            >
              {SlideData.map(SlideOverlay)}
            </OwlCarousel>
            <div className="case_study_portfolio text-center">
              {/* <NavLink className="study_portfolio_btn" to="/who-we-are">
                Our Portfolio
              </NavLink> */}
              <NavLink className="study_portfolio_btn" to="/reviews">
                Client Reviews
              </NavLink>
            </div>
          </div>
        </section>
      </>
    </Fragment>
  );
};

export default WhoWeCaseStudy;
