import React from "react";
// import indFigureIms from "../../ImagesComponents/all-images/indestry_img.jpg";
let indFigureIms = [
  "https://ezmarketingtech.s3.amazonaws.com/img/indestry_img.jpg",
];
const CbdIndustry = () => {
  return (
    <>
      <section className="industury_sec">
        <figure className="industury_sec_figure">
          <img src={indFigureIms[0]} alt="img" />
          <figcaption className="industury_sec_figure_cpation">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="industry_text">
                    <h2>
                      <strong>About CBD Industry</strong>
                    </h2>
                    <p>
                      The CBD industry, a part of the cannabis and hemp
                      industry, is one of the fastest growing industries, thanks
                      to the legalization of cannabis and hemp products in the
                      US. Cannabidiol (CBD), a compound found in hemp and
                      cannabis plants, is gaining immense popularity as a
                      dietary supplement to aid in various health problems, such
                      as chronic pain, stress, anxiety, and more.
                    </p>
                    <p>
                      The CBD market was estimated to reach USD 2,177.99 million
                      in valuation by 2023, growing at a CAGR of 6.36% during
                      the forecast period (2018-2023) Global Cannabidiol (CBD)
                      Market was valued at USD 4.9 Billion in 2021 and was
                      projected to reach the value of USD 47.22 Billion by 2028
                      at a CAGR of 21.3% during the forecast period, 2022–2028.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>
        <div className="container">
          <div className="row mb-5 cbd_growing_row">
            <div className="col-12 col-sm-12 col-md-6">
              <h2>
                Why is the{" "}
                <span>
                  CBD
                  <br />
                  Industry Growing?
                </span>
              </h2>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <p>
                After the US Farm Bill 2018 and legalization of industrial hemp,
                the cannabis industry has grown tremendously.
              </p>
              <p>
                The major factors associated with this steep growth are the
                rapid adoption of cannabis in therapeutic applications and the
                legalization of the usage of medical marijuana in many western
                countries.{" "}
              </p>
              <p>
                The demand for CBD-infused food and drink is also surging
                rapidly, as consumers look for ways to include CBD into their
                daily routines. With more businesses entering the CBD market and
                new CBD products being innovated all the time, the sales of CBD
                will continue to rise in the years to come.{" "}
              </p>
              <p>
                {" "}
                With such a rate of growth of the CBD industry, it makes sense
                to start your own CBD business and make a difference. 
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CbdIndustry;
