import React, { Fragment, useEffect, lazy, Suspense } from "react";
import HomeNewBanner from "./HomeNewBanner";
import HomePartner from "./HomePartner";
import { testimonialData } from "./HomeData";
import HomeServices from "./HomeServices";
//import HomeAboutUs from "./HomeAboutUs";
import HomeScoreCheck from "./HomeScoreCheck";
import HomeReadyForm from "./HomeReadyForm";
import HomeCertifid from "./HomeCertifid";
import HistoricalSEO from "./HistoricalSEO";
import HomeTimeLineAnimate from "./HomeTimeLineAnimate";
import WhoWeCaseStudy from "../WhoWeAreComponents/WhoWeCaseStudy";
import ReviesAll from "../ReviesAll";
import { Helmet } from "react-helmet";

const HomeAboutUs = lazy(() => import("./HomeAboutUs"));
const Home = () => {
  useEffect(() => {
    document.body.classList.add("home-layout");
    return () => {
      document.body.classList.remove("home-layout", "inner-page-layout");
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>
          SEO Agency | Digital Marketing Agency | Internet Marketing Agency
        </title>
        <meta
          name="description"
          content=": EZ Marketing Tech is a leading SEO company in West Hollywood, California, offering efficient
      SEO, SMO, PPC, Local SEO, Technical SEO optimization, and quality link-building services. Call us @ +1 (855)
      781-2099"
        />
        <meta name="robots" content="index"></meta>
      </Helmet>

      <HomeNewBanner />
      <HomePartner />
      <Suspense fallback={<span>Loading..</span>}>
        <HomeAboutUs />
      </Suspense>

      <HomeServices />
      <HistoricalSEO />
      <HomeTimeLineAnimate />

      <WhoWeCaseStudy
        addList="case_study_sec text-center home_case_studies_section"
        addid="home_studies_slider"
      />

      <HomeScoreCheck />

      <ReviesAll
        mainclassName="home_restimonials"
        heading="Testimonials"
        SubHeading="What Our Client Say"
        details={testimonialData}
      />

      <HomeReadyForm />

      <HomeCertifid />
    </Fragment>
  );
};

export default Home;
