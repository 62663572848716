import React from "react";
// import HighlightValueImgs0 from "../../ImagesComponents/all-images/highlight_value_img.jpg";
// import HighlightValueImgs1 from "../../ImagesComponents/all-images/highlight_value_img2.jpg";
let HighlightValueImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/highlight_value_img.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/highlight_value_img2.jpg",
];
const HighlighValue = () => {
  return (
    <>
      <section className="high_Value">
        <div className="container">
          <div className="highlight_wrapper">
            <div className="row sm-col-reverse">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="highlight_value_text">
                  <h4>Highlight Value Propositions</h4>
                  <p>
                    In most cases, you can’t turn leads into customers without
                    communicating the value you offer.
                    <br /> Experts say your product's value proposition is the
                    most important aspect of your CBD business. This means that
                    when you’re marketing your business, you need to highlight
                    the unique value your products offer to your customers while
                    simultaneously improving customer trust and conversion
                    rates.
                  </p>
                  <p>
                    <strong>Let’s take an example:</strong> We highlighted three
                    value propositions: free priority shipping, buy in bulk, and
                    a loyalty program. We ran two experiments over a course of
                    time across the homepage and product page. The results? 30%
                    increase in conversions. This strategy may be classic, but
                    it’s effective.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <img src={HighlightValueImgs[0]} alt="img" width="100%" />
              </div>
            </div>
            <div className="row mb-5 mb-sm-2">
              <div className="col-12 col-sm-12 col-md-6">
                <img src={HighlightValueImgs[1]} alt="img" width="100%" />
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="highlight_value_text">
                  <h4>
                    Use dynamic banners to showcase
                    <br />
                    welcome offers to different customers
                  </h4>
                  <p className="mb-0">
                    The National Center for Biotechnology Information found that
                    62% of CBD consumers use CBD products to manage a medical
                    condition, such as anxiety, pain, depression, and more. 68%
                    of CBD consumers use sublingual products (CBD oil) while the
                    rest use inhalation products (vape pens) and ingestible
                    products (gummies, capsules, chocolate bars) and topicals
                    (creams, lotions, masks, shampoos) in decreasing popularity.
                    By understanding where your audience comes from, and then
                    catering to their unique needs, you can leverage dynamic
                    banners to personalize the welcome experience for your users
                    and make it more likely that they will convert.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="customize_journey">
            <h4>
              {" "}
              <strong>
                Customize popups to meet customers in their journey
              </strong>{" "}
            </h4>
            <p>
              Make popups a vital part of your CBD marketing campaigns as they
              offer a quick and efficient way to capture your visitors'
              attention. To better capture their attention and keep them
              engaged, you can customize your popups based on how they move
              through the sales funnel.
            </p>
          </div>
          <div className="funnel_wrapper row">
            <h4 className="col-12 col-sm-12 col-md-4">
              Top of
              <br /> the Funnel
            </h4>
            <p className="col-12 col-sm-12 col-md-8">
              At the top of the sales funnel, customers would benefit from a
              direct Lead Capture with a request for information regarding
              preferences. Or even a gamified pop-up offering a discount to
              them. They’re more likely to convert with a coupon that they won.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default HighlighValue;
