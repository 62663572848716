import React, { Fragment } from "react";
// import whoWeBannerImgs from "../../ImagesComponents/all-images/who_we_banner.png";
let whoWeBannerImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/who_we_banner.png",
];
function WhoWeAreBanner() {
  return (
    <Fragment>
      <section className="banner_section who_we_banner_sec">
        <div className="container">
          <figure className="who_we_banner_figure">
            <img src={whoWeBannerImgs[0]} alt="Banner" width="100%" />
            <figcaption className="who_we_banner_caption">
              <h2>
                We Set SEO Benchmarks, <span>Others Follow!</span>
              </h2>
              <p>
                EZ Marketing Tech is a{" "}
                <a href="/" target="_blank" rel="noreferrer" className="go_url">
                  full-service digital marketing agency
                </a>
                that specializes in SEO. We bring out-of-the-box SEO strategies
                to the table and help businesses improve their online presence.
                Our goal is to provide customized search engine optimization
                services that meet our clients' needs and goals while also
                delivering exceptional customer experiences.
              </p>
              <p>
                Our dedicated team consists of proactive SEO minds who have a
                knack for creating revenue-generating SEO solutions and
                consistently delivering results that drive business growth.
              </p>
            </figcaption>
          </figure>
        </div>
      </section>
    </Fragment>
  );
}

export default WhoWeAreBanner;
