import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import LocationForm from "../LocationPageComponents/LocationForm";
function LocationBanner() {
  return (
    <Fragment>
      <section className="banner_section location_banner">
        <div className="container">
          <div className="location_banner_wrap">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <div className="location_banner_text">
                  <h2>
                    Rev Up Your Online Growth
                    <br />
                    With Our Results-Driven SEO
                    <br />
                    Approaches In Maryland
                  </h2>
                  <p>
                    At EZ Marketing Tech, we understand how important it's to
                    have a solid online presence in today's fast-paced digital
                    landscape. That's why we're dedicated to providing
                    customized and results-driven SEO services to businesses
                    throughout Maryland. Our cutting-edge strategies will help
                    you take your online presence to new heights and achieve
                    long-lasting results.
                    <br /> We are just a call away! Let us unleash the full
                    potential of your business online!
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                <div className="location_proposal_wrap">
                  <h3>GET MY FREE PROPOSAL</h3>
                  <LocationForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default LocationBanner;
