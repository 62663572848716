import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeaderNew from "./Components/HeaderComponents/HeaderNew";
import Home from "./Components/HomeComponents/Home";
import Prices from "./Components/PricesComponents/Prices";
import PpcPrice from "./Components/PPcComponents/PpcPrice";
import SmoPrice from "./Components/SmoPriceComponents/SmoPrice";
import LocalSeo from "./Components/LocalSeoComponents/LocalSeo";
import NoteFound from "./Components/NoteFound";
import Footer from "./Components/FooterComponent/Footer";
import ScrollTop from "./ScrollTop";
import Location from "./Components/LocationPageComponents/Location";
import LocationCalifornia from "./Components/LocationPageCaliforniaComponents/LocationCalifornia";
import LocationColorado from "./Components/LocationPageColoradoComponents/LocationColorado";
import LocationMaryland from "./Components/LocationPageMarylandComponents/LocationMaryland";
import LocationTexas from "./Components/LocationPageTexasComponents/LocationTexas";
import EcommerceSeo from "./Components/ECommerceSeoComponents/EcommerceSeo";
import ContentMarketingServ from "./Components/ContentMarketingComponents/ContentMarketingServ";
import SocialMediaMarketing from "./Components/SocialMediaMarketingComponents/SocialMediaMarketing";
import ReputationManagementServ from "./Components/ReputationManagementComponents/ReputationManagementServ";
import PpcMarketing from "./Components/PpcMarketingComponents/PpcMarketing";
import SingleCaseStudy from "./Components/WhoWeAreComponents/SingleCaseStudy";
import CancellationPolicy from "./Components/FooterComponent/CancellationPolicy";
import PrivacyPolicy from "./Components/FooterComponent/PrivacyPolicy";
import MainService from "./Components/MainServiceComponents/MainService";
import CbdIndex from "./Components/CbdComponents/CbdIndex";
import AmazonPrice from "./Components/AmazonPricingComponents/AmazonPrice";
import ContactUs from "./Components/ContactusComponents/ContactUs";
import SeoTraffic from "./Components/SeoTrafficComponetns/SeoTraffic";
import SeoAmazon from "./Components/SeoAmazonComponents/SeoAmazon";
import WhoWeAre from "./Components/WhoWeAreComponents/WhoWeAre";
import Review from "./Components/ReviewComponents/Review";
// const Home = loadable({
//   loader: () => import("./Components/HomeComponents/Home"),
//   loading: () => <div>Loading...</div>,
// });

function App() {
  return (
    <Router>
      <ScrollTop />
      <div className="app">
        <Helmet>
          <meta charset="utf-8" />
          <title>EZ Marketing Tech</title>

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <link rel="canonical" href="https://ezmarketingtech.com/" />

          <base href="/" />
          <meta name="robots" content="index"></meta>
          <meta
            name="google-site-verification"
            content="sIXtxylxWdtkSvmw8gmb-hgxm-mxQyCliMzdNeVwZwM"
          />
          <meta
            name="description"
            content=": EZ Marketing Tech is a leading SEO company in West Hollywood, California, offering efficient
      SEO, SMO, PPC, Local SEO, Technical SEO optimization, and quality link-building services. Call us @ +1 (855)
      781-2099"
          />
          <meta property="og:type" content="Website" />
          <meta property="og:url" content="https://ezmarketingtech.com/" />
          <meta property="og:title" content="EZ Marketing Tech" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Ezmarketingtech" />
        </Helmet>

        <HeaderNew />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/main-service" element={<MainService />} />
          <Route exact path="/seo-services" element={<SeoTraffic />} />
          <Route exact path="/amazon-seo-services" element={<SeoAmazon />} />
          <Route exact path="/who-we-are" element={<WhoWeAre />} />
          <Route exact path="/seo-packages" element={<Prices />} />
          <Route exact path="/ppc-packages" element={<PpcPrice />} />
          <Route exact path="/amazon-price" element={<AmazonPrice />} />
          <Route
            exact
            path="/social-media-marketing-packages"
            element={<SmoPrice />}
          />
          <Route exact path="/local-seo-packages" element={<LocalSeo />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/reviews" element={<Review />} />
          <Route
            exact
            path="/social-media-marketing"
            element={<SocialMediaMarketing />}
          />
          <Route exact path="/seo-company-nevada" element={<Location />} />
          <Route
            exact
            path="/seo-company-california"
            element={<LocationCalifornia />}
          />
          <Route
            exact
            path="/seo-company-colorado"
            element={<LocationColorado />}
          />
          <Route
            exact
            path="/seo-company-maryland"
            element={<LocationMaryland />}
          />
          <Route exact path="/seo-company-texas" element={<LocationTexas />} />
          <Route
            exact
            path="/ecommerce-seo-services"
            element={<EcommerceSeo />}
          />
          <Route
            exact
            path="/content-marketing-services"
            element={<ContentMarketingServ />}
          />
          <Route
            exact
            path="/social-media-marketing-services"
            element={<SocialMediaMarketing />}
          />
          <Route
            exact
            path="/reputation-management-services"
            element={<ReputationManagementServ />}
          />
          <Route
            exact
            path="/cancellation-policy"
            element={<CancellationPolicy />}
          />

          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/cbd" element={<CbdIndex />} />
          <Route
            exact
            path="/case-study/:ShortHeading"
            element={<SingleCaseStudy />}
          />
          <Route exact path="/ppc-marketing" element={<PpcMarketing />} />

          <Route path="*" exact={true} element={<NoteFound />} />
          {/* navigate routes */}
          <Route
            exact
            path="/pricing"
            element={<Navigate to="/seo-packages" />}
          />
          <Route
            exact
            path="https://www.ezmarketingtech.com/blog/"
            element={<Navigate to="https://www.ezmarketingtech.com/blog" />}
          />
          <Route
            exact
            path="/cdn-cgi/challenge-platform/h/b"
            element={<Navigate to="/" />}
          />
          <Route
            exact
            path="/cdn-cgi/challenge-platform/h/b/scripts/cb/invisible.js?cb=7b2a7e351b53c3ac"
            element={<Navigate to="/" />}
          />
          <Route
            exact
            path="/cdn-cgi/challenge-platform/h/b/scripts/cb/invisible.js?cb=7a7aadd44ad48262"
            element={<Navigate to="/" />}
          />
          <Route
            exact
            path="/about-us"
            element={<Navigate to="/who-we-are" />}
          />
          <Route
            exact
            path="/services"
            element={<Navigate to="/amazon-seo-services" />}
          />

          <Route
            exact
            path="/cancellation-and-refund-policy"
            element={<Navigate to="/cancellation-policy" />}
          />
          <Route
            exact
            path="/blog/the-benefits-of-engine-optimization-for-small-businesses"
            element={
              <Navigate to="/blog/the-benefits-of-search-engine-optimization-seo-for-small-businesses-2" />
            }
          />
          <Route
            exact
            path="/services/seo"
            element={<Navigate to="/seo-services" />}
          />
          <Route
            exact
            path="/pricing/ppc-packages"
            element={<Navigate to="/ppc-packages" />}
          />
          <Route
            exact
            path="/pricing/seo-packages"
            element={<Navigate to="/seo-packages" />}
          />
          <Route
            exact
            path="/services/ppc-management"
            element={<Navigate to="/ppc-marketing" />}
          />
          <Route
            exact
            path="/locationtexas"
            element={<Navigate to="/seo-company-texas" />}
          />
          <Route exact path="/cbdIndex" element={<Navigate to="/cbd" />} />

          <Route
            exact
            path="/blog/online-strategy"
            element={
              <Navigate to="/blog/the-importance-of-a-solid-digital-marketing-strategy" />
            }
          />
          <Route
            exact
            path="/blog/the-importance-of-anchor-text/"
            element={
              <Navigate to="/blog/the-importance-of-a-solid-digital-marketing-strategy" />
            }
          />
          <Route
            exact
            path="/cdn-cgi/challenge-platform/h/b"
            element={<Navigate to="/" />}
          />
          <Route
            exact
            path="/cdn-cgi/challenge-platform/h/b/scripts/cb/invisible.js?cb=7b2a7e351b53c3ac"
            element={<Navigate to="<NoteFound/>" />}
          />
          <Route
            exact
            path="/cdn-cgi/challenge-platform/h/b/scripts/cb/invisible.js?cb=7a7aadd44ad48262"
            element={<Navigate to="<NoteFound/>" />}
          />
          <Route exact path="/blog/address" element={<Navigate to="/blog" />} />
          <Route exact path="/node/13" element={<Navigate to="/" />} />
          <Route exact path="/node/11" element={<Navigate to="/" />} />
          <Route exact path="/node/10" element={<Navigate to="/" />} />
          <Route exact path="/home" element={<Navigate to="/" />} />
          <Route
            exact
            path="/cdn-cgi/challenge-platform/h/b"
            element={<Navigate to="/" />}
          />

          <Route
            exact
            path="/pricing/web-packages/"
            element={<Navigate to="/" />}
          />
          <Route
            exact
            path="/blog/how-to-block-website-referral-spam"
            element={
              <Navigate to="/blog/how-to-block-website-referral-spam-2" />
            }
          />

          <Route
            exact
            path="/blog/the-benefits-of-search-engine-optimization-for-small-businesses"
            element={
              <Navigate to="/blog/the-benefits-of-search-engine-optimization-seo-for-small-businesses-2" />
            }
          />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
