import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import ReportingImgs0 from "../../ImagesComponents/all-images/reoprting_img.png";
// import ReportingImgs1 from "../../ImagesComponents/all-images/reoprting_img2.png";
// import ReportingImgs2 from "../../ImagesComponents/all-images/reoprting_img3.png";
// import ReportingImgs3 from "../../ImagesComponents/all-images/reoprting_img4.png";
// import ReportingImgs4 from "../../ImagesComponents/all-images/studies_img.png";
// import ReportingImgs5 from "../../ImagesComponents/all-images/matrix.png";
let ReportingImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/reoprting_img.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/reoprting_img2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/reoprting_img3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/reoprting_img4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/studies_img.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/matrix.png",
];
const SeoActivities = () => {
  return (
    <>
      <section className="seo_activities text-center">
        <div className="container">
          <h2>SEO Activities – Monthly Optimization</h2>
          <div className="row mb-5">
            <div className="col-12 col-sm-12 col-md-4 mb-sm-3">
              <div className="activites_box">
                <h3>
                  <strong>
                    Campaign
                    <br /> Performance
                    <br /> Reports
                  </strong>
                </h3>
                <ul>
                  <li>Highlighting campaign status on KPIs</li>
                  <li>Keyword Rankings</li>
                  <li>Organic Traffic</li>
                  <li>Goal Completion</li>
                  <li>Off-page Activities Completed</li>
                  <li>Plan of Action</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 mb-sm-3">
              <div className="activites_box">
                <h3>
                  <strong>
                    {" "}
                    On-page
                    <br /> Optimization
                    <br /> Report{" "}
                  </strong>
                </h3>
                <ul>
                  <li>Activities carried out during the month</li>
                  <li>On Page Elements</li>
                  <li>Organic Traffic</li>
                  <li>Content Suggestions</li>
                  <li>Website Changes</li>
                  <li>Technical Improvements</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 mb-sm-3">
              <div className="activites_box">
                <h3>
                  <strong>
                    Content
                    <br /> Marketing
                  </strong>
                </h3>
                <ul>
                  <li>Content Marketing Activities Summary</li>
                  <li>Back Link Creation</li>
                  <li>Organic Traffic</li>
                  <li>Content syndication</li>
                  <li>Activities on Content marketing platforms</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tool_batter  mb-5">
            <h3>Tool for Better Reporting & Analysis</h3>
            <div className="row j-center mb-5">
              <div className="col-12 col-sm-6 col-md-3 col-border pr-0">
                <figure>
                  <img src={ReportingImgs[0]} alt="img" />
                </figure>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-border pl-0">
                <figure>
                  <img src={ReportingImgs[1]} alt="img" />
                </figure>
              </div>
            </div>
            <div className="row j-center border-top-row">
              <div className="col-12 col-sm-4 col-md-3 col-border pr-0">
                <figure className="mt-3">
                  <img src={ReportingImgs[2]} alt="img" />
                </figure>
              </div>
              <div className="col-12 col-sm-4 col-md-3 col-border pl-0">
                <figure className="mt-3">
                  <img src={ReportingImgs[3]} alt="img" />
                </figure>
              </div>
              <div className="col-12 col-sm-4 col-md-3 col-border pl-0">
                <figure className="mt-3">
                  <img src={ReportingImgs[4]} alt="img" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="metrix_section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <div className="metrix_content">
                <h2>Team & Escalation Matrix</h2>
                <div className="metrix_tree">
                  <h4>
                    <span> Project Head</span>
                  </h4>
                  <h4 className="multi_child_tree">
                    <span>SEO Head</span> <span>SEO Account Manager</span>
                  </h4>
                  <h4>
                    <span>SEO Team Leader</span>
                  </h4>
                  <h4 className="multi_child_tree_four">
                    <span>SEO Executive</span>
                    <span>Content Team</span>
                    <span>SMO Team</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <figure className="metrix_figure">
                <img src={ReportingImgs[5]} alt="img" width="100" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="studies_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <figure className="studies_figure">
                <img src={ReportingImgs[4]} alt="img" width="100%" />
              </figure>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="studies_text">
                <h3>
                  Case Studies <span>Driving performance for Brands</span>
                </h3>
                <HashLink to="/#home_studies_slider" className="know_more">
                  know more
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="with_us">
        <div className="container ai-end">
          <span>
            Let’s start with us:{" "}
            <NavLink to="/">www.ezmarketingtech.com</NavLink>
          </span>
        </div>
      </div>
    </>
  );
};

export default SeoActivities;
