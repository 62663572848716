import React, { Fragment } from "react";

const TimlineImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/Website.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/Optimization.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/Content.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/Analysis.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/Keywords.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/Backlinks.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/Report.svg",
];
const HomeTimeLineAnimate = () => {
  return (
    <Fragment>
      <section className="working_process time-line">
        <div className="container">
          <h2>Work Process</h2>
          <div className="timeline">
            <div className="timeline__event  animated fadeInUp delay-7s timeline__event--type1">
              <div className="timeline__event__icon ">
                <img src={TimlineImgs[0]} alt="img" />
              </div>
              <div className="timeline__event__date">Website</div>
              <div className="timeline__event__content ">
                {/* <div className="timeline__event__title">Website</div> */}
                <div className="timeline__event__description">
                  <p>
                    The first step in any{" "}
                    <a
                      href="https://www.ezmarketingtech.com/who-we-are"
                      target="_blank"
                      rel="noreferrer"
                      className="go_url"
                    >
                      SEO process{" "}
                    </a>
                    is to ensure that the website is optimized for search
                    engines. This involves creating a clear site structure and
                    hierarchy, optimizing the website for mobile devices,
                    securing the website with an SSL certificate, and optimizing
                    website speed by compressing images and using a content
                    delivery network (CDN).
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline__event animated fadeInUp delay-6s timeline__event--type2">
              <div className="timeline__event__icon">
                <img src={TimlineImgs[3]} alt="img" />
              </div>
              <div className="timeline__event__date">Analysis</div>
              <div className="timeline__event__content">
                {/* <div className="timeline__event__title">Analysis</div> */}
                <div className="timeline__event__description">
                  <p>
                    The next step is to conduct a thorough analysis of the
                    website to identify any technical issues that could be
                    negatively impacting the website's search engine rankings.
                    This includes performing a website audit to identify any
                    technical issues, conducting a competitor analysis to
                    understand their strengths and weaknesses, and using keyword
                    research tools to identify high-volume and relevant
                    keywords.
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline__event animated fadeInUp delay-5s timeline__event--type3">
              <div className="timeline__event__icon">
                <img src={TimlineImgs[1]} alt="img" />
              </div>
              <div className="timeline__event__date">Optimization</div>
              <div className="timeline__event__content">
                {/* <div className="timeline__event__title">Optimization</div> */}
                <div className="timeline__event__description">
                  <p>
                    Once you have completed the analysis, the next step is to
                    optimize the website for the identified keywords. This
                    includes on-page optimization, such as optimizing title
                    tags, meta descriptions, header tags, and content, as well
                    as ensuring that the website is user-friendly and easy to
                    navigate. Off-page optimization is also important, which
                    involves building backlinks to the website.
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline__event animated fadeInUp delay-4s timeline__event--type1">
              <div className="timeline__event__icon">
                <img src={TimlineImgs[4]} alt="img" />
              </div>
              <div className="timeline__event__date">Keywords</div>
              <div className="timeline__event__content">
                {/* <div className="timeline__event__title">keywords</div> */}
                <div className="timeline__event__description">
                  <p>
                    Keyword research is a crucial step in any SEO process. It
                    involves using keyword research tools to identify relevant
                    and high-volume keywords that your target audience is
                    searching for. Once the keywords are identified, they need
                    to be strategically placed on the website to improve its
                    rankings.
                  </p>
                </div>
              </div>
            </div>
            {/* second part */}
            <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
              <div className="timeline__event__icon ">
                <img src={TimlineImgs[2]} alt="img" />
              </div>
              <div className="timeline__event__date">Content</div>
              <div className="timeline__event__content ">
                {/* <div className="timeline__event__title"></div> */}
                <div className="timeline__event__description">
                  <p>
                    Creating high-quality, relevant, and engaging content is an
                    essential part of any successful SEO strategy. The content
                    needs to be optimized for the identified keywords and should
                    provide value to the target audience. Regularly publishing
                    fresh content can also help improve the website's rankings.
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
              <div className="timeline__event__icon">
                <img src={TimlineImgs[5]} alt="img" />
              </div>
              <div className="timeline__event__date">Backlinks</div>
              <div className="timeline__event__content">
                {/* <div className="timeline__event__title">Backlinks</div> */}
                <div className="timeline__event__description">
                  <p>
                    Building high-quality backlinks from authoritative websites
                    is an important part of an SEO process. This involves
                    reaching out to relevant websites and asking for backlinks,
                    as well as creating engaging and shareable content that
                    naturally attracts backlinks.
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
              <div className="timeline__event__icon">
                <img src={TimlineImgs[3]} alt="img" />
              </div>
              <div className="timeline__event__date">Ranking</div>
              <div className="timeline__event__content">
                {/* <div className="timeline__event__title">Ranking</div> */}
                <div className="timeline__event__description">
                  <p>
                    The ultimate goal of an SEO process is to improve the
                    website's search engine rankings for the identified
                    keywords. Regularly monitoring and analyzing the website's
                    rankings is crucial to identifying areas for improvement and
                    ensuring that the SEO strategy is on track.
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline__event animated fadeInUp timeline__event--type1">
              <div className="timeline__event__icon">
                <img src={TimlineImgs[6]} alt="img" />
              </div>
              <div className="timeline__event__date">Report</div>
              <div className="timeline__event__content">
                {/* <div className="timeline__event__title">Report</div> */}
                <div className="timeline__event__description">
                  <p>
                    Regular reporting is important to measure the success of an
                    SEO process. This includes tracking website traffic, keyword
                    rankings, and backlinks. This information is used to adjust
                    and refine the SEO strategy to ensure maximum impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default HomeTimeLineAnimate;
