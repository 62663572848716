import React, { Fragment, useEffect } from "react";
import SeoAmazonbanner from "./SeoAmazonbanner";
import SeoAmazonMarketplace from "./SeoAmazonMarketplace";
import SeoAmazonStrategies from "./SeoAmazonStrategies";
// import HomeTestimonials from "../HomeComponents/HomeTestimonials";
import FaqAskQ from "../PricesComponents/FaqAskQ";
import { testimonialData } from "../HomeComponents/HomeData";
import ReviesAll from "../ReviesAll";
import { Helmet } from "react-helmet";
function SeoAmazon() {
  useEffect(() => {
    document.body.classList.add("seo-amazon-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("seo-amazon-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>
          Amazon SEO Services | Optimize Your Amazon Listings for Higher
          Rankings
        </title>
        <meta
          name="description"
          content=" Increase your product visibility and sales with EZ Marketing Tech's Amazon SEO services. Our team of experts will optimize your product listings to rank higher on Amazon search result pages."
        />
      </Helmet>
      <SeoAmazonbanner />
      <SeoAmazonMarketplace />
      <SeoAmazonStrategies />
      <FaqAskQ />
      <ReviesAll
        mainclassName="home_restimonials seo_amazon_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
}

export default SeoAmazon;
