import React, { Fragment, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { CgFormatRight } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import { BsPlusSquare } from "react-icons/bs";
import { AiOutlineMinusSquare } from "react-icons/ai";
import { BsFillStarFill } from "react-icons/bs";
import logoDark from "../../ImagesComponents/logo.png";
let googleRatingImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/google_r.svg",
];
// import { MdClose } from "react-icons/md";
// import ReCAPTCHA from "react-google-recaptcha";

// import nounCall from "../../ImagesComponents/bannerImg/noun-call.png";

function HeaderNew() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
  }, []);

  const [openMenu, setOpenMenu] = useState(false);
  const [isActive, setActive] = useState("false");
  const [isActiveServices, setActiveServices] = useState("false");

  const ToggleClass = (e) => {
    setActive(!isActive);
    setActiveServices("false");
    e.preventDefault();
  };
  const ToggleClass2 = (e) => {
    setActiveServices(!isActiveServices);
    setActive("false");
    e.preventDefault();
  };

  // const [addClass, setaddClass] = useState(" ");

  /***************/
  // const captchaRef = useRef(null);
  // const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false);

  // function onChange(value) {
  //   setIsCaptchaSuccess(true);
  //   console.log("captcha value: ", value);
  // }

  return (
    <Fragment>
      {/* Full width header Start */}
      <section className="full-width-header">
        {/*Header Start*/}
        <header id="rs-header" className="rs-header style1 modify1 main_header">
          <div id="fb-root"></div>
          <div id="fb-customer-chat" className="fb-customerchat"></div>
          {/* Menu Start */}
          <div
            className={
              scroll ? "menu-area menu-sticky sticky" : "menu-area menu-sticky"
            }
          >
            <div className="container-fluid container-header">
              <div className="row custom-row custom-col-cell">
                <div className="col-cell">
                  <div className="logo-area">
                    <Link exact to="/" aria-current="page">
                      <img
                        className="normal-logo"
                        src={logoDark}
                        alt="logo"
                        height="70"
                        width="125"
                      />
                      <img
                        className="sticky-logo"
                        src={logoDark}
                        alt="logo"
                        height="70"
                        width="125"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-cell order-last">
                  <div className="google_revire">
                    <div className="rating_img">
                      <img
                        src={googleRatingImg[0]}
                        alt="Google Rating"
                        height="40"
                        width="40"
                      />
                    </div>
                    <a
                      href="https://www.google.com/search?q=ezmarketingtech&rlz=1C1SQJL_enIN854IN854&oq=&aqs=chrome.0.69i59i450l8.13988743j0j15&sourceid=chrome&ie=UTF-8"
                      className="rating-text"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span>google rating</span>
                      <br />
                      <div className="reviw_wrap">
                        <span className="review_number">4.8</span>
                        <span className="star_cover">
                          <BsFillStarFill />
                          <BsFillStarFill />
                          <BsFillStarFill />
                          <BsFillStarFill />
                          <BsFillStarFill />
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-cell col-cell-toggle">
                  <div
                    className={
                      openMenu ? "rs-menu-area active" : "rs-menu-area"
                    }
                  >
                    <div className="main-menu">
                      <div className="mobile-menu">
                        <NavLink className="rs-menu-toggle" id="nav-expander2">
                          <CgFormatRight
                            name="open_menu_icon"
                            className="menu_tggle_icon menu_tggle_open_icon"
                            onClick={() => setOpenMenu(true)}
                          />
                          <CgClose
                            name="open_close_icon"
                            className="menu_tggle_icon menu_tggle_close_icon"
                            onClick={() => setOpenMenu(false)}
                          />
                        </NavLink>
                      </div>
                      <nav className="rs-menu hidden-md" id="main_nav_menu">
                        <ul className="nav-menu">
                          <li className="nav-item menu-item-has-children ">
                            <NavLink
                              className="nav-link"
                              exact
                              to="/seo-services"
                              onClick={() => setOpenMenu(false)}
                            >
                              SEO
                            </NavLink>
                          </li>
                          <li
                            className={
                              isActiveServices
                                ? "nav-item menu-item-has-children "
                                : "nav-item menu-item-has-children open_dropdown"
                            }
                          >
                            <NavLink
                              className="nav-link drop_icon"
                              exact
                              to="/amazon-seo-services"
                              onClick={() => setOpenMenu(true)}
                            >
                              Services
                              <BsPlusSquare
                                className="mobile_drop_icon"
                                onClick={ToggleClass2}
                              />
                              <AiOutlineMinusSquare
                                className="mobile_drop_icon mobile_drop_icon_close"
                                onClick={ToggleClass2}
                              />
                            </NavLink>

                            <ul className="sub-menu">
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/amazon-seo-services"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  Amazon SEO
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/ppc-marketing"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  Pay Per Click / Adwords
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/content-marketing-services"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  Content Marketing
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/social-media-marketing"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  Social Media Marketing
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/reputation-management-services"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  Reputation Management
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/ecommerce-seo-services">
                                  E-Commerce SEO
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li
                            className={
                              isActive
                                ? "nav-item menu-item-has-children "
                                : "nav-item menu-item-has-children open_dropdown"
                            }
                          >
                            <NavLink
                              exact
                              to="/seo-packages"
                              className="nav-link drop_icon"
                            >
                              pricing
                              <BsPlusSquare
                                id="my_drop_icon"
                                className="mobile_drop_icon"
                                onClick={ToggleClass}
                              />
                              <AiOutlineMinusSquare
                                className="mobile_drop_icon mobile_drop_icon_close"
                                onClick={ToggleClass}
                              />
                            </NavLink>

                            <ul className="sub-menu">
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/seo-packages"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  SEO Pricing
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/ppc-packages"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  PPC Pricing
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/social-media-marketing-packages"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  Social Media Pricing
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/local-seo-packages"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  Local SEO Pricing
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  className="nav-link"
                                  exact
                                  to="/amazon-price"
                                  onClick={() => setOpenMenu(false)}
                                >
                                  Amazon Pricing
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item menu-item-has-children">
                            <NavLink
                              className="nav-link"
                              exact
                              to="/who-we-are"
                              onClick={() => setOpenMenu(false)}
                            >
                              who we are?
                            </NavLink>
                          </li>
                          <li className="nav-item menu-item-has-children">
                            <NavLink
                              className="nav-link"
                              exact
                              to="/reviews"
                              onClick={() => setOpenMenu(false)}
                            >
                              reviews
                            </NavLink>
                          </li>

                          <li className="nav-item menu-item-has-children">
                            <NavLink
                              className="nav-link"
                              exact
                              to="/contact-us"
                              onClick={() => setOpenMenu(false)}
                            >
                              contact us
                            </NavLink>
                          </li>
                        </ul>
                        {/* //.nav-menu */}
                      </nav>
                    </div>
                    {/* //.main-menu */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Menu End */}
        </header>
        {/*Header End*/}
      </section>
      {/*Full width header End*/}
    </Fragment>
  );
}

export default HeaderNew;
