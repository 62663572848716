import React, { Fragment, useState } from "react";
// import reputationIconImgs0 from "../../ImagesComponents/all-images/social_tabs_icon5.png";
// import reputationIconImgs1 from "../../ImagesComponents/all-images/social_tabs_icon6.png";
// import reputationIconImgs2 from "../../ImagesComponents/all-images/social_tabs_icon7.png";
let reputationIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/social_tabs_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/social_tabs_icon6.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/social_tabs_icon7.png",
];
const RaputationTabs = () => {
  const [TabsToggle, SetToggleTabs] = useState(1);
  const TabsChange = (index) => {
    SetToggleTabs(index);
  };
  return (
    <Fragment>
      <section className="social_tab_sec raputation_tab_sec">
        <div className="container">
          <div className="social_tabs_wrapper">
            <ul className="tabs_link_list">
              <li
                onClick={() => TabsChange(1)}
                className={
                  TabsToggle === 1
                    ? "tabs_links tabs_link_active"
                    : "tabs_links"
                }
              >
                <img src={reputationIconImgs[0]} alt="img" />
                <strong>Loss of Existing Buyers</strong>
              </li>
              <li
                onClick={() => TabsChange(2)}
                className={
                  TabsToggle === 2
                    ? "tabs_links tabs_link_active"
                    : "tabs_links"
                }
              >
                <img src={reputationIconImgs[1]} alt="img" />
                <strong>Decreased Revenue Generation</strong>
              </li>
              <li
                onClick={() => TabsChange(3)}
                className={
                  TabsToggle === 3
                    ? "tabs_links tabs_link_active"
                    : "tabs_links"
                }
              >
                <img src={reputationIconImgs[2]} alt="img" />
                <strong>Less Online Visibility</strong>
              </li>
            </ul>

            <div className="social_tabs_content">
              <div
                className={
                  TabsToggle === 1
                    ? "tabs_content tabs_content_active"
                    : "tabs_content"
                }
              >
                <p>
                  Negative online reviews influence both potential and loyal
                  customers. Prospective buyers may be hesitant to approach your
                  business due to a poor online reputation. Existing customers
                  may soon divert towards the competing brands for similar
                  products.
                  <br /> Online negative reviews damage your online image that
                  you spend years building. It can decrease both customer
                  loyalty and lifetime value.
                </p>
              </div>
              <div
                className={
                  TabsToggle === 2
                    ? "tabs_content tabs_content_active"
                    : "tabs_content"
                }
              >
                <p>
                  When you lose existing customers, you also lose significant
                  revenue. Online negativity disturbs the cashflow that comes
                  from online sales, which can be devastating to any online
                  business.
                </p>
              </div>
              <div
                className={
                  TabsToggle === 3
                    ? "tabs_content tabs_content_active"
                    : "tabs_content"
                }
              >
                <p>
                  Search engines like Google and Bing place a high priority on
                  customer satisfaction and often take into account customer
                  reviews when determining a website's ranking in the search
                  engine results pages (SERPs). Brands with a negative
                  reputation and unsatisfied customers are often ranked lower in
                  search results, resulting in reduced online visibility and
                  fewer visits from potential customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RaputationTabs;
