import quoteImg from "../../ImagesComponents/bannerImg/quote2.png";
/***************SEO-Price-Data********/
/***************SEO-top-heading-data********/
const SeoPlan = [
  {
    id: 1,
    planHeading: "Customize Your Experience With A Suitable Pricing Plan",
    planMainText:
      " Customize your SEO experience with EZ Marketing Tech's tailored packages. Boost your website's visibility with comprehensive services such as keyword research, optimization, link building, and content creation. Choose a pricing plan that aligns with your budget and goals, whether you're a startup or an established enterprise. Unlock your online potential or contact us today!",
  },
];
const bayNowPlan = [
  {
    id: 2,
    ClassList: "col-3 col-sm-3 col-md-3 col-price_table col-pt-1 p-8",
    planPriceHeading: "Starter Plan",
    planPriceText:
      " Get started with essential SEO optimizations for small businesses and startups.",
    planPriceSubHeading: "Starts at",
    planPriceStrong: "$749",
    planPerMonth: "Per Month*",
    planBay: "Buy Now",
    planPeriods: "Periods*",
    planKeywords: "No. of Keywords",
    planKeywordsNumber: 30,
    planGnb: "GMB (One-time)",
    planGnbPrice: "$",
    planGuestPost: "Guest Post",
    planGuestPostItems: "X",
    planPaidPress: "Paid Press Release",
    planPaidPressItem: "$",
    planSubmissionLinks: "Submission Links (Per Month)",
    planSubmissionLinksItem: 20,
    planeurl: "https://buy.stripe.com/6oE7u4fBC26CgZG4gg",
  },
  {
    id: 3,
    ClassList: "col-3 col-sm-3 col-md-3 col-price_table col-pt-2 p-8",
    planPriceHeading: "Serious Plan",
    planPriceText:
      " Take your SEO efforts to the next level with advanced strategies for better search engine visibility.",
    planPriceSubHeading: "Starts at",
    planPriceStrong: "$1249",
    planPerMonth: "Per Month*",
    planBay: "Buy Now",
    planPeriods: "Periods*",
    planKeywords: "No. of Keywords",
    planKeywordsNumber: 40,
    planGnb: "GMB (One-time)",
    planGnbPrice: "✔",
    planGuestPost: "Guest Post",
    planGuestPostItems: "X",
    planPaidPress: "Paid Press Release",
    planPaidPressItem: "1",
    planSubmissionLinks: "Submission Links (Per Month)",
    planSubmissionLinksItem: 30,
    planMostRecommended: "Most Recommended",
    planeurl: "https://buy.stripe.com/eVacOocpqcLg9xe3cd",
  },
  {
    id: 4,
    ClassList: "col-3 col-sm-3 col-md-3 col-price_table col-pt-3 p-8",
    planPriceHeading: "Strategy Plan",
    planPriceText:
      "  Elevate your SEO game with a strategic approach to dominate search results.",
    planPriceSubHeading: "Starts at",
    planPriceStrong: "$1749",
    planPerMonth: "Per Month*",
    planBay: "Buy Now",
    planPeriods: "Periods*",
    planKeywords: "No. of Keywords",
    planKeywordsNumber: 50,
    planGnb: "GMB (Posting)",
    planGnbPrice: "6",
    planGuestPost: "Guest Post",
    planGuestPostItems: "1",
    planPaidPress: "Paid Press Release",
    planPaidPressItem: "2",
    planSubmissionLinks: "Submission Links (Per Month)",
    planSubmissionLinksItem: 50,
    planeurl: "https://buy.stripe.com/3csbKkgFG5iO7p69AC",
  },
  {
    id: 5,
    ClassList: "col-3 col-sm-3 col-md-3 col-price_table col-pt-4 p-8",
    planPriceHeading: "Steroid Plan",
    planPriceText:
      " Get the most out of this plan! Unleashing the full power of SEO for rapid growth and maximum impact, and Priority Support.",
    planPriceSubHeading: "Starts at",
    planPriceStrong: "$2999",
    planPerMonth: "Per Month*",
    planBay: "Buy Now",
    planPeriods: "Periods*",
    planKeywords: "No. of Keywords",
    planKeywordsNumber: 100,
    planGnb: "GMB (Posting)",
    planGnbPrice: "10",
    planGuestPost: "Guest Post",
    planGuestPostItems: "2",
    planPaidPress: "Paid Press Release",
    planPaidPressItem: "3",
    planSubmissionLinks: "Submission Links (Per Month)",
    planSubmissionLinksItem: 100,
    planeurl: "https://buy.stripe.com/28o8y8blmbHc38Q3cf",
  },
];
const testimonialData = [
  {
    id: 25,
    revireImg: quoteImg,

    clientReview:
      "Very professional and hardworking team! They go above and beyond to ensure that your online business sees the growth that it deserves. Their work ethic and industry knowledge are remarkable. Cannot recommend them enough!",
    clientName: "Fred Allen",
  },
  {
    id: 26,
    revireImg: quoteImg,

    clientReview:
      "EZ Marketing Tech has really been an asset for SEO. Very organized, prompt, and knowledgeable. I highly recommend!",
    clientName: "Haley Rochelle",
  },
  {
    id: 27,
    revireImg: quoteImg,

    clientReview:
      "CHANDAN AND HIS TEAM ARE THE BEST I HAVE EVER COME ACROSS!! I WAS VERY FOCUSED ON MY NEEDS WITHIN 30 DAYS THEY NAILED IT #1 SPOT ON GOOGLE AND MUCH MORE!I ALWAYS SAY ITS ABOUT TRUST YOU CAN TRUST HIM! PORSCHE AT ITS BEST!",
    clientName: "Robert L Neal",
  },
  {
    id: 28,
    revireImg: quoteImg,

    clientReview:
      "I have worked with some very professional SEO people with EZ Marketing. Vani is the most persistent and caring of them all.",
    clientName: "Dean Nida",
  },
  {
    id: 29,
    revireImg: quoteImg,

    clientReview:
      "Ez Marketing Tech is very knowledgeable and easy to work with. I would recommend them to anyone looking to better their website and SEO campaign.",
    clientName: "Mason Houston",
  },
];
const AllFaqDetail = [
  {
    id: 30,
    faq: "Is there a free trial available?",

    faqAns:
      "EZ Marketing Tech provides online market research solutions. We are a skilled team of former business analysts and technologists from Google and AOL who are familiar with the ecosystem of search engines. We believe in what we offer, and to provide client assurance, the client can make a partial payment of 25% to start the project. However, no, we don't offer a free trial.",
  },
  {
    id: 31,
    faq: " What are the payment options?",

    faqAns:
      "We offer more than one payment option, which helps our clients feel at ease with their purchases. The most common modes of payment available with us include credit cards, PayPal, and bank transfers.",
  },
  {
    id: 32,
    faq: " Are there any hidden fees or costs?",

    faqAns:
      " These days, hidden costs seem to appear out of nowhere. Sometimes you may find yourself paying for more than you had planned, and for the majority of people, these charges are usually unexpected. When additional costs aren't immediately disclosed upfront, it can be especially frustrating. Therefore, we believe in being upfront about everything and have no hidden fees. If the client wants anything that is not part of the package, we charge extra depending on the work.",
  },
  {
    id: 33,
    faq: "  How does billing work? Is it monthly or annually?",

    faqAns:
      "  Deciding whether to use a monthly or yearly billing cycle is one of the most critical decisions for a client. The billing at the end of the day is the client's choice. Though preferably it is monthly, if the client wants to pay us annually, we accept that as well.",
  },
  {
    id: 34,
    faq: "How do I upgrade or downgrade my plan?",

    faqAns:
      " Our plans are flexible, which is one of our best features. Slow month and fewer sales? No issue! Your volume has doubled, and business is booming? Great! All you have to do  is get in touch with your project manager and sit back. He  or she will help you out.",
  },
  {
    id: 35,
    faq: "Do I have to sign a long-term contract?",

    faqAns:
      "EZ Marketing Tech is for you and with you. We do believe in our client's vision, and we don't need any contracts to be signed with us.",
  },
  {
    id: 36,
    faq: "What are the different pricing plans offered?",

    faqAns:
      "EZ Marketing Tech has a track record of successfully bringing clients' websites to the top of search engine  results, working with businesses ranging from large corporations to several SMEs. Our company offers packages per your needs and in various categories, such as SEO, SMO,  and PPC. For further information, check us out at   https://www.ezmarketingtech.com/pricing",
  },
];
/*****************export-from-here*****************/
export default SeoPlan;
export { bayNowPlan, testimonialData, AllFaqDetail };
