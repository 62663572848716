import React, { Fragment, useEffect } from "react";
import WhoWeAreBanner from "./WhoWeAreBanner";
import WhoWeFutureProof from "./WhoWeFutureProof";
import WhoWeJourney from "./WhoWeJourney";
import MemorableContent from "./MemorableContent";
import WhoWeCaseStudy from "./WhoWeCaseStudy";
// import SingleCaseStudy from ".SingleCaseStudy";
import { testimonialData } from "../PricesComponents/PriceData";
import ReviesAll from "../ReviesAll";
// import Seo from "../Seo";
import { Helmet } from "react-helmet";
const WhoWeAre = () => {
  useEffect(() => {
    document.body.classList.add("who_we_are-layout", "inner-page-layout");
    return () => {
      document.body.classList.remove("who_we_are-layout");
    };
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>EZ Marketing Tech – A Premier SEO Services Company</title>
        <link rel="canonical" href="/whoweare" />
        <meta
          name="description"
          content="At EZ Marketing Tech, we provide customized SEO solutions for your unique business needs. Our team of proactive SEO experts is always at your service!"
        />
      </Helmet>
      <WhoWeAreBanner />
      <WhoWeFutureProof />
      <WhoWeJourney />
      <MemorableContent />
      <WhoWeCaseStudy addList="case_study_sec text-center" />
      {/* <SingleCaseStudy /> */}
      <ReviesAll
        mainclassName="home_restimonials price_testimonials Who_we_are_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default WhoWeAre;
