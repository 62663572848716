import parse from "html-react-parser";
const localSeoData = [
  {
    id: 1,
    packageHeding: "What Does Local SEO Mean?",
    packageText:
      parse(`<p>Local SEO (search engine optimization) is the process of enhancing local businesses&#39; visibility
    on search engines, particularly for those with physical locations. It is how you raise a website&#39;s
    position in organic search results. Your local pack rankings may benefit from your website
    performance in those organic results. Typical tasks consist of: </p>
    <ul>
    <li>Enhancing your Google Business profile.</li>
    <li>Finding regional keywords and phrases.</li>
    <li>Producing regionally relevant content.</li>
    </ul>
    <p>Did you know that approximately 30% of Google searches are location-related? That equates to
    billions of searches each day. Using local SEO is the best route for a local business to appear in
    these search results.`),
  },
  {
    id: 2,
    packageHeding: "What is Google My Business (GMB)?",
    packageText:
      "Did you know that 1.5 billion locations are visited each month because of Google searches? Every business owner looking to increase their customer base can make good on this information by setting up a Google business profile to increase their visibility on Google. Google My Business, now known as Google Business Profile, enables company owners to connect with customers through Google Search and Google Maps. The only prerequisite for using it is that you engage in some face-to-face engagement with your customers. It is totally free to use. In the end, you can't truly have a Google My Business listing if you are entirely online.",
  },
  {
    id: 3,
    packageHeding: "What is NAP and Why is it Important?",
    packageText:
      "NAP, which stands for Name, Address, and Phone Number, is also referred to as the most useful information on a listing for customers looking to get in touch with and/or locate the company best suited to address their issue. Because Google values factual data for its users, the NAP for your company must be precise and reliable when optimizing your online and offline presence for local searches. Additionally, it gives search engines and potential new clients the most crucial data about your company.",
  },
  {
    id: 4,
    packageHeding: "How Can My Business Show Up on Google Maps?",
    packageText: parse(
      `<p>Google Maps is a significant driver of clicks and foot traffic for businesses with an actual
      location, like a shop or a cafe, even though Google search still accounts for the overwhelming
      bulk of search queries that lead to website traffic. As a result, your business's Google Maps
      listing is an essential marketing tool. It can assist you in standing out from rival businesses and
      attracting more business, all for no cost. Here&#39;s how to add to or claim your company&#39;s Google
      Business profile:</p>
      <ol>
      <li>Sign in to your computer&#39;s <a href="https://www.google.com/maps" target="_blank"></a>Google Maps.</li>
      <li> There are three ways to add your business:</li> 
      </ol> 
      <ul>
      <li>In the search bar, type in your address. Click <strong>Add Your Business</strong> under the Business Profile section on the left.</li>
      <li>On the map, right-click anywhere. Then click <strong>Add Your Business.</strong></li>
      <li>In the top left, click Menu <strong>Add Your Business.</strong> To complete the registration process for
      your business profile, adhere to the on-screen instructions.</li>
      </ul> `
    ),
  },
  {
    id: 5,
    packageHeding: "How Can I Stimulate Positive Reviews?",
    packageText: parse(
      `<p>The way you handle bad reviews as a business owner can have a significant effect on your revenue. But what some forget is how much a customer&#39;s decision to buy is influenced by positive reviews. Positive reviews increase customer trust in a business, according to <a href="http://cdn.business2community.com/wp-content/uploads/2015/07/online-customer-reviews2.jpg2.jpg?__cf_chl_tk=U3_AziccE_feSC5ySod5zb6wi15V0EGh60OIgZzb5PM-1680615992-0-gaNycGzNBmU"> more than 72% of respondents</a> in a survey of local businesses. So how do you persuade delighted customers to leave glowing reviews for your company on public websites? Here&#39;s how to motivate your clients to post beneficial reviews online:</p>
       
        <ul>
        <li>Determine the appropriate times to ask based on the customer&#39;s buying process.</li>
        <li>Pick a strategy that works for you on a large scale.</li>
        <li>Consult the customer in person.</li>
        <li>Take advantage of happy customer moments.</li>
        <li>Start by posing an open-ended question. Reduce friction wherever possible.</li>
        <li>Tell them how long it will take and when.</li>
        </ul> `
    ),
  },
  {
    id: 6,
    packageHeding: "Should I Respond to Negative Reviews?",
    packageText: parse(
      `<p>Bad reviews are undesirable, but they do exist. Every company, big or small (even those with shining stars), occasionally gets customer complaints. You shouldn&#39;t let that deter you because you can handle the situation. However, you need to respond when you get a bad review. It is ethical and a best practice for reputation management. Here&#39;s why you should:</p>
       
        <ul>
        <li>You are required to respond. It&#39;s safe to say that customers expect you to respond since 89% of consumers read businesses&#39; responses to reviews.</li>
        <li>It can help strengthen your online reputation to respond to reviews.</li>
        <li>Recognize the customer&#39;s complaints when responding to negative online reviews, and be  willing to continue the conversation.</li>
        
        </ul> `
    ),
  },
  {
    id: 7,
    packageHeding: "What are Citations?",
    packageText: parse(
      `<p>Citations are references to your company&#39;s name, address, and phone number (usually known as NAP) on a webpage that isn&#39;t your own in the context of local search engine optimization (SEO). Managing citations for stakeholders is where many new SEOs start their careers. When they&#39;reset up properly, citations can be simple to manage and boost local rankings. Citations are helpful for two reasons in particular:</p>
         
          <ul>
          <li>Being more prominent for local search queries (maybe).</li>
          <li>Helping customers find your company online.</li>
          
          </ul> 
          <p>Did you know that, per a <a href="https://moz.com/learn/seo/local-ranking-factors" target="_blank"> recent Moz survey,</a> citations are indeed the fifth-most significant ranking signal for local lookups? Amazing, isn&#39;t it?</p>`
    ),
  },
  {
    id: 8,
    packageHeding: "How Do Citations Help Rankings?",
    packageText:
      "Citations are a crucial component of local SEO's foundation. They are considered to help search engines confirm your company&#39;s existence, legality, and dependability. The likelihood that Google has increased confidence in your business&#39; existence, viability, and veracity is increased if the same information appears on numerous reliable and relevant websites. With increased trust, Google is more likely to include your company in its local search results. Similarly, as your  NAP details appear on more local sites, the algorithm will begin to associate your listing count with the significance and prominence of your business. Additionally, this might result in better local rankings.",
  },
  {
    id: 9,
    packageHeding: "Why is Local Web Page Content Important?",
    packageText:
      "Can you imagine visiting a website with little to no information or details on the products and services offered by the business? Most people would immediately click away and look for another, more trustworthy source of information. Finding success online now requires an understanding of content in web design. The goal of intriguing website content is to inform and educate visitors, which is also arguably the most crucial factor. Local content can also aid search engines in contextualizing your website&#39;s niche about its local services. Your local content explains to readers and search engines in plain language what your company, item, or service is all about.",
  },
];

export default localSeoData;
