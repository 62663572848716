import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
// import ecomImgs from "../../ImagesComponents/all-images/reputation_img.png";
let ecomImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/reputation_img.png",
];
function EcomReputation() {
  return (
    <Fragment>
      <section className="reputation_section">
        <figure className="reputation_figure">
          <img src={ecomImgs[0]} alt="img" />
          <figcaption className="reputation_caption">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-7"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
                  <div className="reputation_caption_text">
                    <h3>
                      Uphold Reputation with
                      <br /> Reputation Management Facility
                      <br /> in eCommerce SEO Services
                    </h3>
                    <p>
                      Get rid of the malign intentions of your competitors by
                      allowing us to take over your reputation sustenance.
                      Upholding reputation is crucial if you own an eCommerce
                      website. Potential buyers make crucial decisions about
                      your e-store through other customers’ feedback and reviews
                      available on the search engine.
                    </p>
                    <p>
                      Our SEO professionals ensure that your prospective buyers
                      finds positive reviews about your eCommerce website. They
                      also keep a close watch on your rivals who attempt to
                      sling mud at your eCommerce brand.
                    </p>
                    <NavLink className="talk_text" to="/contact-us">
                      let's talk
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>
      </section>
    </Fragment>
  );
}

export default EcomReputation;
