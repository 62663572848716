import React, { Fragment, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import SlideData from "./WhoWeAreData";
import { BiLinkAlt } from "react-icons/bi";
import ReviesAll from "../ReviesAll";
import { testimonialData } from "../PricesComponents/PriceData";
// import CaseStudiesBannerImgs from "../../ImagesComponents/all-images/seo_traffic_bg.jpg";
// import singleStudyBrand from "../../ImagesComponents/all-images/single_study_brand.png";
import { Helmet } from "react-helmet";

let CaseStudiesBannerImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/seo_traffic_bg.jpg",
  "https://ezmarketingtech.s3.amazonaws.com/img/single_study_brand.png",
];
// singleStudyBrand
// let singleStudyBrand = [
//   "https://ezmarketingtech.s3.amazonaws.com/img/single_study_brand.png",
// ];
const SingleCaseStudy = () => {
  useEffect(() => {
    document.body.classList.add("single-case-study-layout");
    return () => {
      document.body.classList.remove("inner-page-layout");
    };
  }, []);
  const { ShortHeading } = useParams();
  const value = SlideData.find(
    (val) =>
      val.ShortHeading.replace(/\s+/g, "-").toLowerCase() === ShortHeading
  );
  return (
    <Fragment>
      <Helmet>
        <title>{value.metaTitle}</title>
        <meta name="description" content={value.metaDescription} />
      </Helmet>
      {value ? (
        <div className="single_blog_wrapper">
          <div className="single_blog_banner">
            <img
              src={CaseStudiesBannerImgs[0]}
              alt="img"
              width="100%"
              height="390"
            />
            <div className="single_blog_banner_overlay">
              <div className="container">
                <ul className="single_blog_overlay_list">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink>{value.ShortHeading}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <section className="single_studies_section">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="sigle_study_brand">
                    <figure className="case_brand_img_figure">
                      <img
                        src={CaseStudiesBannerImgs[1]}
                        alt="singleStudyBrand"
                        className="case_study_img"
                        width="100%"
                        height="390"
                      />
                      <figcaption className="case_study_brand_caption">
                        <div className="row">
                          <div className="col-12 col-sm-7 col-md-7">
                            <div className="insile_laptop">
                              <img
                                src={value.slideOverImg}
                                alt="img"
                                width="100%"
                                height="390"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-5 col-md-5">
                            <div className="insile_laptop_text">
                              <h2>{value.ShortHeading}</h2>
                              <a
                                href={value.weburl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span>
                                  <BiLinkAlt />
                                </span>
                                {value.weburl}
                              </a>
                            </div>
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                  {/* <div className="single_studies_img_wrapper">
                    <img src={value.slideImg} alt="img" width="100%" />
                  </div> */}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="single_studies_content_wrapper">
                    <h3 className="blog-title">
                      About <strong>{value.ShortHeading}</strong>
                    </h3>
                    <p>{value.SlideText}</p>
                    <div className="blog-desc">{value.SingleShortHeading}</div>

                    {value.CaseStudiesDetail}

                    <div className={"map_research " + value.id}>
                      <p className={"map_text1" + value.id}>
                        {value.ConverSion}
                      </p>
                      <img
                        src={value.imgConvert}
                        alt="img"
                        width="100%"
                        className={"map_text_img1" + value.id}
                        height=""
                      />
                      <p className={"map_text2" + value.id}>
                        {value.overallText}
                      </p>
                      <img
                        src={value.overallImg}
                        alt="img"
                        width="100%"
                        className={"map_text_img2" + value.id}
                        height=""
                      />
                      <p className={"map_text3" + value.id}>
                        {value.organicText}
                      </p>
                      <img
                        src={value.organicImg}
                        alt="img"
                        width="100%"
                        className={"map_text_img3" + value.id}
                        height=""
                      />

                      <p className={"map_text4" + value.id}>
                        {value.directText}
                      </p>
                      <img
                        src={value.directImg}
                        alt="img"
                        width="100%"
                        className={"map_text_img4" + value.id}
                        height=""
                      />
                      <p className={"map_text5" + value.id}>
                        {value.socialText}
                      </p>
                      <img
                        src={value.socialImg}
                        alt="img"
                        width="100%"
                        className={"map_text_img5" + value.id}
                        height=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div>{value.dataRivew}</div>
        </div>
      ) : null}
      <ReviesAll
        mainclassName="home_restimonials price_testimonials Who_we_are_testimonials"
        heading="Our Clients Review"
        SubHeading="What our client say about us"
        details={testimonialData}
      />
    </Fragment>
  );
};

export default SingleCaseStudy;
