import React, { Fragment } from "react";

const ServicesInclude = () => {
  return (
    <Fragment>
      <section className="services_include text-center">
        <div className="container">
          <h2>What do our PPC Services Include?</h2>
          <p>
            EZ Marketing Tech is a leading PPC management company that uses paid
            search to drive sales for your business. Whether you want to
            increase leads, website traffic, or both, our tech-enabled PPC
            services will help you achieve your goals, plus measure and maximize
            your ROI from PPC.
          </p>
          <h3>
            Get a preview of what our PPC advertising management services
            include:
          </h3>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <ul className="include_list">
                <li>We provide you with a dedicated account representative</li>
                <li>
                  We build a custom PPC strategy for your business, industry,
                  and goals
                </li>
                <li>
                  We research anywhere from 400 to 10,000 keywords for your
                  campaigns
                </li>
                <li>
                  We offer access to MarketingCloudFX, which has helped our
                  clients earn a 20% lift in PPC performance
                </li>
                <li>
                  We target Google (including search and display), Bing, and
                  Gmail
                </li>
                <li>
                  We use advanced artificial intelligence to research competitor
                  ad strategies
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <ul className="include_list b-left">
                <li>
                  We develop custom ad campaign copy, which we then test for
                  performance
                </li>
                <li>
                  We manage ad bids strategically to maximize your return on ad
                  spend
                </li>
                <li>
                  We optimize current ad copy, design, and targeting to improve
                  performance
                </li>
                <li>
                  We monitor and update account settings to ensure PPC
                  performance
                </li>
                <li>We track up to 100 web lead phone calls</li>
                <li>
                  We provide regular reports to keep your business up-to-date
                </li>
              </ul>
            </div>
          </div>
          <div className="text-center services_deliverables ">
            <p>
              Keep in mind that deliverables for our PPC services will depend on
              your selected plan.
            </p>

            <p>
              For example, if you choose our Basic Plan, it does not include
              website conversion analysis reporting, which can help you see
              where your site’s design may stop conversions. In comparison, the
              Market Leader plan provides that service.
            </p>

            <p>
              If you have questions about our online ad management plans and
              deliverables, contact us online.
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ServicesInclude;
