import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { IoLogoYoutube } from "react-icons/io";
// import ApprochIconImgs0 from "../../ImagesComponents/all-images/california_icon1.png";
// import ApprochIconImgs1 from "../../ImagesComponents/all-images/california_icon2.png";
// import ApprochIconImgs2 from "../../ImagesComponents/all-images/california_icon3.png";
// import ApprochIconImgs3 from "../../ImagesComponents/all-images/california_icon4.png";
// import ApprochIconImgs4 from "../../ImagesComponents/all-images/california_roi_figure.png";
let ApprochIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/california_icon1.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_roi_figure.png",
];
// import NavadaImgs1 from "../../ImagesComponents/all-images/navada_icon2.png";
// import NavadaImgs2 from "../../ImagesComponents/all-images/navada_icon3.png";
// import NavadaImgs3 from "../../ImagesComponents/all-images/navada_icon4.png";
// import NavadaImgs4 from "../../ImagesComponents/all-images/navada_icon5.png";
// import NavadaImgs5 from "../../ImagesComponents/all-images/navada_icon6.png";
let NavadaImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon6.png",
];
function LocationSeoApproch() {
  return (
    <Fragment>
      <section className="seo_approch_sec text-center">
        <div className="container">
          <h2>
            Time To Step Up
            <br />
            In The Search Engine Spotlight
          </h2>
          <p className="mb-5">
            Invest in your business to grow in the online world. Our
            results-driven process guarantees improved rankings across the web
            for your business.
          </p>

          <div className="row j-center mt-5">
            <div className="col-12 col-sm-12 col-md-3 col lg-3 mb-5">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[0]} alt="img" />
                <h3>
                  Get an Edge Over
                  <br />
                  Competitors
                </h3>
                <p>
                  Get the upper hand with tailored SEO strategies and quality
                  traffic designed to beat the competition.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col lg-3 mb-5 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[1]} alt="img" />
                <h3>
                  Jump Ahead On
                  <br />
                  Keywords
                </h3>
                <p>
                  Show up more often in search engine results and move ahead in
                  the rankings faster than ever before.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col mb-5 b-left ">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[2]} alt="img" />
                <h3>
                  Secure Local
                  <br />
                  Targeting
                </h3>
                <p>
                  Reach your ideal audience with geo-targeting strategies for
                  best outcomes in local area codes of California.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col lg-3 mb-5 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[3]} alt="img" />
                <h3>
                  Capture Qualified
                  <br />
                  Leads
                </h3>
                <p>
                  Gain customers from the California location with a top-notch
                  presence on local directories, improved SERP positions, and
                  top ad positioning.
                </p>
              </div>
            </div>
          </div>
          <div className="strategies_wrap">
            <h3>
              Your Californian Business X EZ Marketing Tech
              <br /> = Exceptional Online Presence
            </h3>
            <p>
              Running a business can be tough, but luckily you don’t have to
              tackle all the hard marketing and technical SEO stuff alone.
              Whether you’re looking to drive traffic to your website or improve
              your brand awareness in California, the experienced professionals
              at our SEO company can make it happen.
            </p>
            <p>
              At EZ Marketing Tech, we understand the importance of SEO for
              businesses in California. We have developed proven strategies to
              help improve your search engine rankings and maximize the reach of
              your website. Our SEO solutions are tailored to your business
              needs, considering local markets, competitive landscapes, and
              target customer segments.
            </p>
            <p>
              We employ advanced tactics such as keyword research and link
              building to optimize websites for search engine performance. We
              also use innovative analytics tools to monitor campaigns and track
              key metrics such as conversions, click-through rates, impressions,
              website traffic, and ROI. Additionally, our team banks upon
              creative marketing strategies to ensure maximum engagement from
              potential customers and stay ahead of trends.
            </p>
            <p>
              Whether you need basic or advanced SEO solutions, we are the team
              you can trust to get the job done in the right manner. Let us help
              you make the most out of your digital presence and get best
              results for your business in California!
            </p>
          </div>
          <figure className="roi_figure">
            <img src={ApprochIconImgs[4]} alt="img" />
            <figcaption className="roi_caption">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5 col-lg-5"></div>
                <div className="col-12 col-sm-12 col-md-7 col-lg-7 text-left">
                  <h4>
                    Why Should You Join Forces With
                    <br /> An SEO Agency In California?
                  </h4>
                  <p>
                    Partnering with an SEO agency in California is key to the
                    success of your business. Without effective SEO strategies,
                    your business risks missing out on valuable sales
                    opportunities. Working with a qualified SEO team in
                    California, you can maximize the productivity of your
                    marketing campaigns. Industry experts also help you create
                    engaging content that resonates with potential customers
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </figcaption>
          </figure>
          <div className="navada_social text-center">
            <h3>
              Unparalleled SEO Services In California
              <br />
              So That You’re Seen On:{" "}
            </h3>

            <ul className="navada_Social_list">
              {/* <li>
                <a href="/">
                  <img src={NavadaImgs0} alt="img" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://goo.gl/maps/9sbfKJ2rmYFLoJFu9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[0]} alt="img location" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ezmarketingtech?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[1]} alt="img twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ezmarketingtech/ "
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[2]} alt="img facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ezmarketingtech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[3]} alt="img instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/ez-marketing-tech/"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[4]} alt="img" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCU1DcAmBpnswfX88X3E7XTA "
                  rel="noreferrer"
                  target="_blank"
                >
                  <IoLogoYoutube className="you_tube_icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default LocationSeoApproch;
