import React, { Fragment } from "react";
// import stepIconImgs0 from "../../ImagesComponents/all-images/step_icon.png";
// import stepIconImgs1 from "../../ImagesComponents/all-images/step_icon2.png";
// import stepIconImgs2 from "../../ImagesComponents/all-images/step_icon3.png";
// import stepIconImgs3 from "../../ImagesComponents/all-images/step_icon4.png";
// import stepIconImgs4 from "../../ImagesComponents/all-images/step_icon5.png";
// import stepIconImgs5 from "../../ImagesComponents/all-images/step_icon6.png";
// import stepIconImgs6 from "../../ImagesComponents/all-images/step_icon7.png";
let stepIconImgs = [
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/step_icon.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/step_icon2.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/step_icon3.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/step_icon4.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/step_icon5.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/step_icon6.png",
  "https://infinium-conference.s3.amazonaws.com/EZmarketing/images/step_icon7.png",
];
function AllRoundSteps() {
  return (
    <Fragment>
      <section className="step_section">
        <div className="container">
          <h3>
            7 Steps to All-Round <br />
            EZ Marketing Tech SEO Process
          </h3>
          <p>
            It goes without saying that the adventure of SEO entails playing the
            long game. Optimization is an ongoing process. Therefore, if you're
            just getting started with SEO services, then a step-by-step SEO
            tutorial with EZ Marketing Tech is in order. Here's how:
          </p>
          <div className="row text-left j-center mb-5">
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-color">
              <div className="steps_wrapper">
                <img src={stepIconImgs[0]} alt="img" />
                <h4>Discover</h4>
                <p>
                  As the name suggests, it all starts at the beginning. Like
                  life is all about discovering, so is SEO. But here it’s our
                  team that aims to unravel every aspect of our client’s
                  business once they’re onboarded. The Purpose? It’s pretty
                  simple. Once we understand your needs, goals, and
                  expectations, we move on to the next, i.e., to analyze
                  strengths and weaknesses.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-color">
              <div className="steps_wrapper">
                <img src={stepIconImgs[1]} alt="img" />
                <h4>Analyze</h4>
                <p>
                  It all comes down to SWOT: Strengths, Weaknesses,
                  Opportunities, and Threats. We thoroughly analyze the client's
                  operations, precisely every crook, and cranny, to beat the
                  odds and rise to the top. Here, we examine the company
                  website, the traffic it receives, the number of leads
                  generated, as well as other digital channels like social
                  media.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-color">
              <div className="steps_wrapper">
                <img src={stepIconImgs[2]} alt="img" />
                <h4>Strategize</h4>
                <p>
                  It’s all about planning. Driven by the insights we collect
                  after analyzing the clients, strategizing everything in detail
                  is what our team specializes in. We develop a content-rich SEO
                  and SMO strategy for the client's business to achieve the
                  desired results. We think of everything, from the client's
                  company website to various social media pages, to let them
                  achieve the best results.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-color">
              <div className="steps_wrapper">
                <img src={stepIconImgs[3]} alt="img" />
                <h4>Execute</h4>
                <p>
                  Execution is all about perfection, and we strive to be perfect
                  when we put the plan into action. We carry out various tasks
                  for our clients, including keyword selection, content
                  creation, posting to website technical upkeep, and other
                  tasks. This is where we drive ourselves to our best
                  capabilities, put our experience to work, and try to achieve
                  the results our clients expect us to deliver.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-md-offset-3 col-color">
              <div className="steps_wrapper">
                <img src={stepIconImgs[4]} alt="img" />
                <h4>Measure</h4>
                <p>
                  It’s time to fess up. Just like your heart sped up at the time
                  of the result, this is our time to wait with bated breath for
                  our hard work to show up in the results. We track the results
                  of our digital marketing strategy using various analytics
                  tools. It aids in comprehending the strategy's efficacy after
                  it has been developed and put into action.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-color">
              <div className="steps_wrapper">
                <img src={stepIconImgs[5]} alt="img" />
                <h4>Report</h4>
                <p>
                  Once we measure the performance, it’s time to sit down with
                  the client. We create a thorough report of the strategy's
                  effectiveness, post the findings, and share it with the client
                  monthly. This report analyzes the strategy on various metrics,
                  such as the percentage increase in website traffic, the
                  client's website's position on search engine results pages,
                  etc.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-color">
              <div className="steps_wrapper">
                <img src={stepIconImgs[6]} alt="img" />
                <h4>Adjust</h4>
                <p>
                  This step is all about making adjustments if needed. We
                  continue to ‘adjust’ the strategy as needed based on the
                  digital marketing campaign results to keep it current and
                  effective as time goes on and trends change.
                </p>
              </div>
            </div>
          </div>
          <p>
            Thus, to achieve greater marketing success, develop an SEO strategy
            with us that enables you to address your specific business needs,
            such as increasing customer acquisition.
          </p>
        </div>
      </section>
    </Fragment>
  );
}

export default AllRoundSteps;
