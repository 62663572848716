import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
// import seoProcessImgs from "../../ImagesComponents/all-images/seo_process_img.png";
let seoProcessImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/seo_process_img.png",
];
function SeoProcess() {
  return (
    <Fragment>
      <section className="seo_process">
        <div className="container">
          <div className="row md-col-revers">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="process_text_wrapper">
                <h3>SEO: What is It?</h3>
                <p>
                  SEO is the process of improving a website’s online
                  effectiveness so it can achieve its business goals. “Search
                  Engine Optimization” is, therefore a broad term that covers
                  several technical domains, even though it can be abbreviated
                  in only three letters. In essence, the best SEO practices aim
                  to drive more traffic by fulfilling a users’ search intent by
                  providing relevant, high-quality content and offering a
                  seamless user experience.
                </p>
                <p>
                  Additionally, any SEO strategy should aim to raise your
                  website’s organic search rankings in the search results.
                  Remember that the more people view your website, the higher it
                  appears on the list of results. Good technical SEO entails a
                  vast variety of website optimization activities,including;
                </p>
                <ul className="process_list">
                  <li>
                    <AiOutlineCheck className="process_list_check" />
                    Find relevant keywords with high potential for user-driven
                    search traffic.
                  </li>
                  <li>
                    <AiOutlineCheck className="process_list_check" />
                    Creating valuable content of the highest quality and
                    optimizing it for both users and search engines.
                  </li>
                  <li>
                    <AiOutlineCheck className="process_list_check" />
                    Incorporating directly relevant backlinks from reputable
                    websites and evaluating the outcome.
                  </li>
                  {/* <li>
                    SEO is now regarded as a crucial marketing strategy. But
                    have you ever considered why SEO can bring top ranking on
                    Google?
                  </li> */}
                </ul>
                <p>
                  Search Engine Optimization is now regarded as a crucial
                  integrated marketing strategy. But have you ever wondered why
                  SEO can bring top ranking on Google?
                </p>
                <button className="process_start_btn">
                  <NavLink to="/seo-packages"> Let’s Get Started</NavLink>
                </button>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <figure className="process_figure">
                <img src={seoProcessImgs[0]} alt="img" width="100%" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SeoProcess;
