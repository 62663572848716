import React, { Fragment, useState } from "react";

const PpcImportant = () => {
  const [ToggleTabs, SetToggleTabs] = useState(1);
  const changTab = (index) => {
    SetToggleTabs(index);
  };
  return (
    <Fragment>
      <section className="ppc_important_sec">
        <div className="container">
          <h3>Why is PPC important?</h3>
          <p>
            Having a pay-per-click advertising firm like EZ Marketing Tech
            working with your
            <br /> marketing team has several advantages:
          </p>
          <div className="ppc_important_tabs">
            <div className="row row-sm-revrce">
              <div className="col-12 col-sm-12 col-md-8 col-lg-9">
                <div
                  className={
                    ToggleTabs === 1
                      ? "ppc_important_tabs_content ppc_tabs_content_active"
                      : "ppc_important_tabs_content"
                  }
                >
                  <h4>Aids in Achieving Your Marketing Objectives</h4>
                  <p>
                    The strongest argument for using PPC advertising is
                    frequently this. PPC is the solution if you want to quickly
                    get results for your clients. Pay-per-click ads can assist
                    you whether your objective is raising brand awareness or
                    increasing e-commerce sales. Almost any conversion goal can
                    be monitored. PPC is a valuable tool for matching website
                    traffic sources with target audiences. Through encouraging
                    content downloads, enticing newsletter signups and contest
                    entries, as well as pushing for app downloads, PPC can
                    nurture the happy medium of fostering and serving the
                    midpoint of the funnel in the age of content marketing and
                    innovative thinking.
                  </p>
                  <p>
                    PPC can aid in many stages of the sales process and your
                    prospects' journey from knowledge to conversion. PPC
                    campaigns can be set up successfully regardless of the list
                    of identified objectives. It is highly successful for many
                    and straightforward to determine whether it is working or
                    not, thanks to granular goals, targeting, and robust data to
                    report.
                  </p>
                </div>
                <div
                  className={
                    ToggleTabs === 2
                      ? "ppc_important_tabs_content ppc_tabs_content_active"
                      : "ppc_important_tabs_content"
                  }
                >
                  <h4>Fast and Effective</h4>
                  <p>
                    Launching a pay-per-click campaign is easy once you
                    understand how to create and optimize one. Pay-per-click
                    marketing is a tried-and-true method for achieving results
                    quickly. Compared to an SEO campaign, PPC results are more
                    rapid. Many customers also value PPC ads because they are
                    affordable. As the name implies, you only pay for the
                    placement of the advertisement when a click occurs.
                  </p>
                </div>
                <div
                  className={
                    ToggleTabs === 3
                      ? "ppc_important_tabs_content ppc_tabs_content_active"
                      : "ppc_important_tabs_content"
                  }
                >
                  <h4>Easy to Measure and Track</h4>
                  <p>
                    PPC using Google Ads is simple to measure and track if you
                    want to show your clients how successful you are.
                    (Especially if you combine Google Analytics with the Google
                    Ads tool.) Google makes it simple to monitor the
                    effectiveness of your PPC campaigns. With Google Ads PPC
                    campaigns, gauging the success of your advertising is never
                    difficult.
                  </p>
                </div>
                <div
                  className={
                    ToggleTabs === 4
                      ? "ppc_important_tabs_content ppc_tabs_content_active"
                      : "ppc_important_tabs_content"
                  }
                >
                  <h4>Easy To Get Started</h4>
                  <p>
                    PPC is quick and simple to set up and start using. Marketers
                    can create a free account for Google Ads or Microsoft
                    Advertising and begin creating ads right away. Ads can start
                    generating results as soon as they are created and approved.
                    They start showing up in searches. Ads can be quickly
                    activated and deactivated just as quickly, making increasing
                    brand visibility as simple as flipping a switch.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-3">
                <ul className="ppc_important_tabs_heading">
                  <li
                    onClick={() => changTab(1)}
                    className={
                      ToggleTabs === 1
                        ? "ppc_tabs_item ppc_tabs_item_active"
                        : "ppc_tabs_item"
                    }
                  >
                    <h4>Aids in Achieving Your Marketing Objectives</h4>
                  </li>
                  <li
                    onClick={() => changTab(2)}
                    className={
                      ToggleTabs === 2
                        ? "ppc_tabs_item ppc_tabs_item_active"
                        : "ppc_tabs_item"
                    }
                  >
                    <h4>Fast and Effective</h4>
                  </li>
                  <li
                    onClick={() => changTab(3)}
                    className={
                      ToggleTabs === 3
                        ? "ppc_tabs_item ppc_tabs_item_active"
                        : "ppc_tabs_item"
                    }
                  >
                    <h4>Easy to Measure and Track</h4>
                  </li>
                  <li
                    onClick={() => changTab(4)}
                    className={
                      ToggleTabs === 4
                        ? "ppc_tabs_item ppc_tabs_item_active"
                        : "ppc_tabs_item"
                    }
                  >
                    <h4>Easy To Get Started</h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PpcImportant;
