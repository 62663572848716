import React, { Fragment } from "react";
import SeoPlan, { bayNowPlan } from "./PriceData";
import { FaFire } from "react-icons/fa";
function PriceBannerText(val) {
  return (
    <Fragment>
      <h2>{val.planHeading}</h2>
      <p>{val.planMainText}</p>
    </Fragment>
  );
}
function PlaneToSale(val) {
  return (
    <div className={val.ClassList}>
      <div className="price-detail">
        <div className="price_content">
          <div className="plane_wrap">
            <h3>{val.planPriceHeading}</h3>
            <p>{val.planPriceText}</p>
          </div>

          <div className="price_amount">
            <h4>{val.planPriceSubHeading}</h4>
            <div className="price_wrap">
              <strong>{val.planPriceStrong}</strong>
              <span>{val.planPerMonth}</span>
            </div>
            <button className="by_now">
              <a href={val.planeurl} target="_blank" rel="noopener noreferrer">
                <span>{val.planBay}</span>
              </a>
            </button>
          </div>
        </div>
        <div className="avail_content">
          <h4>
            <FaFire /> {val.planMostRecommended}
          </h4>
          <table className="table table-responsive">
            <thead>
              <th>
                <h3>{val.planPeriods}</h3>
              </th>
              <th></th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>{val.planKeywords}</span>
                </td>
                <td>
                  <span>{val.planKeywordsNumber}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{val.planGnb}</span>
                </td>
                <td>
                  <span>{val.planGnbPrice}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{val.planGuestPost}</span>
                </td>
                <td>
                  <span>{val.planGuestPostItems}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{val.planPaidPress}</span>
                </td>
                <td>
                  <span>{val.planPaidPressItem}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{val.planSubmissionLinks}</span>
                </td>
                <td>
                  <span>{val.planSubmissionLinksItem}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
function PricesContent() {
  return (
    <Fragment>
      <section className="price_table_banner">
        <div className="container">
          <div className="services_heading_wrapper">
            {SeoPlan.map(PriceBannerText)}
          </div>
          <div className="price_plane_wrapper">
            <div className="row m-0 row_scroll">
              {bayNowPlan.map(PlaneToSale)}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default PricesContent;
