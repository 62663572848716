import React, { Fragment } from "react";
// import FormImg from "../../ImagesComponents/bannerImg/form_img.png";
import ServiceForm from "../ECommerceSeoComponents/ServiceForm";
const ContentMarketingBanner = () => {
  return (
    <Fragment>
      <section className="ecom_seo_banner content_ecom_seo_banner">
        <h2>
          Generate Organic Traffic With Engaging Content
          <br /> and Incredible <span>Content Marketing!</span>
        </h2>
        <p>
          Content Marketing is an online strategy that focuses on publishing
          engaging content to captivate users. This content can include anything
          from articles and podcasts to videos and blog posts.
        </p>
        <p>
          Content marketing utilizes interesting content to increase user
          interest. Whether a video or a blog post, it helps gain more leads and
          compels visitors to stay longer on your site. Content marketing is
          also imperative to decrease the bounce rate of a website.
        </p>
        <p>
          Our professional content marketing services focus on producing
          top-notch content. Our highly optimized and comprehensible content
          sets the wheels in motion for gaining substantial brand awareness.
        </p>
        <p>
          As a renowned content marketing agency, we know online sales are
          expedited when leads turn into buyers. It is a crucial phase where
          content plays an instrumental role. Quality content not just presents
          you as another product or service-providing company but a unique one
          that understands its consumers!
        </p>
        <div className="ecom_form_wrapper">
          {/* <img src={FormImg} alt="img" /> */}
          <h3>REQUEST A QUOTE</h3>
          <ServiceForm/>
        </div>
        <div className="ecomm_concern ">
          <h3>Our Assurance for Your Online Business!</h3>
          <p className="text-center">
            At EZ Marketing Tech, our content marketing experts provide you with
            comprehensive content marketing services. These services include
            everything, from developing engaging content and sharing across the
            network to updating it timely, and more.
          </p>
          <p className="text-center">
            We are a content marketing company that leads you to the other side
            of the shore and surrounds you with more buyers to rise above all
            your rivals! Our content marketing agency produces authentic,
            non-plagiarised, credible, original content integrated with relevant
            keywords.
          </p>
          <p className="text-center">
            We also use our reputed affiliates to host your blog posts and
            backlinks to ensure that users see you as a reputable brand. Plus,
            we keenly manage and counter reputation-related conflicts also.
          </p>
          <h4>
            Let our smartly organized and sorted content do all the magic for
            you!
          </h4>
        </div>
      </section>
    </Fragment>
  );
};

export default ContentMarketingBanner;
