// import ServiceMainBanner from "../../ImagesComponents/all-images/service_main_banner.webp"
// import ServiceBannerShow from "../../ImagesComponents/all-images/service_banner_show.png"
let ServiceMainebannerImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/service_main_banner.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/service_banner_show.png",
];
const ServiceMainebanner = () => {
  return (
    <>
      <section className="main_serv_banner">
        <figure className="main_service_figure">
          <img src={ServiceMainebannerImg[0]} alt="img" width="100%" />
          <figcaption className="main_service_figure">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="main_service_content_banner">
                    <h1>
                      EZ Marketing Tech
                      <br />
                      <strong>Services</strong>
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan lacus vel facilisis.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <figure className="service_banner_show_img">
                    <img
                      src={ServiceMainebannerImg[1]}
                      alt="img"
                      width="100%"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>
      </section>
    </>
  );
};

export default ServiceMainebanner;
