import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { CiFaceSmile } from "react-icons/ci";
import { BsCheckCircleFill } from "react-icons/bs";
// import concernSeoImgs from "../../ImagesComponents/all-images/concern_seo.png";
let concernSeoImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/concern_seo.png",
];
function SeoConcern() {
  return (
    <Fragment>
      <section className="concern_seo_section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 v-center">
              <div className="concern_text">
                <h3>Why is SEO important for your website?</h3>
                <p>
                  Our lives now can't exist without search engines. Getting your
                  website ranked highly is crucial because more than a billion
                  websites are available. Being found online is crucial in the
                  digital age we live in. Your business will suffer if your
                  website doesn't appear on Google's first page. It's that
                  crucial!.
                </p>
                <p>
                  A strong SEO strategy can help more than just your home
                  business or online store. Your physical store will benefit
                  from having a strong SEO strategy as well! Today, having a
                  trustworthy online presence is essential if you want your
                  business to succeed.
                </p>
                <p>
                  People use search engines like Google to find the information
                  they need every day. If your website is hidden on the third
                  page of results, or worse, it may not even be seen at all. It
                  is impossible to overstate the value of having a website and
                  performing SEO. This post is meant to teach you the
                  significance of ranking in search results if you don't already
                  know. The internet is a vast resourc.
                </p>
                <p>
                  However, it isn't so large that you can't find what you want.
                  It should go without saying that knowing how to search is a
                  prerequisite for finding anything. So you must make your
                  website visible to search engines if you run an online
                  business and want your customers to find you.
                </p>
                <p>
                  This is why understanding SEO is now so crucial. Let's learn
                  the fundamentals of it!.
                </p>
                <div className="concern_btn">
                  <button className="seo_banner_btn">
                    <NavLink
                      className="seo_banner_link_text"
                      to="/seo-packages"
                    >
                      Start a Project
                    </NavLink>
                  </button>
                  <button className="seo_banner_btn">
                    <NavLink className="seo_banner_link_text" to="/contact-us">
                      Contact Us
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <figure className="concern_figure">
                <img src={concernSeoImgs[0]} alt="img" width="100%" />
                <div className="client">
                  <strong>
                    <CiFaceSmile /> +2.5k
                  </strong>

                  <span>Client Satisfaction</span>
                </div>
                <div className="client client2">
                  <strong>
                    <BsCheckCircleFill /> +20k
                  </strong>

                  <span>Projects Done</span>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SeoConcern;
