import React, { Fragment } from "react";
// import ExTeamIconImgs0 from "../../ImagesComponents/all-images/maryland_seo_icon.png";
// import ExTeamIconImgs1 from "../../ImagesComponents/all-images/colorado_ex_team.png";
// import ExTeamIconImgs2 from "../../ImagesComponents/all-images/colorado_ex_team2.png";
// import ExTeamIconImgs3 from "../../ImagesComponents/all-images/taxas_keyword_icon.png";
// import ExTeamIconImgs4 from "../../ImagesComponents/all-images/california_seo_icon4.png";
// import ExTeamIconImgs5 from "../../ImagesComponents/all-images/california_seo_icon5.png";
// import ExTeamIconImgs6 from "../../ImagesComponents/all-images/california_seo_icon6.png";
let ExTeamIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/maryland_seo_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/taxas_keyword_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon6.png",
];
function TopSeoNevada() {
  return (
    <Fragment>
      <section className="seo_nevada_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <h3>
                Our <span>seo</span> Process
                <br />
                For Businesses In Texas
              </h3>
              <p>
                Join successful businesses from cities like Dallas and Houston,
                TX, who have leveraged our SEO services <br />
                to get to the first page of Google. To make this possible, our
                experienced team will take an <br />
                all-encompassing approach to establish you as an industry
                leader.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <div className="customized_box">
                <div className="experienced_seo_team text-center">
                  <div className="seo_team_wrap">
                    <img src={ExTeamIconImgs[0]} alt="Ex Team icon" />
                    <h4>Research</h4>
                    <p>
                      Research the current standing of a company in Texas with
                      regard to SEO by looking at the website, content, online
                      presence, rankings, etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 customized_box">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[1]} alt="Ex Team icon" />
                  <h4>Analyze</h4>
                  <p>
                    Here, we analyze competitor websites and their performance.
                    Then, we conduct an audit to identify weaknesses that can be
                    addressed in order to improve search engine visibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[2]} alt="Ex Team icon" />
                  <h4>Strategize</h4>
                  <p>
                    Develop an effective SEO strategy tailored to meet the
                    business goals of the company. Then we optimize for key
                    phrases relevant to its industry or geographic area.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[3]} alt="Ex Team icon" />
                  <h4>Keywords</h4>
                  <p>
                    Identify keywords and phrases that will help rank your
                    website on search engines for increased visibility. For
                    this, we utilize tools such as AdWords or Moz Keyword
                    Explorer to find ideal keywords.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[4]} alt="Ex Team icon" />
                  <h4>On-site Optimization</h4>
                  <p>
                    Improve the existing website by making necessary changes,
                    including updating titles, descriptions, URLs, images, and
                    on-page content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[5]} alt="Ex Team icon" />
                  <h4>Link Building & Outreach</h4>
                  <p>
                    Establish relationships with influencers in the industry who
                    can create linkable content or provide citations on other
                    websites pointing back to your site, thus increasing organic
                    traffic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[6]} alt="Ex Team icon" />
                  <h4>Tracking & Reporting</h4>
                  <p>
                    Track the progress of the website over time via web analytic
                    programs like Google Analytics or through various software
                    options such as Moz or Semrush to ensure all efforts are
                    maximizing ROI (Return on Investment).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TopSeoNevada;
