import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { IoLogoYoutube } from "react-icons/io";
// import ApprochIconImgs0 from "../../ImagesComponents/all-images/colorado_icon.png";
// import ApprochIconImgs1 from "../../ImagesComponents/all-images/maryland_icon2.png";
// import ApprochIconImgs2 from "../../ImagesComponents/all-images/colorado_icon3.png";
// import ApprochIconImgs3 from "../../ImagesComponents/all-images/maryland_icon2.png";
// import ApprochIconImgs4 from "../../ImagesComponents/all-images/colorado_icon3.png";
// import ApprochIconImgs5 from "../../ImagesComponents/all-images/maryland_RoiFigure.png";
let ApprochIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/maryland_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/maryland_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/maryland_RoiFigure.png",
];
// import NavadaImgs1 from "../../ImagesComponents/all-images/navada_icon2.png";
// import NavadaImgs2 from "../../ImagesComponents/all-images/navada_icon3.png";
// import NavadaImgs3 from "../../ImagesComponents/all-images/navada_icon4.png";
// import NavadaImgs4 from "../../ImagesComponents/all-images/navada_icon5.png";
// import NavadaImgs5 from "../../ImagesComponents/all-images/navada_icon6.png";
let NavadaImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/navada_icon6.png",
];
function LocationSeoApproch() {
  return (
    <Fragment>
      <section className="seo_approch_sec">
        <div className="container">
          <h2>
            Unlock The Power Of SEO:
            <br />
            Drive Growth And Boost Visibility In Maryland
          </h2>
          <p className="mb-5">
            We believe in the power of SEO to drive growth, increase visibility
            and take businesses to the next level. That's why we offer
            customized and results-driven SEO solutions to businesses in
            Maryland. Our team of experts leverages the latest tools and
            techniques to create tailored strategies designed to meet your
            specific needs and, on top of everything, achieve your business
            goals.
          </p>
          <div className="row j-center">
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[0]} alt="img" />
                <h3>Improved Online Visibility</h3>
                <p>
                  We will help you rank higher on search engines and get more
                  exposure to potential customers.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[1]} alt="img" />
                <h3>Increased Traffic</h3>
                <p>
                  With higher rankings, you can expect more targeted traffic to
                  your website!
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col mb-5 b-left ">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[2]} alt="img" />
                <h3>Enhanced Credibility</h3>
                <p>
                  A robust online presence can enhance your business's
                  credibility and authority in your industry. This means
                  returning customers!
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[3]} alt="img" />
                <h3>
                  Better Return On
                  <br />
                  Investment
                </h3>
                <p>
                  We understand the value of providing a cost-effective return
                  on investment and are committed to optimizing your success
                  through SEO.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col lg-4 mb-5 b-left">
              <div className="approch_improve_text">
                <img src={ApprochIconImgs[4]} alt="img" />
                <h3>
                  Stay Ahead Of The
                  <br />
                  Competition
                </h3>
                <p>
                  Maintain your position as a leader in your industry with our
                  cutting-edge approach to SEO.
                </p>
              </div>
            </div>
          </div>
          <div className="strategies_wrap">
            <h3>
              Take Your Maryland Business To The Next Level With
              <br /> EZ Marketing Tech's Tailored SEO Solutions
            </h3>
            <p>
              Aren't you tired of having a website that fails to generate
              desired results? Are you frustrated with a lack of visibility and
              low traffic, despite your best efforts to improve your online
              presence? It's time to partner with a top SEO services agency in
              Maryland and take your business to the next level.
            </p>
            <p>
              By utilizing cutting-edge techniques and tactics that are
              especially suited to your business and its specific requirements,
              a reputable SEO services agency will assist you in realizing the
              full potential of your online presence. From keyword research and
              on-page optimization to link building and content creation, a top
              SEO services agency will work tirelessly to ensure that your
              website is optimized for search engines. More importantly, it will
              help drive the growth and visibility that your business deserves.
            </p>
          </div>
          <figure className="roi_figure">
            <img src={ApprochIconImgs[5]} alt="img" />
            <figcaption className="roi_caption">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5 col-lg-5"></div>
                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                  <h4>
                    Get The Online Exposure You
                    <br /> Deserve: Partner With Our Expert
                    <br /> SEO Team In Maryland
                  </h4>
                  <p>
                    Welcome to EZ Marketing Tech, where our tailored SEO
                    solutions help Maryland businesses reach new heights. Our
                    team of experts is passionate about driving growth and
                    boosting visibility for businesses like yours. With our
                    proven track record of success, you can trust us to provide
                    the results you need to transform your online presence and
                    reach new heights.
                  </p>
                  <p>
                    Whether you want to increase your search engine rankings,
                    improve your website's user experience, or drive more leads,
                    our customized SEO strategies will help you achieve your
                    goals. So, what are you waiting for? Take your firm and
                    brand to the next level with EZ Marketing Tech's tailored
                    SEO solutions today!
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </figcaption>
          </figure>
          <div className="navada_social text-center">
            <h3>
              Unparalleled SEO Services In Maryland
              <br />
              So That You Rule Over:
            </h3>

            <ul className="navada_Social_list location_Social_list">
              {/* <li>
                <a href="/">
                  <img src={NavadaImgs0} alt="img" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://goo.gl/maps/9sbfKJ2rmYFLoJFu9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[0]} alt="img location" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ezmarketingtech?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NavadaImgs[1]} alt="img twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ezmarketingtech/ "
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[2]} alt="img facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ezmarketingtech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[3]} alt="img instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/ez-marketing-tech/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={NavadaImgs[4]} alt="img" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCU1DcAmBpnswfX88X3E7XTA "
                  rel="noreferrer"
                  target="_blank"
                >
                  <IoLogoYoutube className="you_tube_icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default LocationSeoApproch;
