import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

function WhoWeFutureProof() {
  return (
    <Fragment>
      <section className="future_proof_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
              <div className="future_proof_content">
                <h3>
                  Digital Marketing Services
                  <br />
                  To Future-Proof Your
                  <br />
                  Online Success
                </h3>
                <NavLink className="call_us" to="/contact-us">
                  Call Us Today
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-8 ai-center">
              <div className="future_primery_text">
                <p>
                  As a premier SEO services company, we leverage our 12 years of
                  experience to innovate, outcome-driven search engine
                  optimization strategies that drive more traffic and boost your
                  bottom line.
                </p>
                <p>
                  Our
                  <a
                    href="https://www.ezmarketingtech.com/seo-services"
                    target="_blank"
                    rel="noreferrer"
                    className="go_url"
                  >
                    search engine optimization services
                  </a>{" "}
                  have been fundamental to thousands of businesses' success, and
                  we continue to carry this legacy through our research-backed
                  approach and SEO expertise.
                </p>
                <p>
                  We understand that today’s Search Engine Optimization
                  strategies will not be relevant tomorrow. So, we keep
                  ourselves up to date with the latest Search Engine trends and
                  technologies and constantly changing Search Engine algorithms
                  to stay competitive.
                </p>
                <p>
                  The true strength of our company comes from our SEO experts,
                  who leave no stone unturned to deliver results that matter.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="seo_needs">
                <h3> Your One Stop Digital Shop For All Your Website Needs</h3>
                <p>
                  Our SEO services include{" "}
                  <a
                    href="https://www.ezmarketingtech.com/contact-us"
                    target="_blank"
                    rel="noreferrer"
                    className="go_url"
                  >
                    Digital Marketing consultant
                  </a>
                  services, audit and analysis, content marketing & link
                  building, and everything in between. Every service we offer is
                  key to understanding a website’s Search Engine performance and
                  identifying areas of improvement, which ultimately helps
                  enhance its online presence.
                </p>
                <p>
                  We also understand the pivotal role organic search plays in
                  making a website relevant and ranking it higher on search
                  engines. So, we integrate thoughtfully crafted content
                  marketing strategies into our SEO campaigns to cater to your
                  customers’ needs, expand your online reach, and establish your
                  business as a well-rounded brand.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="high_touch_seo">
                <h3>High-Touch SEO Services, Win-Win Mindset</h3>
                <p>
                  At EZ Marketing Tech, we believe in fostering meaningful
                  relationships with our clients. This helps us set a solid
                  foundation for a remarkable journey—both for us and our
                  clients. We work as an integral part of your business and are
                  committed to bringing your marketing initiatives to fruition
                  as much as you are. Your success matters most.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default WhoWeFutureProof;
