import React, { Fragment } from "react";

function HomeForm() {
  return (
    <Fragment>
      <form
        className="banner_form"
        action="https://beta.ezmarketingtech.com/lets-get-started"
        method="POST"
      >
        <div className="form-group row">
          <input
            className="from-control"
            type="hidden"
            id="key"
            name="key"
            value={window.location.pathname.slice(1)}
          />
          <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-sm-mb-3">
            <input
              type="text"
              name="subject"
              className="form-control"
              placeholder="Enter your website"
              required={true}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-sm-mb-3">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Enter your e-mail"
              required={true}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-sm-mb-3">
            <input
              type="tel"
              name="phone"
              className="form-control"
              placeholder="Enter your phone no."
              required={true}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 d-flex item-center j-center">
            <button type="submit" className="btn btn-md banner_r_btn">
              Let’s Get Started
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

export default HomeForm;
