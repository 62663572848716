import React, { Fragment } from "react";
// import ExTeamIconImgs0 from "../../ImagesComponents/all-images/colorado_ex_team.png";
// import ExTeamIconImgs1 from "../../ImagesComponents/all-images/colorado_ex_team2.png";
// import ExTeamIconImgs2 from "../../ImagesComponents/all-images/colorado_ex_team3.png";
// import ExTeamIconImgs3 from "../../ImagesComponents/all-images/colorado_ex_team8.png";
// import ExTeamIconImgs4 from "../../ImagesComponents/all-images/california_seo_icon6.png";
// import ExTeamIconImgs5 from "../../ImagesComponents/all-images/california_seo_icon5.png";
// import ExTeamIconImgs6 from "../../ImagesComponents/all-images/maryland_seo_icon.png";
// import ExTeamIconImgs7 from "../../ImagesComponents/all-images/colorado_ex_team7.png";
let ExTeamIconImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team8.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon6.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/california_seo_icon5.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/maryland_seo_icon.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/colorado_ex_team7.png",
];
function TopSeoNevada() {
  return (
    <Fragment>
      <section className="seo_nevada_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <h3>
                Reach New Heights With
                <br />
                The Power Of<span>seo</span> In Maryland
              </h3>
              {/* <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIcon} alt="Ex Team icon" />
                  <h4>Experienced Team</h4>
                  <p>
                    From keyword optimization to content optimization and social
                    media campaigns, our team knows what it takes to get you the
                    organic traffic you're looking for. We help our clients
                    achieve their goals and drive business growth.
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="customized_box">
                <p>
                  Are you looking to take your Maryland-based business to new
                  heights? Are you ready to reach new customers, increase your
                  online visibility, and drive growth like never before? Then
                  it's time to harness the power of SEO. Take your business to
                  new heights and unlock the power of SEO with EZ Marketing
                  Tech.
                </p>
                <p>
                  Choosing the right SEO services agency can make all the
                  difference for your business's online success. Want to know
                  why EZ Marketing tech is a good choice? Here is a look at our
                  multi-step process:
                </p>
              </div>
            </div>
          </div>
          <div className="row j-center expert_row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[0]} alt="Ex Team icon" />
                  <h4> Discovery</h4>
                  <p>
                    We analyze your industry, competition, website, and online
                    presence to develop an SEO strategy that fits your business
                    goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[1]} alt="Ex Team icon" />
                  <h4>Keyword Research</h4>
                  <p>
                    Our team of SEO experts researches and identifies target
                    keywords relevant to your business, especially in the
                    Maryland region.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap">
                  <img src={ExTeamIconImgs[2]} alt="Ex Team icon" />
                  <h4>Technical Audit </h4>
                  <p>
                    We examine the technical elements of your website, from
                    hosting to structure, to ensure that it meets Google's
                    quality standards.We examine the technical elements of your
                    website, from hosting to structure, to ensure that it meets
                    Google's quality standards.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[3]} alt="Ex Team icon" />
                  <h4>Content Development</h4>
                  <p>
                    Our content writers and SEO experts work together to develop
                    content that resonates with your audience and drives search
                    engine visibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[4]} alt="Ex Team icon" />
                  <h4>On-Page Optimization</h4>
                  <p>
                    We make sure that each page of your website is optimized to
                    achieve the highest visibility in search engine rankings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[5]} alt="Ex Team icon" />
                  <h4>Link Building </h4>
                  <p>
                    We walk the extra mile to identify link-building
                    opportunities, acquire links, and monitor the performance of
                    external links.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[6]} alt="Ex Team icon" />
                  <h4>Reporting and Analysis </h4>
                  <p>
                    We provide reports and insights to help you understand how
                    our strategy is helping you reach your goals
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-5">
              <div className="experienced_seo_team text-center">
                <div className="seo_team_wrap ">
                  <img src={ExTeamIconImgs[7]} alt="Ex Team icon" />
                  <h4>Management and Adjustment</h4>
                  <p>
                    We continuously adjust our strategy based on data and
                    insights from previous steps, so you can reach the desired
                    result faster in Maryland.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default TopSeoNevada;
