import React, { Fragment } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// import FormImg from "../../ImagesComponents/bannerImg/form_img.png";
function ServiceForm() {
  // const captchaRef = useRef(null);
  // const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false);

  // function onChange(value) {
  //   setIsCaptchaSuccess(true);
  //   console.log("captcha value: ", value);
  // }

  function onSubmit(type) {
    return () => {
      switch (type) {
        case "success":
          NotificationManager.success("Successfully Submitted");
          break;
        default:
        case " ":
      }
    };
  }

  return (
    <Fragment>
      <form
        className="ecom_form"
        action="https://beta.ezmarketingtech.com/service-form"
        method="POST"
        onSubmit={onSubmit("success")}
      >
        <div className="row mb-3">
          <input
            className="from-control"
            type="hidden"
            id="key"
            name="key"
            value={window.location.pathname.slice(1)}
          />
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              type="text"
              name="name"
              className="form-control ecom_name_field"
              placeholder="Full Name"
              required={true}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              type="text"
              name="subject"
              className="form-control ecom_url_field"
              placeholder="Website URL"
              required={true}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              type="email"
              name="email"
              className="form-control ecom_email_field"
              placeholder="Email"
              required={true}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              type="tel"
              name="phone"
              className="form-control ecom_tel_field"
              placeholder="Phone Number"
              required={true}
            />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              type="text"
              name="message"
              className="form-control ecom_email_field"
              placeholder="Select Budget "
              required={true}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              name="date"
              className="form-control ecom_date_field"
              placeholder="How soon do you want to start?"
              onFocus={(e) => (e.currentTarget.type = "date")}
              required={true}
            />
          </div>
        </div>
        <NotificationContainer />
        <div className="captcha-btn recaptcha_group">
          {/* <ReCAPTCHA
            sitekey="6LfYM_oUAAAAAAkkme8vL33ls2GEqWhU3ZRCNX4C"
            onChange={onChange}
            className="recaptcha"
            ref={captchaRef}
          /> */}
          <button className="readon submit new">Submit Now</button>
        </div>
      </form>
    </Fragment>
  );
}

export default ServiceForm;
