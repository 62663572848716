import React, { Fragment } from "react";
// import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

let certifiedImgs = [
  "https://ezmarketingtech.s3.amazonaws.com/img/SEO-Certified.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/Google-certified.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img3.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img4.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img5.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img2.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img6.webp",
];
let Imgs_small_sevice = [
  "https://ezmarketingtech.s3.amazonaws.com/img/SEO-Certified_mobile.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/Google-certified_mobile.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img_mobile.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img3_mobile.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img4_mobile.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img5_mobile.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img2_mobile.webp",
  "https://ezmarketingtech.s3.amazonaws.com/img/certified_img6_mobile.webp",
];
const HomeCertifid = () => {
  return (
    <Fragment>
      <section className="certified_section">
        <div className="container">
          <span className="sub-text ">certified</span>
          <h2>Awards & Certifications</h2>
          <OwlCarousel
            className="rs-carousel owl-carousel owl-certification"
            loop={true}
            margin={10}
            items={8}
            autoplay={true}
            autoplayHoverPause={true}
            responsive={{
              1: {
                items: 1,
              },
              576: {
                items: 1,
              },
              768: {
                items: 2,
              },
              992: {
                items: 3,
              },
            }}
          >
            <div className="blog-item">
              <div className="image-wrap">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcset={Imgs_small_sevice[0]}
                  />
                  <img
                    src={certifiedImgs[0]}
                    alt="img"
                    height="371"
                    width="520"
                  />
                </picture>
              </div>
            </div>
            <div className="blog-item">
              <div className="image-wrap">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcset={Imgs_small_sevice[1]}
                  />
                  <img
                    src={certifiedImgs[1]}
                    alt="img"
                    height="371"
                    width="520"
                  />
                </picture>
              </div>
            </div>
            <div className="blog-item">
              <div className="image-wrap">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcset={Imgs_small_sevice[2]}
                  />
                  <img
                    src={certifiedImgs[2]}
                    alt="img"
                    height="371"
                    width="520"
                  />
                </picture>
              </div>
            </div>
            <div className="blog-item">
              <div className="image-wrap">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcset={Imgs_small_sevice[3]}
                  />
                  <img
                    src={certifiedImgs[3]}
                    alt="img"
                    height="371"
                    width="520"
                  />
                </picture>
              </div>
            </div>
            <div className="blog-item">
              <div className="image-wrap">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcset={Imgs_small_sevice[4]}
                  />
                  <img
                    src={certifiedImgs[4]}
                    alt="img"
                    height="371"
                    width="520"
                  />
                </picture>
              </div>
            </div>
            <div className="blog-item">
              <div className="image-wrap">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcset={Imgs_small_sevice[5]}
                  />
                  <img
                    src={certifiedImgs[5]}
                    alt="img"
                    height="371"
                    width="520"
                  />
                </picture>
              </div>
            </div>
            <div className="blog-item">
              <div className="image-wrap">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcset={Imgs_small_sevice[6]}
                  />
                  <img
                    src={certifiedImgs[6]}
                    alt="img"
                    height="371"
                    width="520"
                  />
                </picture>
              </div>
            </div>
            <div className="blog-item">
              <div className="image-wrap">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcset={Imgs_small_sevice[7]}
                  />
                  <img
                    src={certifiedImgs[7]}
                    alt="img"
                    height="371"
                    width="520"
                  />
                </picture>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </Fragment>
  );
};

export default HomeCertifid;
