import React, { Fragment } from "react";

import OwlCarousel from "react-owl-carousel";

import { BsFillStarFill } from "react-icons/bs";

import DataGoogleClients from "./ReviewAllData";

function GoogleClientsDetail(val) {
  return (
    <div className="testimonial_cover">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="testimonial_wrapper">
            <span className="quote_img">
              <img src={val.GoogleQuoteImg} alt="img" />
            </span>

            <p>{val.GoogleQuoteText}</p>
            <ul className="reviewImg">
              <li className="review_client">
                <img src={val.GoogleReviewClientImgs} alt="img" />
                <h4>
                  {val.ReviewClientName}
                  <br />
                  <span>Local Guide</span>
                </h4>
              </li>
              <li className="review_star">
                <div className="star_wrapper">
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                </div>
                <h5>a month ago</h5>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const ReviewClientSlider = () => {
  return (
    <Fragment>
      <section className="review_client_sec review_google_sec">
        <div className="container">
          <h3>Our Client’s Review</h3>
          <p>
            Businesses around the world use our services to build a stronger
            brand image and enhance their global reputation.
            <br /> Our customers include thousands of professionals belonging to
            varied industries spread across 50+ countries.
          </p>
          <OwlCarousel
            className="rs-carousel owl-carousel review_client_carousel"
            loop={true}
            margin={10}
            nav={true}
            dots={false}
            items={10}
            autoplay={true}
            autoplayHoverPause={true}
            responsive={{
              1: {
                items: 1,
              },

              768: {
                items: 1,
              },
              992: {
                items: 2,
              },
              1200: {
                items: 3,
              },
            }}
          >
            {DataGoogleClients.map(GoogleClientsDetail)}
          </OwlCarousel>
          <div className="view_more_wrapper">
            <a
              href="https://www.google.com/maps/place/EZ+Marketing+Tech/@34.0880898,-118.3794525,17z/data=!3m1!4b1!4m6!3m5!1s0x80c2bbfe61556ac5:0x89f85f7bd9347997!8m2!3d34.0880898!4d-118.3794525!16s%2Fg%2F11bw2h12jx"
              target="_blank"
              rel="noopener noreferrer"
              className="view_more_link"
            >
              View More Reviews
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ReviewClientSlider;
