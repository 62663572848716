import React from "react";
import { RxCross2 } from "react-icons/rx";
import { BsCheckLg } from "react-icons/bs";
function PriceTable() {
  return (
    <section className="Packages_section">
      <div className="container">
        <h2>
          Compare Our Packages and Choose the Perfect Fit for Your Business
        </h2>
        <div className="table-responsive">
          <table className="table price_table_list table-bordered table-striped table-hover">
            <thead>
              <tr className="price_tr">
                <th></th>
                <th>
                  <h4>Starter Plan</h4>
                  <span>
                    $749<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Serious Plan</h4>
                  <span>
                    $1249<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Strategy Plan</h4>
                  <span>
                    $1749<sub>/Month</sub>
                  </span>
                </th>
                <th>
                  <h4>Steroid Plan</h4>
                  <span>
                    $2999<sub>/Month</sub>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="list_body">
              <tr>
                <td>
                  <strong>First Hand SEO Analysis</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>Pre-Optimization Website Analysis</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Competitor Analysis</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Keyword Research & Analysis</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Baseline Ranking Check</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Duplicate Content Check</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Google Penalty Check</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Backlink Analysis (If required)</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>

              <tr>
                <td>
                  <strong>On-Page Optimization Activities</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Website Canonical Check</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Title Tag Optimization</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>META Tags Optimization</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Heading Tags Optimization</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Image Alt Tags Optimization</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Content Optimization</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>SEO Friendly URL Setup</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Site Navigation Analysis</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>

              {/* <tr>
                <td>
                  <strong>Customer Support</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr> */}
              <tr>
                <td>
                  <span>404 Page Implementation</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Broken Links Check</span>
                </td>
                <td>$</td>
                <td>$</td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Website Speed Check</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Google Indexed Pages Check</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Robots.txt Creation</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Google XML Sitemap</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>HTML Sitemap Setup</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Google Webmasters Tool Setup</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Google Analytics Setup</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Structured Data Setup</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>On Site Blog Section Creation</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>On Site Blog Posting</span>
                </td>
                <td>$</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
              </tr>

              <tr>
                <td>
                  <strong>Off-Page Optimization Activities</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Search Engine Submission – Manual</span>
                </td>
                <td>5</td>
                <td>5</td>
                <td>8</td>
                <td>10</td>
              </tr>
              <tr>
                <td>
                  <span>Article Writing & Posting</span>
                </td>
                <td>1</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
              </tr>
              <tr>
                <td>
                  <span>Classified Submissions</span>
                </td>
                <td>4</td>
                <td>5</td>
                <td>8</td>
                <td>15</td>
              </tr>
              <tr>
                <td>
                  <span>Business Listing</span>
                </td>
                <td>1</td>
                <td>1</td>
                <td>2</td>
                <td>4</td>
              </tr>
              <tr>
                <td>
                  <span>Blog Creation(One-Time)</span>
                </td>
                <td>1</td>
                <td>1</td>
                <td>2</td>
                <td>4</td>
              </tr>
              <tr>
                <td>
                  <span>Blog Writing & Posting</span>
                </td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>5</td>
              </tr>
              <tr>
                <td>
                  <span>Blog Marketing</span>
                </td>
                <td>8</td>
                <td>12</td>
                <td>12</td>
                <td>25</td>
              </tr>

              <tr>
                <td>
                  <span>On-site Blog Marketing</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>10</td>
                <td>15</td>
              </tr>
              <tr>
                <td>
                  <span>Image sharing</span>
                </td>
                <td>3</td>
                <td>6</td>
                <td>5</td>
                <td>8</td>
              </tr>
              <tr>
                <td>
                  <span>PPT Submissions</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>1</td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  <span>PDF Submissions</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>1</td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  <span>Profile Creation</span>
                </td>
                <td>1</td>
                <td>1</td>
                <td>2</td>
                <td>4</td>
              </tr>
              <tr>
                <td>
                  <span>Product Listing</span>
                </td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>10</td>
              </tr>
              <tr>
                <td>
                  <span>Quora Or Reddit Posting</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>1</td>
                <td>4</td>
              </tr>
              <tr>
                <td>
                  <span>Podcast Submission</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>1</td>
                <td>2</td>
              </tr>

              <tr>
                <td>
                  <span>Infographic Submission</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>1</td>
              </tr>
              <tr>
                <td>
                  <span>Guest Post Free/Paid</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>1</td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  <span>GMB Creation (One-time)</span>
                </td>
                <td>$</td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>GMB Optimization (One-time)</span>
                </td>
                <td>$</td>
                <td>$</td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Video Marketing (if Client provides)</span>
                </td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>6</td>
              </tr>
              <tr>
                <td>
                  <span>Press Release</span>
                </td>
                <td>$</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
              </tr>
              <tr>
                <td>
                  <span>Others (Event /Coupon /Podcast)</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>
                  <span>Total Number Submission</span>
                </td>
                <td>20</td>
                <td>30</td>
                <td>50</td>
                <td>100</td>
              </tr>
              <tr>
                <td>
                  <strong>SMO Activities</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>

              <tr>
                <td>
                  <span>Facebook Profile Creation</span>
                </td>
                <td>
                  <RxCross2 className=" fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Facebook Fan Page Creation</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Facebook Posting & Sharing</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>4</td>
                <td>8</td>
                <td>16</td>
              </tr>
              <tr>
                <td>
                  <span>Instagram Business Profile Creation</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Creation of Interactive Branded Hashtag</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Instagram Posting</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>4</td>
                <td>8</td>
                <td>16</td>
              </tr>
              <tr>
                <td>
                  <span>Twitter Profile Creation</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
                <td>
                  <BsCheckLg />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Twitter Post</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>4</td>
                <td>8</td>
                <td>16</td>
              </tr>
              <tr>
                <td>
                  <span> Pinterest Account Creation/Management</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Pinterest Followers</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Updating Of Pin Boards</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>4</td>
                <td>8</td>
              </tr>
              <tr>
                <td>
                  <span>Pins (If Client Provides Images)</span>
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <RxCross2 className="fa-red" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Report</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>

              <tr>
                <td>
                  <span>Monthly Website Analytics Report</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Monthly Keywords Ranking Report</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Monthly Off Page Submission Report</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Backlink Submission (per month)</span>
                </td>
                <td>20</td>
                <td>30</td>
                <td>50</td>
                <td>100</td>
              </tr>
              <tr>
                <td>
                  <strong>Customer Support</strong>
                </td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
                <td className="border_ref"></td>
              </tr>
              <tr>
                <td>
                  <span>Email</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Phone</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td>
                  <span>Chat</span>
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
                <td>
                  <BsCheckLg className=" fa-right_check" />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/6oE7u4fBC26CgZG4gg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/eVacOocpqcLg9xe3cd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/3csbKkgFG5iO7p69AC"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
                <td>
                  <button className="by_plan_btn">
                    <a
                      href="https://buy.stripe.com/28o8y8blmbHc38Q3cf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy this plan
                    </a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default PriceTable;
