import React from "react";
// import HistoricImg from "../../ImagesComponents/all-images/historic_icon1.png";
// import HistoricImg2 from "../../ImagesComponents/all-images/historic_icon2.png";
// import HistoricImg3 from "../../ImagesComponents/all-images/historic_icon3.png";
let HistoricImg = [
  "https://ezmarketingtech.s3.amazonaws.com/img/TotalWebsite Traffic.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/TotalLeads.svg",
  "https://ezmarketingtech.s3.amazonaws.com/img/Top6Keywords.svg",
];
const HistoricalSEO = () => {
  return (
    <section className="historical_section">
      <div className="container">
        <div className="graidian_bg">
          <div className="historial_content">
            <h2>
              <span>Historic SEO Results</span>
            </h2>
            <p>
              EZ Marketing Tech enjoyed a historic year in 2019 helping of all
              sizes and industries with their SEO needs. Two of our core beliefs
              are{" "}
              <a
                href="https://www.ezmarketingtech.com/reviews"
                target="_blank"
                rel="noreferrer"
                className="go_url"
              >
                relationships and results,
              </a>{" "}
              and you can see with the following numbers how much impact we had
              for business. Here's a look at EZ Marketing Tech’s 2019 cumulative{" "}
              <a
                href="https://ezmarketingtech.com/reviews"
                target="_blank"
                rel="noreferrer"
                className="go_url"
              >
                SEO results
              </a>{" "}
              across all the client served.
            </p>
            <ul className="historic_list row">
              <li className="col-12 col-sm-6 col-md-4 col-lg-4">
                <img src={HistoricImg[0]} alt="img" />
                <strong>48,356,627</strong>
                <h4>Total Website Traffic</h4>
              </li>
              <li className="col-12 col-sm-6 col-md-4 col-lg-4">
                <img src={HistoricImg[1]} alt="img" />
                <strong>3,128,417</strong>
                <h4>Total Leads</h4>
              </li>
              <li className="col-12 col-sm-6 col-sm-my-3 col-md-4 col-lg-4">
                <img src={HistoricImg[2]} alt="img" />
                <strong>18,610</strong>
                <h4>Top 6 Keywords</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HistoricalSEO;
