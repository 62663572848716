import React, { Fragment } from "react";
import RaputationTabs from "./RaputationTabs";
import ServiceForm from "../ECommerceSeoComponents/ServiceForm";
const ReputationManagementBanner = () => {
  return (
    <Fragment>
      <section className="ecom_seo_banner content_ecom_seo_banner reputation_seo_banner">
        <h2>
          Safeguard Your Online Reputation with Our Fine-detailed <br />
          Online <span>Reputation Management Services</span>
        </h2>
        <p>
          Online Reputation Management (ORM) is a comprehensive approach that
          aims to prevent or address any issues that could harm a brand's online
          image. Negative reviews, for instance, can wreak havoc on your online
          business and hurt your daily sales operations if left unattended.
          That's where ORM comes in! By actively managing your online reputation
          and taking swift action against negative feedback, you can protect
          your brand and keep your customers happy.
        </p>
        <p>
          ORM is important for an online business to tackle malign practices and
          remarks about the brand and its products. These negative statements
          can significantly impact the shopping behavior of potential online
          buyers. In today's online marketplace, there are hundreds of
          competitors vying for attention in the same field. Some even go to
          great lengths to poach your loyal customers by slandering your brand.
          ORM is not just another online tool, but rather an essential practice
          for ensuring customer satisfaction and retaining clients.
        </p>

        <div className="ecom_form_wrapper">
          {/* <img src={FormImg} alt="img" /> */}
          <h3>REQUEST A QUOTE</h3>
          <ServiceForm/>
        </div>
        <div className="ecomm_concern social_media_ecomm_concern">
          <h3>Why Your Business Need Online Reputation Management</h3>
        </div>
        <RaputationTabs />
      </section>
    </Fragment>
  );
};

export default ReputationManagementBanner;
