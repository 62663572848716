import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import * as AllImages from "../../ImagesComponents/all-images";
import googlePartnerImg from "../../ImagesComponents/bannerImg/google_partner_gradient.svg";
import googlePartnerOverlayImg from "../../ImagesComponents/bannerImg/google_partner_color.svg";
import ShopifyGredientImg from "../../ImagesComponents/bannerImg/ShopifyGradient.svg";
import ShopifyGredientImgOverlayImg from "../../ImagesComponents/bannerImg/ShopifyColor.svg";
import HubSpotGradientImg from "../../ImagesComponents/bannerImg/HubspotGradient.svg";
import HubSpotOverlayImg from "../../ImagesComponents/bannerImg/HubspotColor.svg";
import SemrushGredientImg from "../../ImagesComponents/bannerImg/SemrushGradient.svg";
import SemrushGredientOverImg from "../../ImagesComponents/bannerImg/SemrushColor.svg";
import WordpressGredientImg from "../../ImagesComponents/bannerImg/WordpressGradient.svg";
import WordpressGredientOverlayImg from "../../ImagesComponents/bannerImg/WordpressColor.svg";
// let bannerPartner = [
//   "https://ezmarketingtech.s3.amazonaws.com/img/Google-Partner-Gradient.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/Google-partner-Logo.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/Shopify-Gredient.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/Shopify-overlay.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/HubSpot_Gradient.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/HubSpot_Logo.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/Semrush-Gredient.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/Semrush-Logo.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/Wordpress-Gredient.png",
//   "https://ezmarketingtech.s3.amazonaws.com/img/Wordpress-Logo.png",
// ];
const HomePartner = () => {
  return (
    <Fragment>
      <section className="home_partner_brand home-partner">
        <div className="container">
          <OwlCarousel
            className="rs-carousel owl-carousel owl-theme"
            loop={true}
            margin={10}
            items={5}
            autoplay={true}
            autoplayHoverPause={true}
            responsive={{
              1: {
                items: 1,
              },
              576: {
                items: 2,
              },
              768: {
                items: 3,
              },
              992: {
                items: 4,
              },
              1200: {
                items: 5,
              },
            }}
          >
            <div className="grid-figure">
              <div className="log-img">
                <NavLink to="/">
                  <img
                    src={ShopifyGredientImg}
                    className="partner_img_default"
                    alt="images"
                    width="300"
                    height="138"
                  />
                  {/* {itemsToRender} */}
                  <img
                    src={ShopifyGredientImgOverlayImg}
                    alt="img"
                    className="on_hover"
                    width="200"
                    height="92"
                  />
                </NavLink>
              </div>
            </div>
            <div className="grid-figure">
              <div className="log-img">
                <NavLink to="/">
                  <img
                    src={HubSpotGradientImg}
                    className="partner_img_default"
                    alt="images"
                    width="300"
                    height="138"
                  />
                  <img
                    src={HubSpotOverlayImg}
                    alt="img"
                    className="on_hover"
                    width="200"
                    height="92"
                  />
                </NavLink>
              </div>
            </div>
            <div className="grid-figure">
              <div className="log-img">
                <a
                  href="https://www.google.com/partners/agency?id=8867789388"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={googlePartnerImg}
                    className="partner_img_default"
                    alt="images"
                    width="300"
                    height="138"
                  />
                  <img
                    src={googlePartnerOverlayImg}
                    alt="img"
                    className="on_hover"
                    width="200"
                    height="92"
                  />
                </a>
              </div>
            </div>
            <div className="grid-figure">
              <div className="log-img">
                <NavLink to="/">
                  <img
                    src={SemrushGredientImg}
                    alt="images"
                    className="partner_img_default"
                    width="300"
                    height="138"
                  />
                  <img
                    src={SemrushGredientOverImg}
                    alt="img"
                    className="on_hover"
                    width="200"
                    height="92"
                  />
                </NavLink>
              </div>
            </div>
            <div className="grid-figure">
              <div className="log-img">
                <NavLink to="/">
                  <img
                    src={WordpressGredientImg}
                    className="partner_img_default"
                    alt="images"
                    width="300"
                    height="138"
                  />
                  <img
                    src={WordpressGredientOverlayImg}
                    alt="img"
                    className="on_hover"
                    width="300"
                    height="138"
                  />
                </NavLink>
              </div>
            </div>
            <div className="grid-figure">
              <div className="log-img">
                <NavLink to="/">
                  <img
                    src={ShopifyGredientImg}
                    className="partner_img_default"
                    alt="images"
                    width="300"
                    height="138"
                  />
                  <img
                    src={ShopifyGredientImgOverlayImg}
                    alt="img"
                    className="on_hover"
                    width="200"
                    height="92"
                  />
                </NavLink>
              </div>
            </div>
            <div className="grid-figure">
              <div className="log-img">
                <NavLink to="/">
                  <img
                    src={HubSpotGradientImg}
                    className="partner_img_default"
                    alt="images"
                    width="300"
                    height="138"
                  />
                  <img
                    src={HubSpotOverlayImg}
                    alt="img"
                    className="on_hover"
                    width="200"
                    height="92"
                  />
                </NavLink>
              </div>
            </div>
            <div className="grid-figure">
              <div className="log-img">
                <NavLink to="/">
                  <img
                    src={googlePartnerImg}
                    className="partner_img_default"
                    alt="images"
                    width="300"
                    height="138"
                  />
                  <img
                    src={googlePartnerOverlayImg}
                    alt="img"
                    className="on_hover"
                    width="200"
                    height="92"
                  />
                </NavLink>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </Fragment>
  );
};

export default HomePartner;
