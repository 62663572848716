// import best_selling_img1 from "../../ImagesComponents/all-images/best_selling_img1.png";
// import best_selling_img2 from "../../ImagesComponents/all-images/best_selling_img2.png";
// import best_selling_img3 from "../../ImagesComponents/all-images/best_selling_img3.png";
// import best_selling_img4 from "../../ImagesComponents/all-images/best_selling_img4.png";
// import best_selling_img5 from "../../ImagesComponents/all-images/best_selling_img5.png";
let best_selling_img = [
  "https://ezmarketingtech.s3.amazonaws.com/img/best_selling_img1.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/best_selling_img2.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/best_selling_img3.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/best_selling_img4.png",
  "https://ezmarketingtech.s3.amazonaws.com/img/best_selling_img5.png",
];
const BestSellingServices = () => {
  return (
    <section className="bset_selling_service_sec">
      <div className="container">
        <h3>
          <span>Best Selling Services</span>
        </h3>
        <div className="row m-0">
          <div className="col-12 col-sm-12 col-md-6 ai-center p-0 ">
            <div className="bset_selling_text">
              <h4>SEO (Search Engine Optimization)</h4>
              <p>
                SEO means Search Engine Optimization and is the process used to
                optimize a website's technical configuration, content relevance
                and link popularity so its pages can become easily findable,
                more relevant and popular towards user search queries, and as a
                consequence, search engines rank them better.
              </p>
              <a href="/">read more</a>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center p-0">
            <div className="bset_selling_figure">
              <img src={best_selling_img[0]} alt="img" width="100%" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center p-0">
            <div className="bset_selling_figure">
              <img src={best_selling_img[1]} alt="img" width="100%" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center p-0">
            <div className="bset_selling_text">
              <h4>PPC (Pay-Per-Click)</h4>
              <p>
                PPC or pay-per-click marketing is one approach of paid
                advertising which enables your website to rank higher and appear
                among the top search results. PPC or pay-per-click marketing is
                one approach of paid advertising which enables your website to
                rank higher and appear among the top search results.
              </p>
              <a href="/">read more</a>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center p-0">
            <div className="bset_selling_text">
              <h4>Content Marketing</h4>
              <p>
                Content marketing is a marketing strategy used to attract,
                engage, and retain an audience by creating and sharing relevant
                articles, videos, podcasts, and other media. This approach
                establishes expertise, promotes brand awareness, and keeps your
                business top of mind when it's time to buy what you sell.
              </p>
              <a href="/">read more</a>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center p-0">
            <div className="bset_selling_figure">
              <img src={best_selling_img[2]} alt="img" width="100%" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center  p-0">
            <div className="bset_selling_figure">
              <img src={best_selling_img[3]} alt="img" width="100%" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center  p-0">
            <div className="bset_selling_text">
              <h4>Amazon SEO</h4>
              <p>
                Amazon search engine optimization (SEO) is the process of
                optimizing product listings to help them rank higher in product
                searches on Amazon.com. A few simple steps can improve listings
                for product search, boost sales, and help shoppers find your
                brand.
              </p>
              <a href="/">read more</a>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center  p-0">
            <div className="bset_selling_text">
              <h4>Ecommerce SEO</h4>
              <p>
                What is ecommerce SEO? Ecommerce SEO is the search engine
                optimization of an online store. Writing long descriptions with
                relevant keywords on every product page is an example of an
                optimization. Obtaining backlinks from relevant websites can
                also improve your online store's ranking position.
              </p>
              <a href="/">read more</a>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ai-center p-0">
            <div className="bset_selling_figure">
              <img src={best_selling_img[4]} alt="img" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestSellingServices;
