import React, { Fragment } from "react";
// import { NavLink } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const LocationForm = () => {
  // const captchaRef = useRef(null);
  // const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false);

  // function onChange(value) {
  //   setIsCaptchaSuccess(true);
  //   console.log("captcha value: ", value);
  // }

  function onSubmit(type) {
    return () => {
      switch (type) {
        case "success":
          NotificationManager.success("Successfully Submitted");
          break;
        default:
        case " ":
      }
    };
  }
  return (
    <Fragment>
      <form
        className="location_form"
        id="location"
        method="POST"
        action="https://beta.ezmarketingtech.com/location"
        onSubmit={onSubmit("success")}
      >
        <p>
          We just need a little info to <span>Get started</span>
        </p>
        <div className="row">
          <input
            className="from-control"
            type="hidden"
            id="key"
            name="key"
            value={window.location.pathname.slice(1)}
          />

          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <select
              className="location-form-select"
              aria-label="Default select example"
              name="service"
              id="service"
              required={true}
            >
              <option selected>Select the services you need</option>
              <option value="Search Engine Optimization">
                Search Engine Optimization
              </option>
              <option value="Social Media Optimization">
                Social Media Optimization
              </option>
              <option value="Pay Per Click / Google Ads">
                Pay Per Click / Google Ads
              </option>
              <option value="Amazon Marketing">Amazon Marketing</option>
              <option value="Content Marketing">Content Marketing</option>
              <option value="Website Development">Website Development</option>
            </select>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              type="text"
              name="subject"
              id="subject"
              className="location_form_url form-control"
              placeholder="Website URL"
              required={true}
            ></input>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              type="email"
              name="email"
              id="email"
              className="location_form_email form-control"
              placeholder="Email"
              required={true}
            ></input>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <input
              type="tel"
              name="phone"
              id="phone"
              className="location_form_tel form-control"
              placeholder="Phone Number"
              required={true}
            ></input>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <textarea
              className="form-control location_text_area"
              name="message"
              id="message"
              rows="4"
              required={true}
              placeholder="Tell us about your business"
            ></textarea>
          </div>
          <NotificationContainer />
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
            {/* <ReCAPTCHA
              sitekey="6LfYM_oUAAAAAAkkme8vL33ls2GEqWhU3ZRCNX4C"
              onChange={onChange}
              className="recaptcha"
              ref={captchaRef}
            /> */}
            <button
              // disabled={!isCaptchaSuccessful}
              className="btn btn-md location_form_submit"
            >
              Get Started
            </button>
            <span className="hurry_up">
              In a hurry? Give us a call now
              <a href="tel:+1 (855) 781-2099"> at +1 (855) 781-2099</a>
            </span>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default LocationForm;
