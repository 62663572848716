import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import LocationForm from "../LocationPageComponents/LocationForm";
const LocationCaliforniaBanner = () => {
  return (
    <Fragment>
      <section className="banner_section location_banner">
        <div className="container">
          <div className="location_banner_wrap">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <div className="location_banner_text">
                  <h2>
                    Dominate California’s
                    <br />
                    Searches
                  </h2>
                  <p>
                    California is where digital meets culture and new
                    technologies become trends, and you’re looking for ways to
                    make the most out of it. Well, you have come to the right
                    place.
                    <br /> Put your Californian business on top of online
                    platforms with the help of a trusted 360-degree SEO agency —
                    EZ Marketing Tech.
                  </p>
                  <NavLink className="class_us" to="/contact-us">
                    {" "}
                    Contact Us
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                <div className="location_proposal_wrap">
                  <h3>GET MY FREE PROPOSAL</h3>
                  <LocationForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default LocationCaliforniaBanner;
